import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'htmlToPlainText' })
export class HtmlToPlainTextPipe implements PipeTransform {
  public transform(valor: string): string {
    if (!valor) {
      return valor;
    }

    let withoutHtml = valor.replace(/ +(?= )/g, '');
    withoutHtml = withoutHtml.replace(/<[a-zA-Z][^>]*>.*?<\/\1>/g, ' ');

    return withoutHtml;
  }
}
