import { Directive, HostListener } from '@angular/core';

/**
 * Allows the aside to be toggled via click.
 */
@Directive({
  selector: '[appAsideMenuToggler]',
})
export class AsideToggleDirective {
  constructor() {
    /*Empty*/
  }

  @HostListener('click', ['$event'])
  public toggleOpen($event: any) {
    $event.preventDefault();
    if (document.querySelector('app-root')) {
      document.querySelector('app-root').classList.toggle('aside-menu-hidden');
    }
  }
}
