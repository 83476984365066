import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RespuestaRecuperarContrasena } from '@app-models/login.interface';
import { LOGIN } from '@app-services/constantes-login';
import { IdentificarError } from '@app-services/utils/identifyError.service';
import { IResponseSingle } from '@app-shared/models/IResponse';
import { LoadingService } from '@app-shared/services/loading.service';
import { MessagesMainService } from '@app-shared/services/messages-main.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoginExternoService } from '@app-services/login-externo.service';

@Component({
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./../login/login.component.scss'],
})
export class RecuperarContrasenaComponent implements OnInit, OnDestroy {
  /**
   * Formulario reactivo de inicio de sesión externo.
   */
  public formRecuperar: FormGroup;
  /**
   * BehaviorSubject para manejo de loading asincrono.
   */
  public loading$ = new BehaviorSubject(false);
  /**
   * Subject para hacer desubscripciones automáticas de observables.
   */
  private unsubscriptor$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private messagesMainService: MessagesMainService,
    private loginExternoService: LoginExternoService,
    private loadingService: LoadingService
  ) {}

  /**
   * Getter de formcontrol usuario.
   */
  get Usuario() {
    return this.formRecuperar.get('usuario');
  }

  public ngOnInit(): void {
    this.construirFormualrio();
  }

  public ngOnDestroy(): void {
    this.unsubscriptor$.next();
    this.unsubscriptor$.complete();
  }

  /**
   * Verifica si el usuario es interno
   * @param usuario
   * @returns boolean
   */
  public validarUsuarioCoppel(usuario: string): boolean {
    const regex = /@coppel\.com/;
    return regex.test(usuario);
  }

  /**
   * Ejecuta la recuperación de la contraseña.
   */
  public recuperarContrasena(e: Event): void {
    e.preventDefault();
    if (this.formRecuperar.valid) {
      try {
        if (this.validarUsuarioCoppel(this.Usuario.value)) {
          this.messagesMainService.messageWarning(LOGIN.MENSAJES.ALERTAS.REESTABLECER_PASSWORD_USUARIO_COPPEL);
        } else {
          const loading = this.loadingService.trigger();

          this.loginExternoService
            .recuperarPassword(this.Usuario?.value)
            .pipe(
              finalize(() => loading()),
              takeUntil(this.unsubscriptor$)
            )
            .subscribe(
              (respuesta: IResponseSingle<RespuestaRecuperarContrasena>) => {
                if (!respuesta && !respuesta.estado) {
                  this.messagesMainService.messageWarning(respuesta?.error?.mensaje);
                  return;
                }
                this.messagesMainService.messageSuccess('El Correo de Recuperación Ha Sido Enviado con Éxito');
              },
              (error) => {
                this.messagesMainService.messageError(error?.error?.error?.mensaje);
              }
            );
        }
      } catch (ex) {
        this.loading$.next(false);
        this.messagesMainService.messageError(IdentificarError.ObtenerMensaje(ex));
      }
    } else {
      this.formRecuperar.markAllAsTouched();
    }
  }

  /**
   * Construye formulario de inicio de sesión.
   */
  private construirFormualrio(): void {
    this.formRecuperar = this.formBuilder.group({
      usuario: new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(LOGIN.VALIDADORES.EMAIL),
      ]),
    });
  }
}
