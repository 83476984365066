import { Injectable } from '@angular/core';
import { Errors } from '@app-models/errors.interface';
import { MensajesGenerales } from '@app-services/constantes';
import { IdentificarError } from '@app-services/utils/identifyError.service';
import { IResponseList, IResponseSingle } from '@app-shared/models/IResponse';
import { GeneralResponse } from '@app-shared/models/general.interface';
import { Toast } from '@app-shared/services';
import { ToasterService } from '@app-toaster';

type MessageType = 'success' | 'warn' | 'warning' | 'error';

/**
 * Servicio para gestionar los toast en el sistema.
 */
@Injectable({
  providedIn: 'root',
})
export class MessagesNewMainService {
  /**
   * Estado de si está mostrando una alerta tipo error desde interceptor.
   */
  private _mostrandoMensajeErrorInterceptor = false;

  /**
   * @inheritdoc
   * @param toasterServ
   */
  public constructor(public toasterServ: ToasterService) {}

  /**
   * Getter para _mostrandoMensajeErrorInterceptor
   */
  public get getEstadoMensajeInterceptor(): boolean {
    return this._mostrandoMensajeErrorInterceptor;
  }

  /**
   * Setter para _mostrandoMensajeErrorInterceptor
   */
  public set setEstadoMensajeInterceptor(mostrandoMensaje: boolean) {
    this._mostrandoMensajeErrorInterceptor = mostrandoMensaje;
  }

  /**
   * Valida la respuesta de un servicio de tipo IResponseList y muestra una alerta en sistema
   * @param respuesta respuesta de un servicio
   */
  public messageToastResponseList(respuesta: IResponseList<GeneralResponse>) {
    if (respuesta.estado) {
      const toaster: Toast = {
        type: 'success',
        title: MensajesGenerales.INFO.BIEN_HECHO,
        body: MensajesGenerales.INFO.CAMBIOS_GUARDADOS,
      };
      this.toasterServ.showApproval(toaster.body, toaster.title);
    } else {
      const toaster: Toast = {
        type: 'warn',
        title: MensajesGenerales.ADVERTENCIA.SALIO_MAL,
        body: respuesta.error.mensaje,
      };
      this.toasterServ.showWarn(toaster.body, toaster.title);
    }
  }

  /**
   * Valida la respuesta de un servicio de tipo IResponseSingle y muestra una alerta en sistema
   * @param respuesta respuesta de un servicio
   */
  public messageToastResponseSingle(respuesta: IResponseSingle<any>) {
    if (respuesta.estado) {
      const toaster: Toast = {
        type: 'success',
        title: MensajesGenerales.INFO.BIEN_HECHO,
        body: MensajesGenerales.INFO.CAMBIOS_GUARDADOS,
      };
      this.toasterServ.showApproval(toaster.body, toaster.title);
    } else {
      const toaster: Toast = {
        type: 'warning',
        title: MensajesGenerales.ADVERTENCIA.SALIO_MAL,
        body: respuesta.error.mensaje,
      };
      this.toasterServ.showWarn(toaster.body, toaster.title);
    }
  }

  /**
   * Detecta el tipo de alerta a ejecutar y la muestra
   * @param type tipo de mensaje a mostrar
   * @param message Mensaje a mostrar en la alerta
   * @param title Titulo opcional para el mensaje.
   */
  public messageSimple(type: MessageType, message?: string, title?: string) {
    let messageToast: string;
    let sumaryToast: string;
    switch (type) {
      case 'success':
        messageToast = message ?? MensajesGenerales.INFO.CAMBIOS_GUARDADOS;
        sumaryToast = title ?? MensajesGenerales.INFO.BIEN_HECHO;
        this.toasterServ.showApproval(messageToast, sumaryToast);
        return;
      case 'warn':
      case 'warning':
        sumaryToast = title ?? MensajesGenerales.ADVERTENCIA.SALIO_MAL;
        if (message) {
          messageToast = message;
        }
        this.toasterServ.showWarn(messageToast, sumaryToast);
        return;
      case 'error':
        sumaryToast = title ?? MensajesGenerales.ADVERTENCIA.SALIO_MAL;
        if (message) {
          messageToast = message;
        }
        this.toasterServ.showError(messageToast, sumaryToast);
        return;
      default:
        this.toasterServ.showInfo(messageToast, sumaryToast);
        return;
    }
  }

  /**
   * Ejecuta un mensaje de error
   * @param info  - Mensaje a mostrar
   * @param title Titulo opcional para el mensaje.
   * @param callbackOnHide - Funcion para ejecutar cuando se cierra el toast.
   * @param callbackOnShow - Funcion para ejecutar cuando se abre el toast.
   */
  public messageError(info: string, title?: string, callbackOnHide?: () => any, callbackOnShow?: () => any) {
    const toaster: Toast = {
      type: 'error',
      title: title ?? MensajesGenerales.ADVERTENCIA.SALIO_MAL,
      body: info,
      // onHideCallback: () => (callbackOnHide ? callbackOnHide() : null),
      // onShowCallback: () => (callbackOnShow ? callbackOnShow() : null),
    };
    this.toasterServ.showError(toaster.body, toaster.title);
  }

  /**
   * Ejecuta un mensaje de error desde un error.
   */
  public messageFromError(e: Error) {
    this.messageError(IdentificarError.ObtenerMensaje(e as Errors));
  }

  /**
   * Ejecuta un mensaje de advertencia
   * @param info  - Mensaje a mostrar
   * @param title Titulo opcional para el mensaje.
   */
  public messageWarning(info: string, title?: string) {
    const toaster: Toast = {
      type: 'warning',
      title: title ?? MensajesGenerales.ADVERTENCIA.SALIO_MAL,
      body: info,
    };
    this.toasterServ.showWarn(toaster.body, toaster.title);
  }

  /**
   * Ejecuta un mensaje de exito
   * @param info  - Mensaje a mostrar
   * @param title Titulo opcional para el mensaje.
   */
  public messageSuccess(info: string, title?: string) {
    const toaster: Toast = {
      type: 'success',
      title: title ?? 'Mensaje',
      body: info,
    };
    this.toasterServ.showApproval(toaster.body, toaster.title);
  }

  /**
   * Ejecuta un mensaje de información
   * @param info  - Mensaje a mostrar
   * @param title Titulo opcional para el mensaje.
   */
  public messageInfo(info: string, title?: string) {
    const toaster: Toast = {
      type: 'info',
      title: title ?? MensajesGenerales.ADVERTENCIA.SALIO_MAL,
      body: info,
    };
    this.toasterServ.showInfo(toaster.body, toaster.title);
  }
}
