<div class="form-group">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <!-- Field -->
  <p-calendar
    [ngClass]="fieldConfig?.class"
    class="ng-dirty !ng-invalid form-control"
    [style]="{ width: '100%' }"
    [name]="fieldConfig.name"
    [inputId]="id"
    [formControl]="control"
    [placeholder]="placeholder"
    [style]="fieldConfig?.style"
    [styleClass]="fieldConfig?.styleClass"
    [defaultDate]="fieldConfig?.defaultDate"
    [dateFormat]="dateFormat"
    [minDate]="fieldConfig?.minDate"
    [maxDate]="fieldConfig?.maxDate"
    [inline]="fieldConfig?.inline"
    [showIcon]="showIcon"
    [icon]="icon"
    [readonlyInput]="fieldConfig?.readonlyInput"
    [disabledDates]="fieldConfig?.disabledDates"
    [disabledDays]="fieldConfig?.disabledDays"
    [showTime]="fieldConfig?.showTime"
    [selectionMode]="selectionMode"
    [yearNavigator]="true"
    [monthNavigator]="true"
    showButtonBar="true"
    [yearRange]="yearRange"
    (onFocus)="onFocus($event)"
    (onBlur)="onBlur($event)"
    (onInput)="onChange($event)"
    (onSelect)="onChange($event)"
    appendTo="body"
    [disabled]="fieldConfig?.disabled"
  ></p-calendar>

  <!-- Validations -->
  <div class="text-danger" *ngIf="!control.valid">
    <div *ngIf="control.errors?.required">{{ mensajeCampoRequerido }}</div>

    <ng-container *ngFor="let error of errors">
      <div>{{ error }}</div>
    </ng-container>
  </div>
</div>
