import { Injectable } from '@angular/core';
import { IResponseSingle } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsuarioRolDTO } from './usuarios';

@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  /**
   * Constructor de clase `UsuariosService`.
   */
  constructor(private baseService: BaseService) {}

  /**
   * Retorna el listado de roles por usuario.
   */
  public getUsuarioActual(): Observable<IResponseSingle<UsuarioRolDTO>> {
    return this.baseService.get<IResponseSingle<UsuarioRolDTO>>(`${environment.backendURL}/usuarios/actual`);
  }

  public obtenerUsuarioPorNumeroColaborador(numeroColaborador: string): Observable<any> {
    return this.baseService.post<IResponseSingle<any>>(
      `${environment.backendURL}/usuarios/usuarioPorNumeroColaborador`,
      { numeroColaborador }
    );
  }
}
