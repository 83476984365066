import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { AbstractHelperComponent } from '@app-shared/generic-components/components/abstract-helper-component/abstract-helper.component';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { GenericTypedField, GenericTypedInput } from './generic-form.models';

/**
 * Componente que gestiona la autoconstrucción de formularios.
 */
@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
  providers: [GenericFormService],
})
export class GenericFormComponent extends AbstractHelperComponent implements OnInit, OnDestroy {
  /**
   * Retorna un booleano indicando si se debe omitir el fieldset.
   */
  public omitFieldSetValue: boolean;

  /**
   * Configuración base del formulario.
   */
  @Input()
  public config: GenericTypedField[];

  /**
   * Emite un objeto con los datos emitidos por el formulario.
   */
  @Output()
  public submit: EventEmitter<Record<string, object>> = new EventEmitter();

  /**
   * Emite un evento con el form group usado por el componente.
   */
  @Output()
  public form: EventEmitter<FormGroup> = new EventEmitter();

  /***
   * Form group externo para creacion del formulario.
   */
  @Input()
  public formGroup: FormGroup;

  /**
   * Objeto del tipo `AbstractControlOptions` con opciones para la creacion del formulario.
   */
  @Input()
  public groupOptions: AbstractControlOptions;

  /**
   * Fieldset contenedor del formulario.
   */
  @ViewChild('fieldset', { static: false })
  public fieldset: ElementRef<HTMLFieldSetElement>;

  /**
   * Form group base para el formulario.
   */
  public instance: FormGroup;

  /**
   * Booleano que indica si el componente se ha inicializado.
   */
  public initialized: boolean;

  /**
   * Booleano que indica si el fieldset esta deshabilitado.
   */
  public disabledValue = false;

  /**
   * Constructor de `GenericFormComponent`.
   */
  public constructor(
    public genericFormService: GenericFormService,
    protected formBuilder: FormBuilder,
    protected injector: Injector
  ) {
    super(injector);
  }

  /**
   * Setter de booleano que indica si el botón es `small`.
   */
  @Input('omitFieldSet')
  public set omitFieldSet(attribute: boolean | '') {
    this.omitFieldSetValue = attribute === '' || attribute;
  }

  /**
   * Booleano que indica si se debe prevenir el submit.
   */
  public get disableSubmit(): boolean {
    return this.genericFormService.preventSubmit;
  }

  /**
   * Booleano que indica si el fieldset esta deshabilitado.
   */
  public get disableFieldset(): boolean {
    return this.disabledValue;
  }

  /**
   * Booleano que indica si se debe de prevenir el submit del formulario.
   */
  public get preventSubmit(): boolean {
    return this.genericFormService.preventSubmit;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.instance = this.formGroup ?? this.formBuilder.group({}, this.groupOptions ?? null);
    this.form.next(this.instance);

    this.genericFormService.setFormGroup(this.instance);

    this.initialized = true;
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Retorna la clase con width para la columna.
   */
  public getColWidth(input: GenericTypedInput): string {
    return input.colWidth ? `col-md-${input.colWidth}` : `col-md-4`;
  }

  /**
   * Handler para submit.
   */
  public onSubmit(form: FormGroupDirective) {
    this.submit.next(form.value);
  }

  /**
   * Deshabilita el formulario contenido.
   */
  public disable(preventSubmit = true): void {
    this.disabledValue = true;

    this.genericFormService.disable(true, false);

    if (preventSubmit) {
      this.genericFormService.doPreventSubmit(true);
    }
  }

  /**
   * Deshabilita el formulario contenido.
   */
  public enable(enableSubmit = true): void {
    this.disabledValue = false;

    this.genericFormService.disable(false, false);

    if (enableSubmit) {
      this.genericFormService.doPreventSubmit(false);
    }
  }
}
