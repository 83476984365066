import { Injectable } from '@angular/core';
import { IResponseList } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Destinos } from './destinos';

@Injectable({
  providedIn: 'root',
})
export class DestinosService {
  constructor(private baseService: BaseService) {}

  public obtenerDestinos(): Observable<IResponseList<Destinos>> {
    return this.baseService.get<IResponseList<Destinos>>(`${environment.backendURL}/destinos/`);
  }
}
