export const LOGIN = {
  VALIDADORES: {
    EMAIL: '[a-zA-Z0-9-_]+([.][a-zA-Z0-9-_]+)*@[a-zA-Z0-9_\\-]+([.][a-zA-Z0-9_\\-]+)*[.][a-zA-Z]{1,5}',
    PSD: '^(?=.*[.;,!@#$%&\\]\\[\\/()_\\-=+~*])(?!.*(.)\\1)(?=.*[a-z])(?=.*[A-Z])((?![cC][oO][pP][pP][eE][lL]).){8,}$',
    // Valida la presencia de al menos una mayuscula y minuscula
    PSD_MAYUSCULA_MINUSCULA: '(?=.*[A-Z])(?=.*[a-z])\\S{2,}',
    // Valida la presencia de al menos un numero
    PSD_NUMERO: '(?=.*\\d)\\S{1,}',
    // Valida la presencia de al menos un caracter especial
    PSD_CARACTER_ESPECIAL: '(?=.*[.;,!@#$%&\\]\\[\\/()_\\-=+~*])\\S{1,}',
    // Valida la presencia de la palabra coppel
    PSD_COPPEL: '(?=[cC][oO][pP][pP][eE][lL])\\S{1,}',
    // Valida la presencia de dos o mas caracteres iguales consecutivos
    PSD_CARACTERES_CONSECUTIVOS: '(?=.*(.)\\1)\\S{1,}',
  },
  MENSAJES: {
    ALERTAS: {
      REESTABLECER_PASSWORD_NO_COINCIDE: 'Las contraseñas capturadas no coinciden',
      REESTABLECER_PASSWORD_USUARIO_COPPEL: 'Contacte al Administrador del sistema para restablecer su contraseña',
      ERROR_LOGIN: 'La dirección de correo electrónico o la contraseña son incorrectas.',
    },
  },
};
