import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MensajesGenerales } from '@app-services/constantes';

@Directive({ selector: '[appRestringirTipo]' })
export class RestringirTipoDirective {
  public static readonly rfcValidation = '^[a-zA-Z]{3,4}[0-9]{6}[0-9a-zA-Z]{3}$';

  @Input()
  public conDecimales = false;

  @Input()
  public conNegativos = false;

  @Input()
  public separadorDecimal = '.';

  @Input()
  public permitir = 'numeros';

  @Input()
  public canCut = true;

  @Input()
  public canCopy = true;

  @Input()
  public canPaste = true;

  @Input()
  public iniciaConCero = false;

  public valorAnterior = '';

  // --------------------------------------
  //  Regular expressions
  public enteroSinSigno = '^[0-9]*$';
  public enteroConSigno = '^-?[0-9]+$';
  public decimalSinSigno = '^[0-9]+(.[0-9]+)?$';
  public decimalConSigno = '^-?[0-9]+(.[0-9]+)?$';
  public soloLetras = '^[a-zA-ZÀ-ÿñÑ ]*$';
  public alfanumerico = '^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚs ]*$';
  public email = '^[a-zA-Z0-9-_@.]*$';
  public todo = '(.|\n|\r)';
  public letrasGuionMedio = '^[a-zA-ZÀ-ÿ-\u00f1\u00d1 ]+$';
  public letrasNumerosGuionMedio = '^[a-zA-Z0-9À-ÿ-\u00f1\u00d1 ]+$';
  public letrasGuionPuntoComa = '^[a-zA-ZÀ-ÿ-.,\u00f1\u00d1 ]+$';
  public letrasNumerosGuionHashtagComa = '^[a-zA-Z0-9À-ÿ-#,\u00f1\u00d1 ]+$';
  public monto = '^(([1-9]\\d*)|([0]{1}))(\\.\\d{0,2})?$';
  public M2 = '^(([1-9]\\d*)|([0]{1}))(\\.\\d{0,2})?$';
  public porcentaje = '^[0-9]*$';
  public alfanumericoCaracteresEspecialesTipo1 =
    '^[a-zA-Z0-9áéíóúÁÉÍÓÚ\\,\\:\\;\\"\\(\\)\\¿\\?\\¡\\!\\.\\-\u00f1\u00d1 ]*$';
  // Acepta numeros, letras y los siguientes caracteres especiales  , . " " ( ) ¿ ? ¡ ! ñ - : ;
  public alfanumericoCaracteresEspecialesTipo2 =
    '^[a-zA-Z0-9áéíóúÁÉÍÓÚ\\,\\:\\;\\"\\(\\)\\¿\\?\\¡\\!\\.\\-\\_\u00f1\u00d1 ]*$';
  // Acepta numeros, letras y los siguientes caracteres especiales  , . " " ( ) ¿ ? ¡ ! ñ - : ; _
  public mayorACero = '^[1-9][0-9]*$';
  public alfanumericoPuntoGuionMedio = '^[a-zA-Z0-9áéíóúÁÉÍÓÚ\\.\\-\u00f1\u00d1 ]*$';
  public alfanumericoPuntoComa = '^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\\.\\,\u00f1\u00d1 ]*$';

  public readonly alfaNumericoRFC = '^[a-zA-Z0-9]*$';

  /**
   * Restringir tipo para colindancias.
   */
  public readonly alfanumericoColindancias = '^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚs\\/\\-\\# ]*$';

  constructor(private hostElement: ElementRef, @Optional() private control: NgControl) {}

  @HostListener('paste', ['$event'])
  public onPaste(e) {
    if (!this.canPaste) {
      e.preventDefault();
    } else {
      // obtener info del clipboard
      const value = e.clipboardData.getData('text/plain');
      this.validateValue(value, e);
    }
  }

  @HostListener('copy', ['$event'])
  public onCopy(e) {
    if (!this.canCopy) {
      e.preventDefault();
    }
  }

  @HostListener('cut', ['$event'])
  public onCut(e) {
    if (!this.canCut) {
      e.preventDefault();
    }
  }

  @HostListener('blur')
  public onblur() {
    if (this.permitir === 'porcentaje') {
      let valorPorcentaje = this.hostElement.nativeElement.value;
      if (valorPorcentaje !== '') {
        valorPorcentaje = this.hostElement.nativeElement.value.replace('%', '');
        this.hostElement.nativeElement.value = valorPorcentaje + '%';
      }
    }
  }

  private separatorIsCloseToSign(existeSigno, cursorPosition) {
    return existeSigno && cursorPosition <= 1;
  }

  private isAllowingNonNumeric(teclasPermitidas, key, controlOrCommand): boolean {
    return (
      teclasPermitidas.indexOf(key) !== -1 ||
      // Allow: Ctrl+A and Command+A
      (key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === 'x' && controlOrCommand)
    );
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent) {
    const cursorPosition: number = e.target['selectionStart'];
    const valorOriginal: string = e.target['value'];
    const key: string = this.getName(e);
    const controlOrCommand = e.ctrlKey || e.metaKey;
    const existeSigno = valorOriginal.includes('-');
    const existeSeparador = valorOriginal.includes(this.separadorDecimal);

    // allowed keys apart from numeric characters
    const teclasPermitidas = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'];

    // when decimals are allowed, add
    // decimal separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    const separatorIsCloseToSign = this.separatorIsCloseToSign(existeSigno, cursorPosition);
    if (this.conDecimales && !separatorIsCloseToSign && !existeSeparador) {
      teclasPermitidas.push(this.separadorDecimal === '.' ? '.' : ',');
    }
    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    const primerCaracterIsSeparator = valorOriginal.charAt(0) !== this.separadorDecimal;
    if (this.conNegativos && !existeSigno && primerCaracterIsSeparator && cursorPosition === 0) {
      teclasPermitidas.push('-');
    }

    // allow some non-numeric characters
    if (this.isAllowingNonNumeric(teclasPermitidas, key, controlOrCommand)) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.valorAnterior = valorOriginal;

    const regEx = this.obtenerRegex(e);

    setTimeout(() => {
      // Reemplazamos valores que no coincidan con el REGEX
      // Necesario por que algunos valores ingresados mediante SHIFT no eran prevenidos
      // Ya que la tecla a prevenir no era la combinacion si no el valor SHIFT.
      // O caracteres autogenerados por dobles iteraciones de tecla como: "^
      const replaceValue = this.hostElement.nativeElement.value;
      this.hostElement.nativeElement.value = replaceValue
        .split('')
        .filter((i) => new RegExp(regEx).test(i))
        .join('');
    });

    const valorATestear = this.permitir === 'mayorACero' ? valorOriginal + key : key;
    const isValid = new RegExp(regEx).test(valorATestear);

    if (!this.iniciaConCero && this.permitir === 'numeros' && cursorPosition <= 0 && key === '0') {
      e.preventDefault();
      return;
    }
    if (this.permitir === 'numeros' && Number.isFinite(Number(key))) {
      this.validateNumber(e);
    }
    if (isValid) {
      return;
    }

    e.preventDefault();
  }

  public validateNumber(event) {
    setTimeout(() => {
      this.hostElement.nativeElement.value = this.hostElement.nativeElement.value
        .replace(/[^0-9 ]/g, '')
        .replace(/\s/g, '');
      event.preventDefault();
    }, 100);
  }

  public validateValue(value: string, e: any): void {
    value = value?.toString()?.trim();

    let regEx: string;
    switch (this.permitir) {
      case 'numeros':
        regEx = this.getRegex();
        break;
      case 'letrasGuionMedio':
        regEx = this.letrasGuionMedio;
        break;
      case 'letrasNumerosGuionMedio':
        regEx = this.letrasNumerosGuionMedio;
        break;
      case 'letrasGuionPuntoComa':
        regEx = this.letrasGuionPuntoComa;
        break;
      case 'letrasNumerosGuionHashtagComa':
        regEx = this.letrasNumerosGuionHashtagComa;
        break;
      case 'monto':
        regEx = this.monto;
        break;
      case 'M2':
        regEx = this.M2;
        break;
      case 'porcentaje':
        regEx = this.porcentaje;
        break;
      case 'letras':
        regEx = this.soloLetras;
        break;
      case 'email':
        regEx = this.email;
        break;
      case 'alfanumerico':
        regEx = this.alfanumerico;
        break;
      case 'todo':
        regEx = this.todo;
        break;
      case 'alfanumericoCaracteresEspecialesTipo1':
        regEx = this.alfanumericoCaracteresEspecialesTipo1;
        break;
      case 'alfanumericoCaracteresEspecialesTipo2':
        regEx = this.alfanumericoCaracteresEspecialesTipo2;
        break;
      case 'alfanumericoPuntoGuionMedio':
        regEx = this.alfanumericoPuntoGuionMedio;
        break;
      case 'mayorACero':
        regEx = this.mayorACero;
        break;
      case 'rfc':
        regEx = RestringirTipoDirective.rfcValidation;
        break;
      case 'alfanumericoPuntoComa':
        regEx = this.alfanumericoPuntoComa;
        break;
      default:
        e.preventDefault();
        break;
    }
    const valid: boolean = new RegExp(regEx).test(value);
    if (!this.iniciaConCero && this.permitir === 'numeros' && value.substring(0, 1) === '0') {
      e.preventDefault();
    } else if (valid) {
      setTimeout(() => (this.hostElement.nativeElement.value = value));
      return;
    } else {
      e.preventDefault();

      // En el caso del pegado de texto, si es inválido limpiamos el campo y mostramos un error.
      if (this.control && e?.type === 'paste') {
        this.control?.control?.setErrors({ paste: MensajesGenerales.VALIDACIONES.INFORMACION_INVALIDA });
        setTimeout(() => (this.hostElement.nativeElement.value = ''));
      }
    }
  }

  public getRegex(): any {
    let resp = '';

    if (!this.conDecimales && !this.conNegativos) {
      resp = this.enteroSinSigno;
    }
    if (!this.conDecimales && this.conNegativos) {
      resp = this.enteroConSigno;
    }
    if (this.conDecimales && !this.conNegativos) {
      resp = this.decimalSinSigno;
    }
    if (this.conDecimales && this.conNegativos) {
      resp = this.decimalConSigno;
    }
    return resp;
  }

  public getName(e: any): string {
    if (e.key) {
      return e.key;
    }
    // for old browsers
    if (e.keyCode && String.fromCharCode) {
      switch (e.keyCode) {
        case 8:
          return 'Backspace';
        case 9:
          return 'Tab';
        case 27:
          return 'Escape';
        case 37:
          return 'ArrowLeft';
        case 39:
          return 'ArrowRight';
        case 188:
          return ',';
        case 190:
          return '.';
        case 109:
          return '-';
        case 173:
          return '-';
        case 189:
          return '-';
        default:
          return String.fromCharCode(e.keyCode);
      }
    }
    return '';
  }

  private obtenerRegex(e) {
    let regEx: string;
    switch (this.permitir) {
      case 'numeros':
        regEx = this.enteroSinSigno;
        break;
      case 'letrasGuionMedio':
        regEx = this.letrasGuionMedio;
        break;
      case 'letrasNumerosGuionMedio':
        regEx = this.letrasNumerosGuionMedio;
        break;
      case 'letrasGuionPuntoComa':
        regEx = this.letrasGuionPuntoComa;
        break;
      case 'letrasNumerosGuionHashtagComa':
        regEx = this.letrasNumerosGuionHashtagComa;
        break;
      case 'monto':
        regEx = this.monto;
        break;
      case 'M2':
        regEx = this.M2;
        break;
      case 'porcentaje':
        regEx = this.porcentaje;
        break;
      case 'letras':
        regEx = this.soloLetras;
        break;
      case 'email':
        regEx = this.email;
        break;
      case 'alfanumerico':
        regEx = this.alfanumerico;
        break;
      case 'todo':
        regEx = this.todo;
        break;
      case 'alfanumericoCaracteresEspecialesTipo1':
        regEx = this.alfanumericoCaracteresEspecialesTipo1;
        break;
      case 'alfanumericoCaracteresEspecialesTipo2':
        regEx = this.alfanumericoCaracteresEspecialesTipo2;
        break;
      case 'alfanumericoPuntoGuionMedio':
        regEx = this.alfanumericoPuntoGuionMedio;
        break;
      case 'mayorACero':
        regEx = this.mayorACero;
        break;
      case 'rfc':
        regEx = this.alfaNumericoRFC;
        break;
      case 'alfanumericoPuntoComa':
        regEx = this.alfanumericoPuntoComa;
        break;
      case 'colindancias':
        regEx = this.alfanumericoColindancias;
        break;
      default:
        e.preventDefault();
        break;
    }
    return regEx;
  }
}
