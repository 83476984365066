import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Memoize } from '@app-shared/generic-reactive-forms/utils/memoize.decorator';
import { Table } from 'primeng/table';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Action,
  Column,
  LazyLoadRequest,
  TableActionEvent,
  TableClickEvent,
  TableConfiguration,
} from '../models/table-model';
import { GenericTableManagerService } from '../services/generic-table-manager.service';

/**
 * Columna de agrupamiento.
 */
export const GROUP_COLUMN = 'GROUP_COLUMN';

/**
 * Clase abstracta para construccion de listados genericos.
 *
 * Esta clase abstracta sirve como base para construccion de nuevos tipos de listados genericos,
 * que puedan tener comportamientos especificos.
 */
@Component({ template: '' })
export abstract class AbstractTableComponent<T extends TableConfiguration> implements OnInit, OnDestroy {
  /**
   * Referencia del `Table` de PrimeNG.
   */
  @ViewChild('dataTable', { read: Table, static: false })
  public ref: Table;

  /**
   * Configuracion de la tabla.
   */
  @Input()
  public config: T;

  /**
   * Emisor de eventos para acciones del listado.
   */
  @Output()
  public actions$: EventEmitter<TableActionEvent> = new EventEmitter<TableActionEvent>();

  /**
   * Emisor de eventos para acciones de click en el listado.
   */
  @Output()
  public click$: EventEmitter<TableClickEvent> = new EventEmitter<TableClickEvent>();

  /**
   * Arreglo con elementos seleccionados.
   */
  public selection: any[];

  /**
   * Datos de tabla;
   */
  public data: any[] = [];

  /**
   * Metadata de tabla;
   */
  public metaData: Record<string, any> = {};

  /**
   * Columnas.
   */
  public cols: Column[];

  /**
   * Emisor de destruccion del componente.
   *
   * Este emisor es utilizado para la desuscripción de observables dentro del componente.
   */
  protected destroySubject$ = new Subject<void>();

  /**
   * Constructor de clase `GenericTableComponent`.
   */
  constructor(public manager: GenericTableManagerService, protected cd: ChangeDetectorRef) {}

  /**
   * Retorna un booleano que indica si se ha inicializado la configuracion de la tabla.
   */
  public get initialized$(): Observable<boolean> {
    return this.manager.initialized$;
  }

  /**
   * Retorna un booleano que indica si los campos del listado son "draggables".
   */
  public get draggable(): boolean {
    return this.config.draggable;
  }

  /**
   * Retorna un booleano que indica si se debe mostrar el paginador.
   */
  public get paginator(): boolean {
    return (
      (this.config.paginator !== undefined && this.config.paginator) ||
      (this.manager?.tableQuery?.totalItems &&
        this.manager?.tableQuery?.limit &&
        this.manager.tableQuery.totalItems > this.manager?.tableQuery?.limit)
    );
  }

  /**
   * Datos del listado.
   */
  public get tableData(): object[] | null {
    return this.config.data || null;
  }

  /**
   * Booleano que indica si el listado es Lazy.
   */
  public get lazy(): boolean {
    return !this.config.data;
  }

  /**
   * Numero total de elementos en el listado.
   */
  public get totalItems(): number {
    if (this.manager.tableQuery?.totalItems) {
      return this.manager.tableQuery.totalItems;
    }
    return this.config?.data?.length || 0;
  }

  /**
   * Numero de elementos por pagina.
   */
  public get rows(): number {
    return this.manager.tableQuery?.limit;
  }

  /**
   * Offset de resultados.
   *
   * Consumido por el paginador de `p-table`.
   */
  public get first(): number {
    return this.manager.tableQuery?.offset;
  }

  /**
   * Booleano que indica si la tabla es scrollable.
   */
  @Memoize
  public get scrollable(): boolean {
    return !!this.config.scrollHeight;
  }

  /**
   * Scrollheight de la tabla.
   */
  @Memoize
  public get scrollHeight(): string {
    return this.config.scrollHeight ?? null;
  }

  /**
   * Booleano que indica si se debe mostrar la columna de selección.
   */
  @Memoize
  public get showCheckboxes(): boolean {
    return this.config?.showCheckboxes;
  }

  /**
   * Booleano que indica si se debe mostrar la columna de acciones.
   */
  @Memoize
  public get showActions(): boolean {
    return this.config.actions && this.config.actions?.length > 0;
  }

  /**
   * Array de acciones disponibles en el listado.
   */
  @Memoize
  public get actions(): Action[] {
    return this.config.actions || [];
  }

  /**
   * Booleano que indica si el array de datos esta vacio.
   */
  public get empty(): boolean {
    return (this.data || []).length === 0;
  }

  /**
   * Selectores para la tabla.
   */
  @Memoize
  public get styleClass(): string {
    if (this.config.group) {
      return '';
    }

    return this.config.styleClass ?? 'p-datatable-striped p-datatable-gridlines';
  }

  /**
   * Opciones de paginación disponibles.
   */
  @Memoize
  public get paginationOptions(): number[] {
    return this.config.paginationOptions ?? [10, 25, 50];
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.initDataHandler();
    this.initColumnHandler();

    this.manager.initialize(this.config);
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.actions$.complete();
    this.click$.complete();

    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  /**
   * Handler de datos.
   */
  public initDataHandler() {
    if (this.tableData) {
      this.data = this.tableData;
      this.initializeMetadata();
      return;
    }

    this.manager.data$.pipe(takeUntil(this.destroySubject$.asObservable())).subscribe((data) => {
      setTimeout(() => {
        if (this.config.unsafeDataRetrieval) {
          this.data = data;
        } else {
          this.data = JSON.parse(JSON.stringify(data));
        }

        this.initializeMetadata();

        this.cd.detectChanges();
      });
    });
  }

  /**
   * Inicializa metadata de tabla.
   */
  public initializeMetadata(): void {
    const data = this.data;
    if (this.config.group && data?.length) {
      this.metaData[GROUP_COLUMN] = {};
      const groupName = this.config.group;

      const groupMetadata = this.metaData[GROUP_COLUMN];

      data.forEach((v, i) => {
        const item = v[groupName];

        const prev: { index: number; count: number } = groupMetadata[item];
        groupMetadata[item] = { index: prev?.index ?? i, count: (prev?.count ?? 0) + 1 };
      });
    }
  }

  /**
   * Handler de columnas.
   */
  public initColumnHandler() {
    this.manager.column$.pipe(takeUntil(this.destroySubject$.asObservable())).subscribe((cols) => {
      setTimeout(() => {
        this.cols = [...cols];
        this.cd.detectChanges();
      });
    });
  }

  /**
   * Handler para seleccion de elementos.
   */
  public onSelectionChange(selection: []) {
    this.manager.selectionChange(selection);
  }

  /**
   * Handler para lazy load.
   */
  public lazyLoadHandler(params: LazyLoadRequest) {
    this.manager.parseQueryParams(
      {
        order: params.sortOrder === -1 ? 'desc' : 'asc',
        orderColumn: params.sortField,
        limit: params.rows,
      },
      params.first
    );
    this.manager.getData();
  }

  /**
   * Handler para emisor de eventos de acciones.
   */
  public triggerAction(e: MouseEvent, action: Action, data: any) {
    this.actions$.next({ e, action, data });

    if (!action.onClick || e.defaultPrevented) {
      return;
    }

    action.onClick(e, action, data);
  }

  /**
   * Handler para deshabilitación de acciones
   */
  public triggerDisabled(action: Action, data: any): boolean {
    if (action.disabled === undefined) {
      return false;
    }

    return action.disabled(action, data);
  }

  /**
   * Retorna un booleano indicando si se debe mostrar el listado de acciones para una columna.
   */
  public showColumnActions(column: Column) {
    return Array.isArray(column.actions) && column.actions.length > 0;
  }

  /**
   * Click handler.
   */
  public clickHandler(e: MouseEvent, column: Column, data: any): void {
    this.click$.next({ e, column, data });

    if (!column.onClick || e.defaultPrevented) {
      return;
    }

    column.onClick(e, column, data);
  }

  /**
   * Retorna configuración para z-index de tooltip de botón de acción.
   */
  public zIndexToolTip(action: Action): string {
    return action?.tooltipOptions?.zIndex ?? 'auto';
  }

  /**
   * Ejecuta reset de `p-table`.
   */
  public reset(): void {
    this.ref?.reset();
  }
}
