import { Injectable } from '@angular/core';
import { menuTree } from '@app-assets/menu';
import { MenuItem, ProcessedMenuItem } from '@app-models/menu-item.model';
import { GenericRolesService } from '@app-shared/generic-roles/services/generic-roles.service';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

@Injectable()
export class MenuService {
  /**
   * Subject para menu.
   */
  private currentMenuSubject: BehaviorSubject<MenuItem[]> = new BehaviorSubject(null);

  /**
   * Constructor de clase `MenuService`.
   */
  public constructor(private genericRoles: GenericRolesService) {
    this.getMenu().subscribe((value) => {
      this.currentMenuSubject.next(value);
    });
  }

  /**
   * Retorna menu actual.
   */
  public get currentMenu(): Observable<any> {
    return this.currentMenuSubject.asObservable().pipe(filter((i) => Boolean(i)));
  }

  /**
   * Getter de elementos de menu.
   */
  private getMenu(): Observable<MenuItem[]> {
    return this.genericRoles.initialized$().pipe(map(() => this.processItems(menuTree)));
  }

  /**
   * Procesa un array de elementos de menu.
   */
  private processItems(items: MenuItem[]): ProcessedMenuItem[] {
    return items.map((i) => this.processItem(i, '')).filter((i) => i.visible);
  }

  /**
   * Procesador de items de Menu.
   */
  private processItem(i: ProcessedMenuItem, paretUrl: string): ProcessedMenuItem {
    i.fullUrl = paretUrl + i.url;
    if (i?.children) {
      i.children = i.children?.map((item) => this.processItem(item, i.fullUrl));
    }

    if (i.access?.callback) {
      i.visible = i.access.callback();
    }

    if (i.access?.modulos) {
      i.visible = this.genericRoles.validarModulos(i.access.modulos) && (i.visible ?? true);
    }

    i.visible = i.visible ?? true;

    i.children = (i.children as ProcessedMenuItem[])?.filter((item) => item.visible);

    return i;
  }
}
