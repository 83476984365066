<!--Formulario para validar colaborador-->
<form
  name="form"
  (ngSubmit)="f.form.valid && login(f)"
  #f="ngForm"
  novalidate
  class="form2"
>
  <h2 class="text-center">Inicia sesión con tu Huella Digital<span>.</span></h2>
  <p class="text-muted">Los mismos datos que utilizas en Coppel.com</p>
  <div>
    <input
      [ngClass]="{ 'is-invalid': f.submitted && !empleado.valid }"
      type="number"
      name="numEmpleado"
      placeholder="Número Empleado"
      maxlength="8"
      minlength="8"
      class="form-control huella"
      #empleado="ngModel"
      ngModel
      required
    />
  </div>
  <div class="row mt-4">
    <button
      type="submit"
      [disabled]="f.form.invalid"
      class="b-ingresar btn btn-primary"
    >
      Ingresar
    </button>
    <button
      *ngIf="allowBack"
      type="button"
      (click)="back()"
      class="b-ingresar btn btn-secondary"
    >
      Volver
    </button>
  </div>
</form>
