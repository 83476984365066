import {
  Component,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Constante, MensajesGenerales } from '@app-services/constantes';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import {
  FileUpload,
  GenericDropdownOption,
  GenericFileUploadModalInput,
} from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { ConfirmationService } from 'primeng/api';

const OPTIONS = 'optionsctrl';
const IDENTIFIER = 'identifier';
const DOCUMENTS = 'documents';

@Component({
  selector: 'app-generic-file-upload-modal',
  templateUrl: './generic-file-upload-modal.component.html',
  styleUrls: ['./generic-file-upload-modal.component.scss'],
})
export class GenericFileUploadModalComponent
  extends AbstractGenericInputComponent<GenericFileUploadModalInput, FormControl>
  implements OnInit, OnDestroy, OnChanges {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericFileUploadModalInput;

  /**
   * FormGroup base del modal.
   */
  public uploaderformgroup: FormGroup;

  /**
   * Control para mostrado de modal.
   */
  public showModal = false;

  /**
   * Constructor de clase `GenericFileUploadModalComponent`.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService,
    protected confirmationService: ConfirmationService,
    private formBuilder: FormBuilder
  ) {
    super(formRef, genericFormService);
  }

  /**
   * Retorna el indice para el valor de etiqueta de la opcion.
   */
  public get optionLabel(): string {
    return this.fieldConfig?.optionLabel ?? 'value';
  }

  /**
   * Retorna el indice para el valor de la opcion.
   */
  public get optionValue(): string {
    return this.fieldConfig?.optionValue ?? 'key';
  }

  /**
   * Retorna un array de GenericDropdownOption con las opciones disponibles.
   */
  public get options(): GenericDropdownOption[] {
    const index = this.optionValue;

    const required = this.config.requiredOptions ?? [];
    let available = (this.fieldConfig?.options ?? []).filter((o) => !required.includes(o[index]));

    if (this.fieldConfig.hideSelectedOptions) {
      const current = this.documentscontrol.controls.map((c) => c.value?.type);
      available = available.filter((o) => !current.includes(o[index]));
    }

    return available;
  }

  /**
   * Acceso a control de opciones.
   */
  public get optionscontrol(): FormControl {
    return this.uploaderformgroup.get(OPTIONS) as FormControl;
  }

  /**
   * Acceso a control añadir.
   */
  public get documentscontrol(): FormArray {
    return this.uploaderformgroup.get(DOCUMENTS) as FormArray;
  }

  /**
   * Arreglo de formatos válidos para archivo.
   */
  public get accept(): string {
    return Constante.FORMATOS_VALIDOS_ARCHIVO;
  }

  /**
   * Clase CSS para un elemento requerido.
   */
  public get labelClasses(): string {
    return this.requiredConfiguration() && 'required-form-element';
  }

  /**
   * Retorna un booleano que indica si el label debe mostrarse con formato de requerido.
   */
  public get showRequiredLabel(): boolean {
    return this.config.required || this.config?.requiredOptions?.length > 0;
  }

  /**
   * Trigger para keypress de Escape.
   */
  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    const x = event.key;
    if (x === 'Escape') {
      this.cancelar(event);
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    // Obtenemos el root form group.
    this.rootFormGroup = this.formRef?.control ?? this.genericFormService?.form;

    // Si se hace un override del elemento padre lo obtenemos.
    if (this.fieldConfig?.parentGroup) {
      let tempgroup: FormGroup;

      if (typeof this.fieldConfig?.parentGroup === 'string') {
        tempgroup = this.rootFormGroup.get(this.fieldConfig.parentGroup?.toString()) as FormGroup;
      } else {
        tempgroup = this.fieldConfig.parentGroup;
      }

      if (tempgroup) {
        this.rootFormGroup = tempgroup;
      }
    }

    const instance = new FormControl({
      value: this.fieldConfig?.value ?? null,
      disabled: this.fieldConfig?.disabled,
    });

    this.rootFormGroup.addControl(this.fieldConfig.name, instance);

    this.setValidators(instance);

    const modalIdentifier = this.uniqueId();

    this.uploaderformgroup = this.formBuilder.group({
      [OPTIONS]: new FormControl(null),
      [IDENTIFIER]: new FormControl(modalIdentifier),
      [DOCUMENTS]: new FormArray([]),
    });
  }

  /**
   * @inheritdoc
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const config = changes.config;
    const prevconfig = config?.previousValue ?? {};
    const currentconfig = config?.currentValue ?? {};

    // Si han cambiado los validadores del campo, la propiedad requerido o las opciones requeridas.
    // Entonces actualizamos los validadores.
    if (
      this.rootFormGroup &&
      this.control &&
      (prevconfig?.validators?.length !== currentconfig?.validators?.length ||
        prevconfig?.required !== currentconfig?.required ||
        prevconfig?.requiredOptions?.length !== currentconfig?.requiredOptions?.length)
    ) {
      this.setValidators((this.control as any) as FormControl);
    }
  }

  /**
   * Required configuration
   * @returns true es requerido
   */
  public requiredConfiguration(): boolean {
    return this.config.required;
  }

  /**
   * Retorna el valor que determina si es requerido o no el input.
   * @return
   */
  public required(): boolean {
    return this.config.required && this.control?.invalid;
  }

  /**
   * Retorna el valor que determina si se deshabilita el boton.
   * @return boolean
   */
  public disableSave(): boolean {
    return this.config.requiredAllToSave && this.documentscontrol.invalid;
  }

  /**
   * Retorna un booleano indicando si la opcion seleccionada es válida.
   */
  public validOption(): boolean {
    return this.optionscontrol.valid && this.optionscontrol.value !== null;
  }

  /**
   * Handler para añadir archivos.
   */
  public addFile() {
    const identifier = this.uniqueId();

    this.documentscontrol.controls.push(
      this.formBuilder.group({
        type: new FormControl(this.optionscontrol.value),
        file: new FormControl(null, [fileTypeValidatorFactory(identifier), fileSizeValidatorFactory(identifier)]),
        input: new FormControl(),
        identifier: new FormControl(identifier),
      })
    );

    this.documentscontrol.updateValueAndValidity();

    this.limpiarAddControl();
  }

  /**
   * Retorna nombre para tipo de documento.
   */
  public getName(group: FormGroup): string {
    const type = group.get('type') as FormControl;
    const option = (this.fieldConfig?.options ?? [])?.find((e: GenericDropdownOption) => e.key === type.value);
    return option?.value;
  }

  /**
   * Retorna identificador para grupo.
   */
  public getIdentifier(group: FormGroup): string {
    const file = group.get('identifier') as FormControl;
    return file.value;
  }

  /**
   * Retorna el filename de input de control.
   */
  public getControlFileName(group: FormGroup): string {
    const input = group.get('input') as FormControl;
    const file = input.value as File;

    return file?.name ?? Constante.SIN_DOCUMENTO_SELECCIONADO;
  }

  /**
   * Remueve el documento cargado.
   * @param group documento actual
   */
  public removeFile(group: FormGroup): void {
    group.get('file').setValue(null);
    group.get('input').setValue(null);
    group.updateValueAndValidity();
  }

  /**
   * Valida si existe el documento
   * @param group
   * @returns boolean
   */
  public existsDocument(group: FormGroup): boolean {
    const input = group.get('input') as FormControl;
    return input.value !== null;
  }

  /**
   * Handler para cambio de archivo en listado de archivos.
   */
  public changeFile(event: Event, group: FormGroup) {
    const fileiIput = this.getFileHtmlInputElement(group);
    const files = fileiIput.files;

    const input = this.getInputControl(group);
    const fileControl = this.getFileControl(group);

    if (!fileControl.valid) {
      input.setValue(null);
      input.updateValueAndValidity();
      return;
    }

    let file = null;
    if (files?.length) {
      file = files[0];
    }

    input.setValue(file);
    input.updateValueAndValidity();
  }

  /**
   * Retorna elemento HTMLInputElement para campo input.
   */
  public getFileHtmlInputElement(group: FormGroup): HTMLInputElement {
    const identifier = this.getIdentifier(group);
    return <HTMLInputElement>document.getElementById(identifier);
  }

  /**
   * Retorna FormControl para INPUT de archivo.
   */
  public getInputControl(group: FormGroup): FormControl {
    return group.get('input') as FormControl;
  }

  /**
   * Retorna FormControl para FileInput de archivo.
   */
  public getFileControl(group: FormGroup): FormControl {
    return group.get('file') as FormControl;
  }

  /**
   * Guardar datos de modal de subida de archivos.
   */
  public save() {
    this.showModal = false;
    const validFiles = this.documentscontrol.controls.filter((document) => {
      const file: File = document.get('input').value;
      return file;
    });
    const value = validFiles.map((document) => {
      const file: File = document.get('input').value;
      return file
        ? ({
            type: document.get('type').value,
            input: file,
            name: file.name,
          } as FileUpload)
        : null;
    });
    this.control.setValue(value);
  }

  /**
   * Inicializa documentos previamente guardados en el estado del control.
   */
  public initializeDocuments(): void {
    this.documentscontrol.clear();
    const items = (this.control.value || []) as FileUpload[];
    const required = this.config.requiredOptions ?? [];

    // Seteamos archivos pasados por configuracion.
    items.forEach((e: FileUpload) => {
      const itemIdentifier = this.uniqueId();

      this.documentscontrol.push(
        this.formBuilder.group({
          type: new FormControl(e.type),
          file: new FormControl(
            null,
            [
              fileTypeValidatorFactory(itemIdentifier),
              fileSizeValidatorFactory(itemIdentifier),
              required.includes(e.type) && requiredDocumentValidatorFactory(itemIdentifier, e),
            ].filter(Boolean)
          ),
          input: new FormControl(e.input),
          identifier: new FormControl(itemIdentifier),
        })
      );
    });

    const included = items.map((i) => i.type);
    const notIncluded = required.filter((r) => !included.includes(r));

    // Seteamos archivos obligatorios no seleccionados.
    notIncluded.forEach((i) => {
      const itemIdentifier = this.uniqueId();

      this.documentscontrol.push(
        this.formBuilder.group({
          type: new FormControl(i),
          file: new FormControl(null, [
            fileTypeValidatorFactory(itemIdentifier),
            fileSizeValidatorFactory(itemIdentifier),
            required.includes(i) && Validators.required,
          ]),
          input: new FormControl(),
          identifier: new FormControl(itemIdentifier),
        })
      );
    });
  }

  /**
   * Mostrar modal de subida de archivos.
   */
  public open() {
    this.initializeDocuments();
    this.showModal = true;
  }

  /**
   * Nuestra dialogo para cancelar subida de archivos.
   */
  public cancelar(event: Event) {
    event.preventDefault();
    this.confirmationService.confirm({
      key: 'generic-file-upload-modal-dialog',
      header: MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_HEADER,
      message: MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_BODY,
      accept: () => {
        this.showModal = false;
        this.documentscontrol.clear();
        this.limpiarAddControl();
      },
    });
  }

  /**
   * Retorna un booleano indicando si la opcion
   */
  public isRequired(documento: FormGroup) {
    const type = documento.get('type') as FormControl;
    return this.config.requiredOptions?.includes(type.value);
  }

  /**
   * Remueve un documento de la tabla.
   */
  public removeDocument(documento) {
    const identifier = documento.get('identifier') as FormControl;

    const index = this.documentscontrol.controls.findIndex((d) => d.get('identifier')?.value === identifier?.value);

    if (index < 0) {
      return;
    }

    this.documentscontrol.removeAt(index);
  }

  /**
   * @inheritdoc
   */
  protected setValidators(control: FormControl): void {
    Promise.resolve().then(() => {
      control.setValidators(
        [
          ...(this.fieldConfig.validators || []),
          validateRequiredOptionsFactory(this.fieldConfig),
          this.config?.required && this.fieldConfig?.requiredOptions?.length && Validators.required,
        ].filter(Boolean)
      );
      control.updateValueAndValidity();
    });
  }

  /**
   * Limpia selector de archivo para añadir documentos.
   */
  protected limpiarAddControl(): void {
    this.optionscontrol.reset(null);
  }
}

/**
 * Validador de tipo de archivo.
 */
function fileTypeValidatorFactory(id: string): ValidatorFn {
  return (control: FormControl): ValidationErrors => {
    const extensiones = Constante.FORMATOS_VALIDOS_ARCHIVO;
    const validas = extensiones
      .toLowerCase()
      .replace(/"*\.*\s*/g, '')
      .split(',');

    if (control.value === null) {
      return {};
    }

    const extension = control.value.split('.').pop().toLowerCase().toLowerCase();

    if (!validas.includes(extension)) {
      return { requiredFileType: true };
    }

    return {};
  };
}

/**
 * Validador de tamaño de archivo.
 */
function fileSizeValidatorFactory(id: string): ValidatorFn {
  return (control: FormControl): ValidationErrors => {
    if (control.value === null) {
      return {};
    }

    const input: HTMLInputElement = <HTMLInputElement>document.getElementById(id);

    if (!input) {
      return {};
    }

    const file = input.files[0];
    const fileSize = file.size;
    const fileSizeInKB = Math.round(fileSize / 1024);
    const maxFileSizeInKB = Math.round(Constante.TAMANO_MAXIMO_ARCHIVO / 1024);

    if (fileSizeInKB > maxFileSizeInKB) {
      return { fileSizeValidator: true };
    }

    return {};
  };
}

/**
 * Validador de documentos requeridos.
 */
function validateRequiredOptionsFactory(configuration: GenericFileUploadModalInput) {
  return (control: FormControl): ValidationErrors => {
    const current = (control.value as FileUpload[]) || [];
    const required = configuration?.requiredOptions || [];
    if (
      configuration.required &&
      required.length &&
      !required.every((i: string) => current.find((j: FileUpload) => j.type === i))
    ) {
      if (configuration.label === 'Documentos Permuta') {
        if (current.length) {
          return null;
        } else {
          return { requiredOptions: true };
        }
      }
      return {
        requiredOptions: true,
      };
    }

    return null;
  };
}

/**
 * Validador de documento requeridos.
 *
 * Utilizado en la lista de documentos para habilitar/deshabilitar botón de guardado.
 */
function requiredDocumentValidatorFactory(itemIdentifier: string, e: FileUpload): ValidatorFn {
  return (control: FormControl): ValidationErrors => {
    if (!control.value && !e.input) {
      return {
        required: true,
      };
    }

    return null;
  };
}
