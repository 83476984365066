// tslint:disable: directive-selector

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { GenericRolesService } from '../services/generic-roles.service';

@Directive({
  selector: '[genericRolesModulo]',
})
export class GenericRolesModulosDirective {
  /**
   * Constructor de clase `GenericRolesModulosDirective`.
   */
  public constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private genericRoles: GenericRolesService
  ) {}

  /**
   * Modulos en los que se debe mostrar el template.
   */
  @Input()
  public set genericRolesModulo(items: (string | number)[]) {
    const r = this.genericRoles.validarModulos(items.map((i) => i.toString()));

    if (r) {
      this.triggerShow();
    }
  }

  /**
   * Muestra el template.
   */
  private triggerShow(): void {
    this.container.createEmbeddedView(this.templateRef);
  }
}
