import { Directive, HostListener } from '@angular/core';

/**
 * Allows the sidebar to be toggled via click.
 */
@Directive({
  selector: '[appSidebarToggler]',
})
export class SidebarToggleDirective {
  constructor() {
    /*Empty*/
  }

  @HostListener('click', ['$event'])
  public toggleOpen($event: any) {
    $event.preventDefault();
    if (document.querySelector('app-root')) {
      document.querySelector('app-root').classList.toggle('sidebar-hidden');
    }
  }
}
