<div class="form-group" *ngIf="fieldConfig && control">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <!-- Field -->
  <textarea
    pInputTextarea
    [ngClass]="fieldConfig?.class"
    class="ng-dirty !ng-invalid form-control"
    [style]="{ width: '100%' }"
    [name]="fieldConfig.name"
    [id]="id"
    [formControl]="control"
    [placeholder]="placeholder"
    [readonly]="fieldConfig?.readonly"
    [rows]="fieldConfig?.rows"
    [cols]="fieldConfig?.cols"
    [autoResize]="fieldConfig?.autoResize"
    (keydown)="onKeyDown($event)"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"
    (input)="onInput($event)"
    #input
  ></textarea>

  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>
