import { Injectable } from '@angular/core';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/state/session.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeneralesService {
  constructor(private sessionService: SessionService, private baseService: BaseService) {}

  public capitalizarPrimeraLetra(str: string) {
    str = str.toLowerCase();
    const cadena = str.split(' ');
    let nuevaCadena = '';
    cadena.forEach((texto) => (nuevaCadena += `${texto.charAt(0).toUpperCase()}${texto.slice(1)} `));
    return nuevaCadena;
  }

  /**
   * Verificación de huella con sesión actual.
   * Omisión de errores de petición.
   */
  public verificarHuella(template64?: string): Observable<any> {
    return this.baseService.post(
      environment.authIdp.SSO.apiUrl + '/v2/login',
      {
        user: this.sessionService.getCurrentUser().numeroempleado,
        template: template64,
      },
      {
        encabezadosInterceptoresCustom: {
          interceptarErrores: false,
          interceptorMensajes: false,
          InterceptorJWT: false,
        },
      }
    );
  }

  /**
   * Verificación de de huellas de dedo indice.
   */
  public verificarHuellaDedosIndice(data?: object) {
    return data['NumDedo'] === 2 || data['NumDedo'] === 7;
  }
}
