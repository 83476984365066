import {
  Component,
  ElementRef,
  Host,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { genericRestrictHandler } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/generic-restrict-handler';
import { GenericSearchInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GenericAdvancedSearchAbstractComponent } from './generic-advanced-search-abstract.component';
import { GestorModalService } from '@app-services/gestor-modal.service';

/**
 * Tiempo de espera entre escritura y busqueda.
 */
const DEBOUNCETIME = 1000;

@Component({
  selector: 'app-generic-input-search',
  templateUrl: './generic-input-search.component.html',
  styleUrls: ['./generic-input-search.component.scss'],
})
export class GenericInputSearchComponent
  extends AbstractGenericInputComponent<GenericSearchInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericSearchInput;

  /**
   * Viewref a input de busqueda.
   */
  @ViewChild('input', { static: false }) public inputRef: ElementRef<HTMLInputElement>;

  /**
   * Valor interno del campo de lectura.
   */
  public innerValue: string = null;

  /**
   * Key down event subject.
   */
  protected keyDown$ = new Subject<Event>();

  /**
   * Busqueda manual event subject.
   */
  protected search$ = new Subject<Event>();

  /**
   * Constructor para GenericInputSearchComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService,
    public modalService: GestorModalService
  ) {
    super(formRef, genericFormService);

    this.internalValidators.push(searchValidatorFactory(this));
  }

  /**
   * Booleano que indica si se debe mostrar el campo de búsqueda.
   */
  public get showSearch(): boolean {
    return this.config.showSearch ?? true;
  }

  /**
   * Booleano que indica si se debe mostrar el icono de busqueda.
   */
  public get showSearchButton(): boolean {
    return this.config.showSearchButton ?? true;
  }

  /**
   * Atributo autocomplete.
   */
  public get autocomplete(): string {
    return this.config?.autocomplete ?? 'off';
  }

  /**
   * Atributo spellcheck.
   */
  public get spellcheck(): boolean {
    return this.config?.spellcheck ?? false;
  }

  /**
   * Retorna el valor asociado al input.
   */
  public get value(): FormControl {
    return this.rootFormGroup.get(this.fieldConfig.name + 'value') as FormControl;
  }

  /**
   * Retorna icono de busqueda.
   */
  public get icon(): string {
    return this.fieldConfig?.icon ?? 'fa fa-search';
  }

  /**
   * Retorna propiedad maxlength.
   */
  public get maxlength(): number {
    return this.fieldConfig?.maxlength;
  }

  /**
   * Retorna propiedad minlength.
   */
  public get minlength(): number {
    return this.fieldConfig?.minlength;
  }

  /**
   * Retorna propiedad maxlength.
   */
  public get min(): number {
    return this.fieldConfig?.min;
  }

  /**
   * Retorna propiedad minlength.
   */
  public get max(): number {
    return this.fieldConfig?.max;
  }

  /**
   * Retorna propiedad minlength.
   */
  public get step(): number {
    return this.fieldConfig?.step;
  }

  /**
   * Retorna propiedad pattern.
   */
  public get pattern(): RegExp {
    return this.fieldConfig?.pattern;
  }

  /**
   * Retorna propiedad pattern.
   */
  public get type(): string {
    return this.fieldConfig?.type || 'text';
  }

  /**
   * Clase CSS para el campo de busqueda.
   */
  public get searchClasses(): object {
    return { 'ng-invalid': this.control?.invalid, 'ng-dirty': this.control?.invalid };
  }

  /**
   * Funcion para setear el valor asociado al input.
   */
  public setValue(value: string) {
    this.innerValue = value;

    this.value.setValue(this.innerValue);
  }

  public focus(): void {
    if (!this.control.touched) {
      return;
    }
    this.inputRef?.nativeElement?.focus();
  }

  /**
   * On click event handler.
   */
  public onClick(e): void {
    if (this.fieldConfig?.onClick) {
      this.fieldConfig?.onClick(e, {
        setValue: (value) => this.control.setValue(value),
        setAssociatedValue: this.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
        getCurrentValue: () => {
          return this.control.value;
        },
        setCustomErrors: (error) => {
          this.control.setErrors(error);
        },
      });
    }
  }

  /**
   * On focus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        setValue: (value) => this.control.setValue(value),
        setAssociatedValue: this.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
        getCurrentValue: () => {
          return this.control.value;
        },
        setCustomErrors: (error) => {
          this.control.setErrors(error);
        },
      });
    }
  }

  /**
   * On blur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        setValue: (value) => this.control.setValue(value),
        setAssociatedValue: this.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
        getCurrentValue: () => {
          return this.control.value;
        },
        setCustomErrors: (error) => {
          this.control.setErrors(error);
        },
      });
    }
  }

  /**
   * On input event handler.
   */
  public onInput(e): void {
    if (this.fieldConfig?.restrict) {
      genericRestrictHandler<KeyboardEvent>(this.fieldConfig.restrict, e, this.control);
    }

    if (this.fieldConfig?.onInput) {
      this.fieldConfig?.onInput(e, {
        setValue: (value) => this.control.setValue(value),
        setAssociatedValue: this.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
        getCurrentValue: () => {
          return this.control.value;
        },
        setCustomErrors: (error) => {
          this.control.setErrors(error);
        },
      });
    }
  }

  /**
   * On blur event handler.
   */
  public onChange(e): void {
    if (this.control.disabled) {
      return;
    }

    if (!this.config.advancedSearchComponent) {
      this.search$.next(e);

      return;
    }

    const componentRef = this.modalService.show(this.config.advancedSearchComponent as any, {
      class: 'modal-xl',
      backdrop: 'static',
    });
    const component = componentRef.content as GenericAdvancedSearchAbstractComponent;

    component
      .getSelection()
      .pipe(takeUntil(this.destroySubject$.asObservable()))
      .subscribe((data) => {
        componentRef?.hide();
        this.control.setValue(data);
      });

    component
      .onClose()
      .pipe(takeUntil(this.destroySubject$.asObservable()))
      .subscribe((_) => {
        componentRef?.hide();
      });
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.rootFormGroup.addControl(
      this.fieldConfig.name + 'value',
      new FormControl({
        value: null,
        disabled: true,
      })
    );

    this.setValue(null);

    const actions = {
      setValue: (value) => this.control.setValue(value),
      setAssociatedValue: this.setValue.bind(this),
      disable: this.control.disable.bind(this.control),
      enable: this.control.enable.bind(this.control),
      focus: this.focus.bind(this),
      validate: () => this.control.updateValueAndValidity(),
      getCurrentValue: () => {
        return this.control.value;
      },
      setCustomErrors: (error) => {
        this.control.setErrors(error);
      },
    };

    const handleChange = (e: Event) => {
      this.control.disable();
      this.fieldConfig?.onChange(e, actions);
    };

    // Inicializamos handler `onChange` para busquedas.
    if (this.fieldConfig?.onChange) {
      // Realizamos onChange en busqueda por valueChanges.
      this.control.valueChanges
        .pipe(debounceTime(DEBOUNCETIME), distinctUntilChanged())
        .pipe(takeUntil(this.destroySubject$.asObservable()))
        .subscribe((e: Event) => handleChange(e));
      // Realizamos onChange en busqueda por botón.
      this.search$
        .asObservable()
        .pipe(debounceTime(DEBOUNCETIME))
        .pipe(takeUntil(this.destroySubject$.asObservable()))
        .subscribe((e: Event) => handleChange(e));
    }

    if (this.fieldConfig?.onKeyDown) {
      this.keyDown$
        .asObservable()
        .pipe(takeUntil(this.destroySubject$.asObservable()))
        .subscribe((e: Event) => this.fieldConfig?.onKeyDown(e, actions));
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy() {
    super.ngOnDestroy();

    this.keyDown$.complete();
  }

  /**
   * Trigger para keypress de Escape.
   * @param event
   */
  @HostListener('keydown', ['$event'])
  public keyEvent(e: KeyboardEvent) {
    this.keyDown$.next(e);
  }
}

function searchValidatorFactory(component: GenericInputSearchComponent): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (control.disabled) {
      return null;
    }

    if ((control.value === null || control.value === '') && component.innerValue === null) {
      return null;
    }

    if (control.value !== null && component.innerValue === null) {
      return {
        invalid: true,
      };
    }

    return null;
  };
}
