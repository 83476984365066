import { Injectable } from '@angular/core';
import { RolDto, RolGuardarDto } from '@app-modules/roles/models/roles.interface';
import { DescargaDto } from '@app-modules/roles/models/roles.models';
import { RolesService } from '@app-modules/roles/services/roles.service';
import { UsuarioRolService } from '@app-modules/roles/services/usuario-rol.service';
import { UsuarioRolDTO } from '@app-services/modules/Usuarios/usuarios';
import { UsuariosService } from '@app-services/modules/Usuarios/usuarios.service';
import { ModuloPermisoUsuarioDTO } from '@app-shared/generic-roles/models/models';
import {
  GenericRolesModulespermissionsService,
  GenericRolesModulesService,
} from '@app-shared/generic-roles/services/providers';
import { GenericPermissionManager } from '@app-shared/generic-roles/services/providers/generic-permission-manager';
import { deshabilitarRoles } from '@app-shared/generic-roles/utils';
import { IResponseSingle } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { LoadingService } from '@app-shared/services/loading.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, finalize, map, mergeMap, retryWhen, take } from 'rxjs/operators';
import { SessionService } from 'src/app/state/session.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class GenericRolesService {
  /**
   * Usuario obtenido desde servicio.
   */
  private numeroEmpleadoValue: number;

  /**
   * Indica si el servicio se ha inicializado correctamente.
   */
  private initializedSubject$ = new BehaviorSubject<boolean>(false);

  /**
   * Permisos del usuario.
   */
  private permisosData: ModuloPermisoUsuarioDTO;

  /**
   * Datos de usuario para sesin.
   */
  private usuarioData: UsuarioRolDTO;

  /**
   * Rol del usuario.
   */
  private rolData: RolGuardarDto;

  /**
   * Constructor de clase `GenericRolesService`.
   */
  public constructor(
    public baseService: BaseService,
    private modulos: GenericRolesModulesService,
    private permisos: GenericRolesModulespermissionsService,
    private baseClient: BaseService,
    private loadingService: LoadingService,
    private usuarioRolService: UsuarioRolService,
    private usuarioService: UsuariosService,
    private rolesService: RolesService,
    private sessionService: SessionService
  ) {}

  /**
   * Rol del usuario.
   */
  public get rol(): RolDto {
    return this.rolData.rol;
  }

  /**
   * Retorna un booleano indicando si el rol es administrador.
   */
  public get rolAdministrador(): boolean {
    if (deshabilitarRoles()) {
      return true;
    }

    return this.rolData?.rol?.administrador;
  }

  /**
   * ID de usuario.
   */
  public get numeroEmpleado(): number {
    return this.numeroEmpleadoValue;
  }

  /**
   * ID de usuario para sesion actual.
   */
  public get idUsuario(): number {
    return this.usuarioData.id;
  }

  /**
   * Datos del usuario.
   */
  public get usuario(): UsuarioRolDTO {
    return this.usuarioData;
  }

  /**
   * Observable que indica si el servicio se ha inicializado.
   */
  public initialized$(): Observable<boolean> {
    return this.initializedSubject$.asObservable().pipe(filter((r) => r));
  }

  /**
   * Inicializa el servicio de roles.
   */
  public init() {
    let stop;

    // Si los roles estan deshabilitados estamos listos para navegar.
    if (deshabilitarRoles()) {
      stop = this.loadingService.trigger('INICIALIZACIONROLES');
      this.obtenerUsuarioRolData$().subscribe(() => {
        this.initializedSubject$.next(true);
        stop();
      });
      return;
    }

    // Si el usuario esta autenticado.
    this.sessionService.isUserAuthenticated$
      .pipe(
        take(1),
        filter((v) => !!v),
        mergeMap(() => {
          stop = this.loadingService.trigger('INICIALIZACIONROLES');

          this.numeroEmpleadoValue = +this.sessionService.getCurrentUser().numeroempleado;

          // Obtenemos los permisos y modulos disponibles.
          return this.obtenerModulosPermisos(this.numeroEmpleadoValue);
        }),
        mergeMap((permisosData: any) => {
          this.permisosData = permisosData?.datos;

          if (!Array.isArray(this.permisosData.idsModulo) && !Array.isArray(this.permisosData.idsPermiso)) {
            setTimeout(() => window.location.reload(), 5000);
            return null;
          }

          if (stop) {
            stop();
          }

          this.modulos.init(this.permisosData?.idsModulo ?? []);
          this.permisos.init(this.permisosData?.idsPermiso ?? []);

          return this.obtenerUsuarioRolData$();
        })
      )
      .subscribe(() => {
        // Estamos listos para navegar.
        this.initializedSubject$.next(true);
      });
  }

  /**
   * Valida permisos para un módulo.
   */
  public validarModulos(elem: (string | number)[], validationFn: 'every' | 'some' = 'some'): boolean {
    if (deshabilitarRoles()) {
      return true;
    }

    if (!this.initializedSubject$.value) {
      return false;
    }

    if (elem[0]?.toString().split(',').length > 1) {
      elem = elem[0]?.toString().split(',');
    }

    if (validationFn === 'every') {
      return elem.every((i) => this.modulos.hasPermission(i?.toString()));
    }

    return elem.some((i) => this.modulos.hasPermission(i?.toString()));
  }

  /**
   * Valida permisos especiales.
   */
  public validarPermisos(elem: (string | number)[], validationFn: 'every' | 'some' = 'some'): boolean {
    if (deshabilitarRoles()) {
      return true;
    }

    if (!this.initializedSubject$.value) {
      return false;
    }

    if (validationFn === 'every') {
      return elem.every((i) => this.permisos.hasPermission(i?.toString()));
    }

    return elem.some((i) => this.permisos.hasPermission(i?.toString()));
  }

  /**
   * Retorna un manager de la pantalla de seguimiento.
   */
  public getManagerPantallaSeguimientoManager(idAsunto: number | string) {
    const manager = GenericPermissionManager.build();

    this.usuarioRolService
      .getPantallaSeguimientoPermisos$(this.numeroEmpleado, idAsunto)
      .pipe(take(1))
      .subscribe((r) => {
        manager.init(r?.datos?.response ?? []);
      });

    return manager;
  }

  /**
   * Obtiene un listado de estados por usuario.
   */
  public validarDescargaPorUsuarioProceso$(
    idUsuario: string | number,
    idDocumento: string | number
  ): Observable<IResponseSingle<DescargaDto>> {
    return this.baseService.prepareGet<
      IResponseSingle<DescargaDto>
    >`/rol/usuario/:idUsuario/documento/:idDocumento/descarga`({
      idUsuario,
      idDocumento,
    });
  }

  /**
   * Retorna un booleano indicando si los roles están activados.
   */
  public rolesActivados(): boolean {
    return !deshabilitarRoles();
  }

  /**
   * Obtiene datos del usuario.
   */
  private obtenerUsuarioRolData$(): Observable<boolean> {
    return this.usuarioService.getUsuarioActual().pipe(
      take(1),
      map((r) => r?.datos),
      mergeMap((usuario) => {
        this.usuarioData = usuario;
        return this.rolesService.rolPorId(this.usuarioData?.idRol);
      }),
      mergeMap((rol) => {
        this.rolData = rol.datos;
        return of(true);
      })
    );
  }

  /**
   * Retorna el listado de modulos y permisos.
   */
  private obtenerModulosPermisos(numPersona: number | string): Observable<IResponseSingle<ModuloPermisoUsuarioDTO>> {
    const ob = this.baseClient
    .post<IResponseSingle<ModuloPermisoUsuarioDTO>>(`${environment.backendURL}/usuario/rol/modulos-permisos`, {
      numPersona: numPersona,
    })
    .pipe(take(1));
    return genericRolesRetryHandler(ob, this);
  }
}

/**
 * Reintentos
 */
const NUMERO_REINTENTOS = 1;

/**
 * Retraso de milisegundos entre intentos.
 */
const RETRASO_MILISEGUNDOS = 2000;

/**
 * Fabrica de reintentos para peticiones, en caso de que se defina el Safe Handler este se ejecutara despues
 * del limite de reintentos.
 */
function genericRolesRetryHandler<T>(item: Observable<T>, thisOverride: any) {
  const self = thisOverride;
  return item.pipe(
    retryWhen((error) => {
      return error.pipe(
        mergeMap((errorInterno, index) => {
          if (index < NUMERO_REINTENTOS) {
            const stop = self.loadingService.eliminaTodos();
            localStorage.clear();
            sessionStorage.clear();
            return of().pipe(finalize(() => stop()));
          }

          throw errorInterno;
        })
      );
    })
  );
}
