import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CaptchaApiService {
  private statusSubject: BehaviorSubject<any> = new BehaviorSubject({
    loaded: false,
    status: 'Sleep',
  });
  public readonly status$: Observable<any> = this.statusSubject.asObservable();

  constructor() {
    if (environment.inHouse[0] && environment.authMechanisms.colaboradorDigital || environment.authMechanisms.custom) {
      this.initialize();
    }
  }

  /**
   * initialize
   */
  public initialize() {
    if (this.statusSubject.getValue().loaded) {
      this.showBadge();
      return;
    }
    this.loadScript();
  }

  /**
   * getToken
   */
  public getToken(action: string): Promise<string> {
    // @ts-ignore
    const grecaptcha = window.grecaptcha;
    return new Promise((resolve, reject) => {
      if (grecaptcha) {
        grecaptcha.enterprise.ready(() => {
          grecaptcha.enterprise.execute(environment.recaptcha.siteKey, { action }).then(
            (token: string) => resolve(token),
            (error: { message: any }) => reject(error?.message || 'Captcha request error.')
          );
        });
      } else {
        reject(new Error('reCAPTCHA v3 is not loaded correctly.'));
      }
    });
  }

  /**
   * showBadge
   */
  public showBadge() {
    const badge = document.querySelectorAll<HTMLElement>('.grecaptcha-badge')[0];
    if (badge) {
      badge.style.display = 'block';
    }
  }

  /**
   * hideBadge
   */
  public hideBadge() {
    const badge = document.querySelectorAll<HTMLElement>('.grecaptcha-badge')[0];
    if (badge) {
      badge.style.display = 'none';
    }
  }

  /**
   * loadScript
   */
  private loadScript() {
    const scriptWithParams = `${environment.recaptcha.scriptUrl}?render=${environment.recaptcha.siteKey}`;
    const scriptIdx = Array.from(document.scripts).findIndex((script) => script.src === scriptWithParams);
    const script: any = scriptIdx !== -1 ? document.scripts.item(scriptIdx) : document.createElement('script');
    if (scriptIdx === -1) {
      script.type = 'text/javascript';
      script.src = scriptWithParams;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
    this.awaitReadyScript(script);
  }

  /**
   * awaitReadyScript
   */
  private awaitReadyScript(script: any) {
    script.onerror = (error: any) => {
      this.statusSubject.error({ loaded: false, status: 'Error', error });
    };
    if (script.readyState) {
      //IE
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          script.loaded = true;
          this.statusSubject.next({ loaded: true, status: 'Loaded' });
        }
      };
    } else {
      //Others
      script.onload = () => {
        script.loaded = true;
        this.statusSubject.next({ loaded: true, status: 'Loaded' });
      };
    }
  }
}
