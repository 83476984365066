import { Component, OnInit } from "@angular/core";
import { ConfigService } from "src/app/services/config.service";
import { environment as env } from "../../../../../environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./app-footer.component.html",
})
export class AppFooterComponent implements OnInit {
  private config: any;
  public copyRightYear: number;
  public footerTitle: string;
  public isProd = env?.production;
  public envName = env?.envName;
  public version = env?.versions?.app;

  constructor(public configService: ConfigService) {
    this.config = this.configService.getConfig();
  }

  public ngOnInit() {
    this.copyRightYear = new Date().getFullYear();
    this.footerTitle = this.config.footerTitle;
  }
}
