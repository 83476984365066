import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HtmlToPlainTextPipe } from '@app-shared/shared/html-to-plaintext.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonsModule } from './commons/commons.module';
import { AlertaAbandonarVistaComponent } from './commons/components/alerta-abandonar-vista/alerta-abandonar-vista.component';
import { OpenPdfComponent } from './commons/components/app-open-pdf/app-open-pdf.component';
import { CierreSesionComponent } from './commons/components/cierre-sesion/cierre-sesion.component';
import { LoadingComponent } from './commons/components/loading/loading.component';
import { EnvironmentConditionalDirective } from './commons/directives/environment-conditional.directive';
import { LetrasNumerosCaracteresEspeciales } from './commons/directives/letras-numeros-caracteres-especiales.directive';
import { RestringirTipoDirective } from './commons/directives/restringir-tipo.directive';
import { CardFiltersComponent } from './components/card-filters/card-filters.component';
import { DialogComponent } from './components/modals/dialog.component';
import { ModalComentarioComponent } from './components/modals/modal-comentario/modal-comentario.component';
import { TableFormComponentComponent } from './components/table-form-component/tableFormComponent.component';
import { VisorArchivosComponent } from './components/visor-archivos/visor-archivos.component';
import { FiltroDatosModule } from './filtro-datos/filtro-datos.module';
import { GenericComponentsModule } from './generic-components/generic-components.module';
import { GenericReactiveFormsModule } from './generic-reactive-forms/generic-reactive-forms.module';
import { GenericRolesModulosDirective } from './generic-roles/directives/generic-roles-modulos.directive';
import { GenericRolesDirective } from './generic-roles/directives/generic-roles.directive';
import { GenericRolesModule } from './generic-roles/generic-roles.module';
import { GenericTablesModule } from './generic-tables/generic-tables.module';
import { PrimeNgExportsModule } from './prime-ng-exports/prime-ng-exports.module';
import { ModalConfirmacionProcesoComponent } from './components/modals/modal-aceptar/ModalConfirmacionProceso';

@NgModule({
  declarations: [
    TableFormComponentComponent,
    CardFiltersComponent,
    DialogComponent,
    HtmlToPlainTextPipe,
    VisorArchivosComponent,
    ModalComentarioComponent,
    ModalConfirmacionProcesoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    GenericTablesModule,
    GenericComponentsModule,
    CommonsModule,
    PrimeNgExportsModule,
    GenericRolesModule,
    FiltroDatosModule,
    GenericReactiveFormsModule,
  ],
  exports: [
    TableFormComponentComponent,
    CardFiltersComponent,
    HtmlToPlainTextPipe,
    DialogComponent,
    VisorArchivosComponent,
    AlertaAbandonarVistaComponent,
    OpenPdfComponent,
    CierreSesionComponent,
    RestringirTipoDirective,
    LoadingComponent,
    LetrasNumerosCaracteresEspeciales,
    EnvironmentConditionalDirective,
    GenericRolesDirective,
    GenericRolesModulosDirective,
    ModalComentarioComponent,
    ModalConfirmacionProcesoComponent,
  ],
})
export class SharedModule {}
