<ng-container [formGroup]="group">
  <ng-content></ng-content>

  <ng-container *ngIf="fields">
    <div class="row">
      <ng-container *ngFor="let field of fields">
        <div class="{{ getColWidth(field) }}">
          <ng-container genericField [config]="field" [group]="group"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
