import { Directive, HostListener } from '@angular/core';

/**
 * Allows the off-canvas sidebar to be closed via click.
 */
@Directive({
  selector: '[appSidebarClose]',
})
export class SidebarOffCanvasCloseDirective {
  constructor() {
    /*Empty*/
  }

  @HostListener('click', ['$event'])
  public toggleOpen($event: any) {
    $event.preventDefault();

    if (document.querySelector('app-root') && this.hasClass(document.querySelector('app-root'), 'sidebar-off-canvas')) {
      this.toggleClass(document.querySelector('app-root'), 'sidebar-opened');
    }
  }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp(`(\\s|^) ${elementClassName} (\\s|$)`).test(target.className);
  }

  // Toggle element class
  private toggleClass(elem: any, elementClassName: string) {
    let newClass = `${elem.className.replace(/[\t\r\n]/g, ' ')} `;
    if (this.hasClass(elem, elementClassName)) {
      while (newClass.indexOf(` ${elementClassName} `) >= 0) {
        newClass = newClass.replace(` ${elementClassName} `, ' ');
      }
      elem.className = newClass.trim();
    } else {
      elem.className += ' ' + elementClassName;
    }
  }
}
