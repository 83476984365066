import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { HerramientasFormulario } from '@app-models/herramientasFormulario';
import { GenericRolesService } from '@app-shared/generic-roles/services/generic-roles.service';
import { actionsTable } from '@app-shared/models/constant';
import { ActionClick, ColumnActions, TableColumn, TableParamters } from '@app-shared/models/table.interface';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-table-form-component',
  templateUrl: './tableFormComponent.component.html',
  styleUrls: ['./tableFormComponent.component.css'],
})
export class TableFormComponentComponent extends HerramientasFormulario {
  public loading: boolean;

  @Input() public isLazy = false;

  @Input() public tittle: string;
  @Input() public iconTittle: string;
  @Input() public styleClassTable = 'p-datatable-striped p-datatable-gridlines';
  @Input() public showCurrentPageReport = true;
  @Input() public paginator = false;
  @Input() public recodsPerPage = 10;
  @Input() public orderColumn: string;
  @Input() public esOrdenamientoAsc = true;
  @Input() public showMandatoryFieldsMessage = false;
  @Input() public customRequiredMessage = '';

  @Input() public tableColumns: TableColumn[];
  @Input() public columnActions: ColumnActions[];
  @Input() public tableData = [];
  @Input() public isGroupedTable = false;
  @Input() public isFilterable = false;
  @Input() public showPagination = false;
  @Input() public showAddButton = false;
  @Input() public showColumnActions = false;
  @Input() public totalRecords = 0;
  @Input() public formDinamic: FormGroup;
  @Input() public styleTR = '';
  @Input() public showButtonsFooter = true;

  @Input() public deshabilitarNoAdministradores = true;

  @Output() public actionButton: EventEmitter<ActionClick> = new EventEmitter();
  @Output() public onDeleteButtonRow: EventEmitter<any> = new EventEmitter();
  @Output() public onDeleteButtonColumn: EventEmitter<any> = new EventEmitter();
  @Output() public onClickRow: EventEmitter<any> = new EventEmitter();
  @Output() public onAdd: EventEmitter<void> = new EventEmitter();
  @Output() public onLink: EventEmitter<string> = new EventEmitter();
  @Output() public onSort = new EventEmitter<TableParamters>();

  @Output() public onSave = new EventEmitter<any>();
  @Output() public onCancel = new EventEmitter<any>();

  public page = 0;

  /**
   * Constructor de clase `TableFormComponentComponent`.
   */
  public constructor(private genericRolesService: GenericRolesService) {
    super();
  }

  /**
   * El rol del usuario actual es de administrador.
   */
  public get rolAdministrador(): boolean {
    return this.genericRolesService?.rolAdministrador;
  }

  get dataArrayFormTable(): FormArray {
    return this.formDinamic.get('dataArrayFormTable') as FormArray;
  }

  public actionsClick(action: string, data: object[]): void {
    if (actionsTable.indexOf(action) !== -1) {
      this.actionButton.emit({
        action,
        dataRow: data,
      });
    }
  }

  public add(): void {
    this.onAdd.emit();
  }

  public customSort(event: LazyLoadEvent) {
    this.loading = true;

    this.esOrdenamientoAsc = event.sortOrder === 1;

    if (event.sortField !== undefined) {
      this.orderColumn = event.sortField;
    }

    setTimeout(() => {
      this.recodsPerPage = event.rows;
      if (this.recodsPerPage === 0) {
        this.recodsPerPage = 10;
      }

      this.page = event.first / this.recodsPerPage;

      if (isNaN(this.page)) {
        this.page = 0;
      }

      // tslint:disable-next-line: no-shadowed-variable
      const tableParamters: TableParamters = {
        pagina: this.page,
        limite: this.recodsPerPage,
        columna: this.orderColumn,
        esOrdenamientoAsc: this.esOrdenamientoAsc,
      };

      this.onSort.emit(tableParamters);
      this.loading = false;
    }, 1000);
  }

  public onGuardar(): void {
    this.onSave.emit();
  }

  public onCancelar(): void {
    this.onCancel.emit();
  }
}
