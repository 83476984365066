import { Component, Host, Input, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { GenericDropdownSearchInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-generic-input-dropdown-search',
  templateUrl: './generic-input-dropdown-search.component.html',
  styleUrls: ['./generic-input-dropdown-search.component.scss'],
})
export class GenericInputDropdownSearchComponent
  extends AbstractGenericInputComponent<GenericDropdownSearchInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericDropdownSearchInput;

  /**
   * Valor interno del campo de lectura.
   */
  public innerValue: string = null;

  /**
   * Busqueda manual event subject.
   */
  protected search$ = new Subject<Event>();

  /**
   * Constructor para GenericInputSearchComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService
  ) {
    super(formRef, genericFormService);
  }

  /**
   * Retorna el valor asociado al input.
   */
  public get value(): FormControl {
    return this.rootFormGroup.get(this.fieldConfig.name + 'value') as FormControl;
  }

  /**
   * Clase CSS para el campo de busqueda.
   */
  public get searchClasses(): object {
    return { 'ng-invalid': this.control?.invalid, 'ng-dirty': this.control?.invalid };
  }

  /**
   * Altura de el dialogo de resultados del dropdown.
   */
  public get scrollHeight(): string {
    return this.fieldConfig?.scrollHeight ?? '250px';
  }

  /**
   * Nombre de la propiedad que muestra el label.
   */
  public get optionLabel(): string {
    return this.fieldConfig?.optionLabel ?? 'value';
  }

  /**
   * Nombre de la propiedad del valor de la opcion.
   */
  public get optionValue(): string {
    return this.fieldConfig?.optionValue ?? 'key';
  }

  /**
   * Booleano que indica si se mostrará el filtro del dropdown.
   */
  public get filter(): boolean {
    return this.fieldConfig?.filter ?? true;
  }

  /**
   * Texto que se debe mostrar en caso de que no se muestren resultados.
   */
  public get emptyFilterMessage(): string {
    return this.fieldConfig?.emptyFilterMessage ?? this.mensajeNoSeEncontraronResultado;
  }

  /**
   * Texto que se debe mostrar en caso de que no se muestren resultados.
   */
  public get emptyMessage(): string {
    return this.fieldConfig?.emptyMessage ?? this.mensajeNoSeEncontraronResultado;
  }

  /**
   * Booleano que indica si se mostrara el boton de limpieza del campo.
   */
  public get showClear(): boolean {
    return this.fieldConfig?.showClear || true;
  }

  /**
   * Icono que se mostrara para el dropdown.
   */
  public get dropdownIcon(): string {
    return this.fieldConfig?.dropdownIcon ?? 'pi pi-chevron-down';
  }

  /**
   * Nombre de la propiedad por la cual se filtrará el campo.
   */
  public get filterby(): string {
    return this.fieldConfig?.filterBy || this.fieldConfig?.optionLabel || 'value';
  }

  /**
   * Tipo de matcheo que se realiza en el filtrado.
   */
  public get filterMatchMode(): string {
    return this.fieldConfig?.filterMatchMode || 'contains';
  }

  /**
   * Funcion para setear el valor asociado al input.
   */
  public setValue(value: string) {
    this.innerValue = value;

    this.value.setValue(this.innerValue);
  }

  /**
   * On onFocus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }

  /**
   * On onBlur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }

  /**
   * On onInput event handler.
   */
  public onChange(e): void {
    this.search$.next(e);
  }

  /**
   * On onClick event handler.
   */
  public onClick(e): void {
    if (this.fieldConfig?.onClick) {
      this.fieldConfig?.onClick(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }

  /**
   * On onFilter event handler.
   */
  public onFilter(e): void {
    if (this.fieldConfig?.onFilter) {
      this.fieldConfig?.onFilter(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }

  /**
   * On onShow event handler.
   */
  public onShow(e): void {
    if (this.fieldConfig?.onShow) {
      this.fieldConfig?.onShow(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }

  /**
   * On onHide event handler.
   */
  public onHide(e): void {
    if (this.fieldConfig?.onHide) {
      this.fieldConfig?.onHide(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.rootFormGroup.addControl(
      this.fieldConfig.name + 'value',
      new FormControl({
        value: null,
        disabled: true,
      })
    );

    this.setValue(null);

    const actions = {
      setValue: (value) => this.control.setValue(value),
      setAssociatedValue: this.setValue.bind(this),
      disable: this.control.disable.bind(this.control),
      enable: this.control.enable.bind(this.control),
      validate: () => this.control.updateValueAndValidity(),
    };

    const handleChange = (e: Event) => {
      this.control.disable();
      this.fieldConfig?.onChange(e, actions);
    };

    // Inicializamos handler `onChange` para busquedas.
    if (this.fieldConfig?.onChange) {
      // Realizamos onChange en busqueda por valueChanges.
      this.control.valueChanges
        .pipe(
          takeUntil(this.destroySubject$.asObservable()),
          distinctUntilChanged((prev, next) => {
            return prev === next;
          })
        )
        .subscribe((e: Event) => {
          handleChange(e);
        });
      // Realizamos onChange en busqueda por botón.
      this.search$
        .asObservable()
        .pipe(takeUntil(this.destroySubject$.asObservable()))
        .subscribe((e: Event) => handleChange(e));
    }
  }
}
