import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableInput } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessagesMainService } from '../services/messages-main.service';
import { IdentificarError } from '@app-services/utils/identifyError.service';
import { ESTATUS_MENSAJES_INFORMATIVOS, ManejoErroresService } from '../services/manejo-errores.service';

/**
 * Interceptor para gestionar manejo de errores y escenarios donde el servicio devuelva un mensaje.
 * Para ser utilizado necesita el el header 'InterceptorMensajes' con valor 'true'
 */
@Injectable()
export class MensajesInterceptor implements HttpInterceptor {
  /**
   * @inheritdoc
   */
  constructor(private mensajeService: MessagesMainService, private manejoErroresService: ManejoErroresService) {}

  /**
   * Implementación del método intercept de HttpInterceptor
   * @param request
   * @param next
   * @returns
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!(request.headers.has('InterceptorMensajes') && request.headers.get('InterceptorMensajes') === 'true')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse && evt?.body?.error?.mensaje) {
          this.mensajeService.messageWarning(evt.body.error.mensaje);
        }
      }),
      catchError((err: any) => this.interceptCatchError(err))
    );
  }

  private interceptCatchError(err): ObservableInput<any> {
    if (err instanceof HttpErrorResponse && !this.manejoErroresService.aplicandoReintentos) {
      if (ESTATUS_MENSAJES_INFORMATIVOS.includes(err.status)) {
        if (err.error?.error?.mensaje) {
          this.mensajeService.messageWarning(err.error?.error?.mensaje);
        }
        else if(err?.error?.meta?.message){
          this.mensajeService.messageWarning(err?.error?.meta?.message);
        }
        else{
          this.mensajeService.messageWarning(err?.error?.detail);
        }
        
      } else if (!this.mensajeService.getEstadoMensajeInterceptor) {
        const cerrarToast = (): void => {
          this.mensajeService.setEstadoMensajeInterceptor = false;
        };

        const abrirToast = (): void => {
          this.mensajeService.setEstadoMensajeInterceptor = true;
        };
        this.mensajeService.messageError(IdentificarError.ObtenerMensaje(err), null, cerrarToast, abrirToast);
      }
    }
    throw err;
  }
}
