import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.scss'],
})
export class AlertaComponent {
  /*  Tipo de alerta a mostrar */
  /*  Necesario */
  @Input() public type: string;

  /*  Valor para mostrar o no el icono de close en la alerta */
  /* Opcional */
  @Input() public dismissible = true;

  /*   Valor del tiempo que se mostrará la alerta */
  /*  Opcional */
  @Input() public dismissOnTimeout: string;

  /*   Valor del tiempo que se mostrará la alerta */
  @Output() public onClose = new EventEmitter<boolean>();

  /*  Tipo de alertas.
 Colores: Verde, azul y rojo respectivamente  */
  public typeAlerts: unknown[] = [
    {
      type: 'success',
    },
    {
      type: 'info',
    },
    {
      type: 'danger',
    },
  ];

  private alerts = this.typeAlerts;

  /**
   * Reset de alerta.
   */
  public reset(): void {
    this.alerts = this.typeAlerts;
  }

  /* Metodo que cierra de manera manual la alerta */
  public onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
    this.onClose.emit(true);
  }
}
