import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { MensajesGenerales } from '@app-services/constantes';
import { TableTemplateDirective } from '../directive/table-template.directive';
import { Column, TableConfiguration } from '../models/table-model';
import { GenericTableManagerService } from '../services/generic-table-manager.service';
import { AbstractTableComponent, GROUP_COLUMN } from './abstract-table';

type StringVariantType = string | string[] | Set<string> | { [klass: string]: any };

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
  providers: [GenericTableManagerService],
})
export class GenericTableComponent extends AbstractTableComponent<TableConfiguration> implements OnInit, OnDestroy {
  /**
   * Configuracion de la tabla.
   */
  @Input()
  public config: TableConfiguration;

  /**
   * Templates de contenido.
   */
  @ContentChildren(TableTemplateDirective)
  public templates: QueryList<TableTemplateDirective>;

  /**
   * @inheritdoc
   */
  public constructor(public manager: GenericTableManagerService, protected cd: ChangeDetectorRef) {
    super(manager, cd);
  }

  /**
   * Gets no data message
   */
  public get noDataMessage() {
    return this.config.noDataMessageCustom ?? MensajesGenerales.OTROS.NO_HAY_REGISTROS;
  }

  /**
   * Retorna el campo de ordenamiento de la tabla.
   */
  public get sortField(): string {
    return this.config?.params?.orderColumn ?? null;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Retorna un template.
   */
  public getTemplate(template: string): TemplateRef<{ data: any }> {
    if (!template) {
      return null;
    }

    const items = this.templates.toArray();

    const instance = items.find((i) => i.name === template?.toString());

    return instance?.template;
  }

  /**
   * Retorna un booleano indicando si una columna es de template.
   */
  public isTemplateColumn(col: Column): boolean {
    return !!col.useTemplate;
  }

  /**
   * Retorna un booleano indicando si una columna es de template.
   */
  public isHeaderTemplateColumn(col: Column): boolean {
    return !!col.useHeaderTemplate;
  }

  /**
   * Retorna un booleano indicando si una columna es de tipo valor.
   */
  public isValueColumn(col: Column): boolean {
    return !col.useTemplate;
  }

  /**
   * Retorna un booleano indicando si una columna es sorteable.
   */
  public isSortableColumn(col: Column): boolean {
    return col.sortable;
  }

  /**
   * Retorna un booleano indicando si la columna es agrupada.
   */
  public isGroupColumn(col: Column, data: any): boolean {
    return this.config.group && col.field === this.config.group;
  }

  /**
   * Retorna un booleano si la celda de grupo debe renderizarse.
   */
  public renderGroupColumn(col: Column, data: any, index: number): boolean {
    const key = data[this.config.group];
    const metadata = this.metaData[GROUP_COLUMN];

    if (!metadata) {
      return false;
    }

    const columnMetadata: { index: number; count: number } = metadata[key];
    return columnMetadata.index === index;
  }

  /**
   * Retorna un booleano si la celda de grupo debe renderizarse.
   */
  public getRowSpan(col: Column, data: any, index: number): number {
    const key = data[this.config.group];
    const metadata = this.metaData[GROUP_COLUMN];

    const columnMetadata: { index: number; count: number } = metadata[key];
    return columnMetadata.count;
  }

  /**
   * Handler para obtener clase de row.
   */
  public getRowClass(data: any, index: number): StringVariantType {
    if (!this.config?.rowClassHandler) {
      return '';
    }

    return this.config.rowClassHandler(data, index);
  }
}
