import { FormControl, ValidatorFn } from '@angular/forms';
import { MensajesGenerales } from '@app-services/constantes';

/**
 * Validador de whitespace.
 */
export const whiteSpaceValidator: ValidatorFn = (control: FormControl) => {
  let value = control.value as string;

  if (value === '' || value == null) {
    return null;
  }

  value = value?.replace(/\s+/g, '');

  if (value === null || value === '') {
    return {
      empty: MensajesGenerales.INFO.CAMPO_REQUERIDO,
    };
  }

  return null;
};
