import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app-services/authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { INTERCEPTAR_ERRORES } from '../interceptors/http-error.interceptor';
import {
  DeleteRequestOptions,
  GetRequestOptions,
  Interceptores,
  PostRequestOptions,
  PreparedBodyRequestFunction,
  PreparedRequestFunction,
  PutRequestOptions,
} from './base.models';

/**
 * Servicio para manejo de llamadas a servicios rest api.
 * Cliente custom para http client.
 */
@Injectable()
export class BaseService {
  /**
   * Constante Header InterceptorLoading
   */
  protected readonly INTERCEPTOR_LOADING = 'InterceptorLoading';
  /**
   * Constante Header InterceptorMensajes
   */
  protected readonly INTERCEPTOR_MENSAJES = 'InterceptorMensajes';
  /**
   * Constante Header InterceptorMensajes
   */
  protected readonly INTERCEPTOR_JWT = 'InterceptorJWT';

  /**
   * Crea una instancia de BaseService.
   * request headers
   */
  constructor(protected http: HttpClient, protected authenticationService: AuthenticationService) {}

  /**
   * Servicio base para ejecutar peticiones HTTP GET
   *
   * @template T Template del tipo de dato a retornar
   * @param {string} requestURL Endpoint del servicio (URL Completa)
   * @param {*} options Parametros típicos del cliente http de angular a excepción de httpParams, httpQueryParams
   * encabezadosInterceptoresCustom, y mensajes
   * @returns {Observable<T>} Observable con interfaz genérica
   */
  public get<T>(requestURL: string, options?: GetRequestOptions): Observable<T> {
    const defaultHeaders = this.getHeadersPorDefecto(options?.encabezadosInterceptoresCustom);
    const headers = this.procesaHeaders(defaultHeaders, options?.headers);

    if (options?.httpParams) {
      Object.keys(options.httpParams).forEach((key) => {
        requestURL += `/${options.httpParams[key]}`;
      });
    }

    const buildOptions = { ...(options || {}), headers };
    return this.http.get<T>(requestURL, buildOptions);
  }

  /**
   * Wrapper del metódo @get para generar URL's preparadas.
   *
   * Las URL preparadas retornan un objeto del tipo @see PreparedRequestFunction que realiza un post-procesamiento
   * de la URL final ofuscando los argumentos.
   *
   * Las URL deben mencionar los parametros de la siguiente forma:
   *
   *  /url/del/servicio/:parametro1/:parametro2
   *
   * El método retorna una funcion a la cual proporcionar el objeto con los parámetros.
   */
  public prepareGet<T>(requestUrl: TemplateStringsArray): PreparedRequestFunction<T, GetRequestOptions> {
    let url = requestUrl.join('');

    return (data, options) => {
      Object.keys(data).forEach((i) => {
        const key = i.replace(':', i);

        const param = data[key]?.toString();

        url = url.replace(`:${key}`, param);
      });

      const preparedOptions = options;

      const base = environment.backendURL;
      return this.get(`${base}${url}`, preparedOptions);
    };
  }

  /**
   * Servicio base para ejecutar HTTP POST
   *
   * @template T Template del tipo de dato a retornar
   * @param requestURL Endpoint del servicio
   * @param body Body request
   * @param {*} options Parametros típicos del cliente http de angular a excepción de encabezadosInterceptoresCustom
   * @param {object} [options.encabezadosInterceptoresCustom] Encabezados custom para aplicar o no interceptores loading
   *  y mensajes
   * @returns {Observable<T>} Observable con interfaz genérica
   */
  public post<T>(requestURL: string, body?: any, options?: PostRequestOptions): Observable<T> {
    const defaultHeaders = this.getHeadersPorDefecto(options?.encabezadosInterceptoresCustom);
    const headers = this.procesaHeaders(defaultHeaders, options?.headers);

    const buildOptions = { ...(options || {}), headers };
    return this.http.post<T>(`${requestURL}`, body, buildOptions);
  }

  /**
   * Wrapper del metódo @post para generar URL preparadas.
   *
   * Las URL preparadas retornan un objeto del tipo @see PreparedRequestFunction que realiza un post-procesamiento
   * de la URL final ofuscando los argumentos.
   *
   * Las URL deben mencionar los parametros de la siguiente forma:
   *
   *  /url/del/servicio/:parametro1/:parametro2
   *
   * El método retorna una funcion a la cual proporcionar el objeto con los parámetros.
   */
  public preparePost<T>(requestUrl: TemplateStringsArray): PreparedBodyRequestFunction<T, PostRequestOptions> {
    let url = requestUrl.join('');

    return (body, data, options) => {
      Object.keys(data).forEach((i) => {
        const key = i.replace(':', i);

        const param = data[key]?.toString();

        url = url.replace(`:${key}`, param);
      });

      const preparedOptions = options;

      return this.post(`${environment.backendURL}${url}`, body, preparedOptions);
    };
  }

  /**
   * Servicio base para ejecutar HTTP PUT
   *
   * @template T Template del tipo de dato a retornar
   * @param {string} requestURL Endpoint del servicio
   * @param {*} body Body request in Object or JSON format
   * @param options
   * @returns {Observable<T>} Observable con interfaz genérica
   */
  public put<T>(requestURL: string, body?: any, options?: PutRequestOptions): Observable<T> {
    const defaultHeaders = this.getHeadersPorDefecto(options?.encabezadosInterceptoresCustom);
    const headers = this.procesaHeaders(defaultHeaders, options?.headers);

    const buildOptions = { ...(options || {}), headers };
    return this.http.put<T>(`${requestURL}`, body, buildOptions);
  }

  /**
   * Wrapper del metódo @post para generar URL's preparadas.
   *
   * Las URL preparadas retornan un objeto del tipo @see PreparedRequestFunction que realiza un post-procesamiento
   * de la URL final ofuscando los argumentos.
   *
   * Las URL deben mencionar los parametros de la siguiente forma:
   *
   *  /url/del/servicio/:parametro1/:parametro2
   *
   * El método retorna una funcion a la cual proporcionar el objeto con los parámetros.
   */
  public preparePut<T>(requestUrl: TemplateStringsArray): PreparedBodyRequestFunction<T, PutRequestOptions> {
    let url = requestUrl.join('');

    return (body, data, options) => {
      Object.keys(data).forEach((i) => {
        const key = i.replace(':', i);

        const param = data[key]?.toString();

        url = url.replace(`:${key}`, param);
      });

      const preparedOptions = options;

      return this.put(`${environment.backendURL}${url}`, body, preparedOptions);
    };
  }

  /**
   * Servicio base para ejecutar HTTP DELETE
   *
   * @template T Template del tipo de dato a retornar
   * @param {string} requestURL Endpoint del servicio
   * @returns {Observable<T>} Observable con interfaz genérica
   */
  public delete<T>(requestURL: string, options?: DeleteRequestOptions): Observable<T> {
    const defaultHeaders = this.getHeadersPorDefecto(options?.encabezadosInterceptoresCustom);
    const headers = this.procesaHeaders(defaultHeaders, options?.headers);

    const buildOptions = { ...(options || {}), headers };
    return this.http.delete<T>(`${requestURL}`, buildOptions);
  }

  /**
   * Wrapper del metódo @delete para generar URL's preparadas.
   *
   * Las URL preparadas retornan un objeto del tipo @see PreparedRequestFunction que realiza un post-procesamiento
   * de la URL final ofuscando los argumentos.
   *
   * Las URL deben mencionar los parametros de la siguiente forma:
   *
   *  /url/del/servicio/:parametro1/:parametro2
   *
   * El método retorna una funcion a la cual proporcionar el objeto con los parámetros.
   */
  public prepareDelete<T>(requestUrl: TemplateStringsArray): PreparedRequestFunction<T, DeleteRequestOptions> {
    let url = requestUrl.join('');

    return (data, options) => {
      Object.keys(data).forEach((i) => {
        const key = i.replace(':', i);

        const param = data[key]?.toString();

        url = url.replace(`:${key}`, param);
      });

      const preparedOptions = options;

      return this.delete(`${environment.backendURL}${url}`, preparedOptions);
    };
  }

  /**
   * Retorna un objeto `HttpHeaders` con los Headers por defecto.
   */
  protected getHeadersPorDefecto(interceptores: Interceptores): HttpHeaders {
    let headers = new HttpHeaders();

    if (interceptores?.interceptorLoading === undefined || interceptores?.interceptorMensajes) {
      headers = headers.set(this.INTERCEPTOR_LOADING, 'true');
    }

    if (interceptores?.interceptorMensajes === undefined || interceptores?.interceptorMensajes) {
      headers = headers.set(this.INTERCEPTOR_MENSAJES, 'true');
    }

    if (interceptores?.interceptarErrores === undefined || interceptores?.interceptorMensajes) {
      headers = headers.set(INTERCEPTAR_ERRORES, 'true');
    }

    if (interceptores?.InterceptorJWT === false) {
      headers = headers.set(this.INTERCEPTOR_JWT, 'false');
    }

    const token = this.authenticationService.token;
    if (token != null) {
      headers = headers.set('Authorization', token);
    }

    // Trigger lazy initialization.
    const _ = headers.keys();

    return headers;
  }

  /**
   * Valida si existen parametros indicados en la petición y los agrega los headers default.
   */
  protected procesaHeaders(headers: HttpHeaders, headersPeticion: HttpHeaders) {
    let instance = headers;

    if (!headersPeticion) {
      return instance;
    }

    headersPeticion?.keys()?.forEach((key) => {
      if (!instance.has(key)) {
        instance = instance.append(key, headersPeticion.get(key));
      }
    });

    // Trigger lazy initialization.
    const _ = instance.keys();

    return instance;
  }
}
