import { PermissionData, PermissionMap } from '@app-shared/generic-roles/models/models';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class AbstractPermissionManager<T> {
  /**
   * Permisos disponibles.
   */
  private items = new BehaviorSubject<PermissionMap<T>>(new Map());

  /**
   * Inicializa el manager de permisos.
   */
  public abstract init(...params): void;

  /**
   * Inicializa el mapa de servicios.
   */
  public populate(permissions: T[]): void {
    const permissionsMap = this.buildMap();

    permissions.forEach((i) => {
      permissionsMap.set(i?.toString(), { permission: i });
    });

    this.items.next(permissionsMap);
  }

  /**
   * Limpia el mapa de permisos.
   */
  public flush(): void {
    this.items.next(this.buildMap());
  }

  /**
   * Añade un permiso individual.
   */
  public add(index: string, permiso: PermissionData<T>) {
    const permissionsMap = this.items.getValue();

    permissionsMap.set(index, permiso);

    this.items.next(permissionsMap);
  }

  /**
   * Remueve un permiso individual
   */
  public remove(index: string) {
    const permissionsMap = this.items.getValue();

    permissionsMap.delete(index);

    this.items.next(permissionsMap);
  }

  /**
   * Evalua si se cuenta con un permiso.
   */
  public hasPermission(index: string): boolean {
    const permissionsMap = this.items.getValue();

    if (!permissionsMap.has(index)) {
      return false;
    }

    const item = permissionsMap.get(index);
    if (item) {
      return true;
    }

    return false;
  }

  /**
   * Evalua si se cuenta con un permiso.
   */
  public hasPermission$(index: string): Observable<boolean> {
    const permissionsMap = this.items.getValue();

    if (!permissionsMap.has(index)) {
      return of(false);
    }

    const item = permissionsMap.get(index);
    if (item) {
      return of(true);
    }

    if (item.validatorFunction) {
      return item.validatorFunction();
    }
    return null;
  }

  /**
   * Evalua si se cuenta con un listado de permisos.
   */
  public hasPermissions(items: string[]) {
    const permissions$ = items.map((i) => this.hasPermission$(i));

    return forkJoin(permissions$).pipe(map((data) => data.every((i) => i)));
  }

  /**
   * Retorna un mapa de permisos nuevo.
   */
  private buildMap(): Map<string, PermissionData<T>> {
    return new Map<string, PermissionData<T>>();
  }
}
