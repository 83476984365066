import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appMobileSidebarToggler]',
})
export class MobileSidebarToggleDirective {
  constructor() {
    /*Empty*/
  }

  @HostListener('click', ['$event'])
  public toggleOpen($event: any) {
    $event.preventDefault();
    if (document.querySelector('app-root')) {
      document.querySelector('app-root').classList.toggle('sidebar-mobile-show');
    }
  }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp(`(\\s|^) ${elementClassName} (\\s|$)`).test(target.className);
  }
}
