<p-dialog
  [(visible)]="mostrarVisor"
  [header]="titulo"
  [modal]="configModal"
  [position]="configPosition"
  [resizable]="configRezisable"
  [draggable]="configDraggable"
  [style]="configStyle"
  [appendTo]="configAppendTo"
  (onHide)="cerrarModal()"
>
  <ng-container>
    <iframe *ngIf="esDocumento" title="Documento" [src]="contentFile"> </iframe>

    <iframe *ngIf="esArchivoGoogleDrive" title="Visor" [src]="urlVisor"> </iframe>

    <div class="d-flex justify-content-center align-items-center" *ngIf="esImagen">
      <img [src]="contentFile" class="img-fluid" height="100%" alt="Imagen visor" />
    </div>
    <div class="icono_container" *ngIf="mostrarContenedorIconos">
      <div *ngIf="esVideo"><i class="cust_icon-Modal_Visor_Archivo_Video icono_visor"></i></div>
      <div *ngIf="esAudio"><i class="cust_icon-Modal_Visor_Archivo_Audio icono_visor"></i></div>
      <div *ngIf="esComprimido"><i class="cust_icon-Modal_Visor_Archivo_Comprimido icono_visor"></i></div>
      <div *ngIf="esExtensionInvalida" class="formato_obsoleto">
        <i class="cust_icon-Modal_Generico_Visor_Archivos_Obsoleto icono_visor"></i>
        <h3>Formato obsoleto para mostrarse</h3>
      </div>
    </div>

    <div class="mt-3" *ngIf="errorConsultaDocumento && errorConsultaDocumento !== ''">
      <h4 class="text-danger">{{ errorConsultaDocumento }}</h4>
    </div>
  </ng-container>

  <p-footer>
    <app-button
      variant="primary"
      *ngIf="permitirDescarga && !descargandoArchivo && !errorConsultaDocumento"
      (onClick)="descargar()"
      [disabled]="loading"
    >
      <i class="cust_icon-Descargar_archivo1 fa-1x"></i>Descargar Archivo
    </app-button>
    <h6 *ngIf="descargandoArchivo" class="text-info">Descargando Archivo...</h6>
  </p-footer>
</p-dialog>
