<ng-container *ngIf="initialized">
  <form [formGroup]="instance" #f="ngForm" (ngSubmit)="onSubmit(f)">
    <fieldset [disabled]="disableFieldset" *ngIf="!omitFieldSetValue" #fieldset>
      <legend></legend>
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </fieldset>

    <ng-container *ngIf="omitFieldSetValue">
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </ng-container>
  </form>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>

  <div class="row">
    <ng-container *ngIf="config">
      <ng-container *ngFor="let field of config">
        <ng-container *ngIf="!field.hide">
          <div class="{{ getColWidth(field) }}">
            <ng-container genericField [config]="field" [group]="instance"></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
