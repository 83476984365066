<!--Formulario para validar colaborador-->
<form
  name="form"
  (ngSubmit)="f.form.valid && login(f)"
  #f="ngForm"
  novalidate
  class="form2"
>
  <h2 class="text-center">
    Inicia sesión con tu Colaborador Digital<span>.</span>
  </h2>
  <p class="text-muted">Los mismos datos que utilizas en Coppel.com</p>
  <div>
    <p class="colaborador">Correo</p>
    <input
      [ngClass]="{ 'is-invalid': f.submitted && !username.valid }"
      type="text"
      name="logonId"
      placeholder="Correo"
      class="form-control usuario"
      #username="ngModel"
      ngModel
      required
    />
  </div>
  <div class="mt-1">
    <p class="contrase">Contraseña

    </p>
    <input
      [ngClass]="{ 'is-invalid': f.submitted && !password.valid }"
      type="password"
      name="logonPassword"
      placeholder="Password"
      class="form-control pass"
      #password="ngModel"
      ngModel
      required
    />
  </div>
  <div class="row mt-4">
    <button
      type="submit"
      [disabled]="f.form.invalid"
      class="b-ingresar btn btn-primary"
      appCaptchaReader
    >
      Ingresar
    </button>
    <button
      *ngIf="allowBack"
      type="button"
      (click)="back()"
      class="b-ingresar btn btn-secondary"
    >
      Volver
    </button>
  </div>
</form>
