import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-display',
  template: `
    <div class="text-center warning">
      <i class="{{ icon }} no-select icon"></i>
      <p class="text-no-data no-select">{{ report }}</p>
      <!-- <button *ngIf="rutaVolver" class="btn btn-outline-dark" [routerLink]="rutaVolver"><i class="fas fa-arrow-left"></i>  Volver</button>   -->
    </div>
  `,
  styles: [
    `
      .warning {
        padding: 3rem;
      }
      .icon {
        font-size: 10rem;
        padding-top: 3rem;
        padding-bottom: 2rem;
        color: rgba(0, 0, 0, 0.2);
      }
      .text-no-data {
        font-size: 2rem;
        color: rgba(0, 0, 0, 0.2);
      }
    `,
  ],
})
export class ErrorDisplayComponent {
  @Input() public icon = 'fas fa-plug';
  @Input() public report = 'Tenemos algunos problemas. Por favor intenta más tarde.';
  @Input() public rutaVolver;
}
