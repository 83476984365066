import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  selector:
    "[appHostReplace], app-aside, app-breadcrumbs, app-footer, app-header, app-sidebar, app-sidebar-footer, " +
    "app-sidebar-form, app-sidebar-header, app-sidebar-minimizer, app-sidebar-nav, app-sidebar-nav-dropdown, " +
    "app-sidebar-nav-item, app-sidebar-nav-link, app-sidebar-nav-title",
})
export class ReplaceDirective implements OnInit {
  constructor(private el: ElementRef) {}

  // wait for the component to render completely
  public ngOnInit() {
    const nativeElement: HTMLElement = this.el.nativeElement;
    const parentElement: HTMLElement = nativeElement.parentElement;
    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }
    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);
  }
}
