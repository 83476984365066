import { Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  Column,
  TableConfiguration,
  TableQueryParams,
  TableResponse,
} from '@app-shared/generic-tables/models/table-model';
import { LoadingService } from '@app-shared/services/loading.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

/**
 * Valor default de pagina.
 */
const DEFAULT_PAGE = 0;

/**
 * Valor default de limite de resultados.
 */
const DEFAULT_LIMIT = 10;

/**
 * Valor default de ordenación.
 */
const DEFAULT_ORDER = 'asc';

/**
 * Offset inicial.
 */
const DEFAULT_OFFSET = 0;

/**
 * Servicio encargado de gestion intracomponentes del modulo `generic-tables`.
 */
@Injectable()
export class GenericTableManagerService implements OnDestroy {
  /**
   * Emisor de destruccion del componente.
   *
   * Este emisor es utilizado para la desuscripción de observables dentro del servicio.
   */
  protected destroySubject$ = new Subject<void>();

  /**
   * Booleano que indica si se ha inicializado la tabla.
   */
  protected initializedSubject$ = new BehaviorSubject<boolean>(false);

  /**
   * Emisor de datos de la tabla.
   */
  protected dataSubject$ = new BehaviorSubject<any[]>([]);

  /**
   * Emisor de datos de la tabla.
   */
  protected columnSubject$ = new Subject<Column[]>();

  /**
   * Emisor de datos de selección de la tabla.
   */
  protected selectionSubject$ = new Subject<any[]>();

  /**
   * Configuracion de la tabla.
   */
  protected config: TableConfiguration;

  /**
   * Parametros de listado actuales.
   */
  protected query: TableQueryParams;

  /**
   * Constructor de servicio `GenericTableManagerService`.
   */
  constructor(protected zone: NgZone, protected loadingService: LoadingService) {}

  /**
   * Booleano que indica si se ha inicializado la tabla.
   */
  get initialized$(): Observable<boolean> {
    return this.initializedSubject$.asObservable();
  }

  /**
   * Observable de emision de datos de la tabla.
   */
  get data$(): Observable<any[]> {
    return this.dataSubject$.asObservable();
  }

  /**
   * Observable de emisión de columnas.
   */
  get column$(): Observable<Column[]> {
    return this.columnSubject$.asObservable();
  }

  /**
   * Observable de emisión de elementos seleccionados.
   */
  get selection$(): Observable<any[]> {
    return this.selectionSubject$.asObservable();
  }

  /**
   * Get table query.
   */
  get tableQuery(): TableQueryParams {
    return {
      ...this.query,
    };
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.initializedSubject$.next(false);
    this.initializedSubject$.complete();

    this.dataSubject$.complete();
    this.selectionSubject$.complete();

    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  /**
   * Inicializador de configuracion.
   * @param {TableConfiguration} config
   */
  public initialize(config: TableConfiguration) {
    const stopLoading = this.loadingService.trigger();

    this.config = config;
    // Seteamos parametros iniciales.
    this.parseQueryParams();
    // Disparamos columnas existentes.
    this.columnSubject$.next(this.config.columns);
    // Mostramos la tabla.
    setTimeout(() => this.initializedSubject$.next(true));

    stopLoading();
  }

  /**
   * Handler para seleccion de elementos.
   */
  public selectionChange(selection: any) {
    this.selectionSubject$.next(selection);
  }

  /**
   * Handler para obtener datos.
   */
  public getData() {
    const params = this.query;
    const stopLoading = this.loadingService.trigger();

    this.config
      .dataHandler(params)
      .pipe(
        take(1),
        finalize(() => stopLoading())
      )
      .subscribe((response) => {
        if (!response) {
          return;
        }
        this.parseQueryParams(response.query);
        this.dataSubject$.next(response.data);
      });
  }

  /**
   * Realiza una nueva petición con los ultimos parametros de datos.
   *
   * @param {number} offset Offset de elementos en el reset.
   * Indiquese si se debe mantener el paginado despues del reset.
   */
  public reset(offset?: number): void {
    // Seteamos el offser a 0 para ir a la pagina inicial.
    this.parseQueryParams(this.config.params, offset ?? DEFAULT_OFFSET);

    if (this.config.data) {
      const stopLoading = this.loadingService.trigger();
      this.config.data = [...this.config.data];
      setTimeout(() => stopLoading());
      return;
    }

    this.getData();
  }

  /**
   * Limpia los datos de la tabla.
   * @returns {void}
   */
  public clearData(): void {
    const stopLoading = this.loadingService.trigger();
    if (this.config.data) {
      this.config.data = [];
      return;
    }

    this.parseQueryParams();
    this.dataSubject$.next([]);
    setTimeout(() => stopLoading());
  }

  /**
   * Handler para construcción de query de datos.
   */
  public parseQueryParams(params?: TableQueryParams, offset?: number): TableQueryParams {
    const query: TableQueryParams = {
      // Nuestro orden de obtención de datos es primeramente argumentos,
      // despues parametros de configuracion, finalmente valores default.
      page: params?.page ?? this.config?.params?.page ?? DEFAULT_PAGE,
      limit: params?.limit ?? this.config?.params?.limit ?? DEFAULT_LIMIT,
      order: params?.order ?? this.config?.params?.order ?? DEFAULT_ORDER,
      // En el caso de la columna de ordenación es necesario que se brinde desde parametrica.
      orderColumn: params?.orderColumn ?? this.config?.params?.orderColumn,
      // En el caso del numero de resultados, es necesario sea bridando por argumentos.
      totalItems: params?.totalItems,
    };

    // Si existe un offset de datos, lo tomamos para recalcular la pagina inicial.
    if (offset) {
      query.page = offset / query.limit;
    }

    // Seteamos el offset de resultados.
    // Esto se realiza cuando llamamos desde un Lazy Load o un Reset.
    // Es necesario setear este offset para actualizar el numero de pagina en el paginador.
    query.offset = offset ?? params?.offset ?? 0;

    this.query = query;

    return query;
  }
}

/**
 * Funcion para preparar el table response de datos.
 */
export function prepareTableResponse(
  initialParams: TableQueryParams,
  overrideParams: Partial<TableQueryParams>,
  data: any[]
): TableResponse {
  return {
    query: {
      ...initialParams,
      ...overrideParams,
    },
    data,
  };
}
