/**
 * Mapa base para gestion de la fabrica de cache.
 */
const baseMap = new WeakMap();

/**
 * Retorna un objeto de tipo @see Map que esta bindeado a un nombre de cache y a un objeto.
 *
 * Esta fabrica se basa en un @see WeakMap como mapa raiz que mantiene referencias debiles para facilitar el manejo
 * de garbage colection.
 *
 * @param instance Instancia a la que se liga el cache.
 * @param cacheName Nombre del mapa de cache.
 * @returns Mapa de cache.
 */
export function cacheMapFactory<T>(instance: object, cacheName: string): Map<string | number, T> {
  if (!baseMap.has(instance)) {
    const instancesCache = new Map<string, any>();
    baseMap.set(instance, instancesCache);
  }

  const instanceCache = baseMap.get(instance) as Map<string, any>;

  if (!instanceCache.has(cacheName)) {
    const cache = new Map<string | number, T>();
    instanceCache.set(cacheName, cache);
  }

  return instanceCache.get(cacheName);
}

/**
 * Retorna un objeto de tipo @see WeakMap que esta bindeado a un nombre de cache y a un objeto.
 *
 * Esta fabrica se basa en un @see WeakMap como mapa raiz que mantiene referencias debiles para facilitar el manejo
 * de garbage colection.
 *
 * @param instance Instancia a la que se liga el cache.
 * @param cacheName Nombre del mapa de cache.
 * @returns Mapa de cache.
 */
export function cacheWeakMapFactory<T>(instance: object, cacheName: string): WeakMap<object, T> {
  if (!baseMap.has(instance)) {
    const instancesCache = new Map<string, any>();
    baseMap.set(instance, instancesCache);
  }

  const instanceCache = baseMap.get(instance) as Map<string, any>;

  if (!instanceCache.has(cacheName)) {
    const cache = new WeakMap<object, T>();
    instanceCache.set(cacheName, cache);
  }

  return instanceCache.get(cacheName);
}
