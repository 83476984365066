import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Personal } from '@app-services/modules/Personal/personal';
import { PersonalService } from '@app-services/modules/Personal/personal.service';
import { UsuarioRolDTO } from '@app-services/modules/Usuarios/usuarios';
import { FiltroDatos } from '@app-shared/filtro-datos/filtrodatos.decorator';
import {
  IResponseAction,
  IResponseList,
  IResponsePage,
  IResponseSingle,
  ResultsDTO,
} from '@app-shared/models/IResponse';
import { CombosBase, GeneralResponse } from '@app-shared/models/general.interface';
import { BaseService } from '@app-shared/services/base.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  AddUpdateRol,
  CatalogoRoles,
  DatosColaborador,
  EmpresaRol,
  ModuloPermisoDTO,
  OpcionesSeguimientoSucesosFlujoDTO,
  PantallaSeguimientoOpcion,
  ProcesoFlujosDTO,
  Rol,
  RolGuardarDto,
} from '../models/roles.interface';
import { ColaboradorCargaMasivaDTO, UsuarioDTO } from '../models/roles.models';
import { TipoResponsable } from '../models/tipo-responsable';
import { filtroProcesos } from './filtro-procesos.filter';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  /**
   * Constructor de clase `RolesService`.
   */
  constructor(private http: HttpClient, private baseService: BaseService, private personalService: PersonalService) {}

  /**
   * Método que invoca al servicio para consultar el catálogo de roles
   * @returns Observable con los datos de respuesta del servicio.
   */
  public obtenerCatalogoRolesPorIdEmpresa(IdEmpresa: string): Observable<IResponseList<CatalogoRoles>> {
    return this.baseService
      .get<IResponseList<CatalogoRoles>>(`${environment.backendURL}${environment.urls.rolesCatalogo}/${IdEmpresa}`)
      .pipe(map((response) => response));
  }

  /**
   * Método que invoca al servicio para consultar el catálogo de roles
   * @returns Observable con los datos de respuesta del servicio.
   */
  public obtenerEmpresaRol(): Observable<IResponseList<EmpresaRol>> {
    return this.baseService
      .get<IResponseList<EmpresaRol>>(`${environment.backendURL}${environment.urls.rolesEmpresa}/`)
      .pipe(map((response) => response as IResponseList<EmpresaRol>));
  }

  /**
   * Método para obtener el catalogo de roles
   */
  public obtenerCatalogoRoles(): Observable<IResponseList<CatalogoRoles>> {
    return this.baseService.get(`${environment.backendURL}/roles/`);
  }

  /**
   * Método que consulta los registros de los roles
   *
   * @param filtro Filtro opcionales en la busqueda del rol
   * @param pagina Pagina actual (0...N)
   * @param limite Limite de registros a mostrar
   * @param columna Columna por la cual sera ordenada
   * @param esOrdenamientoAsc Bandera si es el ordenamiento de la columna en ASC
   * @returns
   */
  //ts-lint:disable:all
  public obtenerRoles(filtro: {
    numColaborador?: number;
    idRol?: number;
    correo?: string;
    idProceso?: number;
    pagina?: number;
    limite?: number;
    columna?: string;
    page?: number;
    size?: number;
    esOrdenamientoAsc?: boolean;
  }): Observable<IResponseSingle<IResponsePage<Rol>>> {
    return this.baseService.post<IResponseSingle<IResponsePage<Rol>>>(`${environment.backendURL}/roles/buscar`, filtro);
  }

  /**
   * Método para eliminar un colaborador de roles
   */
  public eliminarColaborador(numeroColaborador: string): Observable<IResponseSingle<string>> {
    return this.baseService
      .post<IResponseSingle<string>>(
        `${environment.backendURL}${environment.urls.usuario}${environment.urls.rol}/${numeroColaborador}`
      )
      .pipe(map((response) => response as IResponseSingle<string>));
  }

  /**
   * Método para buscar un colaborador juridico no interno
   */
  public buscarColaboradorJuridicoInterno(idUsuario: number): Observable<IResponseSingle<UsuarioDTO>> {
    return this.baseService.post(`${environment.backendURL}${environment.urls.usuarios}/juridico-interno`, {
      idUsuario,
    });
  }

  /**
   * método para buscar colaboradores para la reasignación de tareas
   */
  public filtrarColaboradoresReasignacionTareas(
    page: number,
    size: number,
    correo: string,
    nombre: string,
    numColaborador: number
  ): Observable<any> {
    return this.baseService.post(
      `${environment.backendURL}${environment.urls.usuarios}/busca-juridico-interno?page=${page}&size=${size}&sort=numero_colaborador`,
      {
        juridicoInterno: true,
        ...(correo ? { correo } : {}),
        ...(nombre ? { nombre } : {}),
        ...(numColaborador ? { numColaborador } : {}),
      }
    );
  }

  /**
   * Método para realizar reasignación masiva de tareas y dar de baja un colaborador de un rol
   */
  public reasignarTareasDarBajaRol(params: {
    idUsuario: number;
    tareas: { idTarea: number; numColaborador: number }[];
  }): Observable<IResponseAction> {
    return this.baseService.put<IResponseAction>(
      `${environment.backendURL}${environment.urls.usuarios}/baja-juridico-no-interno`,
      params
    );
  }

  /**
   * Obteners tareas sin cumplir
   * @param numeroInvolucrado
   * @returns
   */
  public obtenerTareasSinCumplir(numeroInvolucrado: number): Observable<any> {
    return this.baseService.get<any>(
      `${environment.backendURL}${environment.urls.tareas}/sinCumplir?numeroInvolucrado=${numeroInvolucrado}`
    );
  }

  /**
   * Método para validar si se puede dar de baja un usuario interno de un rol
   */
  public validarBajaUsuarioInterno(idUsuario: number): Observable<any> {
    return this.baseService.post(
      `${environment.backendURL}${environment.urls.usuario}${environment.urls.rol}${environment.urls.validar}/${idUsuario}`,
      { idUsuario, idFlujo: null }
    );
  }

  /**
   * Método para eliminar un rol
   */

  public eliminarRol(idRol: string): Observable<IResponseSingle<string>> {
    return this.baseService.post(`${environment.backendURL}${environment.urls.roles}/${idRol}`);
  }

  /**
   * Método para eliminar un rol
   * @param idRol
   * @returns
   */
  public deleteRol(idRol: string): Observable<IResponseSingle<string>> {
    return this.baseService
      .post<IResponseSingle<string>>(`${environment.backendURL}/roles/${idRol}`, {})
      .pipe(map((response) => response as IResponseSingle<string>));
  }

  /**
   * : Documentar
   * @returns
   */
  public categoriasRol(): Observable<IResponseList<CombosBase>> {
    return this.baseService
      .get<IResponseList<CombosBase>>(`${environment.backendURL}/roles/categoria-rol/`)
      .pipe(map((response) => response as IResponseList<CombosBase>));
  }

  public dependeDe(): Observable<IResponseList<CombosBase>> {
    return this.baseService
      .get<IResponseList<CombosBase>>(`${environment.backendURL}/roles/`)
      .pipe(map((response) => response as IResponseList<CombosBase>));
  }

  public tiposDependencias(): Observable<IResponseList<CombosBase>> {
    return this.http
      .get<IResponseList<CombosBase>>(`${environment.backendURL}/roles/tipos-dependencias/`)
      .pipe(map((response) => response as IResponseList<CombosBase>));
  }

  /**
   * Obtiene un objeto del tipo `RolGuardarDto` para el id definido.
   */
  public rolPorId(id: number): Observable<IResponseSingle<RolGuardarDto>> {
    let flujos: ProcesoFlujosDTO[] = [];
    return this.obtenerFlujos$().pipe(
      take(1),
      mergeMap((r) => {
        flujos = r?.datos?.response ?? [];
        return this.baseService.get<IResponseSingle<RolGuardarDto>>(`${environment.backendURL}/roles/${id}`);
      }),
      map((r) => {
        const rol = r.datos;
        const procesos = rol.procesos;
        const flujosFiltrados = flujos.map((f) => f.idFlujo);

        rol.procesos = procesos.filter((i) => flujosFiltrados.includes(i.idFlujo));

        return r;
      })
    );
  }

  /**
   * Retorna un array de `CatalogoLongDto` con los Flujos disponibles.
   * @see com.coppel.sajv2.controllers.ProcesosController.obtenerProcesosPorFlujosActivos
   * @see com.coppel.sajv2.services.impl.ProcesoServiceImpl.obtenerProcesosPorFlujosActivos
   */
  @FiltroDatos(filtroProcesos)
  public obtenerFlujos$(): Observable<IResponseList<ProcesoFlujosDTO>> {
    return this.baseService
      .get<IResponseList<ProcesoFlujosDTO>>(`${environment.backendURL}/procesos/flujos-activos`)
      .pipe(take(1));
  }

  /**
   * Retorna un array de `ProcesoFlujosDTO` con los Flujos consultados por proceso.
   * @see com.coppel.sajv2.controllers.ProcesosController.obtenerProcesoFlujosPorIdProceso
   * @see com.coppel.sajv2.services.impl.ProcesoServiceImpl.obtenerProcesoFlujosPorIdsProcesos
   */
  public obtenerFlujosPorIdProceso(flujos: number[]): Observable<IResponseList<ProcesoFlujosDTO>> {
    if (!flujos.length) {
      return of(null);
    }

    const items = encodeURIComponent(flujos.join(','));

    return this.http
      .get<IResponseList<ProcesoFlujosDTO>>(`${environment.backendURL}/procesos/procesos-flujos/${items}`)
      .pipe(take(1));
  }

  /**
   * Retorna un array de `CatalogoLongDto` con los Flujos disponibles.
   * @see com.coppel.sajv2.controllers.PantallaSeguimientoController.pantallaSeguimientoOpciones
   * @see com.coppel.sajv2.services.impl.PantallaSeguimientoServiceImpl.obtenerOpcionesPantallaSeguimiento
   */
  public obtenerOpcionesPantallaSeguimiento(): Observable<IResponseList<PantallaSeguimientoOpcion>> {
    return this.http
      .get<IResponseList<PantallaSeguimientoOpcion>>(`${environment.backendURL}/pantalla-seguimiento`)
      .pipe(take(1));
  }

  /**
   * Retorna un array de `CatalogoLongDto` con los Flujos disponibles.
   * @see com.coppel.sajv2.controllers.PantallaSeguimientoController.pantallaSeguimientoOpcionesSucesosPorFlujo
   * @see com.coppel.sajv2.services.impl.PantallaSeguimientoServiceImpl.OpcionesSeguimientoSucesosFlujoDTO
   */
  public pantallaSeguimientoOpcionesSucesosPorFlujo(
    flujo: number
  ): Observable<IResponseSingle<OpcionesSeguimientoSucesosFlujoDTO>> {
    return this.http
      .get<IResponseSingle<OpcionesSeguimientoSucesosFlujoDTO>>(
        `${environment.backendURL}/pantalla-seguimiento/${flujo}`
      )
      .pipe(take(1));
  }

  /**
   * Retorna un array de objetos `Modulo` con los modulos disponibles en sistema.
   * @see com.coppel.sajv2.controllers.ModulosController.buscarModulos
   * @see com.coppel.sajv2.services.impl.ModulosServiceImpl.buscarModulos
   */
  public obtenerModulos(): Observable<IResponseList<ModuloPermisoDTO>> {
    return this.http.get<IResponseList<ModuloPermisoDTO>>(`${environment.backendURL}/modulos/`).pipe(take(1));
  }

  /**
   * Retorna un array de objetos `Modulo` con los modulos consultados.
   * @see com.coppel.sajv2.controllers.ModulosController.buscarModulosPermisos
   * @see com.coppel.sajv2.services.impl.ModulosServiceImpl.buscarModulosPermisos
   */
  public obtenerModulosPorID(modulos: number[]): Observable<IResponseList<ModuloPermisoDTO>> {
    if (!modulos.length) {
      return of(null);
    }

    return this.baseService
      .post<IResponseList<ModuloPermisoDTO>>(`${environment.backendURL}/modulos/modulo-permisos`, {
        idsModulos: modulos,
      })
      .pipe(take(1));
  }

  /**
   * Retorna un objeto de tipo `Personal` referente al id consultado.
   */
  public getColaborador$(id: string): Observable<Personal> {
    return this.personalService.getColaborador(id).pipe(
      map((r) => r?.data),
      take(1)
    );
  }

  /**
   * Método para obtener un colaborador en input de busqueda automatica
   * @param numColaborador
   * @returns
   */
  public getColaboradorInterno(numColaborador: number): Observable<GeneralResponse> {
    return this.baseService.post(`${environment.backendURL}${environment.urls.usuarios}/busca-usuarios-locales`, {
      numColaborador,
    });
  }

  public modulosRol(): Observable<IResponseSingle<any>> {
    return this.http
      .get<IResponseSingle<GeneralResponse>>(`${environment.backendURL}/modulos/modulo-permisos`)
      .pipe(map((response) => response as IResponseSingle<GeneralResponse>));
  }

  public saveRoles(dataSave: AddUpdateRol) {
    return this.baseService.post<IResponseList<GeneralResponse>>(`${environment.backendURL}/roles/`, dataSave);
  }

  public updateRoles(dataUpdate: AddUpdateRol) {
    return this.baseService.put<IResponseList<GeneralResponse>>(`${environment.backendURL}/roles/`, dataUpdate);
  }

  public saveColaborador(idRol: number, data): Observable<IResponseSingle<string>> {
    return this.http
      .post<IResponseSingle<string>>(`${environment.backendURL}/roles/${idRol}/colaborador`, data)
      .pipe(map((response) => response as IResponseSingle<string>));
  }

  public getActivos() {
    return this.baseService.get<IResponseList<GeneralResponse>>(`${environment.backendURL}/procesos/flujos-activos`);
  }

  public getProcesos(process: number[]) {
    return this.http.post<IResponseList<GeneralResponse>>(
      `${environment.backendURL}/procesos/procesos-flujos/`,
      process
    );
  }

  /**
   * Realiza parseo de datos de colaboradores desde un archivo EXCEL.
   */
  public cargaMasivaColaboradores(
    documento: File,
    tipoColaborador: string
  ): Observable<IResponseList<ColaboradorCargaMasivaDTO>> {
    const formData: any = new FormData();
    formData.append('documento', documento);
    formData.append('tipoColaborador', tipoColaborador);

    return this.baseService.post<IResponseList<ColaboradorCargaMasivaDTO>>(
      `${environment.backendURL}/usuario/rol/carga-masiva/colaboradores`,
      formData
    );
  }

  public validProcess(idRol: number, idProcess: number) {
    return this.http.get<IResponseList<GeneralResponse>>(`${environment.backendURL}/roles/${idRol}/${idProcess}`);
  }

  public obtenerColaborador(numeroColaborado: number, tipoColaborador: string) {
    return this.http.get<IResponseSingle<DatosColaborador>>(
      `${environment.backendURL}/usuarios/${numeroColaborado}/colaborador/${tipoColaborador}`
    );
  }

  public getRolBase(idRolBase: number): Observable<IResponseSingle<GeneralResponse>> {
    return this.baseService.get<IResponseSingle<GeneralResponse>>(
      `${environment.backendURL}/${environment.urls.rolBase}/${idRolBase}`
    );
  }

  /**
   *  Obtiene el catalogo de responsables
   */
  public obtenerCatalogoTipoResponsable(): Observable<IResponseList<TipoResponsable>> {
    return this.baseService.get(
      `${environment.backendURL}${environment.urls.catalogo}${environment.urls.tipoResponsable}`
    );
  }

  /**
   * Método para obtener los usuarios de un rol
   */
  public obteneUsariosRoles(
    idRol: number,
    nombre: string,
    numColaborador: number,
    correo: string,
    idProceso: number
  ): Observable<IResponseSingle<ResultsDTO<UsuarioRolDTO>>> {
    return this.baseService.post(
      `${environment.backendURL}${environment.urls.usuario}${environment.urls.rol}/buscarUsuariosRol`,
      {
        ...(idRol ? { idRol } : {}),
        ...(nombre ? { nombre } : {}),
        ...(numColaborador ? { numColaborador } : {}),
        ...(correo ? { correo } : {}),
        ...(idProceso ? { idProceso } : {}),
      }
    );
  }

  /**
   * Método para obtención de cátalogo de procesos
   */

  public obtenerProcesos(idRol: number): Observable<IResponseList<any>> {
    return this.baseService.post(
      `${environment.backendURL}${environment.urls.usuario}${environment.urls.rol}/procesosPorRol`,
      { idRol }
    );
  }
}
