/**
 * Interfaz para hacer request de inicio de sesión externo.
 * @see LoginExternoService
 */
export interface Login {
  usr: string;
  auth: string;
}

/**
 * Interfaz para response de logueo.
 * @see LoginExternoService
 */
export interface RespuestaLogin {
  token: string;
}

/**
 * Interfaz para hacer request de recuperar contraseña.
 * @see LoginExternoService
 */
export interface RecuperarContrasena {
  usr: string;
}

/**
 * Interfaz para hacer request de cambiar contraseña.
 * @see LoginExternoService
 */
export interface CambiarContrasena {
  usrBase64: string;
  newAuth: string;
}

/**
 * Interfaz para cambiar y determinar contraseña
 * @see LoginExternoService
 */
export interface EstablecerContrasena {
  numeroColaborador: string;
  pd: string;
}

/**
 * Interfaz para obtener la respuesta al crear una contrasena
 * @see LoginExternoService
 */
export interface RespuestaCrearContrasena {
  numeroColaborador: number;
  curp: string;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  correo: string;
}

/**
 * Interfaz para el envio de credenciales de usuario
 */
export interface UserCredentials {
  correo: string;
  password: string;
}

/**
 * Interfaz para obtener la respuesta al validar el usuario para determinar su contrasenia
 */
export interface RespuestaValidarUsuario {
  id: number;
  numeroColaborador: number;
  passwordActivo: boolean;
}

/**
 * Interfaz para hacer request de recuperar contraseña.
 * @see LoginExternoService
 */
export interface RespuestaRecuperarContrasena {
  mensaje: string;
}

/**
 * Enum para identificar acciones de login externo.
 */
export enum ACCIONES_LOGIN {
  LOGIN = 1,
  RECUPERAR_PASSWORD = 2,
  CAMBIO_PASSWORD = 3,
}
