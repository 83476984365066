<ng-container *ngIf="initialized$ | async">
  <p-table
    #dataTable
    [styleClass]="styleClass"
    [value]="tableData ? tableData : data"
    [columns]="cols"
    [paginator]="paginator"
    [rows]="rows"
    [lazy]="lazy"
    [lazyLoadOnInit]="lazy"
    (onLazyLoad)="lazy ? lazyLoadHandler($event) : null"
    [(selection)]="selection"
    (selectionChange)="onSelectionChange($event)"
    selectionMode="multiple"
    [rowsPerPageOptions]="paginationOptions"
    [totalRecords]="totalItems"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando {first} a {last} resultados de {totalRecords}"
    [autoLayout]="true"
    [first]="first"
    [scrollable]="scrollable"
    [scrollHeight]="scrollHeight"
    paginatorDropdownScrollHeight="200px"
    [sortField]="sortField"
  >
    <!-- Header -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th scope="col" *ngIf="config?.showCheckboxes">
          <p-tableHeaderCheckbox #headerCheckBox></p-tableHeaderCheckbox>
          <span class="p-ml-2">Todos</span>
        </th>

        <ng-container *ngIf="draggable">
          <th scope="col" style="width: 3rem"></th>
        </ng-container>

        <ng-container *ngFor="let col of columns">
          <ng-container *ngIf="isHeaderTemplateColumn(col)">
            <th scope="col" [hidden]="col.hide" [ngClass]="col.classlist">
              <div class="header-field">
                <app-template-cell
                  [template]="getTemplate(col?.useHeaderTemplate)"
                  [column]="col"
                  [row]="col"
                ></app-template-cell>
              </div>
            </th>
          </ng-container>

          <ng-container *ngIf="!isHeaderTemplateColumn(col) && isSortableColumn(col)">
            <th scope="col" [pSortableColumn]="col.field" [ngClass]="col.classlist">
              {{ col.name }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </ng-container>

          <ng-container *ngIf="!isHeaderTemplateColumn(col) && !isSortableColumn(col)">
            <th scope="col" [hidden]="col.hide" [ngClass]="col.classlist">
              {{ col.name }}
            </th>
          </ng-container>
        </ng-container>

        <th scope="col" *ngIf="showActions">Acciones</th>
      </tr>
    </ng-template>

    <!-- Body -->
    <ng-template pTemplate="body" let-item let-columns="columns" let-index="rowIndex">
      <ng-container *ngIf="!draggable">
        <tr [ngClass]="getRowClass(item, index)">
          <ng-template
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ columns: columns, item: item, index: index }"
          ></ng-template>
        </tr>
      </ng-container>

      <ng-container *ngIf="draggable">
        <tr [ngClass]="getRowClass(item, index)" [pReorderableRow]="index">
          <td>
            <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>

          <ng-template
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ columns: columns, item: item, index: index }"
          ></ng-template>
        </tr>
      </ng-container>
    </ng-template>

    <!-- Mensaje cuando no hay datos -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td
          [attr.colspan]="(columns?.length || 0) + (config?.showCheckboxes ? 1 : 0) + (showActions ? 1 : 0)"
          class="text-center"
        >
          {{ noDataMessage }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>

<!-- Template de fila -->
<ng-template #rowTemplate let-columns="columns" let-index="index" , let-item="item">
  <td *ngIf="config?.showCheckboxes">
    <p-tableCheckbox [value]="item"></p-tableCheckbox>
  </td>

  <ng-container *ngFor="let col of columns">
    <ng-template
      [ngTemplateOutlet]="columntemplate"
      [ngTemplateOutletContext]="{ column: col, value: item, index: index }"
    >
    </ng-template>
  </ng-container>

  <td *ngIf="showActions">
    <ng-template
      [ngTemplateOutlet]="actionstemplate"
      [ngTemplateOutletContext]="{ actions: actions, item: item }"
    ></ng-template>
  </td>
</ng-template>

<!-- Template de accion -->
<ng-template #actionstemplate let-actions="actions" let-item="item">
  <div class="actions-container">
    <ng-container *ngFor="let action of actions">
      <ng-container *ngIf="!action.show || action.show(item, action)">
        <ng-container *ngIf="action.variant; else elseBlock">
          <app-button
            [variant]="action.variant"
            [pTooltip]="action.title"
            [tooltipZIndex]="zIndexToolTip(action)"
            [disabled]="triggerDisabled(action, item)"
            (onClick)="triggerAction($event, action, item)"
          >
            <i class="{{ action.icon }}"></i>
          </app-button>
        </ng-container>
        <ng-template #elseBlock>
          <button
            class="{{ action.classlist }}"
            title="{{ action.title }}"
            (click)="triggerAction($event, action, item)"
          >
            <i class="{{ action.icon }}"></i>
          </button>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<!-- FIN: Template de accion -->

<!-- Template de columna -->
<ng-template #columntemplate let-column="column" let-value="value" let-index="index">
  <!-- Renderizado de columna de grupo -->
  <ng-container *ngIf="isGroupColumn(column, value); else plainblock">
    <!-- Valida si el row debe de imprimirse -->
    <ng-container *ngIf="renderGroupColumn(column, value, index)">
      <td [attr.rowspan]="getRowSpan(column, value, index)">
        <ng-container *ngIf="isTemplateColumn(column)">
          <ng-template
            [ngTemplateOutlet]="columntemplatetemplate"
            [ngTemplateOutletContext]="{ column: column, value: value, index: index }"
          >
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isValueColumn(column)">
          <ng-template
            [ngTemplateOutlet]="columntemplatebasic"
            [ngTemplateOutletContext]="{ column: column, value: value, index: index }"
          >
          </ng-template>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>

  <!-- Renderizado de columna normal -->
  <ng-template #plainblock>
    <td [hidden]="column.hide">
      <div class="column--inner">
        <ng-container *ngIf="isTemplateColumn(column)">
          <ng-template
            [ngTemplateOutlet]="columntemplatetemplate"
            [ngTemplateOutletContext]="{ column: column, value: value, index: index }"
          >
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isValueColumn(column)">
          <ng-template
            [ngTemplateOutlet]="columntemplatebasic"
            [ngTemplateOutletContext]="{ column: column, value: value, index: index }"
          >
          </ng-template>
        </ng-container>
      </div>
    </td>
  </ng-template>
</ng-template>
<!-- FIN: Template de columna -->

<ng-template #columntemplatetemplate let-column="column" let-value="value" let-index="index">
  <app-template-cell
    [template]="getTemplate(column?.useTemplate)"
    [index]="index"
    [column]="column"
    [row]="value"
  ></app-template-cell>
</ng-template>

<ng-template #columntemplatebasic let-column="column" let-value="value" let-index="index">
  <div class="column--inner-content" [ngClass]="column.classlist" (click)="clickHandler($event, column, value)">
    <ng-container *ngIf="column.useFunction; else noFunction">
      {{ column.useFunction(value, column) }}
    </ng-container>
    <ng-template #noFunction>
      {{ value[column.field] }}
    </ng-template>
  </div>

  <div *ngIf="showColumnActions(column)" class="column--actions">
    <ng-container *ngFor="let action of column.actions">
      <button
        class="btn btn-sm btn-light"
        [ngClass]="action.classlist"
        title="{{ action.title }}"
        (click)="triggerAction($event, action, value)"
      >
        <i class="{{ action.icon }}"></i>
      </button>
    </ng-container>
  </div>
</ng-template>
