import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { DevguideService } from "./services/devguide.service";

@Component({
  selector: "app-root",
  template: `
    <app-toaster></app-toaster>
    <ng-container *ngIf="!isIframe">
      <router-outlet></router-outlet>
    </ng-container>
  `,
})
export class AppComponent implements OnInit {
  public isIframe = false;

  constructor(private guide: DevguideService) {
    if (environment.production === false) {
      this.guide.fullConfigVerification();
    }
  }

  public ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
