import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * Ambientes disponibles.
 */
export enum AvailableEnvironmentsEnum {
  DEV = 'DEV',
  QA = 'QA',
  PROD = 'PROD',
  JURIDICOINMOBILIARIO = 'JURIDICOINMOBILIARIO',
  JURIDICOCOLECTIVO = 'JURIDICOCOLECTIVO',
}

/**
 * Directiva estructural de ambiente.
 *
 * Uso:
 * <tag *displayOn="['DEV']; hide: ['JURIDICOINMOBILIARIO']></tag>
 */
// tslint:disable: directive-selector
@Directive({
  selector: '[displayOn]',
})
export class EnvironmentConditionalDirective {
  /**
   * Constructor de clase `EnvironmentConditionalDirective`.
   */
  public constructor(private templateRef: TemplateRef<any>, private container: ViewContainerRef) {}

  /**
   * Ambientes en los que se debe mostrar el template.
   */
  @Input()
  public set displayOn(value: AvailableEnvironmentsEnum[]) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return;
    }

    if ((value ?? []).includes(environment.envName as AvailableEnvironmentsEnum)) {
      this.triggerShow();
      return;
    }

    this.triggerHide();
  }

  /**
   * Ambientes en los que se debe ocultar el template.
   */
  @Input()
  public set displayOnHide(value: AvailableEnvironmentsEnum[]) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return;
    }

    if ((value ?? []).includes(environment.envName as AvailableEnvironmentsEnum)) {
      this.triggerHide();
      return;
    }

    this.triggerShow();
  }

  /**
   * Muestra el template.
   */
  private triggerShow(): void {
    this.container.createEmbeddedView(this.templateRef);
  }

  /**
   * Oculta el template.
   */
  private triggerHide(): void {
    this.container.clear();
  }
}
