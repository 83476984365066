import { Component } from "@angular/core";

// Import navigation elements
import { MenuService } from "src/app/services/menu.service";

@Component({
  selector: "app-sidebar-nav",
  template: `
    <nav class="sidebar-nav">
      <ul class="nav">
        <ng-template ngFor let-navitem [ngForOf]="navigation | async">
          <li *ngIf="isDivider(navitem)" class="nav-divider"></li>
          <ng-template [ngIf]="isTitle(navitem)">
            <app-sidebar-nav-title [title]="navitem"></app-sidebar-nav-title>
          </ng-template>
          <ng-template [ngIf]="!isDivider(navitem) && !isTitle(navitem)">
            <app-sidebar-nav-item [item]="navitem"></app-sidebar-nav-item>
          </ng-template>
        </ng-template>
      </ul>
    </nav>
  `,
})
export class AppSidebarNavComponent {
  public navigation;
  public user: any;

  constructor(private menuService: MenuService) {
    this.navigation = this.menuService.currentMenu;
  }

  public isDivider(item) {
    if (item.divider) {
      return true;
    }
    return false;
  }
  public isTitle(item) {
    if (item.title) {
      return true;
    }
    return false;
  }
}
