import { Injectable } from '@angular/core';
import { cacheMapFactory } from '@app-shared/generic-reactive-forms/utils/cache-factory';
import { TipoAccesoConstantes, TipoUsuarioConstantes } from '@app-shared/generic-roles/models/enum';
import { TipoAccesoDto } from '@app-shared/generic-roles/models/models';
import { deshabilitarRoles } from '@app-shared/generic-roles/utils';
import { IResponseSingle } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { MessagesMainService } from '@app-shared/services/messages-main.service';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GenericRolesService } from './generic-roles.service';

@Injectable({ providedIn: 'root' })
export class RolesAwareService {
  private CONTACTE_AL_ADMINISTRADOR = 'Contacte a su administrador';
  private SIN_PERMISOS_SUFICIENTES =
    'Su usuario no posee permisos suficientes para acceder a la pantalla seleccionada.';

  /**
   * Constructor de clase `RolesAwareFormService`.
   */
  public constructor(
    public genericRolesService: GenericRolesService,
    public baseService: BaseService,
    public messagesmainservice: MessagesMainService
  ) {}

  /**
   * Retorna un valor de tipo `TipoAccesoDto` indicando el tipo de acceso para un asunto.
   */
  public getAccesoPorAsuntoID(id: number | string): Observable<TipoAccesoDto> {
    const cache = cacheMapFactory<TipoAccesoDto>(this, this.getAccesoPorAsuntoID.name);

    if (cache.has(id)) {
      return of(cache.get(id));
    }

    return this.baseService
      .get<IResponseSingle<TipoAccesoDto>>(
        `${environment.backendURL}/rol/usuario/${this.genericRolesService.idUsuario}/asunto/${id}/tipo-acceso`
      )
      .pipe(
        map((r) => {
          cache.set(id, r?.datos);

          return r?.datos;
        })
      );
  }

  /**
   * Retorna un valor de tipo `TipoAccesoDto` indicando el tipo de acceso para un asunto.
   */
  public getAccesoPorAsuntoFolio(folio: number | string): Observable<TipoAccesoDto> {
    const cache = cacheMapFactory<TipoAccesoDto>(this, this.getAccesoPorAsuntoFolio.name);

    if (cache.has(folio)) {
      return of(cache.get(folio));
    }

    return this.baseService
      .get<IResponseSingle<TipoAccesoDto>>(
        `${environment.backendURL}/rol/usuario/${this.genericRolesService.idUsuario}/asunto/folio/${folio}/tipo-acceso`
      )
      .pipe(
        map((r) => {
          cache.set(folio, r?.datos);

          return r?.datos;
        })
      );
  }

  /**
   * Retorna un valor de tipo `TipoAccesoDto` indicando el tipo de acceso para un asunto.
   */
  public getAccesoPorExpedienteUnicoID(id: number | string): Observable<TipoAccesoDto> {
    const cache = cacheMapFactory<TipoAccesoDto>(this, this.getAccesoPorExpedienteUnicoID.name);

    if (cache.has(id)) {
      return of(cache.get(id));
    }

    return this.baseService
      .get<IResponseSingle<TipoAccesoDto>>(
        `${environment.backendURL}/rol/usuario/${this.genericRolesService.idUsuario}/expedienteunico/${id}/tipo-acceso`
      )
      .pipe(
        map((r) => {
          cache.set(id, r?.datos);

          return r?.datos;
        })
      );
  }

  /**
   * Handler para acceso de asunto.
   */
  public getAsuntoHandler(
    asuntoId: number | string,
    handle: (acceso: TipoAccesoConstantes) => void,
    prevenirAccesoConsulta = false
  ) {
    if (deshabilitarRoles()) {
      handle(TipoAccesoConstantes.ENCARGADO);
      return;
    }

    this.getAccesoPorAsuntoID(asuntoId)
      .pipe(take(1))
      .subscribe((acceso) => {
        if (acceso && acceso.tipoUsuario === TipoUsuarioConstantes.EXTERNO) {
          handle(TipoAccesoConstantes.ENCARGADO);
          return;
        }

        if (acceso && acceso.tipoAcceso !== TipoAccesoConstantes.CONSULTA) {
          handle(acceso.tipoAcceso as TipoAccesoConstantes);
          return;
        }

        if (acceso && acceso.tipoAcceso === TipoAccesoConstantes.CONSULTA && !prevenirAccesoConsulta) {
          handle(acceso.tipoAcceso as TipoAccesoConstantes);
          return;
        }

        this.messagesmainservice.messageInfo(this.SIN_PERMISOS_SUFICIENTES, this.CONTACTE_AL_ADMINISTRADOR);
      });
  }

  /**
   * Handler para acceso de asunto.
   */
  public getAsuntoFolioHandler(
    asuntoId: number | string,
    handle: (acceso: TipoAccesoConstantes) => void,
    prevenirAccesoConsulta = false
  ) {
    if (deshabilitarRoles()) {
      handle(TipoAccesoConstantes.ENCARGADO);
      return;
    }

    this.getAccesoPorAsuntoFolio(asuntoId)
      .pipe(take(1))
      .subscribe((acceso) => {
        if (acceso && acceso.tipoUsuario === TipoUsuarioConstantes.EXTERNO) {
          handle(TipoAccesoConstantes.ENCARGADO);
          return;
        }

        if (acceso && acceso.tipoAcceso !== TipoAccesoConstantes.CONSULTA) {
          handle(acceso.tipoAcceso as TipoAccesoConstantes);
          return;
        }

        if (acceso && acceso.tipoAcceso === TipoAccesoConstantes.CONSULTA && !prevenirAccesoConsulta) {
          handle(acceso.tipoAcceso as TipoAccesoConstantes);
          return;
        }

        this.messagesmainservice.messageInfo(this.SIN_PERMISOS_SUFICIENTES, this.CONTACTE_AL_ADMINISTRADOR);
      });
  }

  /**
   * Retorna un valor de tipo `TipoAccesoDto` indicando el tipo de acceso para una notificación.
   */
  public getAccesoPorNotificacionFolio(id: number | string): Observable<TipoAccesoDto> {
    const cache = cacheMapFactory<TipoAccesoDto>(this, this.getAccesoPorNotificacionFolio.name);

    if (cache.has(id)) {
      return of(cache.get(id));
    }

    return this.baseService
      .get<IResponseSingle<TipoAccesoDto>>(
        `${environment.backendURL}/rol/usuario/${this.genericRolesService.idUsuario}/notificacion/folio/${id}/tipo-acceso`
      )
      .pipe(
        map((r) => {
          cache.set(id, r?.datos);

          return r?.datos;
        })
      );
  }

  /**
   * Retorna un valor de tipo `TipoAccesoDto` indicando el tipo de acceso para una notificación.
   */
  public getAccesoPorNotificacionID(id: number | string): Observable<TipoAccesoDto> {
    const cache = cacheMapFactory<TipoAccesoDto>(this, this.getAccesoPorNotificacionID.name);

    if (cache.has(id)) {
      return of(cache.get(id));
    }

    return this.baseService
      .get<IResponseSingle<TipoAccesoDto>>(
        `${environment.backendURL}/rol/usuario/${this.genericRolesService.idUsuario}/notificacion/${id}/tipo-acceso`
      )
      .pipe(
        map((r) => {
          cache.set(id, r?.datos);

          return r?.datos;
        })
      );
  }

  /**
   * Handler para acceso de notificacion.
   */
  public getNotificacionHandler(
    notificacionID: number | string,
    handle: (acceso: TipoAccesoConstantes) => void,
    prevenirAccesoConsulta = false
  ) {
    if (deshabilitarRoles()) {
      handle(TipoAccesoConstantes.ENCARGADO);
      return;
    }

    this.getAccesoPorNotificacionID(notificacionID)
      .pipe(take(1))
      .subscribe((acceso) => {
        if (acceso && acceso.tipoUsuario === TipoUsuarioConstantes.EXTERNO) {
          handle(TipoAccesoConstantes.ENCARGADO);
          return;
        }

        if (acceso && acceso.tipoAcceso !== TipoAccesoConstantes.CONSULTA) {
          handle(acceso.tipoAcceso as TipoAccesoConstantes);
          return;
        }

        if (acceso && acceso.tipoAcceso === TipoAccesoConstantes.CONSULTA && !prevenirAccesoConsulta) {
          handle(acceso.tipoAcceso as TipoAccesoConstantes);
          return;
        }

        this.messagesmainservice.messageInfo(this.SIN_PERMISOS_SUFICIENTES, this.CONTACTE_AL_ADMINISTRADOR);
      });
  }

  /**
   * Retorna un valor de tipo `TipoAccesoDto` indicando el tipo de acceso para una tarea.
   */
  public getAccesoPorTareaID(id: number | string): Observable<TipoAccesoDto> {
    const cache = cacheMapFactory<TipoAccesoDto>(this, this.getAccesoPorTareaID.name);

    if (cache.has(id)) {
      return of(cache.get(id));
    }

    return this.baseService
      .get<IResponseSingle<TipoAccesoDto>>(
        `${environment.backendURL}/rol/usuario/${this.genericRolesService.idUsuario}/tarea/${id}/tipo-acceso`
      )
      .pipe(
        map((r) => {
          cache.set(id, r?.datos);

          return r?.datos;
        })
      );
  }

  /**
   * Handler para acceso de tarea.
   */
  public getTareaHandler(
    tareaID: number | string,
    handle: (acceso: TipoAccesoConstantes) => void,
    prevenirAccesoConsulta = false
  ) {
    if (deshabilitarRoles()) {
      handle(TipoAccesoConstantes.ENCARGADO);
      return;
    }

    this.getAccesoPorTareaID(tareaID)
      .pipe(take(1))
      .subscribe((acceso) => {
        if (acceso && acceso.tipoUsuario === TipoUsuarioConstantes.EXTERNO) {
          handle(TipoAccesoConstantes.ENCARGADO);
          return;
        }

        if (acceso && acceso.tipoAcceso !== TipoAccesoConstantes.CONSULTA) {
          handle(acceso.tipoAcceso as TipoAccesoConstantes);
          return;
        }

        if (acceso && acceso.tipoAcceso === TipoAccesoConstantes.CONSULTA && !prevenirAccesoConsulta) {
          handle(acceso.tipoAcceso as TipoAccesoConstantes);
          return;
        }

        this.messagesmainservice.messageInfo(this.SIN_PERMISOS_SUFICIENTES, this.CONTACTE_AL_ADMINISTRADOR);
      });
  }
}
