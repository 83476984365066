import { AbstractControl, FormGroup } from '@angular/forms';
import { v4 as uuid } from 'uuid';

interface Options {
  toString?: boolean;
}

/**
 * Funcion de utilidad para obtener valores de un `FormGroup`.
 */
export function getValue(
  group: FormGroup | AbstractControl,
  path: string,
  defaultValue: any = null,
  options: Options = { toString: true }
) {
  const value = group?.get(path)?.value;

  if (value && options?.toString && value.toString) {
    return value.toString();
  }

  return value ?? defaultValue;
}

/**
 * Remueve propiedades null de un objeto.
 */
export function removeNullFromObject(obj: Record<string, any>): Record<string, any> {
  return Object.entries(obj)
    .filter(([_, v]) => v !== null && v !== '')
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

/**
 * Convierte un string a numero.
 */
export function toNumber(value: string, def: unknown): number {
  if (value === null) {
    return def as number;
  }

  try {
    const v = +value;

    if (v || v === 0) {
      return v;
    }

    return def as number;
  } catch {
    return def as number;
  }
}

/**
 * Retorna null si el valor infresado is "falso".
 */
export function nullIfFalsy(value) {
  if (value && Boolean(value)) {
    return value;
  }

  return null;
}

/**
 * Fabrica de debouncers.
 */
export function debouncerFactory<T>(timeout = 2500) {
  const debouncerMap = new Map<T, number>();

  return (sym: T, func: () => void) => {
    let timer: number = debouncerMap.get(sym);

    if (timer) {
      clearTimeout(timer);
    }

    timer = +setTimeout(() => {
      func();
      debouncerMap.delete(sym);
    }, timeout);

    debouncerMap.set(sym, timer);
  };
}

/**
 * Interfaz de opciones para restricciones.
 */
interface RestrictOptions {
  regex?: RegExp;
  maxlength?: number;
}

/**
 * Handler para restringir input.
 */
export function restrictInputBase(e: KeyboardEvent, options: RestrictOptions) {
  const { regex, maxlength } = options;

  const input = e.target as HTMLInputElement;
  let value = input?.value ?? '';

  if (regex) {
    value = value.replace(regex, '');
  }

  if (maxlength) {
    value = value.slice(0, maxlength);
  }

  input.value = value;
}

/**
 * Retorna un identificado unico para consultar por el campo input.
 * @see https://jsmates.com/blog/generating-simple-unique-identifier-using-javascript
 */
export function uniqueId(): string {
  return uuid().toString();
}
