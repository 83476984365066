import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MensajesGenerales } from '@app-services/constantes';
import { ColumnsTree, DataTreeTable, NodeSelectTree } from '@app-shared/models/general.interface';
import { TableColumn } from '@app-shared/models/table.interface';
import { ConfirmationService, TreeNode } from 'primeng/api';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit {
  @Input() public tittle: string;
  @Input() public columsTree: ColumnsTree[] = [];
  @Input() public dataContent: [];
  @Input() public objectContent: string;
  @Input() public selectedContent: TreeNode[];
  @Input() public selectedContentTable: object[] = [];
  @Input() public dataInTable: object[];
  @Input() public tableColumns: TableColumn[] = [];
  @Input() public showCheckbox = false;
  @Input() public showButtons = true;
  @Input() public files: [];
  @Input() public urlVisor = '';

  /**
   * Booleano que indica si se debe mostrar dialogo de cancelacion.
   */
  @Input() public showCancelDialog = false;

  @Output() public onClose: EventEmitter<boolean> = new EventEmitter();
  @Output() public onSaveData: EventEmitter<{ dataAdd: DataTreeTable[]; dataDelete: TreeNode[] }> = new EventEmitter();
  @Output() public onSelectRow: EventEmitter<any> = new EventEmitter();

  public display = false;
  public selectedNodes: TreeNode[] = [];
  public allDataSelected: DataTreeTable[] = [];
  public allDataTreeTable: DataTreeTable[] = [];
  public dataTreeTableDelete: TreeNode[] = [];
  public dataTreeTableDeleteIndividual: TreeNode[] = [];
  public dataContentTree: TreeNode[];
  public dataContentTable: object[];
  public dataSelectedTable: any;

  /**
   * Indica los estilos a asignar al modal.
   */
  @Input()
  public style: { [key: string]: {} };

  /*
   * En caso de querer mostrar un contenido custom enviar aquí un template con el contenido a mostrar
   * además de definir el input objectContent el valor de 'template'
   */
  @Input()
  public template: TemplateRef<any>;

  /**
   * Emmitter con id del documento a mostrar.
   */
  @Input() public disableSaveButton: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Indica si mostrar la leyenda de campos requeridos con asterisco.
   */
  @Input() public showLegendRequiredFieldsFooter = false;

  /**
   * Emiter al abrir el modal.
   */
  @Output() public onOpenDialog: EventEmitter<any> = new EventEmitter();

  /**
   * Obtiene el valor del mensaje para campos requeridos con *
   */
  public mensajeCamposRequeridosAsterico = MensajesGenerales.INFO.CAMPOS_CON_ASTERISCO;

  /**
   * Constructor de clase `DialogComponent`.
   */
  constructor(private cd: ChangeDetectorRef, protected confirmationService: ConfirmationService) {}

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    if (!this.style) {
      this.style = { width: '30vw' };
    }
    this.display = true;
    if (this.objectContent === 'treeTable') {
      this.dataContentTree = this.dataContent;
      if (this.selectedContent !== undefined) {
        const dataSelect: string[] = [];
        this.selectedContent.forEach((item) => {
          if (item.children !== undefined && item.children.length > 0) {
            item.children.forEach((itemChildren) => {
              dataSelect.push(itemChildren.data['todos']);
            });
          }
          if (!dataSelect.includes(item.data['todos'])) {
            dataSelect.push(item.data['todos']);
          }
        });
        this.checkNode(this.dataContentTree, dataSelect);
      }
    }
    if (this.objectContent === 'table') {
      this.dataContentTable = this.dataContent;
    }
  }

  /**
   * Metodo para cerrar el dialogo.
   */
  public close(): void {
    this.display = false;
    this.onClose.emit(this.display);
  }

  /**
   * Metodo para disparar cancelación.
   */
  public cancel($event?: Event): void {
    if (!this.showCancelDialog) {
      this.close();
    }

    this.confirmationService.confirm({
      key: 'head-dialog-component-confirm',
      header: MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_HEADER,
      message: MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_BODY,
      accept: () => {
        this.close();
      },
    });
  }

  /**
   * Trigger para keypress de Escape.
   * @param event
   */
  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    const x = event.key;
    if (x === 'Escape') {
      if (!this.showCancelDialog) {
        this.close();
      }

      this.cancel(event);
    }
  }

  public checkNode(nodes: TreeNode[], str: string[]) {
    if (!nodes) {
      return;
    }

    nodes.forEach((node) => {
      if (str.includes(node.data['todos'])) {
        this.selectedNodes.push(node);
      }
      if (!node.children) {
        return;
      }

      node.children.forEach((child) => {
        if (str.includes(child.data) && !str.includes(node.data)) {
          node.partialSelected = true;
          child.parent = node;
        }

        if (str.includes(node.data)) {
          child.parent = node;
          str.push(child.data);
        }
      });

      this.checkNode(node.children, str);
      node.children.forEach((child) => {
        if (child.partialSelected) {
          node.partialSelected = true;
        }
      });
    });
  }

  public getParentForChildrens(): string[] {
    const valuesAlreadySeen = [];
    // tslint:disable-next-line: prefer-for-of
    for (const element of this.selectedContent) {
      const value = element.data['todos'];
      valuesAlreadySeen.push(value);
    }
    // tslint:disable-next-line: no-shadowed-variable
    const toFindDuplicates = (valuesAlreadySeen) =>
      valuesAlreadySeen.filter((item, index) => valuesAlreadySeen.indexOf(item) !== index);
    return toFindDuplicates(valuesAlreadySeen);
  }

  public save(): void {
    if (this.objectContent === 'treeTable') {
      this.saveTreeOperations();
      this.close();
    } else if (this.objectContent === 'table') {
      this.saveTableOperations();
      this.close();
    } else if (this.objectContent === 'template') {
      this.saveTemplateOperations();
    }
  }

  public nodeSelect(data: NodeSelectTree) {
    if (!data.node.children) {
      if (data.node.parent) {
        const val = {
          selectedValue: data.node['data'],
          parentData: data.node.parent.data,
        };
        this.allDataTreeTable.push(val);
        this.deleteIndividualsNode(val);
      }
    } else {
      if (data.node.data && data.node.children.length > 0) {
        this.addChildrens(data.node.children, data.node.data);
        this.deleteChildrenNode(data.node.children, data.node.data);
      }
      if (data.node.data && data.node.children.length === 0) {
        const val = {
          selectedValue: data.node['data'],
          parentData: data.node.parent,
        };
        this.allDataTreeTable.push(val);
        this.deleteIndividualsNode(val);
      }
    }
  }

  public deleteIndividualsNode(val) {
    const dataIndividualsDelete = [...this.dataTreeTableDeleteIndividual];
    for (let i = dataIndividualsDelete.length - 1; i >= 0; i--) {
      if (dataIndividualsDelete[i].data['todos'] === val['selectedValue']['todos']) {
        dataIndividualsDelete.splice(i, 1);
      }
    }
    this.dataTreeTableDeleteIndividual = dataIndividualsDelete;
  }

  public deleteChildrenNode(childrens, node) {
    const dataDelete = [...this.dataTreeTableDelete];
    for (let i = dataDelete.length - 1; i >= 0; i--) {
      if (childrens.some((itemChildren) => itemChildren['data']['todos'] === dataDelete[i].data['todos'])) {
        dataDelete.splice(i, 1);
      }
    }
    this.dataTreeTableDelete = dataDelete;
  }

  public addChildrens(childrens, parentData) {
    childrens.forEach((item) => {
      const val = {
        selectedValue: item['data'],
        parentData,
      };
      if (!this.allDataTreeTable.some((itemAdd) => itemAdd.selectedValue['todos'] === item['data']['todos'])) {
        this.allDataTreeTable.push(val);
      }
    });
  }

  public nodeUnselect(event: NodeSelectTree): void {
    if (event.node.children) {
      if (event.node.data && event.node.children.length > 0) {
        this.unMarkNode(event.node.children, event.node.data);
      } else {
        this.dataTreeTableDeleteIndividual.push(event.node);
      }
    } else {
      this.dataTreeTableDeleteIndividual.push(event.node);
    }
  }

  public unMarkNode(childrens, nodo): void {
    const newSelectedNodes = [];
    this.dataTreeTableDelete = childrens;
    this.selectedNodes.forEach((element) => {
      if (element['data']['todos'] !== nodo['todos']) {
        newSelectedNodes.push(element);
      }
    });
    this.selectedNodes = newSelectedNodes;
  }

  public saveTreeOperations() {
    this.onSaveData.emit({
      dataAdd: this.allDataTreeTable,
      dataDelete: this.dataTreeTableDelete.concat(this.dataTreeTableDeleteIndividual),
    });
  }

  public saveTableOperations() {
    this.onSaveData.emit(this.dataSelectedTable);
  }

  public saveTemplateOperations() {
    this.onSaveData.emit(null);
  }

  public getDataSelected(dataSelected) {
    this.dataSelectedTable = dataSelected;
  }

  public nodeAllSelect(data) {
    if (!data.children) {
      if (data.parent) {
        const val = {
          selectedValue: data['todos'],
          parentData: data.parent.data,
        };
        this.allDataTreeTable.push(val);
      }
    } else {
      if (data.data && data.children.length > 0) {
        this.addChildrens(data.children, data.data);
      }
      if (data.data && data.children.length === 0) {
        const val = {
          selectedValue: data['data'],
          parentData: data.parent,
        };
        this.allDataTreeTable.push(val);
      }
    }
  }

  public deleteAllChildren(childrens, parent) {
    childrens.map((item) => {
      const val = {
        data: item['data'],
        parentData: parent,
      };
      this.dataTreeTableDelete.push(val);
    });
  }

  public selectAll(value: boolean): void {
    if (value) {
      this.dataContentTree.forEach((item) => {
        this.nodeAllSelect(item);
      });
    } else {
      this.dataContentTree.forEach((item) => {
        if (item.children) {
          if (item.data && item.children.length > 0) {
            this.deleteAllChildren(item.children, item.data);
          } else {
            this.dataTreeTableDeleteIndividual.push(item);
          }
        } else {
          this.dataTreeTableDeleteIndividual.push(item);
        }
      });
    }
  }

  public onShow(event: unknown): void {
    this.onOpenDialog.emit(event);
  }
}
