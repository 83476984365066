import { AbstractControl } from '@angular/forms';
import { RestrictOptions } from '@app-shared/generic-reactive-forms/models/input-types';

/**
 * Handler generico para restricción de inputs.
 */
export function genericRestrictHandler<T extends Event>(
  options: RestrictOptions,
  e: T,
  control: AbstractControl
): void {
  const { prevent: regex, maxlength, prevent, allow } = options;

  const input = e.target as HTMLInputElement;
  let value = input?.value ?? '';

  if (regex ?? prevent) {
    value = value.replace(regex ?? prevent, '');
  }

  if (allow) {
    value = value
      .split('')
      .filter((i) => allow.test(i))
      .join('');
  }

  if (maxlength) {
    value = value.slice(0, maxlength);
  }

  input.value = value;

  Promise.resolve().then(() => {
    control.setValue(value);
    control.updateValueAndValidity();
  });
}
