import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as _sodium from "libsodium-wrappers";

@Injectable({
  providedIn: "root",
})
export class SecureStorageService {
  public loaded;
  public key!: Uint8Array;
  public nonce!: Uint8Array;
  private nonceBytes!: number;

  constructor() {
    this.loaded = _sodium.ready;
  }

  // INITIALIZATION

  public load(): void {
    this.loaded.then(() => {
      this.key = _sodium.crypto_generichash(
        32,
        _sodium.from_string(environment.appId),
      );
      this.nonceBytes = _sodium.crypto_aead_xchacha20poly1305_ietf_NPUBBYTES;
      const nonce = localStorage.getItem("nonce");
      if (nonce) {
        this.nonce = _sodium.from_base64(nonce);
      } else {
        this.nonce = _sodium.randombytes_buf(this.nonceBytes);
        localStorage.setItem("nonce", _sodium.to_base64(this.nonce));
      }
    });
  }

  /**
   * @param {string} message
   * @param {string} key
   * @returns {string}
   */
  private encrypt(message: string): string {
    const encryptedData = _sodium.crypto_aead_xchacha20poly1305_ietf_encrypt(
      message,
      null,
      this.nonce,
      this.nonce,
      this.key,
    );
    return _sodium.to_hex(this.nonce) + _sodium.to_hex(encryptedData);
  }

  /**
   * @param {string} nonceAndCiphertextStr
   * @param {string} key
   * @returns {string}
   */
  private decrypt(nonceAndCiphertextStr: string): string {
    try {
      // Split the encrypted string into nonce and ciphertext
      const nonceSize = this.nonceBytes * 2;
      const nonce = _sodium.from_hex(
        nonceAndCiphertextStr.slice(0, nonceSize),
      );
      const ciphertext = _sodium.from_hex(
        nonceAndCiphertextStr.slice(nonceSize),
      );
      return _sodium.crypto_aead_xchacha20poly1305_ietf_decrypt(
        nonce,
        ciphertext,
        null,
        nonce,
        this.key,
        "text",
      );
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      return "";
    }
  }

  // PUBLIC FUNCTIONS

  public setItem(key: string, value: string): void {
    const secureValue = this.encrypt(value);
    localStorage.setItem(key, secureValue);
  }

  public getItem(key: string): string {
    const secureValue = localStorage.getItem(key);
    if (!secureValue || secureValue.length === 0) {
      return "";
    }
    return this.decrypt(secureValue);
  }
}
