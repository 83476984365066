import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  private messageSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly $message: Observable<any> =
    this.messageSubject.asObservable();

  /**
   * sendMessage
   */
  private async sendMessage(
    type: string,
    body: string,
    title: string | undefined,
  ) {
    this.messageSubject.next({ type, body, title });
  }

  /**
   * toasterVariants
   */
  public showError(body: string, title?: string) {
    this.sendMessage("error", body, title);
  }
  public showWarn(body: string, title?: string) {
    this.sendMessage("warn", body, title);
  }
  public showInfo(body: string, title?: string) {
    this.sendMessage("info", body, title);
  }
  public showApproval(body: string, title?: string) {
    this.sendMessage("approval", body, title);
  }
  public showMesage(body: string, title?: string) {
    this.sendMessage("message", body, title);
  }
}
