import { isObservable, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { FiltrodatosService } from './filtrodatos.service';

/**
 * Método para añadir filtrados de datos.
 */
export function FiltroDatos(callback: FiltroCallback<any>) {
  const injector = FiltrodatosService.getService;
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    // Obtenemos el método a añadir el filtrado.
    const originalMethod = descriptor.value;

    descriptor.value = function (...arg) {
      const result = originalMethod.apply(this, arg);

      if (!isObservable(result)) {
        throw new Error('El decorador FiltroDatos solo debe aplicarse a metodos que devuelvan observables.');
      }

      return result.pipe(
        mergeMap((response) => {
          return callback(injector, response);
        })
      );
    };

    return descriptor;
  };
}

/**
 * Tipo de datos para método injector.
 */
type InjectorType = typeof FiltrodatosService.getService;

/**
 * Tipo de data para el callback de filtro.
 */
export type FiltroCallback<T> = (inject: InjectorType, data: T) => Observable<T>;
