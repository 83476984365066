import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HuellaService } from '@oc/ngx-huella';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'login-huella',
  templateUrl: './login-huella.component.html',
  styleUrls: ['./../login/login.component.scss'],
})
export class LoginHuellaComponent {
  @Output() credentials = new EventEmitter<any>();
  @Output() goback = new EventEmitter<any>();
  @Input() allowBack = false;

  constructor(private huellaService: HuellaService) {}

  public back() {
    this.goback.emit();
  }
  public login(f: NgForm) {
    this.huellaService.getHuella().subscribe({
      next: (respuesta) => {
        if (respuesta.error !== '') {
          this.credentials.emit({ error: respuesta.error });
        } else {
          this.credentials.emit({
            user: f.form.value.numEmpleado,
            template: respuesta.template64,
          });
        }
      },
      error: (error) => this.credentials.emit({ erro: error.message }),
    });
  }
}
