import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { GenericRolesService } from '@app-shared/generic-roles/services/generic-roles.service';
import { MessagesMainService } from '@app-shared/services/messages-main.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

type BoolUrlTree = boolean | UrlTree;

@Injectable()
export class GenericRolesGuard implements CanActivate, CanActivateChild {
  /**
   * Constructor de clase `GenericRolesGuard`.
   */
  public constructor(
    private rolesService: GenericRolesService,
    private messagesMainService: MessagesMainService,
    private router: Router
  ) {
    this.rolesService.init();
  }

  /**
   * @inheritdoc
   */
  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): BoolUrlTree | Observable<BoolUrlTree> | Promise<BoolUrlTree> {
    const data = childRoute?.data;
    const modulo: string = data?.modulo?.toString();

    /**
     * Solamente se pueden activar el acceso a paginas una vez que se ha inicializado el servicio de roles si no se
     * ha definido un modulo.
     */
    if (!modulo) {
      return this.rolesService.initialized$();
    }

    /**
     * Se realiza la comprobacion del modulo.
     */
    return this.rolesService.initialized$().pipe(
      map(() => {
        return this.rolesService.validarModulos([modulo]);
      }),
      mergeMap((r) => {
        if (!r) {
          this.router.navigate(['/dashboard']);
          this.messagesMainService.messageInfo(
            'El rol asignado a su cuenta no posee permisos suficientes para acceder a la pantalla seleccionada.',
            'Contacte a su administrador'
          );
        }

        return of(r);
      })
    );
  }

  /**
   * @inheritdoc
   */
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<BoolUrlTree> | Promise<BoolUrlTree> | BoolUrlTree {
    const data = next?.data;
    const modulo: string = data?.modulo?.toString();

    /**
     * Solamente se pueden activar el acceso a paginas una vez que se ha inicializado el servicio de roles si no se
     * ha definido un modulo.
     */
    if (!modulo) {
      return this.rolesService.initialized$();
    }

    /**
     * Se realiza la comprobacion del modulo.
     */
    return this.rolesService.initialized$().pipe(
      map(() => {
        return this.rolesService.validarModulos([modulo]);
      }),
      mergeMap((r) => {
        if (!r) {
          this.router.navigate(['/dashboard']);
          this.messagesMainService.messageInfo(
            'El rol asignado a su cuenta no posee permisos suficientes para acceder a la pantalla seleccionada.',
            'Contacte a su administrador'
          );
        }

        return of(r);
      })
    );
  }
}
