import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { Constante } from '@app-services/constantes';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-alerta-cancelar-cambios',
  templateUrl: './alerta-cancelar-cambios.component.html',
  styleUrls: ['./alerta-cancelar-cambios.component.scss'],
})
export class AlertaCancelarCambiosComponent implements OnDestroy {
  /**
   * Mensaje para cuerpo modal.
   */
  public mensajeModal = Constante.ALERTA_CANCELAR;
  /**
   * Mensaje para titulo modal.
   */
  public tituloModal = Constante.ALERTA_DEJAR_FORMULARIO;
  /**
   * Event de eventos
   */
  public event: EventEmitter<any> = new EventEmitter();

  /**
   * Crea una instancia de `AlertaCancelarCambiosComponent`
   */
  public constructor(public bsModalRefAlert: BsModalRef) {}

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.event?.unsubscribe();
  }

  /**
   * Acción para cerrar modal.
   */
  public cerrarModal() {
    this.bsModalRefAlert.hide();
  }

  /**
   * Acción click aceptar
   */
  public aceptar() {
    this.event.emit({ data: { aceptar: true }, res: 200 });
  }
}
