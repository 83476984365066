import { Component, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { GenericTypedInput } from '@app-shared/generic-reactive-forms/components/generic-form/generic-form.models';
import { GenericInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';

@Component({
  selector: 'app-generic-form-group',
  templateUrl: './generic-form-group.component.html',
  styleUrls: ['./generic-form-group.component.scss'],
})
export class GenericFormGroupComponent implements OnInit {
  @Input()
  public config: GenericInput | GenericTypedInput;

  /**
   * Inputs tipados para autoconstrucción.
   */
  public fields: GenericTypedInput[];

  /**
   * `FormGroup` de formulario padre.
   */
  public rootFormGroup: FormGroup;

  /**
   * Constructor de clase `GenericFormGroupComponent`.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() private formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService
  ) {}

  /**
   * Configuración del campo.
   */
  public get fieldConfig(): GenericInput {
    return this.config as GenericInput;
  }

  /**
   * Grupo.
   */
  public get group(): FormGroup {
    return this.rootFormGroup.get(this.fieldConfig.name) as FormGroup;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    if (!this.rootFormGroup) {
      this.rootFormGroup = this.formRef?.control ?? this.genericFormService?.form;
    }

    this.rootFormGroup.addControl(this.fieldConfig.name, new FormGroup({}));

    const fields = (Object.values(this.config ?? {}) ?? []).filter((i) => i?.genericType) as TypedInput[];

    this.fields = fields;
  }

  /**
   * Retorna la clase con width para la columna.
   */
  public getColWidth(input: GenericTypedInput): string {
    return input.colWidth ? `col-md-${input.colWidth}` : `col-md-4`;
  }
}

/**
 * Alias para tipado de campos genericos.
 *
 * Este alias solo se usa para encontrar campos genericos que deben ser autoconstruidos.
 */
type TypedInput = GenericInput & GenericTypedInput;
