import { Errors } from '@app-models/errors.interface';
import { MensajesGenerales } from '../constantes';

export class IdentificarError {
  public static ObtenerMensaje(e: Errors) {
    let msg = MensajesGenerales.ERRORES.ERROR_NO_IDENTIFICADO;
    if (e?.error?.error) {
      if (e?.error?.error?.message === 'Forbidden') {
        msg = MensajesGenerales.ERRORES.ERROR_NO_ACCESOS;
      } else if (e?.error?.error?.message === 'Unauthorized') {
        msg = MensajesGenerales.ERRORES.ERROR_TOKEN_EXPIRADO;
      } else if (e?.error?.error?.message) {
        msg = e.error.error.message;
      } else {
        msg = MensajesGenerales.ERRORES.ERROR_NO_IDENTIFICADO;
      }
    } else if (e?.error) {
      msg = MensajesGenerales.ERRORES.ERROR_CONEXION;
    } else if (e.mensaje) {
      msg = e.mensaje;
    } else if (e) {
      msg = MensajesGenerales.ERRORES.ERROR_NO_IDENTIFICADO;
    }

    if (msg === MensajesGenerales.ERRORES.ERROR_TOKEN_EXPIRADO) {
      setTimeout(() => {
        const origin = sessionStorage.getItem('origin');
        window.location.href = origin;
      }, 1500);
    }

    return msg;
  }
}
