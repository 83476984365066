import { Injectable } from '@angular/core';
import { IResponseList } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmpresasFiliales } from './empresasfiliales';

@Injectable({
  providedIn: 'root',
})
export class EmpresasFilialesService {
  /**
   * Crea una instancia de la clase EmpresasFilialesService
   * @param http
   */
  public constructor(private http: BaseService) {}

  /**
   * Obtiene una lista de empresas filiales
   * @returns {Observable<IResponseList<EmpresasFiliales>>}
   */
  public getEmpresasFiliales(): Observable<IResponseList<EmpresasFiliales>> {
    return this.http.get<IResponseList<EmpresasFiliales>>(`${environment.backendURL}/empresas/`);
  }
}
