<div class="form-group" *ngIf="fieldConfig && control">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <!-- Field -->
  <div class="form-group--input-wrapper">
    <input
      pInputText
      [type]="fieldConfig?.type"
      [autocomplete]="autocomplete"
      [ngClass]="fieldConfig?.class"
      class="ng-dirty !ng-invalid form-control"
      [style]="{ width: '100%' }"
      [name]="fieldConfig.name"
      [id]="id"
      [formControl]="control"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      [minlength]="minlength"
      [pattern]="pattern"
      [spellcheck]="spellcheck"
      [readonly]="fieldConfig?.readonly"
      (keydown)="onKeyDown($event)"
      (input)="onInput($event)"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
      #input
    />
    <button type="button" class="clear" (click)="clear()" *ngIf="showClear"><i class="pi pi-times"></i></button>
  </div>

  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>
