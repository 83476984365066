import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToasterComponent } from "./toaster.component";
import { ToasterService } from "./services/toaster.service";

@NgModule({
  imports: [CommonModule],
  declarations: [ToasterComponent],
  providers: [ToasterService],
  exports: [ToasterComponent],
})
export class ToasterModule {}
export { ToasterService } from "./services/toaster.service";
