<!--Formulario para validar colaborador-->
<form name="form" (ngSubmit)="f.form.valid && login(f)" #f="ngForm" novalidate class="form2">
  <h2 class="text-center">Inicia sesión<span>.</span></h2>
  <div>
    <p class="colaborador">Usuario</p>
    <input
      [ngClass]="{ 'is-invalid': f.submitted && !correo.valid }"
      type="text"
      name="correo"
      placeholder="Usuario"
      class="form-control usuario"
      #correo="ngModel"
      ngModel
      required
    />
  </div>
  <div class="mt-1">
    <p class="contrase">
      Contraseña
      <a href="login/v2/recuperar-contrasena">¿Olvidaste tu contraseña?</a>
    </p>
    <input
      [ngClass]="{ 'is-invalid': f.submitted && !pd.valid }"
      type="password"
      name="pd"
      placeholder="Password"
      class="form-control pass"
      #pd="ngModel"
      ngModel
      required
    />
  </div>
  <div class="row mt-4">
    <button type="submit" [disabled]="f.form.invalid" class="b-ingresar btn btn-primary" appCaptchaReader>
      Ingresar
    </button>
    <button *ngIf="allowBack" type="button" (click)="back()" class="b-ingresar btn btn-secondary">Volver</button>
  </div>
</form>
