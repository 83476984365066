/*
 * Enum para los response HTTP Errores de Clientes
 */
export enum HTTP_ERROR {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUEST = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  INTERNAL_SERVER_ERROR = 500,
}
