import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Memoize } from '@app-shared/generic-reactive-forms/utils/memoize.decorator';

/**
 * Colores disponibles
 * @see src\scss\_bootstrap-variables.scss
 */
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'link'
  | 'info'
  | 'gray'
  | 'dark'
  | 'verde' // #006fb8
  | 'azul' // #0d8e16
  | 'pink'
  | 'gray-dark'
  | 'rosa' // #e83e8c
  | 'amarillo' // #ffdc2f
  | 'accent'
  | 'light'
  | 'indigo'; // #6610f2

/**
 * Tamaños disponibles
 * @see node_modules\bootstrap\scss\_buttons.scss
 */
type ButtonSize = 'small' | 'large';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  /**
   * Tipo de botón.
   */
  @Input()
  public variant: ButtonVariant = 'primary';

  /**
   * Tipo de botón.
   */
  @Input()
  public type: 'button' | 'submit' | 'reset' = 'button';

  /**
   * Tamaño de botón.
   */
  @Input()
  public size: ButtonSize;

  /**
   * Emisor de eventos para click.
   */
  @Output()
  public onClick = new EventEmitter<unknown>();

  /**
   * Routerlink options.
   */
  @Input()
  public routerLink = null;

  /**
   * Valor de parametro outline.
   */
  public outlineValue = false;

  /**
   * Valor de parametro small.
   */
  public smallValue = false;

  /**
   * Valor de parametro Block.
   */
  public btnBlock = false;

  /**
   * Valor de parametro disabled.
   */
  public disabledValue = false;

  /**
   * Valor de parametro squared.
   */
  public squaredValue = false;

  /**
   * Valor de parametro noresponsivo.
   */
  public noResponsivoValue = false;

  /**
   * Valor de parametro circle.
   */
  public backButtonValue = false;

  /**
   * Setter de booleano que indica si el botón es `small`.
   */
  @Input('disabled')
  public set disabled(attribute: boolean | '') {
    this.disabledValue = attribute === '' || attribute;
  }

  /**
   * Setter de booleano que indica si el botón es `small`.
   * @deprecated Todos los botones deben ser del mismo tamaño.
   */
  @Input('small')
  public set small(attribute: boolean | '') {
    this.smallValue = attribute === '' || attribute;
  }

  /**
   * Setter de booleano que indica si el botón es `block`.
   */
  @Input('block')
  public set block(attribute: boolean | '') {
    this.btnBlock = attribute === '' || attribute;
  }

  /**
   * Setter de booleano que indica si el botón es de tipo Outline.
   */
  @Input('outline')
  public set outline(attribute: boolean | '') {
    this.outlineValue = attribute === '' || attribute;
  }

  /**
   * Setter de booleano que indica si el botón es de tipo Squared.
   */
  @Input('squared')
  public set squared(attribute: boolean | '') {
    this.squaredValue = attribute === '' || attribute;
  }

  /**
   * Setter de booleano que indica si el botón debe ser responsivo o no
   */
  @Input('noresponsivo')
  public set noresponsivo(attribute: boolean | '') {
    this.noResponsivoValue = attribute === '' || attribute;
  }

  /**
   * Setter de booleano que indica si el botón debe ser responsivo o no
   */
  @Input('backButton')
  public set backButton(attribute: boolean | '') {
    this.backButtonValue = attribute === '' || attribute;
  }

  /**
   * Getter para lista de clases.
   * @see src\scss\_bootstrap-variables.scss
   * @see node_modules\bootstrap\scss\_buttons.scss
   */
  @Memoize
  public get classlist(): Record<string, boolean> {
    const colorVariants = {
      primary: 'primary',
      accent: 'accent',
      secondary: 'secondary',
      success: 'success',
      warning: 'warning',
      danger: 'danger',
      info: 'info',
      gray: 'light',
      dark: 'dark',
      verde: 'verde',
      azul: 'azul',
      pink: 'pink',
      'gray-dark': 'gray-dark',
      rosa: 'rosa',
      amarillo: 'amarillo',
      indigo: 'indigo',
    };

    const classes: Record<string, boolean> = {
      // Basicos
      ['btn-rounded']: !this.squaredValue,

      // Boton de bloque
      ['btn-block']: this.btnBlock,

      // Responsivo
      ['noresponsivo']: this.noResponsivoValue,

      // Circular
      ['backButton']: this.backButtonValue,

      // Link
      ['btn-link']: this.variant === 'link',
    };

    Object.keys(colorVariants).forEach((key) => {
      const variant = colorVariants[key];
      classes[`btn-${variant}`] = this.variant === key && !this.outlineValue;
      classes[`btn-outline-${variant}`] = this.variant === key && this.outlineValue;
    });

    return classes;
  }

  /**
   * Handler para evento de click
   */
  public onClickHandler(e: Event): void {
    if (this.disabledValue) {
      return;
    }

    this.onClick.next(e);
  }
}
