import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { cacheWeakMapFactory } from '../utils/cache-factory';

/**
 * Control invalidador de formularios.
 *
 * Su principal funcion es invalidar formularios para prevenir que sean guardados.
 */
const INVALIDATOR = 'invalidator';

/**
 * Enum para guardar el valor del ultimo estado de habilitación de un campo.
 */
export enum LAST_STATE_DISABLED {
  DISABLED = 1,
  ENABLED = 0,
}

@Injectable()
export class GenericFormService {
  /**
   * Referencia del `FormGroup` instanciado en @see GenericFormComponent.
   */
  private formRef: FormGroup;

  /**
   * Subject para control de bloqueo y desbloqueo del formulario.
   */
  private disabledSubject$ = new BehaviorSubject<boolean>(false);

  /**
   * Booleano para prevenir el submit del formulario.
   */
  private preventSubmitValue = false;

  /**
   * Booleano que indica si se previene el submit del formulario.
   */
  public get preventSubmit(): boolean {
    return this.preventSubmitValue;
  }

  /**
   * Getter para la propiedad `formRef`
   */
  public get form(): FormGroup {
    return this.formRef;
  }

  /**
   * Getter para el emisor de deshabilitación del formulario.
   */
  public get disabled$(): Observable<boolean> {
    return this.disabledSubject$.asObservable();
  }

  /**
   * Setter para el `FormGroup` instanciado en @see GenericFormComponent.
   */
  public setFormGroup(form: FormGroup) {
    this.formRef = form;
  }

  /**
   * Booleano para prevenir el submit del formulario.
   */
  public doPreventSubmit(prevent: boolean) {
    this.preventSubmitValue = prevent;
  }

  /**
   * Deshabilita/Habilita el formulario segun el valor indicado.
   *
   * Un valor verdadero deshabilitará el formulario.
   * Un valor falso habilitará el formulario.
   */
  public disable(disabled: boolean, useInvalidator: boolean): void {
    this.disabledSubject$.next(disabled);

    this.doDisableFormGroup(disabled, this.formRef);

    if (useInvalidator) {
      this.invalidateFormInstance(disabled);
    }
  }

  /**
   * Deshabilita objeto de tipo `FormControl`.
   */

  public disableControl(disabled: boolean, control: FormControl) {
    this.doDisableFormControl(disabled, control);
  }

  /**
   * Añade un invalidador para el formulario.
   */
  private invalidateFormInstance(disabled: boolean) {
    if (disabled) {
      this.form.addControl(INVALIDATOR, new FormControl(null, () => ({ invalid: true })));
      return;
    }

    this.form.removeControl(INVALIDATOR);
  }

  /**
   * Deshabilita objeto de tipo `FormGroup`.
   */
  private doDisableFormGroup(disabled: boolean, group: FormGroup, name?: string | number) {
    Object.entries(group.controls).forEach(([controlName, control]) => {
      if (control instanceof FormControl) {
        this.doDisableFormControl(disabled, control, controlName);
      }

      if (control instanceof FormGroup) {
        this.doDisableFormGroup(disabled, control, controlName);
      }

      if (control instanceof FormArray) {
        this.dodisableFormArray(disabled, control, controlName);
      }
    });
  }

  /**
   * Deshabilita objeto de tipo `FormArray`.
   */
  private dodisableFormArray(disabled: boolean, control: FormArray, name?: string | number) {
    control.controls.forEach((instance, index) => {
      if (instance instanceof FormControl) {
        this.doDisableFormControl(disabled, instance, index);
      }

      if (instance instanceof FormGroup) {
        this.doDisableFormGroup(disabled, instance, index);
      }

      if (instance instanceof FormArray) {
        this.dodisableFormArray(disabled, instance, index);
      }
    });
  }

  /**
   * Deshabilita objeto de tipo `FormControl`.
   */
  private doDisableFormControl(disabled: boolean, control: FormControl, name?: string | number) {
    const disabledMapCache = cacheWeakMapFactory<LAST_STATE_DISABLED>(this, this.doDisableFormControl.name);

    // Esta variable almacena el último valor del campo.
    let lastState: LAST_STATE_DISABLED = disabledMapCache.get(control);

    // Al deshabilitar memoizamos el ultimo estado.
    if (disabled) {
      lastState = control.disabled ? LAST_STATE_DISABLED.DISABLED : LAST_STATE_DISABLED.ENABLED;
      disabledMapCache.set(control, lastState);

      control.disable({ emitEvent: false });
      return;
    }

    // Al restaurar si no tenemos un ultimo estado no hacemos nada.
    if (lastState === null) {
      return;
    }

    // Si el ultimo estado era deshabilitado, lo mantenemos.
    if (lastState === LAST_STATE_DISABLED.DISABLED) {
      control.disable({ emitEvent: false });
      disabledMapCache.set(control, null);
      return;
    }

    // Si el ultimo estado era habilitado lo habilitamos.
    disabledMapCache.set(control, null);
    control.enable({ emitEvent: false });
  }
}
