import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appBrandMinimizer]',
})
export class BrandMinimizeDirective {
  constructor() {
    /*Empty*/
  }

  @HostListener('click', ['$event'])
  public toggleOpen($event: any) {
    $event.preventDefault();
    if (document.querySelector('app-root')) {
      document.querySelector('app-root').classList.toggle('brand-minimized');
    }
  }
}
