import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'login-custom',
  templateUrl: './login-custom.component.html',
  styleUrls: ['./../login/login.component.scss'],
})
export class LoginCustomComponent {
  @Output() credentials = new EventEmitter<any>();
  @Output() goback = new EventEmitter<any>();
  @Input() allowBack = false;

  public back() {
    this.goback.emit();
  }
  public login(f: NgForm) {
    this.credentials.emit(f.form.value);
  }
}
