import { Component, ElementRef, Host, Input, OnDestroy, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Constante } from '@app-services/constantes';
import { ButtonVariant } from '@app-shared/generic-components/components/button.component';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { GenericFileInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-generic-input-file',
  templateUrl: './generic-input-file.component.html',
  styleUrls: ['./generic-input-file.component.scss'],
})
export class GenericInputFileComponent
  extends AbstractGenericInputComponent<GenericFileInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericFileInput;

  /**
   * Viewref a input de busqueda.
   */
  @ViewChild('input', { static: false })
  public inputRef: ElementRef<HTMLInputElement>;

  /**
   * Grupo interior para campo.
   */
  public innerGroup: FormGroup;

  /**
   * Constructor de clase GenericInputNumberComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService
  ) {
    super(formRef, genericFormService);
  }

  /**
   * Control para handling de input interno.
   */
  public get innerControl(): AbstractControl {
    return this.innerGroup.get('control');
  }

  /**
   * File type identifier para archivos aceptados.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
   */
  public get accept(): string {
    return this.config.accept ?? Constante.FORMATOS_VALIDOS_ARCHIVO;
  }

  /**
   * Booleano que indica si se pueden aceptar multiples archivos.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/multiple
   */
  public get multiple(): boolean {
    return this.config.multiple ?? false;
  }

  /**
   * Retorna un listado separado por comas con los nombres de los archivos seleccionados.
   */
  public get fileName(): string {
    const items: FileList = this.inputRef?.nativeElement?.files;

    let name = Array.from(items ?? [])
      ?.map((i) => i.name)
      ?.join(', ');

    if (!name) {
      name = Array.from(this.control.value ?? [])
        ?.map((f: File) => f?.name)
        ?.join(', ');
    }
    return name;
  }

  /**
   * Variante de botón
   */
  public get variant(): ButtonVariant {
    return this.fieldConfig.btnVariant ?? 'primary';
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.internalValidators = [
      fileTypeValidatorFactory(this.accept, this.inputRef),
      fileSizeValidatorFactory(this.config.maxFileSize),
    ];

    super.ngOnInit();

    this.control.valueChanges.pipe(takeUntil(this.destroySubject$.asObservable())).subscribe((v) => {
      if (v === null && this.innerControl.value !== null) {
        this.innerControl.reset();
      }
    });

    this.innerGroup = new FormGroup({
      control: new FormControl(),
    });

    this.innerControl.valueChanges
      .pipe(
        takeUntil(this.destroySubject$.asObservable()),
        filter((v) => v)
      )
      .subscribe((v) => {
        this.control.setValue(this.inputRef.nativeElement?.files);
      });
  }

  /**
   * Trigger de click.
   */
  public trigger(): void {
    this.inputRef.nativeElement.click();
  }

  /**
   * Handler para realizar focus en el elemento.
   */
  public focus(): void {
    if (!this.control.touched) {
      return;
    }

    this.inputRef?.nativeElement?.focus();
  }

  /**
   * On onFocus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        setValue: this.control.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * On onBlur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        setValue: this.control.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * On onInput event handler.
   */
  public onInput(e): void {
    if (this.fieldConfig?.onInput) {
      this.fieldConfig?.onInput(e, {
        setValue: this.control.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * Key-down event handler.
   */
  public onKeyDown(e): void {
    if (this.fieldConfig?.onKeyDown) {
      this.fieldConfig?.onKeyDown(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: this.focus.bind(this),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Input event handler.
   */
  public onChange(e): void {
    if (this.fieldConfig?.onChange) {
      this.fieldConfig?.onChange(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: this.focus.bind(this),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }
}

/**
 * Validador de tipo de archivo.
 */
function fileTypeValidatorFactory(extensiones: string, input: ElementRef<HTMLInputElement>): ValidatorFn {
  return (control: FormControl): ValidationErrors => {
    const validas = extensiones
      .toLowerCase()
      .replace(/"*\.*\s*/g, '')
      .split(',');

    if (control.value === null) {
      return {};
    }

    const invalidFiles = Array.from((control.value as FileList) ?? []).some((file) => {
      const extension = file.name.split('.').pop().toLowerCase().toLowerCase();

      return !validas.includes(extension);
    });

    return invalidFiles ? { requiredFileType: true } : {};
  };
}

/**
 * Validador de tamaño de archivo.
 */
function fileSizeValidatorFactory(maxFileSize: number): ValidatorFn {
  return (control: FormControl): ValidationErrors => {
    if (control.value === null) {
      return {};
    }

    const invalidFiles = Array.from((control.value as FileList) ?? []).some((file) => {
      const fileSizeInKB = Math.round(file.size / 1024);
      const maxFileSizeInKB = Math.round(Constante.TAMANO_MAXIMO_ARCHIVO / 1024);
      return fileSizeInKB > (maxFileSize ?? maxFileSizeInKB);
    });

    return invalidFiles ? { fileSizeValidator: true } : {};
  };
}
