/**
 * General Email Regex (RFC 5322 Official Standard)
 * @see https://emailregex.com/
 */
export const emailPattern = /^([\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,6})$/;
export const ALERTA_CANCELAR = '¿Tienes Cambios sin Guardar, Desea Cancelar?';
export const ALERTA_SUBTITULO_ELIMINAR = 'Esta acción no se podrá revertir';

export const typeData = {
  Dropdown: 'dropdown',
  String: 'string',
  Input: 'input',
  Porcentaje: 'porcentaje',
  Link: 'link',
  InputSearch: 'inputSearch',
  CheckboxList: 'checkboxList',
  DropDownDinamic: 'dropDownDinamic',
  Calendar: 'calendar',
  DropdownSearch: 'dropdownSearch',
  Date: 'date',
  ViewDocument: 'viewDocument',
  CheckboxCustom: 'CheckboxCustom',
};

export const actionsNames = {
  Update: 'actionUpdate',
  Add: 'actionAdd',
  Delete: 'actionDelete',
  ReAsign: 'actionReAsign',
  Modal: 'actionModal',
  Asociar: 'actionAsociar',
  Contestar: 'actionContestar',
  Cancelar: 'actionCancelar',
  UpdateNotificacionManual: 'actionUpdateNotificacionManual',
  UpdateReasignar: 'actionUpdateReasignar',
  ComoCierre: 'actionNotificacionCierre',
  Aceptar: 'actionAceptar',
};

export const actionsTable = [
  actionsNames.Update,
  actionsNames.Add,
  actionsNames.Delete,
  actionsNames.ReAsign,
  actionsNames.Modal,
  actionsNames.UpdateNotificacionManual,
  actionsNames.UpdateReasignar,
];

export const typeObjects = {
  TreeTable: 'p-treeTable',
};
