import { Component, ElementRef, Host, Input, OnDestroy, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { GenericToggleInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';

@Component({
  selector: 'app-generic-input-toggle',
  templateUrl: './generic-input-toggle-checkbox.component.html',
  styleUrls: ['./generic-input-toggle-checkbox.component.scss'],
})
export class GenericInputToggleComponent
  extends AbstractGenericInputComponent<GenericToggleInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericToggleInput;

  /**
   * Referencia del input.
   */
  @ViewChild('input', { static: false })
  public input: ElementRef<HTMLInputElement>;

  /**
   * Constructor de clase GenericInputTextComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService
  ) {
    super(formRef, genericFormService);
  }

  /**
   * Getter para objeto de clases.
   */
  public get classes(): Record<string, boolean> {
    return {
      'switch-disabled': this.control.disabled,
      'switch-required': this.config.required,
    };
  }

  /**
   * Label "Si".
   */
  public get yesLabel(): string {
    return this.config?.yesLabel ?? 'Sí';
  }

  /**
   * Label "No".
   */
  public get noLabel(): string {
    return this.config?.yesLabel ?? 'No';
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Focus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * Blur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.input?.nativeElement?.focus(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Input event handler.
   */
  public onChange(e): void {
    if (this.fieldConfig?.onChange) {
      this.fieldConfig?.onChange(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.input?.nativeElement?.focus(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Key-down event handler.
   */
  public onKeyDown(e): void {
    if (this.fieldConfig?.onKeyDown) {
      this.fieldConfig?.onKeyDown(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.input?.nativeElement?.focus(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Key-down event handler.
   */
  public onInput(e): void {
    if (this.fieldConfig?.onInput) {
      this.fieldConfig?.onInput(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.input?.nativeElement?.focus(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }
}
