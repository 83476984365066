import { NgModule } from '@angular/core';
import { FiltrodatosService } from './filtrodatos.service';

@NgModule({
  providers: [FiltrodatosService],
})
export class FiltroDatosModule {
  /**
   * Constructor de clase FiltroDatosModule
   */
  public constructor(_: FiltrodatosService) {
    // Solamente requerimos el servicio FiltrodatosService para que se
    // inicialize a nivel aplicación.
  }
}
