import { Component, ElementRef, Host, Input, OnDestroy, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { genericRestrictHandler } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/generic-restrict-handler';
import { GenericTextInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { whiteSpaceValidator } from '@app-shared/generic-reactive-forms/validators/whitespace';

@Component({
  selector: 'app-generic-input-text',
  templateUrl: './generic-input-text.component.html',
  styleUrls: ['./generic-input-text.component.scss'],
})
export class GenericInputTextComponent
  extends AbstractGenericInputComponent<GenericTextInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericTextInput;

  /**
   * Referencia del input.
   */
  @ViewChild('input', { static: false })
  public input: ElementRef<HTMLInputElement>;

  /**
   * Constructor de clase GenericInputTextComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService
  ) {
    super(formRef, genericFormService);
  }

  /**
   * String para indicar si se debe realizar autocompletado en el campo.
   */
  public get autocomplete(): string {
    return this.config?.autocomplete ?? 'off';
  }

  /**
   * Booleano que indica si se debe realizar comprobacion ortografica en el campo.
   */
  public get spellcheck(): boolean {
    return this.config?.spellcheck ?? false;
  }

  /**
   * Tipo del campo.
   */
  public get type(): string {
    return this.config?.type ?? 'text';
  }

  /**
   * Longitud máxima del campo.
   */
  public get maxlength(): number {
    return this.fieldConfig?.maxlength;
  }

  /**
   * Longitud minima del campo.
   */
  public get minlength(): number {
    return this.fieldConfig?.minlength;
  }

  /**
   * Patron para validación del campo.
   */
  public get pattern(): RegExp {
    return this.fieldConfig?.pattern;
  }

  /**
   * Booleano que indica si se debe mostrar el botón para limpiar el campo.
   */
  public get showClear(): boolean {
    return this.fieldConfig?.showClear && this.control.value?.length > 0;
  }

  /**
   * Handler para limpiar valor del campo.
   */
  public clear(): void {
    this.control.setValue(null);
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.internalValidators = [whiteSpaceValidator];

    super.ngOnInit();
  }

  /**
   * Focus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * Blur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.input?.nativeElement?.focus(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Input event handler.
   */
  public onChange(e): void {
    if (this.fieldConfig?.onChange) {
      this.fieldConfig?.onChange(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.input?.nativeElement?.focus(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Key-down event handler.
   */
  public onKeyDown(e): void {
    if (this.fieldConfig?.onKeyDown) {
      this.fieldConfig?.onKeyDown(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.input?.nativeElement?.focus(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Key-down event handler.
   */
  public onInput(e): void {
    if (this.fieldConfig?.restrict) {
      genericRestrictHandler<KeyboardEvent>(this.fieldConfig.restrict, e, this.control);
    }

    if (this.fieldConfig?.onInput) {
      this.fieldConfig?.onInput(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.input?.nativeElement?.focus(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }
}
