import { deshabilitarRoles } from '@app-shared/generic-roles/utils';
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs/operators';
import { AbstractPermissionManager } from './abstract-permission-manager';

export class GenericPermissionManager extends AbstractPermissionManager<string> {
  /**
   * Subject de inicializacion del manager.
   */
  private initializedSubject$ = new BehaviorSubject<boolean>(false);

  /**
   * Constructor de clase `GenericRolesModulespermissionsService`.
   */
  private constructor() {
    super();
  }

  /**
   * Observable que indica si se ha inicializado el manager de permisos.
   */
  public get initialized$(): Observable<boolean> {
    return this.initializedSubject$.asObservable().pipe(filter((r) => r));
  }

  /**
   * @inheritdoc
   */
  public init(items: number[]): void {
    this.populate(items.map((i) => i?.toString()));
    this.initializedSubject$.next(true);
  }

  /**
   * @inheritdoc
   */
  public hasPermission(index: string): boolean {
    if (deshabilitarRoles()) {
      return true;
    }

    return super.hasPermission(index);
  }

  /**
   * @inheritdoc
   */
  public hasPermission$(index: string): Observable<boolean> {
    if (deshabilitarRoles()) {
      return of(true);
    }

    return super.hasPermission$(index);
  }

  /**
   * @inheritdoc
   */
  public hasPermissions(items: string[]) {
    if (deshabilitarRoles()) {
      return of(true);
    }

    return super.hasPermissions(items);
  }

  /**
   * Fabrica una instancia de esta clase.
   */
  public static build() {
    return new GenericPermissionManager();
  }
}
