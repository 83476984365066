import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { ManejoErroresService, ESTATUS_MENSAJES_INFORMATIVOS } from '@app-shared/services/manejo-errores.service';
import { HTTP_ERROR } from '../models/http-error.enum';

/**
 * Header de intercepción de errores.
 */
export const INTERCEPTAR_ERRORES = 'InterceptErrors';

/**
 * Número de reintentos de petición cuando ocurre un error.
 */
const NUMERO_REINTENTOS = 2;

/**
 * Tiempo en milisegundos de retraso al reintentar la petición.
 */
const RETRASO_MILISEGUNDOS = 2000;

/**
 * Interceptor para gestionar pantalla genérica de error en sistema.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * @inheritdoc
   * @param manejoErrorService
   */
  constructor(private manejoErrorService: ManejoErroresService) {}

  /**
   * Implementación del método intercept de HttpInterceptor
   * @param request
   * @param next
   * @returns
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has(INTERCEPTAR_ERRORES)) {
      return retryHandler(next.handle(request), this.manejoErrorService);
    }

    return retryHandler(next.handle(request), this.manejoErrorService, () =>
      this.manejoErrorService.recibeEstadoError(true)
    );
  }
}

/**
 * Fabrica de reintentos para peticiones, en caso de que se defina el Safe Handler este se ejecutara despues
 * del limite de reintentos.
 */
function retryHandler(
  item: Observable<HttpEvent<any>>,
  servicioErrores: ManejoErroresService,
  safeHandler?: () => void
) {
  return item.pipe(
    retryWhen((error) => {
      return error.pipe(
        mergeMap((errorInterno, index) => {
          if (
            index < NUMERO_REINTENTOS &&
            (errorInterno.status === HTTP_ERROR.INTERNAL_SERVER_ERROR ||
              errorInterno?.data?.errorCode === HTTP_ERROR.INTERNAL_SERVER_ERROR)
          ) {
            servicioErrores.aplicandoReintentos = true;
            return of(errorInterno).pipe(delay(RETRASO_MILISEGUNDOS));
          }

          servicioErrores.aplicandoReintentos = false;
          if (safeHandler && !ESTATUS_MENSAJES_INFORMATIVOS.includes(errorInterno.status)) {
            safeHandler();
          }

          throw errorInterno;
        })
      );
    })
  );
}
