import { Component, OnInit } from '@angular/core';
import {
  GenericConfirmation,
  GenericConfirmationService,
} from '@app-shared/generic-components/services/generic-confirm-dialog.service';
import { uniqueId } from '@app-shared/generic-reactive-forms/utils/generics.utils';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-generic-confirm-dialog',
  templateUrl: './generic-confirm-dialog.component.html',
  styleUrls: ['./generic-confirm-dialog.component.scss'],
  providers: [ConfirmationService],
})
export class GenericConfirmDialogComponent implements OnInit {
  /**
   * Mapa de mensajes de confirmacion.
   */
  public confirmationsMap: Map<symbol, string> = new Map();

  /**
   * Subject de destruccion.
   */
  private readonly destroySubject$ = new Subject<void>();

  /**
   * Clase custom para dialogo.
   */
  private customStyleClass = '';

  /**
   * Constructor de clase `GenericConfirmDialogComponent`.
   */
  public constructor(
    private confirmDialogService: GenericConfirmationService,
    private primeConfirmationService: ConfirmationService
  ) {}

  /**
   * Iterador de confirmaciones.
   */
  public get confirmations(): string[] {
    return Array.from(this.confirmationsMap.values());
  }

  /**
   * Obtiene el valor en caso de existir de una clase custom.
   */
  public get customClass(): string {
    return this.customStyleClass;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.confirmDialogService.onConfirm$
      .pipe(takeUntil(this.destroySubject$.asObservable()))
      .subscribe((confirmation) => {
        this.confirmation(confirmation);
      });
  }

  /**
   * Retorna la etiqueta de aceptar a mostrar en el dialogo.
   */
  public getAcceptLabel(dialog): string {
    return dialog.option('acceptLabel') ?? 'Confirmar';
  }

  /**
   * Retorna la etiqueta de rechazar a mostrar en el dialogo.
   */
  public getRejectLabel(dialog): string {
    return dialog.option('rejectLabel') ?? 'Cancelar';
  }

  /**
   * Retorna una opcion de la configuracion del dialogo.
   */
  public getOption(dialog, optionName): any {
    return dialog.option(optionName);
  }

  /**
   * Handler para disparar eventos de confirmación.
   */
  private confirmation(confirmation: GenericConfirmation) {
    const identifier = Symbol();
    const id = uniqueId();

    this.customStyleClass = confirmation.customClass;
    this.confirmationsMap.set(identifier, id);

    const { accept, reject, ...config } = confirmation;

    const newAccept = () => {
      if (accept) {
        accept();
      }

      setTimeout(() => this.confirmationsMap.delete(identifier));
    };

    const newReject = () => {
      if (reject) {
        reject();
      }

      setTimeout(() => this.confirmationsMap.delete(identifier));
    };

    setTimeout(() => {
      this.primeConfirmationService.confirm({ ...config, key: id, accept: newAccept, reject: newReject });
    });
  }
}
