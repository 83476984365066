import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MensajesGenerales } from '@app-services/constantes';
import { MessagesMainService } from '@app-shared/services/messages-main.service';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { LoginExternoService } from '../services/login-externo.service';

type tipos = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({
  providedIn: 'root',
})
export class DeterminarPasswordGuard implements CanActivate {
  /**
   * Constructor de la clase DeterminarPasswordGuard
   * @inheritdoc
   */
  public constructor(
    private loginExternoService: LoginExternoService,
    private messagesMainService: MessagesMainService
  ) {}

  /**
   * Valida si el usuario puede acceder a la vista de Determinar Contrasenia
   * @inheritdoc
   */
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): tipos {
    try {
      const usuario = atob(next.paramMap.get('usuario'));

      return this.loginExternoService.validarCorreo(usuario).pipe(
        take(1),
        map((response) => {
          if (!response || !response.estado) {
            this.messagesMainService.messageError('La Liga Solicitada no es Válida');
            return false;
          }
          return true;
        }),
        catchError((error) => {
          if (error?.error?.error?.codigo === '412') {
            this.messagesMainService.messageError('La Liga Solicitada no es Válida');
          } else {
            this.messagesMainService.messageError(error?.error?.error?.mensaje || error?.error?.error);
          }
          return of(false);
        })
      );
    } catch {
      this.messagesMainService.messageError(MensajesGenerales.ERRORES.ERROR_NO_IDENTIFICADO);
      return false;
    }
  }
}
