/**
 * Enum de modulos disponibles
 */
export enum GenericRolesModulosEnum {
  ASUNTOS = 1,
  ASUNTOS_BUSQUEDAASUNTOS = 2,
  TAREAS = 3,
  TAREAS_CUMPLIRTAREAS = 4,
  TAREAS_VALIDARTAREAS = 5,
  TAREAS_BUSQUEDA_DE_TAREAS_PARA_ASIGNAR = 12,
  PENDIENTES_DEL_DIA = 6,
  PENDIENTES_DEL_DIA_NOTIFICACIONES_PERSONALIZADAS = 7,
  PENDIENTES_DEL_DIA_TAREAS_SIN_CUMPLIR = 8,
  PENDIENTES_DEL_DIA_TAREAS_SIN_VALIDAR = 9,
  ADMINISTRACION_DE_ROLES = 10,
  ROLES_BUSQUEDA_DE_ROLES = 11,
  NOTIFICACIONES = 13,
  NOTIFICACIONES_AGREGAR_NOTIFICACIONES = 14,
  NOTIFICACIONES_BUSQUEDA_DE_NOTIFICACIONES_COMUNES = 15,
  NOTIFICACIONES_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS = 16,
  NOTIFICACIONES_BUSQUEDA_DE_NOTIFICACIONES_COLABORADOR = 70,
  PARAMETRIZACION = 17,
  PARAMETRIZACION_ESTADO_PROCESAL = 18,
  PARAMETRIZACION_CHECKLIST_ASUNTOS = 19,
  PARAMETRIZACION_CHECKLIST_NOTIFICACIONES = 20,
  PARAMETRIZACION_PLANTILLAS_DE_TAREAS = 21,
  PARAMETRIZACION_INTENTOS_VALIDACION_TAREAS = 22,
  PARAMETRIZACION_ACCIONES_FLUJOS = 23,
  PARAMETRIZACION_SUCESOS = 24,
  PARAMETRIZACION_NOTIFICACIONES_AUTOMATICAS = 25,
  PARAMETRIZACION_PLANTILLAS_DE_NOTIFICACIONES = 26,
  PARAMETRIZACION_PLANTILLAS = 27,
  PARAMETRIZACION_SEMAFOROS = 28,
  PARAMETRIZACION_CORREOS_Y_DOMINIOS = 29,
  INMUEBLES = 30,
  INMUEBLES_BUSQUEDA_DE_INMUEBLES = 31,
  REPORTES = 32,
  REPORTES_ESTATUS_DE_CONTRATO_DE_SERVICIO_CFE = 33,
  REPORTES_LICENCIAS_DE_INMUEBLES_EN_OBRA = 34,
  REPORTES_OBRAS_EN_PROCESO = 35,
  REPORTES_LICENCIAS_POR_EXPIRAR = 36,
  ADMINISTRACION_DE_CATALOGOS = 37,
  CATALOGOS_CONTROL_DE_ADMINISTRACION_DE_CATALOGOS = 38,
  CATALOGOS_ADMINISTRADOR_DE_SINDICATOS = 39,
  CATALOGOS_AUTORIDAD_LABORAL = 40,
  CATALOGOS_EMPRESA_FILIAL = 41,
  CATALOGOS_BUSQUEDA_DE_MATERIAS = 42,
  CATALOGOS_BUSQUEDA_DE_AREA = 43,
  CATALOGOS_BUSQUEDA_DE_PROCESOS = 44,
  BOVEDA = 45,
  CONSULTA_EN_BOVEDA = 46,
  GEOGRAFIA = 47,
  GEOGRAFIA_PAIS = 48,
  GEOGRAFIA_ESTADO = 49,
  GEOGRAFIA_MUNICIPIO = 50,
  GEOGRAFIA_CIUDAD = 51,
  LICENCIAS_Y_PERMISOS = 35,
  QUEJAS_PROFECO = 52,
  MULTA_PROFECO = 53,
  AREAS_INTERNAS_PARA_BUSQUEDA_DE_SOLUCION = 54,
  RESPONSABLES_DE_AREAS_INTERNAS = 55,
  AUDIENCIAS_PROFECO = 56,
  DELEGACION_SUBDELEGACION_UNIDAD_DE_SERVICIO = 57,
  MOTIVO_SALVO_LOS_DERECHOS = 58,
  MOTIVOS_DE_MULTA_PROFECO = 59,
  PROVEEDORES_CITADOS_EN_LA_AUDIENCIA = 60,
  TIPOS_DE_MOTIVOS_DE_QUEJA_PROFECO = 61,
  TIPOS_DE_ACUERDO_INCOMPARECENCIA = 62,
  REPRESENTANTES_LEGALES = 63,
  AUTORIDADES_MULTAS = 64,
  MOTIVOS_DE_NO_IMPUGNACION = 65,
  PLANTILLAS_PREDETERMINADAS = 66,
  DOCUMENTOS_DE_NOTIFICACION = 67,
  CONDICIONES_DE_MULTAS = 68,
  SECCIONES_EUI = 72,
  RESGUARDO_EN_BOVEDA = 73,
}

/**
 * Enums de acciones.
 */
export enum GenericRolesPermisosEnum {
  ASUNTOS_ASIGNARNOTARIOS = 1,
  ASUNTOS_AGREGARASUNTO = 2,
  ASUNTOS_PANTALLASEGUIMIENTO = 3,
  ASUNTOS_INFORMACIONASUNTO = 4,
  ASUNTOS_CERRARASUNTO = 5,
  TAREAS_CUMPLIRTAREAS_HISTORICO = 6,
  AGREGAR_ROL_BUSQUEDA_DE_ROLES = 7,
  EDITAR_ROL_BUSQUEDA_DE_ROLES = 8,
  USUARIOS_BUSQUEDA_DE_ROLES = 9,
  ELIMINAR_ROL_BUSQUEDA_DE_ROLES = 10,
  VALIDACION_DE_DOCUMENTOS_BUSQUEDA_DE_ASUNTOS = 11,
  VER_ESTATUS_BUSQUEDA_DE_ASUNTOS = 12,
  REASIGNAR_TAREAS_BUSQUEDA_DE_TAREAS_PARA_ASIGNAR = 13,
  EDITAR_TAREA_BUSQUEDA_DE_TAREAS_PARA_ASIGNAR = 14,
  EXPORTAR_BUSQUEDA_DE_TAREAS_PARA_ASIGNAR = 15,
  AGREGAR_CUMPLIMIENTO_BUSQUEDA_DE_TAREAS_POR_CUMPLIR = 16,
  VALIDAR_CUMPLIMIENTO_BUSQUEDA_DE_TAREAS_POR_VALIDAR = 17,
  ENVIAR_NOTIFICACION_AGREGAR_NOTIFICACIONES = 18,
  VER_DETALLE_BUSQUEDA_DE_NOTIFICACIONES_COMUNES = 20,
  VALIDAR_NOTIFICACION_BUSQUEDA_DE_NOTIFICACIONES_COMUNES = 21,
  ASOCIAR_NOTIFICACION_A_ASUNTO_BUSQUEDA_DE_NOTIFICACIONES_COMUNES = 22,
  UTILIZA_NOTIFICACION_COMO_CIERRE_DE_ASUNTO_BUSQUEDA_DE_NOTIFICACIONES_COMUNES = 23,
  REASIGNAR_NOTIFICACION_BUSQUEDA_DE_NOTIFICACIONES_COMUNES = 24,
  CONTESTAR_NOTIFICACION_BUSQUEDA_DE_NOTIFICACIONES_COMUNES = 25,
  CANCELAR_NOTIFICACION_BUSQUEDA_DE_NOTIFICACIONES_COMUNES = 26,
  VER_DETALLE_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS = 28,
  VALIDAR_NOTIFICACION_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS = 29,
  ASOCIAR_NOTIFICACION_A_ASUNTO_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS = 30,
  UTILIZA_NOTIFICACION_COMO_CIERRE_DE_ASUNTO_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS = 31,
  REASIGNAR_NOTIFICACION_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS = 32,
  CONTESTAR_NOTIFICACION_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS = 33,
  CANCELAR_NOTIFICACION_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS = 34,
  AGREGAR_ESTADO_PROCESAL = 35,
  ELIMINAR_ESTADO_PROCESAL = 36,
  GUARDAR_ESTADO_PROCESAL = 37,
  AGREGAR_CHECKLIST_ASUNTOS = 38,
  ELIMINAR_CHECKLIST_ASUNTOS = 39,
  GUARDAR_CHECKLIST_ASUNTOS = 40,
  AGREGAR_CHECKLIST_NOTIFICACIONES = 41,
  ELIMINAR_CHECKLIST_NOTIFICACIONES = 42,
  GUARDAR_CHECKLIST_NOTIFICACIONES = 43,
  VER_PLANTILLA_PLANTILLAS_DE_TAREAS = 44,
  CARGAR_PLANTILLA_PLANTILLAS_DE_TAREAS = 45,
  ELIMINAR_PLANTILLA_PLANTILLAS_DE_TAREAS = 46,
  GUARDAR_INTENTOS_VALIDACION_TAREAS = 47,
  AGREGAR_ACCIONES_FLUJOS = 48,
  EDITAR_ACCIONES_FLUJOS = 49,
  ELIMINAR_ACCIONES_FLUJOS = 50,
  AGREGAR_SUCESOS = 51,
  EDITAR_SUCESOS = 52,
  GUARDAR_NOTIFICACIONES_AUTOMATICAS = 53,
  VER_PLANTILLA_PLANTILLAS_DE_NOTIFICACIONES = 54,
  CARGAR_PLANTILLA_PLANTILLAS_DE_NOTIFICACIONES = 55,
  ELIMINAR_PLANTILLA_PLANTILLAS_DE_NOTIFICACIONES = 56,
  VER_PLANTILLA_PLANTILLAS = 57,
  CARGAR_PLANTILLA_PLANTILLAS = 58,
  ELIMINAR_PLANTILLA_PLANTILLAS = 59,
  GUARDAR_SEMAFOROS = 60,
  AGREGAR_CORREOS_Y_DOMINIOS = 61,
  ACTIVAR_BLOQUEAR_CORREOS_Y_DOMINIOS = 62,
  ELIMINAR_CORREOS_Y_DOMINIOS = 63,
  VER_EXPEDIENTE_BUSQUEDA_DE_INMUEBLES = 64,
  AGREGAR_ASUNTO_BUSQUEDA_DE_INMUEBLES = 65,
  EXPORTAR_ESTATUS_DE_CONTRATO_DE_SERVICIO_CFE = 66,
  EXPORTAR_LICENCIAS_DE_INMUEBLES_EN_OBRA = 67,
  EXPORTAR_OBRAS_EN_PROCESO = 68,
  EXPORTAR_LICENCIAS_POR_EXPIRAR = 69,
  GUARDAR_CONTROL_DE_ADMINISTRACION_DE_CATALOGOS = 70,
  AGREGAR_ADMINISTRADOR_DE_SINDICATOS = 71,
  EDITAR_ADMINISTRADOR_DE_SINDICATOS = 72,
  ELIMINAR_ADMINISTRADOR_DE_SINDICATOS = 73,
  AGREGAR_AUTORIDAD_LABORAL = 74,
  EDITAR_AUTORIDAD_LABORAL = 75,
  ELIMINAR_AUTORIDAD_LABORAL = 76,
  AGREGAR_EMPRESA_FILIAL = 77,
  EDITAR_EMPRESA_FILIAL = 78,
  ELIMINAR_EMPRESA_FILIAL = 79,
  AGREGAR_BUSQUEDA_DE_MATERIAS = 80,
  DETALLE_DE_ASUNTO_BUSQUEDA_DE_ASUNTOS = 81,
  PRESTAR_BUSQUEDA_DE_ASUNTOS = 82,
  ELIMINAR__BUSQUEDA_DE_ASUNTOS = 83,
  AGREGAR_PAIS = 84,
  EDITAR_PAIS = 85,
  ELIMINAR_PAIS = 86,
  AGREGAR_ESTADO = 87,
  EDITAR_ESTADO = 88,
  ELIMINAR_ESTADO = 89,
  AGREGAR_MUNICIPIO = 90,
  EDITAR_MUNICIPIO = 91,
  ELIMINAR_MUNICIPIO = 92,
  AGREGAR_CIUDAD = 93,
  EDITAR_CIUDAD = 94,
  ELIMINAR_CIUDAD = 95,
  ACCEPTAR_NOTIFICACIONES_COMUNES = 142,
  ACCEPTAR_NOTIFICACIONES_PERSONALIZADAS = 143,
  ELIMINAR_SUCESO = 144,
  PENDIENTES_DIA_NOTIFICACIONES_VER_DETALLE = 146,
  PENDIENTES_DIA_NOTIFICACIONES_ACEPTAR = 147,
  PENDIENTES_DIA_NOTIFICACIONES_ASOCIAR_FOLIO_ASUNTO = 148,
  PENDIENTES_DIA_NOTIFICACIONES_REASIGNAR = 149,
  PENDIENTES_DIA_NOTIFICACIONES_VALIDAR = 150,
  PENDIENTES_DIA_NOTIFICACIONES_UTILIZAR_DOCUMENTO_CIERRE = 151,
  PENDIENTES_DIA_NOTIFICACIONES_CANCELAR = 152,
  PENDIENTES_DIA_NOTIFICACIONES_CONTESTAR = 154,
  EUI_DATOS_INMUEBLE = 155,
  EUI_DOMICILIO_CARACTERISTICAS_FISICAS = 156,
  EUI_ESCRITURACION = 157,
  EUI_ARRENDAMIENTO = 158,
  EUI_DATOS_OBRA_NUEVA = 159,
  EUI_HISTORICO_CAMBIOS = 160,
  EUI_DOMICLIOS = 161,
  EUI_DOCUMENTACION_INMOBILIARIA = 162,
  EUI_LICENCIAS_Y_PERMISOS = 163,
  EUI_DOCUMENTOS_ADICIONALES = 164,
  EUI_CONTRATOS = 165,
  ASIGNAR_TAREAS = 166,
}

export enum GenericRolesPantallaSeguimiento {
  EDITAR_ASUNTO = 1,
  EDITAR_INMUEBLE = 2,
  RESGUARDO_BOVEDA = 5,
  INICIAR_REMODELACION = 6,
  TAREAS = 7,
  PREDICTAMEN = 8,
  DICTAMEN = 9,
  SUCESOS_Y_DOCUMENTO = 10,
  ESTADO_PROCESAL = 11,
  CIERRE_DE_ASUNTO = 12,
  PARAMETRIZAR_DOCUMENTOS = 13,
  CREAR_ASUNTO_ASOCIADO = 14,
  INFORMACION_DEL_ASUNTO = 16,
  PANTALLA_INFORMATIVA = 17,
  EDITAR_ESPECIFICACIONES_COLECTIVO = 19,
  GESTION_LICENCIAS = 20,
  EDITAR_CENTRO_COLECTIVO = 21,
}

/**
 * ID de tipo de asignacion encargado.
 */
export enum TipoAccesoConstantes {
  ENCARGADO = 'encargado',
  CONSULTA = 'consulta',
}

/**
 * ID de tipo de usuario..
 */
export enum TipoUsuarioConstantes {
  EXTERNO = 'externo',
  INTERNO = 'interno',
}
