import { Injectable } from '@angular/core';
import { AbstractPermissionManager } from './abstract-permission-manager';

@Injectable()
export class GenericRolesModulesService extends AbstractPermissionManager<string> {
  /**
   * Constructor de clase `GenericRolesModulesService`.
   */
  public constructor() {
    super();
  }

  /**
   * @inheritdoc
   */
  public init(items: number[]): void {
    this.populate(items.map((i) => i?.toString()));
  }
}
