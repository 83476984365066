import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alerta-abandonar-vista',
  templateUrl: './alerta-abandonar-vista.component.html',
  styleUrls: ['./alerta-abandonar-vista.component.css'],
})
export class AlertaAbandonarVistaComponent implements OnDestroy {
  public event: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRefAlert: BsModalRef) {}
  public ngOnDestroy(): void {
    this.event?.unsubscribe();
  }

  public cerrarModal() {
    this.bsModalRefAlert.hide();
  }

  public aceptar() {
    this.event.emit({ data: { aceptar: true }, res: 200 });
  }
}
