import { Location } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormBuilder } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { GenericFormComponent } from '@app-shared/generic-reactive-forms/components/generic-form/generic-form.component';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { GenericRolesService } from '@app-shared/generic-roles/services/generic-roles.service';
import { deshabilitarRoles } from '@app-shared/generic-roles/utils';
import { MessagesMainService } from '@app-shared/services/messages-main.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-roles-admin-aware-form',
  templateUrl: './roles-admin-aware-form.component.html',
  styleUrls: ['./roles-admin-aware-form.component.scss'],
  providers: [GenericFormService],
})
export class RolesAdminAwareFormComponent extends GenericFormComponent implements OnInit, OnDestroy {
  /**
   * Subject de acceso.
   */
  private accesoSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @inheritdoc
   */
  public constructor(
    public genericRolesService: GenericRolesService,
    public genericFormService: GenericFormService,
    protected formBuilder: FormBuilder,
    protected injector: Injector,
    protected location: Location,
    protected messagesMainService: MessagesMainService,
    @Optional() @SkipSelf() protected parentComponent: RolesAdminAwareFormComponent
  ) {
    super(genericFormService, formBuilder, injector);
  }

  /**
   * Booleano que indica si se debe de prevenir el submit del formulario.
   */
  public get preventSubmit(): boolean {
    return this.disabledValue;
  }

  /**
   * Tipo de acceso.
   */
  public get acceso(): boolean {
    return this.accesoSubject$.value;
  }

  /**
   * Tipo de acceso.
   */
  public get acceso$(): Observable<boolean> {
    return this.accesoSubject$.asObservable();
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();

    if (this.parentComponent) {
      this.parentComponent.acceso$.pipe(takeUntil(this.destructionSubject$.asObservable())).subscribe((r) => {
        this.setTipoAcceso(!this.parentComponent.disableFieldset);
      });
      return;
    }

    this.inicializar();
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.accesoSubject$.complete();
  }

  /**
   * Inicializador del componente.
   */
  public inicializar() {
    if (deshabilitarRoles()) {
      this.accesoSubject$.next(true);
      return;
    }

    this.setTipoAcceso(this.genericRolesService?.rol?.administrador);
  }

  /**
   * Setea el tipo de acceso para el formulario.
   */
  private setTipoAcceso(acceso: boolean): void {
    this.accesoSubject$.next(true);

    if (!acceso) {
      this.disable();
    }
  }
}
