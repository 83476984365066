import { AbstractControl, FormControl } from '@angular/forms';

/**
 * Validador para evitar entradas con solo espacios en blanclo
 * @param control
 * @returns
 */
export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

/**
 * Función para devolver un mensaje de error al no pasar la validación
 * @param control
 * @returns
 */
export function noWhitespaceValidatorMessage(control: FormControl) {
  return `"${control.value}" Ingrese Información Válida.`;
}

/**
 * Función para no permite la entrada de espacios en blanco al prinicipio de la cadena
 * @param control
 * @returns
 */
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
