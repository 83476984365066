import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HerramientasFormulario } from '@app-models/herramientasFormulario';
import { GestorModalRef, GestorModalService } from '@app-services/gestor-modal.service';
import { GenericFormElements, GenericTextInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { ConfirmationService } from 'primeng/api';
import { DataModalConfirmacionProceso } from '../ConfiguracionModalConfirmacionProceso.models';

@Component({
  selector: 'app-modal-confirmacion',
  templateUrl: './ModalConfirmacionProceso.html',
  styleUrls: ['./ModalConfirmacionProceso.css'],
  providers: [ConfirmationService],
})
export class ModalConfirmacionProcesoComponent extends HerramientasFormulario implements OnInit, OnDestroy {
  /** Formulario */
  public form: FormGroup;
  public btnAceptar = true;

  /*
   * varible para guardar configuracion del modal
   */
  public infoModal: DataModalConfirmacionProceso;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public formBuilder: FormBuilder,
    public bsModalRef: GestorModalRef,
    public modalService: GestorModalService,
    private confirmationService: ConfirmationService
  ) {
    super(bsModalRef, modalService);
  }

  /**
   * Configuracion para componente generico de formulario
   */
  public get config(): GenericFormElements {
    return {
      texConfirmacion: {
        name: 'texConfirmacion',
        minlength: this.infoModal.data.textConfirmation?.length ?? 9,
        maxlength: this.infoModal.data.textConfirmation?.length ?? 9,
        placeholder: this.infoModal.data?.textConfirmation ?? 'CONFIRMAR',
        onChange: () => {
          const confirmacion = this.form.get('texConfirmacion').value;
          if (confirmacion === (this.infoModal.data?.textConfirmation ?? 'CONFIRMAR')) {
            this.btnAceptar = false;
          } else {
            this.btnAceptar = true;
          }
        },
      } as GenericTextInput,
    };
  }

  public ngOnInit(): void {
    this.buildFormModal();
  }

  public ngOnDestroy(): void {
    this.event?.unsubscribe();
  }

  public cerrarModal(): void {
    this.bsModalRefx.hide();
  }

  public cancelar(): void {
    this.confirmationService.confirm({
      message: `¿Está seguro de que desea Cancelar?`,
      header: 'Cancelación',
      accept: () => {
        this.bsModalRefx.hide();
      },
    });
  }

  public aceptar(): void {
    this.event.emit({ data: { aceptar: true }, res: 200 });
    this.bsModalRefx.hide();
  }

  private buildFormModal() {
    return new Promise((resolve, reject) => {
      this.form = this.formBuilder.group({
        texConfirmacion: new FormControl(null, [
          Validators.required,
          Validators.pattern(this.infoModal.data?.validatorsPattern ?? '^[A-Z]+$'),
        ]),
      });
      resolve(true);
    });
  }
}
