import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CaptchaApiService } from "./services/captcha-api.service";
import { CaptchaReaderDirective } from "./directives/captcha-reader.directive";

@NgModule({
  declarations: [CaptchaReaderDirective],
  imports: [CommonModule],
  providers: [CaptchaApiService],
  exports: [CaptchaReaderDirective],
})
export class RecaptchaV3Module {}
export { CaptchaApiService } from "./services/captcha-api.service";
