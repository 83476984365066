import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, filter, fromEvent, interval, merge, Subject, Subscription, takeUntil, timer } from 'rxjs';
import { GestorModalOptions, GestorModalRef, GestorModalService } from './gestor-modal.service';

import { CierreSesionComponent } from '@app-shared/commons/components/cierre-sesion/cierre-sesion.component';
import { SecureStorageService } from '../services/secure-storage.service';
import { AadService } from '@app-services/idps/aad.service';
import { AuthenticationResult, BrowserAuthError } from '@azure/msal-browser';
import { MessagesMainService } from '@app-shared/services';


/**
 * Tiempo de inactividad (30 minutos).
 */
const INACTIVITY_TIME = 30 * 60 * 1000;

/**
 * Tiempo de validacion de token (30 segundos).
 */
const POLLING_TIME = 30000;

/**
 * Tiempo de margen antes de que expire el token
 */
const MARGIN_TIME = 5 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class ActivityTimeService {
  /**
   * Variables que importamos a timer
   */
  private timer: Subscription = new Subscription();

  /**
   * Subject de destrucción del servicio.
   */
  private destructionSubject$ = new Subject<void>();

  /**
   * Subject de expiracion del token.
   */
  private expiredSubject$ = new BehaviorSubject<boolean>(false);

  constructor(
    private ngZone: NgZone,
    public bsModalRefx: GestorModalRef = null,
    public modalService: GestorModalService = null,
    private storageService: SecureStorageService,
    private aadService: AadService,
    private messageService: MessagesMainService,
  ) {
  }

  /**
   * Dispara subscripcion a metodos de comprobación periodica.
   */
  public inicializarPeriodicos(): void {
    this.polling();
    this.startWatching();
  }

  /**
   * Metodo que hace conteo hasta 30 mins de inactividad.
   */
  public startWatching(): void {
    const onActivity$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );

    this.ngZone.runOutsideAngular(() => {
      onActivity$
        .pipe(
          takeUntil(this.destructionSubject$.asObservable()),
          takeUntil(this.expiredSubject$.asObservable().pipe(filter((r) => r))),
        )
        .subscribe(() => {
          this.resetTimer();
        });

      this.startTimer();
    });
  }

  /**
   * Inicia nuevo timer al detectar actividad de usuario.
   */
  public resetTimer() {
    this.timer.unsubscribe();
    this.startTimer();
  }

  /**
   * Finalización de sesión al cumplir 30 mins. de inactividad.
   */
  public startTimer() {
    this.timer = timer(INACTIVITY_TIME)
      .pipe(takeUntil(this.expiredSubject$.asObservable().pipe(filter((r) => r))))
      .subscribe(() => {
        this.clearStorage();
        this.modalCierreSesion();
      });
  }

  /**
   * Verificación de token vigente, cada 30 segundos.
   */
  public polling(): void {

    const next = (tokenResponse: AuthenticationResult) => {
      const token = tokenResponse.accessToken;
      this.storageService.setItem('token', token);
    };

    const error = (err) => {
      if (err instanceof BrowserAuthError && err.errorCode === 'popup_window_error') {
        this.messageService.messageWarning('Se cerrara la sesión en 30 segundos, para evitar esto en un futuro por favor activa las ventanas emergentes.', 'Permisos del navegador');
      } else {
        this.messageService.messageWarning('Se cerrara la sesión en 30 segundos.', 'Expiro la sesión');
      }
      setTimeout(() => {
        this.modalCierreSesion();
      }, 30000);
    };


    interval(POLLING_TIME).subscribe({
      next: () => {
        const timeToExpire = this.aadService.getTokenTimeToExpire();
        if (timeToExpire < MARGIN_TIME) {
          this.aadService.renewToken().subscribe({
            next: next,
            error: error,
          });
        }
      },
    });
  }

  /**
   * Alerta informativa de cierre de sesión.
   */
  public modalCierreSesion() {
    this.expiredSubject$.next(true);

    const option: GestorModalOptions = {
      backdrop: 'static',
    };

    this.bsModalRefx = this.modalService.show(CierreSesionComponent, option);
  }

  /**
   * Limpieza de sesión.
   */
  private clearStorage(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
}
