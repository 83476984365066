import { Component, Host, Input, OnDestroy, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { GenericNumberInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { InputNumber } from 'primeng/inputnumber';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-generic-input-number',
  templateUrl: './generic-input-number.component.html',
  styleUrls: ['./generic-input-number.component.scss'],
})
export class GenericInputNumberComponent
  extends AbstractGenericInputComponent<GenericNumberInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericNumberInput;

  /**
   * Viewref a input de busqueda.
   */
  @ViewChild('input', { static: false }) public inputRef: InputNumber;

  /**
   * Constructor de clase GenericInputNumberComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService
  ) {
    super(formRef, genericFormService);
  }

  /**
   * String para indicar si se debe realizar autocompletado en el campo.
   */
  public get autocomplete(): string {
    return this.config?.autocomplete ?? 'off';
  }

  /**
   * Valor maximo para el campo.
   */
  public get max(): number {
    return this.fieldConfig?.max;
  }

  /**
   * Valor minimo para el campo.
   */
  public get min(): number {
    return this.fieldConfig?.min;
  }

  /**
   * Paso minimo para el campo.
   */
  public get step(): number {
    return this.fieldConfig?.step ?? 1;
  }

  /**
   * Booleano que indica si se debe usar separadores de agrupacion numerica.
   */
  public get useGrouping(): boolean {
    return this.fieldConfig?.useGrouping ?? false;
  }

  /**
   * Longitud maxima del valor.
   */
  public get maxlength(): number {
    return this.fieldConfig?.maxlength ?? null;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.control.valueChanges.pipe(takeUntil(this.destroySubject$.asObservable())).subscribe(() => {
      this.onChange(null);
    });
  }

  /**
   * Handler para realizar focus en el elemento.
   */
  public focus(): void {
    if (!this.control.touched) {
      return;
    }

    // : Implement focus.
  }

  /**
   * Input event handler.
   */
  public onChange(e): void {
    if (this.fieldConfig?.onChange) {
      this.fieldConfig?.onChange(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * On onFocus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        setValue: this.control.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * On onBlur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        setValue: this.control.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * On onInput event handler.
   */
  public onInput(e): void {
    if (this.fieldConfig?.onInput) {
      this.fieldConfig?.onInput(e, {
        setValue: this.control.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }
}
