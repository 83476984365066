<p-dialog
  [(visible)]="display"
  [modal]="true"
  [draggable]="true"
  [breakpoints]="{ '960px': '75vw' }"
  [resizable]="false"
  [position]="'center'"
  [style]="style"
  [closable]="false"
  [closeOnEscape]="false"
  (onShow)="onShow($event)"
>
  <ng-template pTemplate="header">
    <div class="modal-title">{{ tittle }}</div>

    <div class="p-dialog-header-icons">
      <app-button variant="primary" (onClick)="cancel($event)">
        <span class="p-dialog-header-close-icon pi pi-times"></span>
      </app-button>
    </div>
  </ng-template>

  <p-treeTable
    *ngIf="objectContent === 'treeTable'"
    [value]="dataContentTree"
    styleClass="borderTree"
    [columns]="columsTree"
    selectionMode="checkbox"
    [(selection)]="selectedNodes"
    (onNodeSelect)="nodeSelect($event)"
    (onNodeUnselect)="nodeUnselect($event)"
    (onHeaderCheckboxToggle)="selectAll($event['checked'])"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox>
        <span class="p-ml-2">Todos</span>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr>
        <td *ngFor="let col of columns; let i = index">
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
          <p-treeTableCheckbox [value]="rowNode" *ngIf="i == 0" #idData></p-treeTableCheckbox>
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>
  </p-treeTable>

  <div *ngIf="objectContent === 'visor'">
    <div *ngIf="files.length > 0">
      <iframe title="Visor" [src]="urlVisor"> </iframe>
    </div>
  </div>

  <ng-container *ngIf="objectContent === 'template'" [ngTemplateOutlet]="template"></ng-container>

  <ng-template pTemplate="footer" *ngIf="showButtons">
    <div class="clearfix">
      <label class="col-form-label float-left" *ngIf="showLegendRequiredFieldsFooter"
        ><strong>{{ mensajeCamposRequeridosAsterico }}</strong></label
      >
      <div class="group-buttons float-right">
        <app-button variant="gray" (onClick)="cancel()"> <i class="fa fa-times-circle"></i> Cancelar </app-button>
        <app-button variant="primary" (onClick)="save()" [disabled]="disableSaveButton | async">
          <i class="fas fa-check-circle"></i> Guardar
        </app-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-confirmDialog #cd styleClass="head-dialog-component" key="head-dialog-component-confirm">
  <p-footer>
    <app-button variant="gray" type="submit" (onClick)="cd.reject()">
      <i class="fas fa-times-circle"></i> Cancelar
    </app-button>
    <app-button variant="primary" (onClick)="cd.accept()"> <i class="fa fa-check-circle"></i> Confirmar </app-button>
  </p-footer>
</p-confirmDialog>
