import { Injectable } from '@angular/core';
import { IResponseList } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Municipio } from './municipio';

@Injectable({
  providedIn: 'root',
})
export class MunicipioService {
  constructor(private baseService: BaseService) {}

  public all(): Observable<Municipio[]> {
    return this.baseService
      .get(`${environment.backendURL}/municipio/`)
      .pipe(map((response) => response as Municipio[]));
  }

  public getMunicipioById(id): Observable<Municipio> {
    return this.baseService.get<Municipio>(`${environment.backendURL}/municipio/${id}`);
  }

  public getMunicipioIDEstado(id: number): Observable<IResponseList<Municipio>> {
    return this.baseService.get<IResponseList<Municipio>>(`${environment.backendURL}/municipio/estado/${id}`);
  }
}
