import { environment } from 'src/environments/environment';
import { AvailableEnvironmentsEnum } from '../commons/directives/environment-conditional.directive';

/**
 * Bypass para deshabilitar roles.
 *
 * Solo funcional en entornos de desarrollo DEV/QA.
 */
export function deshabilitarRoles() {
  return isDevelopment() && existeQueryDeshabilitacion();
}

/**
 * Retorna un booleano indicando si el ambiente corresponde a alguno de desarrollo.
 */
function isDevelopment() {
  return (
    environment.envName === AvailableEnvironmentsEnum.DEV ||
    environment.envName === AvailableEnvironmentsEnum.QA ||
    (environment.envName !== AvailableEnvironmentsEnum.PROD && environment.permitirDehabilitarRoles)
  );
}

/**
 * Retorna un booleano indicando si existe en el ambiente un query param de deshabilitación.
 */
function existeQueryDeshabilitacion() {
  if (sessionStorage.getItem('deshabilitarroles')) {
    return true;
  }

  const params = new URLSearchParams(window.location.search);
  const paramExists = params.get('deshabilitarroles');

  if (paramExists) {
    sessionStorage.setItem('deshabilitarroles', '1');
  }

  return paramExists;
}
