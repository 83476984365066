import { Component } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
})
export class AppSidebarComponent {
  constructor(private menuService: MenuService) {
    this.loadMenu();
  }

  public loadMenu() {
    this.menuService.currentMenu.subscribe((menu: Array<any>) => {
      if ((menu && menu.length > 0) && document.querySelector('app-root')) {
        document.querySelector('app-root').classList.remove('sidebar-hidden');
      }
    });
  }
}
