import { EventEmitter, Injectable, StaticProvider, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class GestorModalService {
  /**
   * Constructor del servicio `GestorModalService`.
   */
  public constructor(public bsModalService: BsModalService) {}

  /**
   * Handler para mostrar el modal.
   */
  public show(content: string | TemplateRef<any> | any, options?: GestorModalOptions): GestorModalRef {
    const modalRef = new GestorModalRef();
    const config = {
      providers: [
        {
          provide: GestorModalRef,
          useFactory: () => {
            return modalRef;
          },
        },
      ],
      ...options,
    };

    const ref = this.bsModalService.show(content, config);
    modalRef.reference = ref;

    return modalRef;
  }
}
export class GestorModalRef<T = any> {
  reference: BsModalRef;
  get onHide(): EventEmitter<unknown> {
    return this.reference?.onHide;
  }
  get onHidden(): EventEmitter<unknown> {
    return this.reference?.onHidden;
  }
  get id(): string | number {
    return this.reference?.id;
  }
  get content(): T {
    return this.reference?.content;
  }
  get hide(): () => void {
    return this.reference?.hide;
  }
  get setClass(): (newClass: string) => void {
    return this.reference?.setClass;
  }
}

export class GestorModalOptions<T = Record<string, unknown>> {
  id?: number | string;
  backdrop?: boolean | 'static';
  keyboard?: boolean;
  focus?: boolean;
  show?: boolean;
  ignoreBackdropClick?: boolean;
  class?: string;
  animated?: boolean;
  initialState?: Partial<T>;
  closeInterceptor?: CloseInterceptorFn;
  providers?: StaticProvider[];
  ariaLabelledBy?: string;
  ariaDescribedby?: string;
}
export declare type CloseInterceptorFn = () => Promise<void>;
