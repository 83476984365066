/**
 * Decorador para metodos y getters que gestiona un memoize de datos automático mediante WeakMap.
 * @see https://gist.github.com/dsherret/cbe661faf7e3cfad8397
 * @see https://www.typescriptlang.org/docs/handbook/decorators.html
 * @see https://stackoverflow.com/a/70684316
 */
export function Memoize(target: any, propertyName: string, descriptor: TypedPropertyDescriptor<any>) {
  if (descriptor.value !== undefined) {
    descriptor.value = memoizeFactory(descriptor.value, target, propertyName);
    return;
  }

  if (descriptor.get !== undefined) {
    descriptor.get = memoizeFactory(descriptor.get, target, propertyName);
    return;
  }

  throw new Error('Only put a Memoize decorator on a method or get accessor.');
}

/**
 * WeakMap para cache global.
 */
const globalCache = new WeakMap<object, Map<symbol, unknown>>();

/**
 * Fabrica de cache de componente.
 */
function memoizeFactory(getter: (...args: any[]) => void, target: any, propertyName: string) {
  let targetName = target?.constructor?.name;
  targetName = targetName ? `${targetName}::` : '';
  const identifier = Symbol(`${targetName}${propertyName}`);

  return function (this: any, ...args: any[]) {
    let componentCache = globalCache.get(this);

    if (componentCache === undefined) {
      componentCache = new Map<symbol, unknown>();
      globalCache.set(this, componentCache);
    }

    const key = identifier;

    let value: any;
    if (componentCache.has(key)) {
      value = componentCache.get(key);
      return value;
    }

    value = getter.apply(this, args);
    componentCache.set(key, value);
    return value;
  };
}
