<ng-container *ngIf="isDev">
  <div class="generic-form-debug--container">
    <div>
      <strong>Generic data debugger</strong>
    </div>
    <ng-container *ngIf="rootFormGroup">
      <details class="generic-form-debug--item">
        <summary>Form errors <small>Errores de validacion del formulario</small></summary>
        <pre>{{ errors | async }}</pre>
      </details>
      <details class="generic-form-debug--item">
        <summary>Form values <small>Estado interno de valores del formulario.</small></summary>
        <pre>{{ value | async }}</pre>
      </details>
    </ng-container>
    <ng-container *ngIf="dataDebug !== null">
      <details class="generic-form-debug--item">
        <summary>Data debug <small>Datos para debugar.</small></summary>
        <pre>{{ dataDebug }}</pre>
      </details>
    </ng-container>
  </div>
</ng-container>
