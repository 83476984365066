export const Paths = {
  urls: {
    abogado: '/abogado',
    abogados: '/abogados',
    abogadoExterno: '/abogadoExterno/',
    abogadoAsignado: '/abogadoAsignado/',
    asuntos: '/asuntos',
    asunto: '/asunto',
    busqueda: '/busqueda',
    buscar: '/buscar',
    buscarPersonalizado: '/buscarPersonalizado',
    buscarPorColaborador: '/buscarPorColaborador',
    ciudades: '/ciudades',
    completoIncompleto: '/completoIncompleto',
    personal: '/personal',
    centro: '/centro/',
    estado: '/estado/',
    conTarea: '/conTarea',
    cumplimiento: '/cumplimiento',
    destinos: '/destinos/',
    empresaFilial: '/empresas/',
    estadoProcesal: '/estadoProcesal/',
    estados: '/estados',
    seguimientoAsunto: '/seguimiento-asunto/',
    pais: '/pais/',
    paises: '/paises/',
    municipio: '/municipio',
    sindicato: '/sindicato',
    autoridadLaboral: '/catalogos/autoridad-laboral',
    estatus: '/aas/estatus',
    empleado: '/empleado',
    procesos: '/procesos',
    proceso: '/proceso/',
    procesosarea: '/procesos/area',
    regiones: '/regiones/',
    remodelacionMantenimiento: '/remodelacionMantenimiento',
    tareas: '/tareas',
    tarea: '/tarea',
    tipoAviso: '/tipo-tarea',
    tipoActor: '/tipoActor',
    tipoAdquisiciones: '/tipos-negociaciones/',
    tiposPersonas: '/tipos-personas',
    notarias: '/notarias',
    tipoNegociacion: '/tipos-negociacion',
    tipoNegociaciones: '/tipos-negociaciones',
    validacion: '/validacion',
    catalogo: '/catalogo',
    catalogos: '/catalogos',
    estadosProcesales: '/estatus-procesales',
    tipNegociacion: '/tipo-negociacion',
    nombre: '/nombre',
    documentoValido: '/documento-valido',
    viabilidadCompra: '/viabilidadCompra',
    suceso: '/suceso',
    statusInmueble: '/catalogacionInmueble',
    dictamen: '/dictamen',
    documentos: '/listaVersion',
    aas: '/aas',
    adquisicion: '/adquisicion',
    inmueble: '/inmuebles/modificar/inmueble',
    inmuebleBloqueo: '/inmuebles/estado/edicion-bloqueo',
    inmueble_bis: '/inmuebles',
    inmueble_cerrado: '/obtenerInmuebleCerrado',
    inmueble_buscar_por_numero_tienda: '/buscar-por-numero-tienda',
    resumen: '/resumen',
    documento: '/documento',
    documentosTarea: '/documentos',
    base64: '/base64',
    escrituracion: '/escritura',
    arrendamiento: '/arrendamiento',
    historial: '/historial',
    tipoInmueble: '/tipo-inmueble',
    tipoAnuncio: '/tipo-anuncio',
    estatusCr: '/estatus-cr',
    destinosEstatusInmueble: '/destinos',
    rolesCatalogo: '/roles/empresa',
    rolesEmpresa: '/roles/empresas',
    roles: '/roles',
    cumplimientoRegulatorio: '/cr',
    expedienteUnico: '/expedienteunico',
    tareasConfiguracion: '/tareas/configuracion',
    proyecto: '/proyecto',
    materias: '/materias/',
    areas: '/areas',
    buscarAreas: '/buscarAreas',
    buscarProcesos: 'buscarProcesos',
    areasMaterias: '/areas/materia/',
    procesosAreas: '/procesos/area/',
    tipoNegociacionProceso: '/tipos-negociaciones/proceso/',
    documentosbase64: '/documento/base64',
    tipoSolicitudNegociacion: '/tipo-solicitud-notificacion',
    notificaciones: '/notificaciones',
    estatusColectivo: '/estatus-colectivo',
    estatusInmueble: '/estado-inmuebles',
    ulc: '/ulc',
    licenciaPermiso: '/licencia-permiso',
    configuracion: '/configuracion',
    configuraciones: '/configuraciones',
    obtener: '/obtener',
    tipoResponsable: '/tipoResponsable',
    actualizar: '/actualizar',
    crear: '/crear',
    borrar: '/borrar',
    notificacionesDias: '/notificaciones-dias',
    tipoDias: '/tipo-dias',
    rolBase: 'usuarios/rol-base',
    reporte: '/reporte',
    pdf: '/pdf',
    reasignar: '/reasignar',
    inmuebleOperacionConfirmar: '/confirmar-termino',
    informativa: '/informativa',
    ifsResidenteObra: '/ifs/consultarResidenteObra',
    ifsGerenteConstruccions: '/ifs/consultarGerenteConstruccion',
    parametrizacionPlantillas: '/parametrizacion-plantillas',
    obtenerLicenciaPermiso: '/obtener-licencia-permiso',
    agregarLicenciaPermiso: '/agregar-licencia-permiso',
    eliminarLicenciaPermiso: '/eliminar-licencia-permiso',
    modificarLicenciaPermiso: '/modificar-licencia-permiso',
    clasificacionLicenciaPermiso: '/clasificacion-licencias-permisos',
    tipoSolicitudChecklist: '/tipo-solicitud-notificacion',
    solicitudNotificacion: '/solicitud-notificacion',
    sucesosNoPertenecenLista: '/sucesosNoPertenecenLista',
    checklistNotificaciones: '/checklist-notificacion',
    obtenerChecklistNotificaciones: '/obtenerCheckListPorSolicitud',
    guardarChecklistNotificaciones: '/guardarListado',
    responsableSeguimiento: '/responsableSeguimiento',
    numeroPersona: 'numeroPersona',
    tiposDictamen: '/tipos-dictamen',
    crearNotaSuceso: '/historico-asunto-suceso',
    variableTarea: '/variable-tarea',
    todos: '/findAllAcciones',
    guardaVariableTarea: '/guardar',
    eliminarVariableTarea: '/eliminar',
    tiposNegociacion: '/findListadoAccionesASeguir',
    guardarEdicionTarea: '/tarea-docs',
    isCrud: '/isCrud',
    enabled: '/enabled',
    ubicacionAsunto: '/ubicacionAsunto',
    bovedaDetalle: '/boveda/detalleAsunto',
    obtenerdocumentoestatusproceso: '/obtenerdocumentoestatusproceso',
    usuario: '/usuario',
    usuarios: '/usuarios',
    rol: '/rol',
    validarColaborador: '/validarColaborador',
    boveda: '/boveda',
    contenidoCaja: '/contenidoCaja',
    cargaMasiva: '/cargaMasiva',
    prestamoExpediente: '/prestamoExpediente',
    devolverExpediente: '/devolverExpediente',
    eliminarExpediente: '/ubicacion',
    intentosValidaciones: '/obtener-intento-validaciones',
    actualizarIntentosValidaciones: '/intentos-validacion',
    obrasEnProceso: '/obras-en-proceso',
    catalogacionTipoCierreAsunto: '/catalogacionTipoCierreAsunto',
    catalogacionTipoCierreAsuntoPorProceso: '/catalogacionTipoCierreAsuntoPorProceso',
    revisionsalarial: '/revisionsalarial',
    colaboradores: '/colaboradores',
    guardar: '/guardar',
    puesto: '/puestosPorCentro',
    estadosCentro: '/getEstadosByCentro',
    ciudadesCentro: '/getCiudadesByCentro',
    mostrarMensajeRequisicionDictamen: '/mostrarMensajeRequisicionDictamen',
    especificarRequiereDictamen: '/especificarRequiereDictamen',
    eliminar: '/eliminar',
    asuntoRSPorAsuntoPadre: '/asuntoRSPorAsuntoPadre',
    informacionAsunto: '/informacion-asunto',
    notificacionOrigen: '/notificacion-origen',
    abogadosAsignados: '/abogados-asignados',
    tareasAsignadas: '/tareas-asignadas',
    historialTareasReasignadas: '/historial-tareas-reasignadas',
    cierreAsunto: '/cierre',
    inmueblesFusiones: '/inmueblesfusiones',
    modificacion: '/modificacion',
    validar: '/validar',
    asignarInvolucrado: '/asignar-involucrado',
    contratos: '/contratos',
    obtenerInmuebleCerrado: '/obtenerInmuebleCerrado',
    obtenerInmuebleRenta: '/obtenerInmuebleRenta',
    responder: '/responder',
    asociar: '/asociar',
    detalle: '/detalle',
    notificacion: '/notificacion',
    motivo_rechazo: '/motivo_rechazo',
    cambioEstatus: '/cambioEstatusAceptar',
    informacion: '/informacion',
    accesorio: '/accesorio',
  },
};
