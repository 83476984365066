import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { firstValueFrom } from 'rxjs';

import { SecureStorageService } from '../services/secure-storage.service';
import { SessionService } from '../state/session.service';
import { ToasterService } from '@app-toaster';

import { AadService } from '../services/idps/aad.service';
import { SsoService } from '../services/idps/sso.service';
import { CustomService } from '../services/idps/custom.service';
import { ActivityTimeService } from '../services/activity-time.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private session: SessionService,
    private storageService: SecureStorageService,
    private aadService: AadService,
    private ssoService: SsoService,
    private cstService: CustomService,
    private toasterService: ToasterService,
    private msalGuard: MsalGuard,
    private activityTimeService: ActivityTimeService
  ) {}

  // - REGLAS DE AUTENTICACIÓN
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Si se definió algún IdP no existe sesión
    const idpSetted = typeof localStorage.getItem('idp') == 'string';
    if (!idpSetted) {
      return this.session.logout();
    }

    // Si el IdP seleccionado es AAD, se delega la lógica a msalGuard
    const idpSelected = this.storageService.getItem('idp');
    if (idpSelected === 'AAD') {
      const $msalGuard = this.msalGuard.canActivate(route, state);
      const msalResult = await firstValueFrom($msalGuard);
      if (!msalResult || this.aadService.isTokenExpired()) {
        return this.interrupt('La sesión expiró');
      }
    }

    // Se obtienen los datos de sesión
    return new Promise((resolve) => {
      this.session.currentUser.subscribe((currentUser) => {
        if (currentUser != null) {
          resolve(true);
        } else if (idpSelected === 'AAD') {
          this.fetchAadProfile();
        } else if (idpSelected === 'SSO') {
          this.fetchSSOProfile();
        } else if (idpSelected === 'CST') {
          this.fetchApiProfile();
        }
      });
    });
  }

  // - LÓGICA DE CIERRE DE SESIÓN
  private interrupt(error: any): boolean {
    if (typeof error === 'string') {
      this.toasterService.showError(error, 'Session Error');
    }
    this.session.logout();
    return false;
  }

  // - MÉS DE OBTENCIÓN DE DATOS DE SESIÓN
  private fetchAadProfile(): void {
    this.aadService.fetchProfile().subscribe({
      next: (profile) => {
        this.session.setCurrentUser(profile);
        this.activityTimeService.inicializarPeriodicos();
      },
      error: (e) => this.interrupt(e),
    });
  }

  private fetchApiProfile(): void {
    this.cstService.fetchProfile().subscribe({
      next: (profile) => {
        this.session.setCurrentUser(profile);
      },
      error: (e) => this.interrupt(e),
    });
  }

  private fetchSSOProfile(): void {
    this.ssoService.fetchProfile().subscribe({
      next: (profile) => {
        this.session.setCurrentUser(profile);
      },
      error: (e) => this.interrupt(e),
    });
  }
}
