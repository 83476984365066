import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Host,
  Input,
  isDevMode,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-generic-form-debug',
  templateUrl: './generic-form-debug.component.html',
  styleUrls: ['./generic-form-debug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFormDebugComponent implements OnInit, OnDestroy {
  /**
   * FormGroup para debugar
   */
  @Input()
  public formGroup: FormGroup;

  /**
   * Datos para debuggar.
   */
  @Input()
  public data: Record<string, any>;

  /**
   * Booleano que indica si el entorno se encuentra en modo de desarrollo.
   */
  public readonly isDev: boolean;

  /**
   * Subject de valores del formulario.
   */
  public formValueSubject$ = new BehaviorSubject(null);

  /**
   * Subject de errores del formulario.
   */
  public formErrorsSubject$ = new BehaviorSubject(null);

  /**
   * `FormGroup` de formulario padre.
   */
  public rootFormGroup: FormGroup;

  /**
   * Subject para realizar desuscripciones en `ngOnDestroy`.
   */
  protected destroySubject$ = new Subject<void>();

  /**
   * Booleano para hacer validaciones
   */
  private validacion = true;

  /**
   * Constructor de clase `GenericFormDebugComponent`.
   */
  constructor(@Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective, protected cdr: ChangeDetectorRef) {
    this.isDev = isDevMode() ?? this.existeQueryDebug();
  }

  /**
   * Observable con el valor del formulario.
   */
  get value(): Observable<string | null> {
    return this.formValueSubject$.asObservable();
  }

  /**
   * Observable con el valor del formulario.
   */
  get errors(): Observable<string | null> {
    return this.formErrorsSubject$.asObservable();
  }

  get dataDebug(): string | null {
    return (Boolean(this.data) && JSON.stringify(this.data)) || null;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.rootFormGroup = this.formRef?.control ?? this.formGroup;
    // Inicializamos setter de valor y errores de formulario en `formValueSubject$`.
    this.setFormValue();
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    // Emitimos un evento para desuscribir todos los observables
    // abiertos y pipeados con `takeUntil`.
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  /**
   * Handler para seteo asincrono del valor y errores del formulario.
   */
  public setFormValue(): void {
    this.rootFormGroup.valueChanges.pipe(takeUntil(this.destroySubject$)).subscribe((v) => {
      Promise.resolve().then(() => {
        this.formValueSubject$.next(JSON.stringify(v, null, 2));
        this.formErrorsSubject$.next(JSON.stringify(this.getFormErrors(this.rootFormGroup), null, 1));
      });
    });
  }

  /**
   * Retorna un objeto `ValidationErrors` con los errores reportados para el formulario.
   */
  protected getFormErrors(form: AbstractControl): ValidationErrors | null {
    if (form instanceof FormControl) {
      return form.errors ?? null;
    }
    if (form instanceof FormGroup) {
      const groupErrors = form.errors;
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach((key) => {
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          formErrors[key] = error;
        }
      });
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
    return null;
  }

  /**
   * Retorna un booleano indicando si existe en el ambiente un query param de deshabilitación.
   */
  protected existeQueryDebug(): boolean {
    if (sessionStorage.getItem('debug')) {
      return true;
    }

    const params = new URLSearchParams(window.location.search);
    const paramExists = params.get('debug');

    if (paramExists) {
      sessionStorage.setItem('debug', '1');
    }

    return (Boolean(paramExists) && this.validacion) ?? false;
  }
}
