import { Injectable } from '@angular/core';
import { LoaderService } from '@app-loader';
import { BehaviorSubject } from 'rxjs';

/**
 * Servicio para manejo de estado de pantalla loading global.
 * @see LoadingInterceptor
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingOverrideService {
  /**
   * BehaviorSubject para manejar el estado del loading.
   */
  protected loading$ = new BehaviorSubject(false);

  /**
   * Contiene la(s) petición(es) de loading en progreso.
   */
  protected loadingMap: Map<string | symbol, boolean> = new Map<string, boolean>();

  /**
   * Constructor de clase `LoadingOverrideService`.
   */
  public constructor(loaderService: LoaderService) {
    this.loading$.asObservable().subscribe((show) => {
      loaderService.setVisibleState(show);
    });
  }

  /**
   * Este metodo dispara la creación de un spinner unico global y retorna una funcion que al ser ejecutada finaliza
   * el funcionamiento del mismo.
   */
  public trigger(identifier?: string): SpinnerFinalizadorFn {
    const index = Symbol();

    this.recibeEstadoLoading(true, identifier ?? index);

    return () => {
      this.recibeEstadoLoading(false, identifier ?? index);
    };
  }

  /**
   * Fuerza a eliminar todos los spinners de carga del sistema.
   * Tener cuidado en su implementación, de momento solo está contemplado para el uso de un escenario
   * específico en el interceptor JwtInterceptor.
   * @see JwtInterceptor
   */
  public eliminaTodos(): void {
    if (this.loadingMap.size > 0) {
      this.loadingMap = new Map<string, boolean>();
    }

    this.loading$.next(false);
  }

  /**
   * Recibe un valor para ser emitido y cambiar el estado del loading.
   */
  private recibeEstadoLoading(mostrar: boolean, identificador: string | symbol): void {
    if (!identificador) {
      throw new Error('Se debe especificar un identificador por solicitud loading.');
    }

    if (mostrar) {
      this.loadingMap.set(identificador, mostrar);
    }

    if (!mostrar && this.loadingMap.has(identificador)) {
      this.loadingMap.delete(identificador);
    }

    this.loading$.next(this.loadingMap.size > 0);
  }
}

/**
 * Finalizador de spinners dinamicos.
 */
type SpinnerFinalizadorFn = () => void;