<div class="card-footer clearfix">
  <div class="card-footer--content">
    <ng-content></ng-content>
    <div class="card-footer--content-inner">
      <div class="card-footer--content-left">
        <div class="card-footer--content-left-inner">
          <ng-content select="[left]"></ng-content>
        </div>
      </div>
      <div class="card-footer--content-right">
        <div class="card-footer--content-right-inner">
          <ng-content select="[right]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
