import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

const PHONE_REGEX = new RegExp(/^\d{10}$/);

export function PhoneValidator(control: FormControl): ValidationErrors | null {
  const valid = !control.value ? true : PHONE_REGEX.test(control.value);

  if (valid) {
    return null;
  }

  return {
    phone: true,
  };
}

export function PhoneValidatorMessage(control: FormControl | AbstractControl) {
  return `"${control.value}" No es un Teléfono Válido.`;
}
