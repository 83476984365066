import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

/**
 * Interceptor para gestionar "spinner" loading oficial del proyecto.
 * Para que este pueda ser utilizado en las peticiones HTTP se debe incluir
 * el header 'InterceptorLoading' con valor 'true'
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  /**
   * @inheritdoc
   */
  constructor(private loadingService: LoadingService) {}

  /**
   * Implementación del método intercept de HttpInterceptor
   * @param request
   * @param next
   * @returns
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has('InterceptorLoading') && request.headers.get('InterceptorLoading') === 'true') {
      const stop = this.loadingService.trigger(request.url);

      return next.handle(request).pipe(finalize(() => stop()));
    }

    return next.handle(request);
  }
}
