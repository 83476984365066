<div>
  <div class="modal-header">
    <h4 class="modal-title">{{ infoModal.data.title }}</h4>
    <div class="p-dialog-header-icons">
      <app-button variant="primary" (onClick)="cerrarModal()">
        <span class="p-dialog-header-close-icon pi pi-times"></span>
      </app-button>
    </div>
  </div>
  <div class="modal-body">
    <h5 class="modal-title pull-left">
      <div class="modal-text">{{ infoModal.data.text }}</div>
    </h5>
    <div class="body-text" [innerHTML]="infoModal.data.body"></div>
    <form [formGroup]="form">
      <app-generic-input-text [config]="config.texConfirmacion"></app-generic-input-text>
    </form>
    <div class="group-buttons float-right" style="margin-bottom: 15px">
      <div>
        <app-button variant="gray" (onClick)="cerrarModal()"><i class="fas fa-times-circle"></i> Volver</app-button>
        <app-button [disabled]="btnAceptar" variant="primary" (onClick)="aceptar()">
          <i class="fa fa-check-circle"></i> Si, Confirmar Inicio
        </app-button>
      </div>
    </div>
  </div>
</div>
