/***
 * Enum para procesos de asuntos.
 */
export enum ProcesoAsuntos {
  SIN_ASIGNAR = 0,
  ADQUISICION_INMUEBLES = 1,
  PERMUTA = 5,
  SUBDIVISION = 6,
  FUSION = 7,
  CONTRATO_COLECTIVO = 9,
  LICENCIAS_Y_PERMISOS = 15,
  COMERCIALIZACION_DE_INMUEBLES = 88,
  ADMINISTRACION_INMOBILIARIA = 106,
  OBRA_NUEVA = 107,
  REMODELACION = 108,
  INMUEBLE_EN_OPERACION = 109,
  ALINEAMIENTO_NO_OFICIAL = 110,
  RENOVACION_LICENCIAS_PERMISOS = 111,
  REGLAMENTO_INTERIOR_DE_TRABAJO = 114,
  ADEHESION = 112,
  REVISION = 113,
  CONFLICTO_COLECTIVO = 14,
  CONTRATO_COLECTIVO_OBRA = 6,
  EXPENDIENTE_SINDICAL = 48,
  ACTIVO_SIN_OPERACION_COLECTIVO = 117,
  PASIVO_TERMINACION_CCT = 0,
  REVISION_DEL_CCT = 113,
  ADHESION_CCT = 112,
  REVISION_SALARIAL = 116,
  CCT_EMPRESA_NUEVA = 0,
  COMPRA = 2,
  RENTA = 3,
  ARRENDADOR = 4,
  PROCESO_PASIVO_CIERRE_CC = 115,
  CONVENIO_COLECTIVO = 121,
  ACCESORIOS_INMOBILIARIOS = 122,
}
