import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EstablecerContrasena } from '@app-models/login.interface';
import { LoginExternoService } from '@app-services/login-externo.service';
import { LOGIN } from '@app-services/constantes-login';
import { MensajesGenerales } from '@app-services/constantes';
import { IdentificarError } from '@app-services/utils/identifyError.service';
import { LoadingService } from '@app-shared/services/loading.service';
import { MessagesMainService } from '@app-shared/services/messages-main.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

/**
 * Enum de identificador de titulos para pantalla de Determinar y Recuperar contrasenia
 */
enum Titulos {
  REESTABLECER = 'REESTABLECER CONTRASEÑA',
  REESTABLECER_BUTTON = 'Reestablecer',
  DETERMINAR = 'DETERMINAR CONTRASEÑA',
  DETERMINAR_BUTTON = 'Guardar',
}

@Component({
  templateUrl: './reestablecer-contrasena.component.html',
  styleUrls: ['./../login/login.component.scss'],
})
export class ReestablecerContrasenaComponent implements OnInit, OnDestroy {
  // Expresion regular valida la presencia de por lo menos una mayuscula y minuscula
  public validaLetras = new RegExp(LOGIN.VALIDADORES.PSD_MAYUSCULA_MINUSCULA);
  // Expresion regular valida la presencia de por lo menos un numero
  public validaNumero = new RegExp(LOGIN.VALIDADORES.PSD_NUMERO);
  // Expresion regular valida la presencia de por lo menos un caracter especial
  public validaCaracterEspecial = new RegExp(LOGIN.VALIDADORES.PSD_CARACTER_ESPECIAL);
  // Expresion regular valida la presencia de la palabra coppel
  public validarCoppel = new RegExp(LOGIN.VALIDADORES.PSD_COPPEL);
  // Expresion regular valida la presencia de 2 o mas caracteres iguales consecutivos
  public validaConsecutivos = new RegExp(LOGIN.VALIDADORES.PSD_CARACTERES_CONSECUTIVOS);
  /**
   * Establece el titulo a mostrar en la vista
   */
  public titulo: string;
  /**
   * Establece el titulo a mostrar en el boton de guardado
   */
  public tituloBoton: string;
  /**
   * Bandera para la definir si el proceso es DETERMINAR CONTRASEÑA
   */
  public determinarContrasenia: boolean;
  /**
   * Formulario reactivo de inicio de sesión externo.
   */
  public formReestablecer: FormGroup;
  /**
   * BehaviorSubject para manejo de loading asincrono.
   */
  public loading$ = new BehaviorSubject(false);
  /**
   * Subject para hacer desubscripciones automáticas de observables.
   */
  private unsubscriptor$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private messagesMainService: MessagesMainService,
    private loginExternoService: LoginExternoService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private router: Router
  ) {}

  /**
   * Getter de formcontrol passwordConfirmado.
   */
  get PsdConfirmado() {
    return this.formReestablecer.get('psdConfirmado');
  }

  /**
   * Getter de formcontrol passwordNuevo.
   */
  get PsdNuevo() {
    return this.formReestablecer.get('psdNuevo');
  }

  public ngOnInit(): void {
    /** Establece los titulos para el caso de reestablecer y determianr contrasena */
    this.determinarContrasenia = this.activatedRoute.snapshot.url[1].path === 'determinar-contrasena';
    this.titulo = this.determinarContrasenia ? Titulos.DETERMINAR : Titulos.REESTABLECER;
    this.tituloBoton = this.determinarContrasenia ? Titulos.DETERMINAR_BUTTON : Titulos.REESTABLECER_BUTTON;
    this.construirFormualrio();
  }

  public ngOnDestroy(): void {
    this.unsubscriptor$.next();
    this.unsubscriptor$.complete();
  }

  /**
   * Ejecuta la determinacion de la contrasena
   * @param e
   */
  public cambiarContrasenia(e: Event): void {
    e.preventDefault();
    if (this.formReestablecer.valid) {
      if (this.PsdConfirmado.value === this.PsdNuevo.value) {
        try {
          // Obtiene el dato del usuario
          const usuario = atob(this.activatedRoute.snapshot.params.usuario);
          const loading = this.loadingService.trigger();

          // Obtiene el token de acceso al servicio
          this.loginExternoService
            .tokenCrearPassword()
            .pipe(
              takeUntil(this.unsubscriptor$),
              finalize(() => loading())
            )
            .subscribe(
              (response) => {
                // Obtiene el token de acceso
                const token = response?.access_token;

                this.determinarORestablecerContrasenia(token, usuario);
              },
              (error) => {
                this.messagesMainService.messageError(
                  IdentificarError.ObtenerMensaje(error),
                  MensajesGenerales.ADVERTENCIA.SALIO_MAL
                );
              }
            );
        } catch (ex) {
          this.messagesMainService.messageError(
            IdentificarError.ObtenerMensaje(ex),
            MensajesGenerales.ADVERTENCIA.SALIO_MAL
          );
        }
      } else {
        this.messagesMainService.messageWarning(LOGIN.MENSAJES.ALERTAS.REESTABLECER_PASSWORD_NO_COINCIDE);
      }
    } else {
      this.formReestablecer.markAllAsTouched();
    }
  }

  /**
   * Construye formulario de inicio de sesión.
   */
  private construirFormualrio(): void {
    this.formReestablecer = this.formBuilder.group({
      psdConfirmado: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.pattern(LOGIN.VALIDADORES.PSD),
      ]),
      psdNuevo: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.pattern(LOGIN.VALIDADORES.PSD),
      ]),
    });
  }

  private determinarORestablecerContrasenia(token, usuario) {
    if (!token) {
      return;
    }

    const datos: EstablecerContrasena = {
      numeroColaborador: usuario,
      pd: this.PsdNuevo.value,
    };
    const loading = this.loadingService.trigger();
    // Identifica si la accion corresponde a Reestablecer o Determinar Contrasenia
    if (this.determinarContrasenia) {
      // Ejecuta la accion para determinar la contrasenia
      this.loginExternoService
        .crearPassword(datos, token)
        .pipe(
          takeUntil(this.unsubscriptor$),
          finalize(() => loading())
        )
        .subscribe(
          (res) => {
            if (!res.estado) {
              this.messagesMainService.messageWarning(res?.error?.mensaje);
              return;
            }
            this.messagesMainService.messageSuccess(MensajesGenerales.INFO.EXITOSA);
            this.router.navigate(['@app-modules/generic-login/'], { relativeTo: this.activatedRoute });
          },
          (error) => {
            this.messagesMainService.messageError(error?.error?.error?.mensaje);
          }
        );
    } else {
      // Ejecuta la accion para reestablecer de contrasenia
      this.loginExternoService
        .cambiarPassword(datos, token)
        .pipe(
          finalize(() => loading()),
          takeUntil(this.unsubscriptor$)
        )
        .subscribe(
          (respuesta) => {
            if (!respuesta.estado) {
              this.messagesMainService.messageWarning(respuesta?.error?.mensaje);
              return;
            }
            this.messagesMainService.messageSuccess(MensajesGenerales.INFO.EXITOSA);
            this.router.navigate(['@app-modules/generic-login/'], { relativeTo: this.activatedRoute });
          },
          (error) => {
            this.messagesMainService.messageError(error?.error?.error?.mensaje);
          }
        );
    }
  }
}
