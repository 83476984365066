<ng-container *ngIf="routerLink; else elseBlock">
  <button
    type="button"
    class="btn"
    [ngClass]="classlist"
    (click)="onClickHandler($event)"
    [disabled]="disabledValue"
    [routerLink]="routerLink"
  >
    <span class="content">
      <ng-container *ngTemplateOutlet="contentOutlet"></ng-container>
    </span>
  </button>
</ng-container>

<ng-template #elseBlock>
  <button type="button" class="btn" [ngClass]="classlist" (click)="onClickHandler($event)" [disabled]="disabledValue">
    <span class="content">
      <ng-container *ngTemplateOutlet="contentOutlet"></ng-container>
    </span>
  </button>
</ng-template>

<ng-template #contentOutlet>
  <ng-content></ng-content>
</ng-template>
