<div class="modal-header header flex">
  <h4 class="modal-title pull-left">Cierre de Sesión</h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="aceptar()">
    <span aria-hidden="true" class="visually-hidden letras-blancas">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h5 class="modal-title pull-left card-titulo">
    <b>Su sesión ha expirado.</b>
    <br />
    <ng-container *ngIf="usuarioExterno(); else usuarioInterno">
      Es necesario cerrar sesión e ingresar de nuevo.
    </ng-container>
    <ng-template #usuarioInterno> Es necesario cerrar sesión en la Intranet e ingresar de nuevo. </ng-template>
  </h5>
  <div class="group-buttons float-right modal-boton">
    <div>
      <app-button variant="primary" (onClick)="aceptar()"> <i class="fa fa-check-circle"></i> Aceptar </app-button>
    </div>
  </div>
</div>
