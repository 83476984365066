import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, from, map, mergeMap, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResponseAuth } from '@app-models/currentUser.interface';
import { SecureStorageService } from '../secure-storage.service';

const API_URL = environment.authIdp.SSO.apiUrl;

/**
 * Enum de credenciales para la autorizacion de token
 */
export enum DatosAcceso {
  SAJ_USR = 'saj_app',
  SAJ_PSD = 'admin',
}

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  private recaptchaParams = {
    channel: environment.authIdp.SSO.channel,
    origin: 'web',
  };

  constructor(private http: HttpClient, private secureStorage: SecureStorageService) {}

  private _handleError(error: HttpErrorResponse) {
    let errorMessage = 'No se obtuvo respuesta del servidor de autenticación. Verifica tu conexión a red Interna.';
    if (error.error.data?.userMessage) {
      errorMessage = error.error.data.userMessage;
    } else if (error.error.data?.errorCode) {
      errorMessage = error.error.data.message;
    }
    return throwError(() => new Error(errorMessage));
  }

  // ------ Bloque de métodos de lógica de autenticación

  public loginHuella(credentials: any): Observable<string> {
    let httpOptions = {};
    if (!environment.production) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-DEVELOPMENT': 'true',
          'X-APPLICATION': 'gcdigital',
        }),
      };
    }
    return this.http.post(API_URL + '/v2/login', credentials, httpOptions).pipe(
      map(({ data }: any) => data.token),
      catchError(this._handleError)
    );
  }

  public loginColaboradorDigital(credentials: any): Observable<string> {
    let httpOptions = {};
    if (!environment.production) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-DEVELOPMENT': 'true',
          'X-APPLICATION': 'gcdigital',
        }),
      };
    }

    credentials.loginType = 10;
    return this.http.post(API_URL + '/v2/login', { ...this.recaptchaParams, ...credentials }, httpOptions).pipe(
      map(({ data }: any) => data.token),
      catchError(this._handleError)
    );
  }

  public loginAuthCode(authCode: any): Observable<string> {
    localStorage.clear();
    sessionStorage.clear();
    let httpOptions = {};
    if (!environment.production) {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: authCode,
          'X-DEVELOPMENT': 'true',
          'X-APPLICATION': 'gcdigital',
        }),
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: authCode,
        }),
      };
    }

    return this.http.post<any>(API_URL + '/v2/verify', {}, httpOptions).pipe(
      map(({ data }: any) => data.token),
      catchError(this._handleError)
    );
  }

  public fetchProfile(): Observable<any> {
    return from(this.tokenAcceso()).pipe(
      switchMap(() => this.http.post<any>(API_URL + '/v1/me', null)),
      map(({ data }: any) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Ejecuta la peticion a web service para obtener el token de autenticacion
   * @param username
   * @param psd
   * @returns {Observable<IResponseAuth>}
   */
  public tokenAcceso(): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Basic ` + btoa(`${DatosAcceso.SAJ_USR}:${DatosAcceso.SAJ_PSD}`),
      }),
    };

    // Generar token de aplicacion
    return this.http
      .post<IResponseAuth>(`${environment.autorizacion}/oauth/token?grant_type=client_credentials`, {}, httpOptions)
      .pipe(
        mergeMap((r) => {
          // Consulta/registro de usuario nuevo
          return this.checkUser(r.access_token, this.secureStorage.getItem('token'));
        })
      );
  }

  /**
   * Metodo que consulta o registra un usuario que ingresa por primera vez por intranet
   */
  public checkUser(tokenApp: string, tokenUser: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenApp}`,
      }),
    };

    return this.http.post<any>(`${environment.autenticacion}/createinternaluser/${tokenUser}`, null, httpOptions).pipe(
      mergeMap((r) => {
        return of(true);
      }),
      catchError((e) => {
        return of(false);
      })
    );
  }
}
