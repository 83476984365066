import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '@app-toaster';
import { CaptchaApiService } from '@app-recaptcha';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public authMechanisms = environment.authMechanisms;
  public loginSelected!: string;
  public loginUnique!: string;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    private toaster: ToasterService,
    private recaptcha: CaptchaApiService
  ) {
    const loginsWithForm = [];
    const loginsWithButton = [];

    for (const [idp, isEnabled] of Object.entries(this.authMechanisms)) {
      if (isEnabled === true && idp !== 'authCode') {
        loginsWithButton.push(idp);
        if (idp !== 'azureAD') {
          loginsWithForm.push(idp);
        }
      }
    }

    if (loginsWithButton.length === 1 && loginsWithForm.length === 1) {
      this.loginUnique = this.loginSelected = loginsWithForm[0];
    }
  }

  public ngOnInit() {
    this.verify();
    this.recaptcha.showBadge();
  }
  public ngOnDestroy(): void {
    this.recaptcha.hideBadge();
  }

  public verify() {
    const idpSetted = typeof localStorage.getItem('idp') == 'string';
    this.route.queryParams.subscribe(({ token }) => {
      if (token) {
        this.auth.login('authCode', token);
      } else if (idpSetted) {
        this.router.navigate(['']);
      }
    });
  }

  public async login(authMechanism: string, credentials: any = {}) {
    if (authMechanism != 'colaboradorDigital') {
      if (authMechanism === 'custom') {
        const captchaToken: string = await this.recaptcha.getToken('createAccount');
        return this.auth.login(authMechanism, credentials, captchaToken);
      }
      return this.auth.login(authMechanism, credentials);
    }
    try {
      const captchaToken: string = await this.recaptcha.getToken('createAccount');
      this.auth.login(authMechanism, credentials, captchaToken);
    } catch (error) {
      this.toaster.showError('Ocurrió un error inesperado. Intente más tarde.');
    }
  }
}
