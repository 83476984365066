<app-card>
  <!-- Inicio del card header -->
  <app-card-header> <i [class]="iconTittle"></i> {{ tittle }} </app-card-header>
  <!-- Fin del card header -->
  <!-- Inicio del card body -->
  <app-card-body>
    <div class="row">
      <div class="col-sm-12 col-md-4" *ngFor="let inputs of dataInput">
        <div class="form-group">
          <!-- Inicio Form -->
          <form [formGroup]="formDinamic">
            <!-- Inicio del Div formArrayName -->
            <div formArrayName="dataArray">
              <!-- Inicio del Div formDinamic -->
              <div
                *ngFor="let dataControlName of formDinamic.get('dataArray')['controls']; let idx = index"
                [formGroupName]="idx"
              >
                <!-- Inicio del Label -->
                <label class="form-label" style="padding-top: 10px" [class]="inputs.required == true ? 'requerido' : ''"
                  >{{ inputs.label }}<span *ngIf="inputs.required">*</span></label
                >
                <!-- Fin del Label -->

                <!-- Inicio del Input Text -->
                <div *ngIf="inputs.type === 'input'">
                  <input
                    pInputText
                    [placeholder]="inputs.placeholder"
                    [class]="inputs.class"
                    type="text"
                    [disabled]="inputs.disable"
                    appRestringirTipo
                    [permitir]="inputs.validators.permitir"
                    [maxlength]="inputs.validators.maxlength"
                    [minlength]="inputs.validators.minlength"
                    [formControlName]="inputs.formControlName"
                  />

                  <div class="text-danger">
                    <div *ngFor="let error of getErrors(idx, inputs.formControlName)">
                      {{ error }}
                    </div>
                  </div>
                </div>
                <!-- Fin del Input Text -->

                <!-- Inicio del Input Dropdown -->
                <div *ngIf="inputs.type === 'dropdown'">
                  <p-dropdown
                    [class]="inputs.class"
                    [options]="inputs.dropdown.options"
                    [optionLabel]="inputs.dropdown.optionLabel"
                    [emptyMessage]="inputs.dropdown.emptyMessage"
                    [style]="{ width: '100%' }"
                    [placeholder]="inputs.placeholder"
                    [required]="inputs.required"
                    (onChange)="changeValue($event, inputs.formControlName)"
                    [formControlName]="inputs.formControlName"
                    [filter]="inputs.dropdown.filter"
                    [showClear]="inputs.dropdown.showClear"
                    [emptyFilterMessage]="inputs.dropdown.emptyFilterMessage"
                  >
                  </p-dropdown>
                  <div class="text-danger">
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.incorrect">
                      Ingrese Información Válida.
                    </div>
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.required">
                      Ingrese Información Válida.
                    </div>
                  </div>
                </div>

                <!-- Fin Input Dropdown -->

                <!-- Inicio del Input Text And Search -->
                <div *ngIf="inputs.type === 'inputSearch'">
                  <div class="d-flex">
                    <div class="col-md-4 noPadding">
                      <input
                        pInputText
                        on-keydown.enter="searchInput($event.target.value, inputs.formControlName)"
                        (keyup)="searchInput($event.target.value, inputs.formControlName)"
                        (input)="searchInput($event.target.value, inputs.formControlName)"
                        on-change="searchInput($event.target.value, inputs.formControlName)"
                        type="text"
                        [class]="inputs.class"
                        [placeholder]="inputs.placeholder"
                        [formControlName]="inputs.formControlName"
                        [maxlength]="inputs.validators.maxlength"
                        [minlength]="inputs.validators.minlength"
                        appRestringirTipo
                        [permitir]="inputs.validators.permitir"
                        #inputs.formControlName
                      />
                    </div>
                    <div class="col-md-8 input-group search-btn no-padding-right" tooltipPosition="top">
                      <input
                        pInputText
                        type="text"
                        [class]="inputs.subInput.class"
                        [placeholder]="inputs.subInput.placeholder"
                        [formControlName]="inputs.subInput.formControlName"
                      />
                      <div class="input-group-appFin">
                        <button type="button" [class]="inputs.subInput.subInputButton.class">
                          <i [class]="inputs.subInput.subInputButton.subInputButtonIcon.class"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger">
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.incorrect">
                      Ingrese Información Válida.
                    </div>
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.required">
                      Ingrese Información Válida.
                    </div>
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.maxlength">
                      El Campo Solo Acepta un M&aacute;ximo de {{ inputs.validators.maxlength }} Caracteres.
                    </div>
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.minlength">
                      El Campo Solo Acepta un M&iacute;nimo de {{ inputs.validators.minlength }} Caracteres.
                    </div>
                  </div>
                </div>
                <!-- Fin Input Text And Search -->

                <!-- Inicio del Calendar -->
                <div *ngIf="inputs.type === 'calendar'">
                  <p-calendar
                    [formControlName]="inputs.formControlName"
                    [placeholder]="inputs.placeholder"
                    [styleClass]="inputs.class"
                    [dateFormat]="inputs.calendar.dateFormat"
                    [maxDate]="inputs.calendar.maxDate"
                    [minDate]="inputs.calendar.minDate"
                    [showIcon]="inputs.calendar.showIcon"
                    inputId="icon"
                    [locale]="inputs.calendar.locale"
                    (onSelect)="changeValue($event, inputs.formControlName)"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="1900:2100"
                    appendTo="body"
                  >
                  </p-calendar>
                  <div class="text-danger">
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.incorrect">
                      Ingrese Información Válida.
                    </div>
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.required">
                      Campo Requerido.
                    </div>
                  </div>
                </div>
                <!-- Fin del Calendar -->

                <!-- Inicio Dropdown And Search -->
                <div *ngIf="inputs.type === 'dropdownSearch'">
                  <div class="d-flex">
                    <div class="col-md-4 noPadding">
                      <p-dropdown
                        [class]="inputs.class"
                        [options]="inputs.dropdown.options"
                        [optionLabel]="inputs.dropdown.optionLabel"
                        [emptyMessage]="inputs.dropdown.emptyMessage"
                        [style]="{ width: '100%' }"
                        [placeholder]="inputs.placeholder"
                        [required]="inputs.required"
                        (onChange)="changeValue($event, inputs.formControlName)"
                        [formControlName]="inputs.formControlName"
                        [filter]="inputs.dropdown.filter"
                        [showClear]="inputs.dropdown.showClear"
                        [emptyFilterMessage]="inputs.dropdown.emptyFilterMessage"
                      >
                      </p-dropdown>
                    </div>
                    <div class="col-md-8 input-group search-btn" tooltipPosition="top">
                      <input
                        pInputText
                        type="text"
                        [class]="inputs.subInput.class"
                        [placeholder]="inputs.subInput.placeholder"
                        [formControlName]="inputs.subInput.formControlName"
                      />
                      <div class="input-group-appFin" *ngIf="inputs.subInput.showSearchButton">
                        <button type="button" [class]="inputs.subInput.subInputButton.class">
                          <i [class]="inputs.subInput.subInputButton.subInputButtonIcon.class"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger">
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.incorrect">
                      Ingrese Información Válida.
                    </div>
                    <div *ngIf="dataControlName.controls[inputs.formControlName]?.errors?.required">
                      Ingrese Información Válida.
                    </div>
                  </div>
                </div>
                <!-- Fin Dropdown And Search -->
              </div>
              <!-- Fin del Div formDinamic -->
            </div>
            <!-- Fin div formArrayName -->
          </form>
          <!-- Fin Form -->
        </div>
      </div>
    </div>
  </app-card-body>
  <!-- Fin del card body  -->
  <!-- Inicio del card footer -->
  <app-card-footer>
    <ng-container left>
      <label *ngIf="showMandatoryFieldsMessage" class="col-form-label"
        ><strong>{{ mensajeCamposConAsterisco }}</strong></label
      >
    </ng-container>
    <ng-container right>
      <app-button variant="accent" [disabled]="isValid()" (onClick)="filterData()">
        <i class="fas fa-search"></i>&nbsp;Filtrar
      </app-button>
    </ng-container>
  </app-card-footer>
  <!-- Fin del card footer -->
</app-card>
<!-- Fin del card -->
