import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardActionEvent, CardHeaderAction, CardHeaderConfig } from '../models/card-models';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
})
export class CardHeaderComponent implements OnInit {
  /**
   * Icono del header.
   *
   *  Definir el icono desde el HTML que acepta el componente app-card-header por `content projection`.
   */
  @Input()
  public icon;

  /**
   * Configuración para header.
   * Usar el input actions para definir las acciones del componente.
   */
  @Input()
  public config: CardHeaderConfig;

  /**
   * Acciones disponibles del header.
   */
  @Input()
  public actions?: CardHeaderAction[];

  /**
   * Emisor de eventos para acciones del listado.
   */
  @Output()
  public actions$: EventEmitter<CardActionEvent> = new EventEmitter<CardActionEvent>();

  /**
   * Acciones disponibles del header.
   */
  private actionItems: CardHeaderAction[] = [];

  /**
   * Constructor de clase `CardHeaderComponent`
   */
  public constructor(private cd: ChangeDetectorRef) {}

  /**
   * Icono del header.
   *
   *  Definir el icono desde el HTML que acepta el componente app-card-header por `content projection`.
   */
  get headerIcon(): string {
    return this.config?.icon ?? this.icon;
  }

  /**
   * Booleano que indica si se deben mostrar las acciones.
   */
  get showActions(): boolean {
    return this.headerActions?.length > 0;
  }

  /**
   * Listado de acciones del header.
   */
  get headerActions(): CardHeaderAction[] {
    return this.actionItems;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.actionItems = this.actions ?? [];
  }

  /**
   * Handler para emisor de eventos de acciones.
   */
  public triggerAction(e: MouseEvent, action: CardHeaderAction) {
    this.actions$.next({ e, action });

    if (!action.onClick || e.defaultPrevented) {
      return;
    }

    action.onClick(e, action);
  }

  /**
   * Handler para validar si alguna accion esta deshabilitada.
   */
  public isDisabled(action: CardHeaderAction): boolean {
    if (action.disabled instanceof Function) {
      return action.disabled();
    }

    return action.disabled;
  }
}
