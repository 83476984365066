import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GestorModalService } from '@app-services/gestor-modal.service';

@Component({
  selector: 'app-open-pdf',
  templateUrl: './app-open-pdf.component.html',
  styles: [
    `
      .big-model-dialog {
        width: 900px;
      }
    `,
  ],
  styleUrls: ['./app-open-pdf.component.css'],
})
export class OpenPdfComponent implements OnInit {
  public file: any;
  public modalConfig: any;
  public redirect: string;
  @Input() public nombre: string;
  @Input() private visualizar: string;

  @Input() private openPdf: EventEmitter<boolean>;
  @ViewChild('btnModal', { static: true }) private btnModal: ElementRef;
  private CONTENT_TYPE = 'application/pdf';

  constructor(private modalService: GestorModalService, private route: ActivatedRoute, private router: Router) {}

  public ngOnInit() {
    this.modalConfig = {
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      size: 'lg',
      class: 'modal-lg',
    };

    if (this.openPdf) {
      this.openPdf.subscribe((obj: any) => {
        if (typeof obj === 'object') {
          this.redirect = obj.redirect;
          this.file = obj.file;
        } else {
          this.file = obj;
        }
        this.mostrar();
      });
    }
  }

  public mostrar() {
    switch (this.visualizar) {
      case 'modal':
        this.pdfModal();
        break;

      case 'download':
        this.pdfDownload();
        break;

      case 'tab':
        this.pdfTab();
        break;

      default:
        this.pdfModal();
        break;
    }
  }

  public pdfModal() {
    const visor = document.getElementById('visorPDF');
    visor.querySelectorAll('*').forEach((n) => n.remove());
    const file = this.base64ToPDF(this.file);

    const fileUrl = URL.createObjectURL(file);
    const elemento = document.createElement('object');
    elemento.data = fileUrl;
    elemento.type = this.CONTENT_TYPE;
    elemento.style.cssText = 'width: 100%;height: 75vh';
    visor.appendChild(elemento);
    setTimeout(() => {
      this.btnModal.nativeElement.click();
    }, 500);
  }

  public cerrarPdf(element) {
    element.hide();
    if (this.redirect != null) {
      this.router.navigate([this.redirect]);
      this.redirect = null;
    }
  }

  public pdfDownload() {
    const file = this.base64ToPDF(this.file);
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    setTimeout(() => {
      link.href = fileURL;
      link.download = 'documento.pdf';
      link.click();
    }, 500);
  }

  public pdfTab() {
    const file = new Blob([this.file], { type: this.CONTENT_TYPE });
    const fileURL = URL.createObjectURL(file);
    setTimeout(() => {
      window.open(fileURL);
    }, 500);
  }

  private base64ToPDF(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: this.CONTENT_TYPE });
  }
}
