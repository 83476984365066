<div class="contenedor mw-100 vh-100">
  <!-- Primer elipse -->
  <div class="elipse1"></div>
  <!-- Elipse blanco -->
  <div class="elipseB"></div>
  <!-- Elipse Amarillo chico -->
  <div class="elipseAC"></div>
  <!-- Elipse Amarillo Grande -->
  <div class="elipseAG"></div>
  <!-- Elipse Chico azul marino -->
  <div class="elipseCA"></div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="carPadre">
        <div class="card-login">
          <div class="card-body">
            <!--Formulario donde se muestra el tipo de inicio de sesion-->
            <article class="form1">
              <!-- Selección de tipo de inicio de sesión -->
              <div class="img">
                <a>
                  <img src="../../../../../assets/img/logo_SAJ_V2_Original.svg" alt="Coppel SAJv2" />
                </a>
              </div>
              <form
                [formGroup]="formRecuperar"
                (ngSubmit)="f.form.valid && recuperarContrasena($event)"
                (keydown.enter)="f.form.valid && recuperarContrasena($event)"
                #f="ngForm"
                class="formulario-login"
                novalidate
                class="form2"
              >
                <h2 class="text-center">RECUPERAR CONTRASE&Ntilde;A</h2>
                <div>
                  <p class="contrase">Usuario</p>
                  <input
                    [ngClass]="{ 'is-invalid': Usuario?.touched && !Usuario?.valid }"
                    type="text"
                    name="usuario"
                    formControlName="usuario"
                    placeholder="Ingresar Usuario"
                    class="form-control pass"
                    autocomplete="off"
                    maxlength="255"
                    appRestringirTipo
                    permitir="email"
                  />
                </div>
                <div class="text-danger mt-2 text-center" *ngIf="!Usuario.valid">
                  <div *ngIf="Usuario.errors.pattern">Ingrese Información Válida.</div>
                  <div *ngIf="Usuario.errors.maxlength">El Campo Solo Acepta 255 Caracteres.</div>
                  <div *ngIf="Usuario.touched && Usuario.errors.required">Campo Requerido</div>
                </div>
                <div class="row mt-4">
                  <button
                    type="submit"
                    [disabled]="!formRecuperar.valid"
                    class="b-ingresar btn btn-primary"
                    appCaptchaReader
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
