<div class="contenedor mw-100 vh-100">
  <!-- Primer elipse -->
  <div class="elipse1"></div>
  <!-- Elipse blanco -->
  <div class="elipseB"></div>
  <!-- Elipse Amarillo chico -->
  <div class="elipseAC"></div>
  <!-- Elipse Amarillo Grande -->
  <div class="elipseAG"></div>
  <!-- Elipse Chico azul marino -->
  <div class="elipseCA"></div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="carPadre">
        <div class="card-login">
          <div class="card-body">
            <!--Formulario donde se muestra el tipo de inicio de sesion-->
            <article class="form1">
              <!-- Selección de tipo de inicio de sesión -->
              <div class="img">
                <a>
                  <img src="../../../../../assets/img/logo_SAJ_V2_Original.svg" alt="Coppel SAJv2" />
                </a>
              </div>
              <form
                [formGroup]="formReestablecer"
                (ngSubmit)="f.form.valid && cambiarContrasenia($event)"
                (keydown.enter)="f.form.valid && cambiarContrasenia($event)"
                #f="ngForm"
                novalidate
                class="form2"
              >
                <h2 class="text-center">{{ this.titulo }}</h2>
                <p class="text-muted">Nueva Contrase&ntilde;a</p>
                <div>
                  <p class="contrase">Ingresar Nueva Contraseña</p>
                  <input
                    [ngClass]="{ 'is-invalid': PsdNuevo?.touched && !PsdNuevo?.valid }"
                    type="password"
                    name="psdNuevo"
                    formControlName="psdNuevo"
                    placeholder="Ingresar Nueva Contraseña"
                    class="form-control pass"
                    autocomplete="off"
                    minlength="8"
                    maxlength="25"
                  />
                </div>
                <div class="text-danger mt-2 text-center" *ngIf="!PsdNuevo?.valid">
                  <div *ngIf="PsdNuevo?.errors?.minlength">El Campo Solo Acepta un Mínimo de 8 Caracteres.</div>
                  <div *ngIf="PsdNuevo?.errors?.maxlength">El Campo Solo Acepta un Máximo de 25 Caracteres.</div>
                  <div *ngIf="PsdNuevo?.touched && PsdNuevo?.errors?.required">Campo Requerido</div>
                  <div *ngIf="PsdNuevo?.touched && PsdNuevo?.errors?.pattern">
                    <ul class="list-unstyled">
                      <li *ngIf="validarCoppel.test(PsdNuevo?.value)">
                        La Contrase&ntilde;a No Puede Contener la Palabra Coppel
                      </li>
                      <li *ngIf="validaConsecutivos.test(PsdNuevo?.value)">
                        La Contrase&ntilde;a No Puede Contener Más de dos Caracteres, Números o Alfabéticos Idénticos en
                        Forma Consecutiva
                      </li>
                      <li *ngIf="!validaLetras.test(PsdNuevo?.value)">
                        La Contrase&ntilde;a debe Incluir Mayúsculas y Minúsculas
                      </li>
                      <li *ngIf="!validaNumero.test(PsdNuevo?.value)">
                        La Contrase&ntilde;a debe Incluir Mínimo un Número
                      </li>
                      <li *ngIf="!validaCaracterEspecial.test(PsdNuevo?.value)">
                        La Contrase&ntilde;a debe Contener Mínimo un Caracter Especial ;,!@#$%&()_+-=~*.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mt-1">
                  <p class="contrase mt-4">Confirmar Contrase&ntilde;a</p>
                  <input
                    [ngClass]="{ 'is-invalid': PsdConfirmado?.touched && !PsdConfirmado?.valid }"
                    type="password"
                    name="psdConfirmado"
                    formControlName="psdConfirmado"
                    placeholder="Ingresar Confirmar Contraseña"
                    class="form-control pass"
                    autocomplete="off"
                    minlength="8"
                    maxlength="25"
                  />
                </div>
                <div class="text-danger mt-2 text-center" *ngIf="!PsdConfirmado?.valid">
                  <div *ngIf="PsdConfirmado?.errors?.minlength">El Campo Solo Acepta un Mínimo de 8 Caracteres.</div>
                  <div *ngIf="PsdConfirmado?.errors?.maxlength">El Campo Solo Acepta un Máximo de 25 Caracteres.</div>
                  <div *ngIf="PsdConfirmado?.touched && PsdConfirmado?.errors?.required">Campo Requerido</div>
                  <div *ngIf="PsdConfirmado?.touched && PsdConfirmado?.errors?.pattern">
                    <ul class="list-unstyled">
                      <li *ngIf="validarCoppel.test(PsdConfirmado?.value)">
                        La Contrase&ntilde;a No Puede Contener la Palabra Coppel
                      </li>
                      <li *ngIf="validaConsecutivos.test(PsdConfirmado?.value)">
                        La Contrase&ntilde;a No Puede Contener Más de dos Caracteres, Números o Alfabéticos Idénticos en
                        Forma Consecutiva
                      </li>
                      <li *ngIf="!validaLetras.test(PsdConfirmado?.value)">
                        La Contrase&ntilde;a debe Incluir Mayúsculas y Minúsculas
                      </li>
                      <li *ngIf="!validaNumero.test(PsdConfirmado?.value)">
                        La Contrase&ntilde;a debe Incluir Mínimo un Número
                      </li>
                      <li *ngIf="!validaCaracterEspecial.test(PsdConfirmado?.value)">
                        La Contrase&ntilde;a debe Contener Mínimo un Caracter Especial ;,!@#$%&()_+-=~*.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row mt-4">
                  <button
                    type="submit"
                    [disabled]="!formReestablecer.valid"
                    class="b-ingresar btn btn-primary"
                    appCaptchaReader
                  >
                    {{ this.tituloBoton }}
                  </button>
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
