import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericFileUploadModalComponent } from '../generic-fileupload-modal/generic-file-upload-modal.component';
import { GenericFormGroupComponent } from '../generic-form-group/generic-form-group.component';
import { GenericInputCalendarComponent } from '../generic-input-calendar/generic-input-calendar.component';
import { GenericInputDropdownComponent } from '../generic-input-dropdown/generic-input-dropdown.component';
import { GenericInputFileComponent } from '../generic-input-file/generic-input-file.component';
import { GenericInputNumberComponent } from '../generic-input-number/generic-input-number.component';
import { GenericInputSearchComponent } from '../generic-input-search/generic-input-search.component';
import { GenericInputTextComponent } from '../generic-input-text/generic-input-text.component';
import { GenericInputTextAreaComponent } from '../generic-input-textarea/generic-input-textarea.component';
import { GenericInputToggleComponent } from '../generic-input-toggle-checkbox/generic-input-toggle-checkbox.component';
import { GenericTypedField } from './generic-form.models';
import { GenericInputTypes as GenericInputTypesEnum } from './generic-input-types';

// tslint:disable: directive-selector

@Directive({
  selector: '[genericField]',
})
export class GenericFieldDirective implements OnInit {
  /**
   * Configuracion del input.
   */
  @Input()
  public config: GenericTypedField;

  /**
   * Grupo padre.
   */
  @Input()
  public group: FormGroup;

  /**
   * Referencia del componente instanciado.
   */
  public component: ComponentRef<any>;

  /**
   * Constructor de clase `GenericFieldDirective`.
   */
  public constructor(private container: ViewContainerRef, private resolver: ComponentFactoryResolver) {}

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    const componentFactory = this.getComponentFactory(this.config.genericType);

    this.component = this.container.createComponent(componentFactory);
    const instance = this.component.instance;

    if (!instance.config) {
      instance.config = this.config;
    }

    if (!instance.rootFormGroup) {
      instance.rootFormGroup = this.group;
    }
  }

  protected getComponentFactory(type: GenericInputTypesEnum): ComponentFactory<any> {
    switch (type) {
      case GenericInputTypesEnum.GenericFileUploadModal:
        return this.resolver.resolveComponentFactory(GenericFileUploadModalComponent);
      case GenericInputTypesEnum.GenericFormGroup:
        return this.resolver.resolveComponentFactory(GenericFormGroupComponent);
      case GenericInputTypesEnum.GenericInputCalendar:
        return this.resolver.resolveComponentFactory(GenericInputCalendarComponent);
      case GenericInputTypesEnum.GenericInputDropdown:
        return this.resolver.resolveComponentFactory(GenericInputDropdownComponent);
      case GenericInputTypesEnum.GenericInputFile:
        return this.resolver.resolveComponentFactory(GenericInputFileComponent);
      case GenericInputTypesEnum.GenericInputNumber:
        return this.resolver.resolveComponentFactory(GenericInputNumberComponent);
      case GenericInputTypesEnum.GenericInputSearch:
        return this.resolver.resolveComponentFactory(GenericInputSearchComponent);
      case GenericInputTypesEnum.GenericInputText:
        return this.resolver.resolveComponentFactory(GenericInputTextComponent);
      case GenericInputTypesEnum.GenericInputTextArea:
        return this.resolver.resolveComponentFactory(GenericInputTextAreaComponent);
      case GenericInputTypesEnum.GenericInputToggle:
        return this.resolver.resolveComponentFactory(GenericInputToggleComponent);
    }
    return null;
  }
}
