import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { MensajesGenerales } from '@app-services/constantes';
import { GenericValidators } from '@app-shared/generic-reactive-forms/validators';

/**
 * Tipado de validadores genericos.
 */
type GenericValidatorsType = typeof GenericValidators;
@Component({
  selector: 'app-generic-errors-container',
  templateUrl: './generic-errors-container.component.html',
  styleUrls: ['./generic-errors-container.component.scss'],
})
export class GenericErrorsContainerComponent {
  /**
   * Bloque de mensajes fijos.
   */
  public readonly mensajeCampoRequerido: string = MensajesGenerales.INFO.CAMPO_REQUERIDO;
  public readonly mensajeNoSeEncontraronResultado: string = MensajesGenerales.INFO.NO_RESULTADOS_ENCONTRADOS;
  public readonly mensajeCamposConAsterisco: string = MensajesGenerales.INFO.CAMPOS_CON_ASTERISCO;
  public readonly mensajeSinCoincidencias: string = MensajesGenerales.INFO.SIN_COINCIDENCIAS;
  public readonly mensajeFormatoInvalido: string = MensajesGenerales.VALIDACIONES.MENSAJE_FORMATO_INVALIDO;
  public readonly mensajeTamanoInvalido: string = MensajesGenerales.VALIDACIONES.MENSAJE_TAMANO_INVALIDO;
  public readonly mensajeCantidadInvalida: string = MensajesGenerales.VALIDACIONES.CANTIDAD_INVALIDA;
  public readonly mensajeInformacionInvalida: string = MensajesGenerales.VALIDACIONES.INFORMACION_INVALIDA;
  public readonly mensajeCancelacionCuerpo: string = MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_BODY;
  public readonly mensajeCancelacionEncabezado: string = MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_HEADER;

  /**
   * Errores de validación.
   */
  @Input()
  public control: AbstractControl;

  /**
   * Validadores genericos
   */
  public get genericvalidators(): GenericValidatorsType {
    return GenericValidators;
  }

  /**
   * Retorna los errores del input.
   */
  public get errors(): ValidationErrors {
    const errors: ValidationErrors = this.control.errors;

    // Removemos los erroes que se manejan especificamente.
    const {
      requiredFileType,
      fileSizeValidator,
      required,
      maxlength,
      minlength,
      pattern,
      email,
      phone,
      invalid,
      min,
      max,
      ...rest
    } = errors || {};

    return Object.values(rest);
  }

  /**
   * Valida clausulas para mostrar mensaje de default de formato invalido
   */
  public validarMensajeInvalidoDefault(): boolean {
    return this.control.errors?.pattern || this.control.errors?.email || this.control?.errors?.requiredFileType;
  }
}
