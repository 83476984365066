import { AlertaAbandonarVistaComponent } from '@app-shared/commons/components/alerta-abandonar-vista/alerta-abandonar-vista.component';
import { AlertaCancelarCambiosComponent } from '@app-shared/commons/components/alerta-cancelar-cambios/alerta-cancelar-cambios.component';
import { GenericRolesModulosEnum, GenericRolesPermisosEnum } from '@app-shared/generic-roles/models/enum';
import { AccionesFormulario } from '@app-shared/models/acciones-formulario.enum';
import { Translation } from 'primeng/api';
import { ProcesoAsuntos } from '@app-modules/asuntos/models/enums/proceso-asuntos.enum';
import { Constante, MensajesGenerales, ParametrosModulos } from '@app-services/constantes';
import { GestorModalOptions, GestorModalRef, GestorModalService } from '@app-services/gestor-modal.service';

export interface IHerramientasFormulario {
  alertaCambioFormulario: () => Promise<boolean>;
  alertaCambioCancelarFormulario: () => Promise<boolean>;
  getConfiguracionCalendarioEs(): Translation;
}

export abstract class HerramientasFormulario implements IHerramientasFormulario {
  /** Indica los estados del formulario
   *
   * @memberof HerramientasFormulario
   */
  public estadoFormulario = {
    inicial: 1, // espiner des-activo
    recibiendoInformacion: 2, // espiner activo
    enviandoInformacion: 3, // espiner activo
    informacionRecibida: 4, // espiner des-activo
    informacionEnviada: 5, // espiner des-activo
    errorRecibido: 6, // espiner des-activo
  };

  /**
   * Nos permite saber si el usuario ya realizo algun cambio en el formulario
   *
   * @memberof HerramientasFormulario
   */
  public existenCambiosEnElFormulario = false;

  /**
   * Nos permite saber en que tipo de estado se encuentra el formulario
   *
   * @memberof HerramientasFormulario
   */
  public actualEstadoFormulario = this.estadoFormulario.inicial;

  /**
   * Nos permite saber si el formulario cargo todos los elementos en el DOM correctamente al iniciar
   *
   * @memberof HerramientasFormulario
   */
  public formularioCargadoCorrectamente = false;

  /**
   * Nos permite saber si existio algun tipo de error a la carga de los elemento en el DOM
   *
   * @memberof HerramientasFormulario
   */
  public errorEnInformacionCargada = false;

  public paginacion = {
    totalElementos: 0,
    totalElementosPorPagina: 10,
    segmentosPorPagina: [10, 25, 50],
    paginaActual: 0,
  };

  /**
   * Nos permite tener un atributo para identificar la acción actual que se está ejecutando en el formulario.
   *
   * @memberof HerramientasFormulario
   */
  public accionActualFormulario: AccionesFormulario;

  /**
   * Enum de permisos.
   * @memberof HerramientasFormulario
   */
  public permisos = GenericRolesPermisosEnum;

  /**
   * Enum de modulos.
   * @memberof HerramientasFormulario
   */
  public modulos = GenericRolesModulosEnum;

  public mensajeCampoRequerido: string = MensajesGenerales.INFO.CAMPO_REQUERIDO;
  public mensajeNoSeEncontraronResultado: string = MensajesGenerales.INFO.NO_RESULTADOS_ENCONTRADOS;
  public mensajeCamposConAsterisco: string = MensajesGenerales.INFO.CAMPOS_CON_ASTERISCO;
  public mensajeSinCoincidencias: string = MensajesGenerales.INFO.SIN_COINCIDENCIAS;
  public mensajeFormatoInvalido: string = MensajesGenerales.VALIDACIONES.MENSAJE_FORMATO_INVALIDO;
  public mensajeTamanoInvalido: string = MensajesGenerales.VALIDACIONES.MENSAJE_TAMANO_INVALIDO;
  public mensajeCantidadInvalida: string = MensajesGenerales.VALIDACIONES.CANTIDAD_INVALIDA;
  public mensajeInformacionInvalida: string = MensajesGenerales.VALIDACIONES.INFORMACION_INVALIDA;
  public mensajeCancelacionCuerpo: string = MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_BODY;
  public mensajeCancelacionEncabezado: string = MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_HEADER;

  /**
   * Mensaje para indicar que excede el límite de archivos permitidos.
   */
  public mensajeExcedeLimiteArchivo: string = MensajesGenerales.VALIDACIONES.MENSAJE_LIMITE_ARCHIVO;

  /**
   * Mensaje para indicar el detalle al exceder el tamaño máximo de archivo.
   */
  public mensajeTamanioMaximoArchivo: string = MensajesGenerales.VALIDACIONES.MENSAJE_DETALLE_TAMANO_ARCHIVO_INVALIDO;

  /**
   * Mensaje para indicar el límite de archivos a adjuntar.
   */
  public mensajeDetalleLimiteArchivo: string = MensajesGenerales.VALIDACIONES.MENSAJE_DETALLE_LIMITE_ARCHIVO;

  /**
   * Mensaje de error para extensión de archivo invalida.
   */
  public mensajeTipoArchivoInvalido: string = MensajesGenerales.VALIDACIONES.MENSAJE_TIPO_ARCHIVO_INVALIDO;

  /**
   * Mensaje de error para tamaño máximo archivos con detalles.
   */
  public mensajeTamanoInvalidoDetalles: string =
    MensajesGenerales.VALIDACIONES.MENSAJE_TAMANO_MAXIMO_SUPERADO_FILE_UPLOAD;

  /**
   * Mensaje con lista de archivos permitidos para componente prime
   */
  public mensajeDetalleTipoArchivoInvalido: string =
    MensajesGenerales.VALIDACIONES.MENSAJE_DETALLE_TIPO_ARCHIVO_INVALIDO;

  /**
   * Formatos permitidos para archivos.
   */
  public formatosValidosArchivos: string = Constante.FORMATOS_VALIDOS_ARCHIVO;

  /**
   * Tamaño máximo de archivo permitido.
   */
  public tamanioMaximoArchivo: number = Constante.TAMANO_MAXIMO_ARCHIVO;

  /**
   * Crea una instancia de HerramientasFormulario
   * @constructor
   * @param bsModalRefx
   * @param modalService
   */
  public constructor(public bsModalRefx: GestorModalRef = null, public modalService: GestorModalService = null) {}

  /**
   * Lanza alerta de aviso al abandonar la pantalla.
   *
   */
  public alertaCambioFormulario(): Promise<boolean> {
    if (this.existenCambiosEnElFormulario) {
      return new Promise((resolve, reject) => {
        const option: GestorModalOptions = {
          backdrop: 'static',
          initialState: {},
        };

        this.bsModalRefx = this.modalService.show(AlertaAbandonarVistaComponent, option);
        this.bsModalRefx.content.closeBtnName = 'Cerrar';

        this.bsModalRefx.content.event.subscribe((res) => {
          this.bsModalRefx.hide();
          resolve(true);
        });
      });
    }

    return Promise.resolve(true);
  }

  public alertaCambioCancelarFormulario(): Promise<boolean> {
    if (this.existenCambiosEnElFormulario) {
      return new Promise((resolve) => {
        const option: GestorModalOptions = {
          backdrop: 'static',
        };

        this.bsModalRefx = this.modalService.show(AlertaCancelarCambiosComponent, option);
        this.bsModalRefx.content.closeBtnName = 'Cerrar';

        this.bsModalRefx.content.event.subscribe((res) => {
          this.bsModalRefx.hide();
          resolve(true);
        });
      });
    }

    return Promise.resolve(true);
  }

  /**
   * Retorna la configuración para un calendario.
   */
  public getConfiguracionCalendarioEs(): Translation {
    return {
      dayNames: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.DIAS_NOMBRES,
      dayNamesShort: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.DIAS_NOMBRES_CORTOS,
      dayNamesMin: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.DIAS_NOMBRES_CORTOS_MIN,
      monthNames: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.MESES_NOMBRES,
      monthNamesShort: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.MESES_NOMBRES_CORTOS,
      today: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.HOY,
      clear: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.BORRAR,
      weekHeader: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.SEMANA,
    };
  }

  /**
   * Valida si  un proceso pertenece a Juridico Inmobiliario.
   * @param idProceso
   * @returns {boolean}
   */
  public esProcesoJI(idProceso: number): boolean {
    if (idProceso === ProcesoAsuntos.ACCESORIOS_INMOBILIARIOS) {
      return;
    }

    return (
      idProceso === ProcesoAsuntos.ADQUISICION_INMUEBLES ||
      idProceso === ProcesoAsuntos.COMERCIALIZACION_DE_INMUEBLES ||
      idProceso === ProcesoAsuntos.ADMINISTRACION_INMOBILIARIA ||
      idProceso === ProcesoAsuntos.ACCESORIOS_INMOBILIARIOS
    );
  }

  /**
   * Valida si  un proceso pertenece a Juridico Inmobiliario.
   * @param idProceso
   * @returns {boolean}
   */
  public esProcesoJC(idProceso: number): boolean {
    return idProceso === ProcesoAsuntos.CONFLICTO_COLECTIVO;
  }

  /**
   * Valida si  un proceso pertenece a Cumplimento Regulatorio.
   * @param idProceso
   * @returns {boolean}
   */
  public esProcesoCR(idProceso: number): boolean {
    return (
      idProceso === ProcesoAsuntos.REMODELACION ||
      idProceso === ProcesoAsuntos.OBRA_NUEVA ||
      idProceso === ProcesoAsuntos.INMUEBLE_EN_OPERACION ||
      idProceso === ProcesoAsuntos.ALINEAMIENTO_NO_OFICIAL ||
      idProceso === ProcesoAsuntos.RENOVACION_LICENCIAS_PERMISOS
    );
  }
}
