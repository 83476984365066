import { Component, Host, Input, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormControl, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { ParametrosModulos } from '@app-services/constantes';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { GenericCalendarInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-generic-input-calendar',
  templateUrl: './generic-input-calendar.component.html',
  styleUrls: ['./generic-input-calendar.component.scss'],
})
export class GenericInputCalendarComponent
  extends AbstractGenericInputComponent<GenericCalendarInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericCalendarInput;

  /**
   * Constructor de clase GenericInputCalendarComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService,
    private primeConfig: PrimeNGConfig
  ) {
    super(formRef, genericFormService);
  }

  /**
   * Formato de fecha.
   */
  public get dateFormat(): string {
    return this.fieldConfig?.dateFormat ?? 'dd/mm/yy';
  }

  /**
   * Booleano que indica si se debe mostrar icono.
   */
  public get showIcon(): boolean {
    return this.fieldConfig?.showIcon ?? true;
  }

  /**
   * Regresa el icono para el calendario.
   */
  public get icon(): string {
    return this.fieldConfig?.icon ?? 'pi pi-calendar';
  }

  /**
   * Regresa el modo de selección de fechas del calendario.
   */
  public get selectionMode(): 'single' | 'multiple' | 'range' {
    return this.fieldConfig?.selectionMode ?? 'single';
  }

  /**
   * Genera el rango de años para el selector de componente de fechas.
   */
  public get yearRange(): string {
    const now = new Date();
    return `${now.getFullYear() - 100}:${now.getFullYear() + 100}`;
  }

  /**
   * Atributo `placeholder`.
   */
  public get placeholder(): string | null {
    if (this.control.disabled) {
      return this.fieldConfig.label;
    }

    return this.fieldConfig?.placeholder ?? 'Seleccionar Fecha';
  }

  /**
   * Retorna los errores del input.
   */
  public get errors(): ValidationErrors {
    const errors: ValidationErrors = this.control.errors;

    const { required, ...rest } = errors || {};

    return Object.values(rest);
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.primeConfig.setTranslation({
      dayNames: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.DIAS_NOMBRES,
      dayNamesShort: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.DIAS_NOMBRES_CORTOS,
      dayNamesMin: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.DIAS_NOMBRES_CORTOS_MIN,
      monthNames: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.MESES_NOMBRES,
      monthNamesShort: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.MESES_NOMBRES_CORTOS,
      today: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.HOY,
      clear: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.BORRAR,
      weekHeader: ParametrosModulos.DATA.MODULOS.INMUEBLES.CONSTANTES.FECHAS.SEMANA,
    });
  }

  /**
   * On onFocus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }

  /**
   * On onBlur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }

  /**
   * On onInput event handler.
   */
  public onChange(e): void {
    if (this.fieldConfig?.onChange) {
      this.fieldConfig?.onChange(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
      });
    }
  }
}
