import { Injectable } from '@angular/core';
import { IResponseSingle } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Personal } from './personal';
import { ApiResponseDTO } from '@app-services/modules/ApiResponse/ApiResponse.interface';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PersonalService {
  /**
   * Creates an instance of personal service.
   * @param baseService servicio para realizar llamadas al backend.
   */
  public constructor(private baseService: BaseService) {}

  /**
   * : Documentar.
   */
  public getPersonalID(numeroPersona): Observable<ApiResponseDTO<Personal>> {
    const params = new HttpParams().append('numeroPersona', numeroPersona);

    return this.baseService.get<ApiResponseDTO<Personal>>(`${environment.microPersonal}/empleado`, { params: params });
  }

  /**
   * Método que obtiene la informacion de un colaborador de IFS
   * @param numeroPersona
   * @returns
   */
  public getColaborador(numeroPersona: string): Observable<ApiResponseDTO<Personal>> {
    return this.baseService
      .get<ApiResponseDTO<Personal>>(`${environment.microPersonal}/empleado?numeroPersona=${numeroPersona}`)
      .pipe(map((response) => response as ApiResponseDTO<Personal>));
  }

  /**
   * Método que obtiene la informacion de un colaborador externo por correo.
   */
  public getColaboradorExternoPorCorreo(id: string): Observable<IResponseSingle<Personal>> {
    return this.baseService
      .get<IResponseSingle<Personal>>(`${environment.backendURL}/personal/empleadoCorreo/?correo=${id}`, {
        encabezadosInterceptoresCustom: { interceptorMensajes: false, interceptarErrores: false },
      })
      .pipe(map((response) => response as IResponseSingle<Personal>));
  }
}
