import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { HTTP_ERROR } from '../models/http-error.enum';

/**
 * Lista de estatus de error para mensajes informativos en el sistema.
 */
export const ESTATUS_MENSAJES_INFORMATIVOS = [HTTP_ERROR.PRECONDITION_FAILED, HTTP_ERROR.LENGTH_REQUIRED];

@Injectable({
  providedIn: 'root',
})
export class ManejoErroresService implements OnDestroy {
  /**
   * BehaviorSubject para manejar el estado del error.
   */
  protected error$ = new BehaviorSubject(false);

  /**
   * Estado del interceptor de errores e indica si se encuentra aplicando reintentos
   */
  private _aplicandoReintentos = false;

  /**
   * Subject para desuscribir en automático.
   */
  private unsubscriptor$ = new Subject<void>();

  /**
   * Crea una instancia de servicio ManejoErroresService
   * @constructor
   */
  public constructor(private router: Router) {}

  /**
   * Getter para _aplicandoReintentos
   */
  public get aplicandoReintentos(): boolean {
    return this._aplicandoReintentos;
  }

  /**
   * Setter para _aplicandoReintentos
   */
  public set aplicandoReintentos(aplicaReintentos: boolean) {
    this._aplicandoReintentos = aplicaReintentos;
  }

  /**
   * Emite un observable para indicar si existe un error en el sistema que
   * impida continuar trabajando con el mismo.
   * @see FullLayoutComponent
   */
  public get existeErrorEnSistema(): Observable<boolean> {
    return this.error$.asObservable();
  }

  /**
   * Ciclo de vida destroy de la clase.
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.unsubscriptor$.next();
    this.unsubscriptor$.complete();
  }

  /**
   * @summary Escucha eventos de el cambio de rutas para ocultar la pantalla de error cuando se active la navegación
   * en caso de que esté mostrandose la pantalla de error.
   *
   * Solo implementar en el layout principal de la app.
   */
  public escuchaEventosRuta(): void {
    this.router.events.pipe(takeUntil(this.unsubscriptor$)).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.recibeEstadoError(false);
      }
    });
  }

  /**
   * Recibe un valor para ser emitido y cambiar el estado del error en sistema.
   * @param pHayError - Indica si existe o no un error para mostrar la pantalla <app-error-display>
   * @see FullLayoutComponent
   */
  public recibeEstadoError(pHayError: boolean): void {
    this.error$.next(pHayError);
  }
}
