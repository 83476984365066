import { Injectable } from '@angular/core';
import { AlertaAbandonarVistaComponent } from '@app-shared/commons/components/alerta-abandonar-vista/alerta-abandonar-vista.component';
import { AbstractHelperComponent } from '@app-shared/generic-components/components/abstract-helper-component/abstract-helper.component';
import { Observable, Observer, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { GestorModalService, GestorModalOptions } from '@app-services/gestor-modal.service';

/**
 * Tipado para unbinder.
 */
export type UnbindCallback = () => void;

/**
 * Servicio auxiliar para @see AbstractHelperComponent.
 */
@Injectable({
  providedIn: 'root',
})
export class HelperComponentService {
  /**
   * Mapa de bindings.
   */
  private componentBindings = new Map<symbol, AbstractHelperComponent>();

  /**
   * Constructor de clase `HelperComponentService`.
   */
  public constructor(public modalService: GestorModalService) {}

  /**
   * Registra el componente en el servicio de helper.
   */
  public bindComponent(instance: AbstractHelperComponent): UnbindCallback {
    const key = Symbol();
    this.componentBindings.set(key, instance);

    return () => this.componentBindings.delete(key);
  }

  /**
   * Handler para cambios de navegacion.
   */
  public onNavigation(): Observable<boolean> {
    if (!this.componentBindings.size) {
      return of(true);
    }

    const changed = Array.from(this.componentBindings.entries())
      .filter((binding) => {
        const component = binding[1];
        return component.existenCambios();
      })
      .map((binding) => {
        return binding[0];
      });

    if (changed && changed.length > 0) {
      return new Observable<boolean>((obs: Observer<boolean>) => {
        const option: GestorModalOptions = { backdrop: 'static' };
        const ref = this.modalService.show(AlertaAbandonarVistaComponent, option);
        const instance = ref.content as AlertaAbandonarVistaComponent;

        instance.event.pipe(take(1)).subscribe(() => {
          ref.hide();
          obs.next(true);
          obs.complete();
          this.componentBindings.clear();
        });
      });
    }

    return of(true);
  }
}
