import { MenuItem } from '@app-models/menu-item.model';
import { GenericRolesModulosEnum, GenericRolesPantallaSeguimiento } from '@app-shared/generic-roles/models/enum';
import { environment } from 'src/environments/environment';

/**
 * Ambientes que no son permitidos para mostrar los elementos.
 */
const blacklistedEnvs = ['JURIDICOINMOBILIARIO'];

/**
 * Ambientes que no son permitidos para mostrar administrador de roles.
 */
const blacklistedAdminEnvs = ['JURIDICOCOLECTIVO2'];

/**
 * Callback base para ambientes.
 */
const accessCallback: () => boolean = () => !blacklistedEnvs.includes(environment.envName);

/**
 * Callback para admiminstrador de roles.
 */
const administradorRolesCallback: () => boolean = () => {
  return Boolean(
    ((!blacklistedEnvs.includes(environment.envName) && !blacklistedAdminEnvs.includes(environment.envName)) ||
      habilitarAdministradorRoles()) ??
      false
  );
};

/**
 * Listado de rutas.
 */
export const menuTree: MenuItem[] = [
  {
    name: 'Inicio',
    url: '/dashboard',
    icon: 'cust_icon-Inicio_menu_icono',
  },
  {
    title: true,
    name: 'Administración',
  },
  {
    name: 'Asuntos',
    url: '/asuntos',
    icon: 'cust_icon-Icono_Menu_Asuntos',
    access: {
      modulos: [GenericRolesModulosEnum.ASUNTOS_BUSQUEDAASUNTOS],
    },
  },
  {
    name: 'Tareas',
    url: '/tareas',
    icon: 'cust_icon-tareas',
    access: {
      modulos: [GenericRolesModulosEnum.TAREAS_CUMPLIRTAREAS, GenericRolesModulosEnum.TAREAS_VALIDARTAREAS],
    },
    children: [
      {
        name: 'Asignar Tareas',
        url: '/tareas/tareas-por-asignar',
        icon: 'cust_icon-Icono_Menu_Asignar_Tareas',
        access: {
          modulos: [GenericRolesModulosEnum.TAREAS_BUSQUEDA_DE_TAREAS_PARA_ASIGNAR],
          callback: accessCallback,
        },
      },
      {
        name: 'Cumplir Tareas',
        url: '/tareas/tareas-por-cumplir',
        icon: 'cust_icon-Icono_Menu_Cumplir_Tareas',
        access: {
          modulos: [GenericRolesModulosEnum.TAREAS_CUMPLIRTAREAS],
        },
      },
      {
        name: 'Validar Tareas',
        url: '/tareas/tareas-por-validar',
        icon: 'cust_icon-Editar_asunto',
        access: {
          modulos: [GenericRolesModulosEnum.TAREAS_VALIDARTAREAS],
        },
      },
    ],
  },
  {
    name: 'Notificaciones',
    url: '/notificaciones',
    icon: 'cust_icon-notifiacion',
    access: {
      modulos: [
        GenericRolesModulosEnum.NOTIFICACIONES,
        GenericRolesModulosEnum.NOTIFICACIONES_AGREGAR_NOTIFICACIONES,
        GenericRolesModulosEnum.NOTIFICACIONES_BUSQUEDA_DE_NOTIFICACIONES_COMUNES,
        GenericRolesModulosEnum.NOTIFICACIONES_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS,
      ],
    },
    children: [
      {
        name: 'Agregar Notificaciones',
        url: '/notificaciones/agregar-notificaciones',
        icon: 'cust_icon-Agregar_notificaciones',
        access: {
          modulos: [GenericRolesModulosEnum.NOTIFICACIONES_AGREGAR_NOTIFICACIONES],
        },
      },
      {
        name: 'Notificaciones Comunes',
        url: '/notificaciones/notificaciones-comunes',
        icon: 'cust_icon-Notificaciones_comunes',
        access: {
          modulos: [GenericRolesModulosEnum.NOTIFICACIONES_BUSQUEDA_DE_NOTIFICACIONES_COMUNES],
        },
      },
      {
        name: 'Notificaciones Personalizadas',
        url: '/notificaciones/notificaciones-personalizadas',
        icon: 'cust_icon-Notificaciones_personalizadas',
        access: {
          modulos: [GenericRolesModulosEnum.NOTIFICACIONES_BUSQUEDA_DE_NOTIFICACIONES_PERSONALIZADAS],
        },
      },
      {
        name: 'Consultar Notificaciones',
        url: '/notificaciones/consultar-notificaciones',
        icon: 'cust_icon-icon_consultar_notificaciones',
        access: {
          modulos: [GenericRolesModulosEnum.NOTIFICACIONES_BUSQUEDA_DE_NOTIFICACIONES_COLABORADOR],
        },
      },
    ],
  },
  {
    name: 'Inmuebles',
    url: '/EUI',
    icon: 'pi pi-home',
    access: {
      callback: accessCallback,
      modulos: [GenericRolesModulosEnum.INMUEBLES_BUSQUEDA_DE_INMUEBLES],
    },
  },
  {
    name: 'Bóveda',
    url: '/boveda',
    icon: 'cust_icon-boveda_plasta',
    access: {
      modulos: [
        GenericRolesModulosEnum.BOVEDA,
        GenericRolesModulosEnum.RESGUARDO_EN_BOVEDA,
        GenericRolesModulosEnum.CONSULTA_EN_BOVEDA,
      ],
    },
    children: [
      {
        name: 'Resguardo en Bóveda',
        url: '/boveda/resguardo-en-boveda',
        icon: 'cust_icon-boveda_plasta',
        access: {
          modulos: [GenericRolesModulosEnum.RESGUARDO_EN_BOVEDA],
        },
      },
      {
        name: 'Consulta en Bóveda',
        url: '/boveda/consulta-de-boveda',
        icon: 'cust_icon-boveda_plasta',
        access: {
          modulos: [GenericRolesModulosEnum.CONSULTA_EN_BOVEDA],
        },
      },
    ],
  },
  {
    name: 'Reportes',
    url: '/reportes',
    icon: 'cust_icon-reportes_menu',
    access: {
      callback: accessCallback,
      modulos: [
        GenericRolesModulosEnum.REPORTES_ESTATUS_DE_CONTRATO_DE_SERVICIO_CFE,
        GenericRolesModulosEnum.REPORTES_LICENCIAS_DE_INMUEBLES_EN_OBRA,
        GenericRolesModulosEnum.REPORTES_OBRAS_EN_PROCESO,
        GenericRolesModulosEnum.LICENCIAS_Y_PERMISOS,
      ],
    },
    children: [
      {
        name: 'Estatus de Contrato de Servicio CFE',
        url: '/reportes/estatus-contrato-servicio-cfe',
        icon: 'cust_icon-plasta_icono_submenu_estatus_de_contrato_servicio_cfe',
        access: {
          modulos: [GenericRolesModulosEnum.REPORTES_ESTATUS_DE_CONTRATO_DE_SERVICIO_CFE],
        },
      },
      {
        name: 'Licencias de Inmuebles en Obra',
        url: '/reportes/licencias-inmuebles-obra',
        icon: 'cust_icon-plasta_icono_submenu_licencias_de_inmuebles_en_obra',
        access: {
          modulos: [GenericRolesModulosEnum.REPORTES_LICENCIAS_DE_INMUEBLES_EN_OBRA],
        },
      },
      {
        name: 'Obras en Proceso',
        url: '/reportes/obras-en-proceso-cco',
        icon: 'cust_icon-plasta_icono_submenu_reporte_de_obras',
        access: {
          modulos: [GenericRolesModulosEnum.REPORTES_OBRAS_EN_PROCESO],
        },
      },
      {
        name: 'Consulta Licencias y/o Permisos',
        url: '/reportes/licencias-permisos',
        icon: 'cust_icon-submenu_licencias_permisos_plasta',
        access: {
          modulos: [GenericRolesModulosEnum.LICENCIAS_Y_PERMISOS],
        },
      },
      {
        name: 'Contratos Colectivos de Obra',
        url: '/reportes/contrato-colectivos-obra',
        icon: 'cust_icon-icon_Submenu_Contratos_Colectivos_Obra',
      },
      {
        name: 'Contratos Colectivos de Trabajo',
        url: '/reportes/contrato-colectivos-trabajo',
        icon: 'cust_icon-icon_contratos_colectivos_de_trabajo',
      },
      {
        name: 'Conflictos Colectivos',
        url: '/reportes/conflictos-colectivos',
        icon: 'cust_icon-ICONO_CONFLICTO_COLECTIVO',
      },
      {
        name: 'Convenios Colectivos',
        url: '/reportes/convenios-colectivos',
        icon: 'cust_icon-ICON_CONVENIO_COLECTIVO-01',
      },
    ],
  },
  {
    name: 'Parametrización',
    url: '/parametrizacion',
    icon: 'cust_icon-Parametrizacion-1',
    access: {
      modulos: [
        GenericRolesModulosEnum.PARAMETRIZACION_ESTADO_PROCESAL,
        GenericRolesModulosEnum.PARAMETRIZACION_CHECKLIST_ASUNTOS,
        GenericRolesModulosEnum.PARAMETRIZACION_PLANTILLAS_DE_TAREAS,
        GenericRolesModulosEnum.PARAMETRIZACION_INTENTOS_VALIDACION_TAREAS,
        GenericRolesModulosEnum.PARAMETRIZACION_ACCIONES_FLUJOS,
        GenericRolesModulosEnum.PARAMETRIZACION_SUCESOS,
        GenericRolesModulosEnum.PARAMETRIZACION_NOTIFICACIONES_AUTOMATICAS,
        GenericRolesModulosEnum.PARAMETRIZACION_PLANTILLAS_DE_NOTIFICACIONES,
        GenericRolesModulosEnum.PARAMETRIZACION_PLANTILLAS,
        GenericRolesModulosEnum.PARAMETRIZACION_SEMAFOROS,
        GenericRolesModulosEnum.PARAMETRIZACION_CORREOS_Y_DOMINIOS,
      ],
    },
    children: [
      {
        name: 'Estado Procesal',
        url: '/parametrizacion/Estado-procesal',
        icon: 'cust_icon-Estado_procesal',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_ESTADO_PROCESAL],
        },
      },
      {
        name: 'Checklist Asuntos',
        url: '/parametrizacion/checklist-asunto',
        icon: 'cust_icon-Plasta_Icono_Submenu_Checklist',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_CHECKLIST_ASUNTOS],
        },
      },
      {
        name: 'Tareas',
        url: '/parametrizacion/tareas',
        icon: 'cust_icon-tareas',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_PLANTILLAS_DE_TAREAS],
        },
      },
      {
        name: 'Intentos Validación Tareas',
        url: '/parametrizacion/intentos-validacion-tareas',
        icon: 'cust_icon-icono_submenu_intentos_validacion_tareas_plasta',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_INTENTOS_VALIDACION_TAREAS],
        },
      },
      {
        name: 'Acciones de Flujos',
        url: '/parametrizacion/acciones-flujos',
        icon: 'cust_icon-Icono_SubMenu_Plasta_Acciones_de_flujos',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_ACCIONES_FLUJOS],
        },
      },
      {
        name: 'Sucesos',
        url: '/parametrizacion/sucesos',
        icon: 'cust_icon-Plasta_Icono_Submenu_Sucesos',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_SUCESOS],
        },
      },
      {
        name: 'Notificaciones Automáticas',
        url: '/parametrizacion/notificaciones-automaticas',
        icon: 'cust_icon-Plasta-Icono_Submenu_Notificacion_Automaticas',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_NOTIFICACIONES_AUTOMATICAS],
        },
      },

      {
        name: 'Plantillas de Notificaciones',
        url: '/parametrizacion/platillas-notificaciones',
        icon: 'cust_icon-Icono_submenu_plantillas_de_notificaciones',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_PLANTILLAS_DE_NOTIFICACIONES],
        },
      },
      {
        name: 'Plantillas',
        url: '/parametrizacion/plantillas-colectivo',
        icon: 'cust_icon-Icono_submenu_Plantillas_Colectivo',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_PLANTILLAS],
        },
      },
      {
        name: 'Semáforos',
        url: '/parametrizacion/checklist-semaforo',
        icon: 'cust_icon-Icono_Submenu_Plasta_Semaforos',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_SEMAFOROS],
        },
      },
      {
        name: 'Correo y Dominios',
        url: '/parametrizacion/administracion-correos',
        icon: 'cust_icon-Icono_Submenu_Plasta_Correo_y_Dominios',
        access: {
          modulos: [GenericRolesModulosEnum.PARAMETRIZACION_CORREOS_Y_DOMINIOS],
        },
      },
    ],
  },
  {
    name: 'Administración Roles',
    url: '/roles',
    icon: 'fa fa-users',
    access: {
      callback: administradorRolesCallback,
      modulos: [GenericRolesModulosEnum.ROLES_BUSQUEDA_DE_ROLES],
    },
  },
  {
    name: 'Administración Catálogos',
    url: '/catalogos',
    icon: 'cust_icon-administracion_catalogos',
    access: {
      callback: accessCallback,
      modulos: [
        GenericRolesModulosEnum.ADMINISTRACION_DE_CATALOGOS,
        GenericRolesModulosEnum.CATALOGOS_CONTROL_DE_ADMINISTRACION_DE_CATALOGOS,
        GenericRolesModulosEnum.CATALOGOS_AUTORIDAD_LABORAL,
        GenericRolesModulosEnum.CATALOGOS_EMPRESA_FILIAL,
        GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_MATERIAS,
        GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_AREA,
        GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_PROCESOS,
        GenericRolesModulosEnum.GEOGRAFIA,
        GenericRolesModulosEnum.GEOGRAFIA_CIUDAD,
        GenericRolesModulosEnum.GEOGRAFIA_ESTADO,
        GenericRolesModulosEnum.GEOGRAFIA_MUNICIPIO,
        GenericRolesModulosEnum.GEOGRAFIA_PAIS,
        GenericRolesModulosEnum.MULTA_PROFECO,
        GenericRolesModulosEnum.QUEJAS_PROFECO,
        GenericRolesModulosEnum.MOTIVOS_DE_MULTA_PROFECO,
      ],
    },
    children: [
      {
        name: 'Control Administración Catálogos',
        url: '/catalogos/control-admnistracion-catalogos',
        icon: 'cust_icon-Icono_Submenu_Plasta_Control_Administracion_Catalogos',
        access: {
          modulos: [GenericRolesModulosEnum.CATALOGOS_CONTROL_DE_ADMINISTRACION_DE_CATALOGOS],
        },
      },
      {
        name: 'Licencias y Permisos',
        url: '/catalogos/licencias-permisos',
        icon: 'cust_icon-submenu_licencias_permisos_plasta',
        access: {
          modulos: [GenericRolesModulosEnum.ADMINISTRACION_DE_CATALOGOS],
        },
      },
      {
        name: 'Administrador de Sindicatos',
        url: '/catalogos/sindicato',
        icon: 'cust_icon-Sindicato',
        access: {
          callback: () => false,
          modulos: [GenericRolesModulosEnum.CATALOGOS_ADMINISTRADOR_DE_SINDICATOS],
        },
      },
      {
        name: 'Autoridad Laboral',
        url: '/catalogos/autoridad',
        icon: 'cust_icon-Autoridad_laboral',
        access: {
          modulos: [GenericRolesModulosEnum.CATALOGOS_AUTORIDAD_LABORAL],
        },
      },
      {
        name: 'Empresa Filial',
        url: '/catalogos/empresas-filiales',
        icon: 'cust_icon-Icono_Submenu_Plasta_Empresas_Fiales',
        access: {
          modulos: [GenericRolesModulosEnum.CATALOGOS_EMPRESA_FILIAL],
        },
      },
      {
        name: 'Motivos de Multa',
        url: '/catalogos/motivos-multa-profeco',
        icon: 'cust_icon-clinical_notes',
        access: {
          modulos: [GenericRolesModulosEnum.MOTIVOS_DE_MULTA_PROFECO],
        },
      },
      {
        name: 'PROFECO',
        icon: 'cust_icon-fmd_bad',
        access: {
          modulos: [
            GenericRolesModulosEnum.MULTA_PROFECO,
            GenericRolesModulosEnum.QUEJAS_PROFECO,
            GenericRolesModulosEnum.MOTIVOS_DE_MULTA_PROFECO,
          ],
        },
        children: [
          {
            name: 'Tipos de Motivos de Queja PROFECO',
            url: '/catalogos/profeco/profeco/motivos-queja-profeco',
            icon: 'cust_icon-tipomotivosqueja',
            access: {
              modulos: [GenericRolesModulosEnum.QUEJAS_PROFECO],
            },
          },
          {
            name: 'Proveedores Profeco',
            url: '/catalogos/profeco/profeco/proveedores-profeco',
            icon: 'cust_icon-proveedores_profeco',
            access: {
              modulos: [GenericRolesModulosEnum.PROVEEDORES_CITADOS_EN_LA_AUDIENCIA],
            },
          },
          {
            name: 'Tipos de Acuerdo Incomparecencia',
            url: '/catalogos/profeco/profeco/tiposacuerdoincomparecencia-profeco',
            icon: 'cust_icon-acuerdos-incomparecencia',
            access: {
              modulos: [GenericRolesModulosEnum.TIPOS_DE_ACUERDO_INCOMPARECENCIA],
            },
          },
          {
            name: 'Delegaciones ODECO',
            url: '/catalogos/profeco/profeco/delegaciones-odeco-profeco',
            icon: 'cust_icon-delegaciones-odeco',
            access: {
              modulos: [],
            },
          },
          {
            name: 'Motivos Salvo Los Derechos',
            url: '/catalogos/profeco/profeco/motivosalvoderechos-profeco',
            icon: 'cust_icon-motivosalvoderechos',
            access: {
              modulos: [GenericRolesModulosEnum.MOTIVO_SALVO_LOS_DERECHOS],
            },
          },
        ],
      },
      {
        name: 'Geografía',
        url: '/geografia',
        icon: 'cust_icon-Geografia-1',
        access: {
          modulos: [
            GenericRolesModulosEnum.GEOGRAFIA,
            GenericRolesModulosEnum.GEOGRAFIA_PAIS,
            GenericRolesModulosEnum.GEOGRAFIA_CIUDAD,
            GenericRolesModulosEnum.GEOGRAFIA_ESTADO,
            GenericRolesModulosEnum.GEOGRAFIA_MUNICIPIO,
          ],
        },
        children: [
          {
            name: 'País',
            url: '/catalogos/geografia/geografia-paises',
            icon: 'cust_icon-Plasta_Icono_Submenu_Paises',
            access: {
              modulos: [GenericRolesModulosEnum.GEOGRAFIA_PAIS],
            },
          },
          {
            name: 'Estado',
            url: '/catalogos/geografia/geografia-estados',
            icon: 'cust_icon-Plasta_Icono_Estado',
            access: {
              modulos: [GenericRolesModulosEnum.GEOGRAFIA_ESTADO],
            },
          },
          {
            name: 'Municipio',
            url: '/catalogos/geografia/geografia-municipio',
            icon: 'cust_icon-Municipio',
            access: {
              modulos: [GenericRolesModulosEnum.GEOGRAFIA_MUNICIPIO],
            },
          },
          {
            name: 'Ciudad',
            url: '/catalogos/geografia/geografia-ciudad',
            icon: 'cust_icon-Icono_Submenu_Ciudad',
            access: {
              modulos: [GenericRolesModulosEnum.GEOGRAFIA_CIUDAD],
            },
          },
        ],
      },
      {
        name: 'Procesos',
        url: '/procesos',
        icon: 'cust_icon-Icono_Submenu_Procesos',
        access: {
          modulos: [
            GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_MATERIAS,
            GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_AREA,
            GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_PROCESOS,
          ],
        },
        children: [
          {
            name: 'Materia',
            url: '/catalogos/procesos/procesos-materia',
            icon: 'cust_icon-Icono_Submenu_Materia',
            access: {
              modulos: [GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_MATERIAS],
            },
          },
          {
            name: 'Área',
            url: '/catalogos/procesos/procesos-area',
            icon: 'cust_icon-Icono_Submenu_Areas',
            access: {
              modulos: [GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_AREA],
            },
          },
          {
            name: 'Proceso',
            url: '/catalogos/procesos/procesos-proceso',
            icon: 'cust_icon-Icono_Submenu_Procesos_plasta',
            access: {
              modulos: [GenericRolesModulosEnum.CATALOGOS_BUSQUEDA_DE_PROCESOS],
            },
          },
        ],
      },
    ],
  },
];

/**
 * Bypass para habilitacion administrador de roles.
 *
 * Solo funcional en entornos de desarrollo DEV/QA.
 */
export function habilitarAdministradorRoles() {
  return environment.habilitarAdministradorRoles && existeQueryHabilitacionAdministradorRoles();
}

/**
 * Retorna un booleano indicando si existe en el ambiente un query param de habilitacion.
 */
function existeQueryHabilitacionAdministradorRoles() {
  if (sessionStorage.getItem('habilitaradministradorroles')) {
    return true;
  }

  const params = new URLSearchParams(window.location.search);
  const paramExists = params.get('habilitaradministradorroles');

  if (paramExists) {
    sessionStorage.setItem('habilitaradministradorroles', '1');
  }

  return paramExists;
}
