import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenericFormElements, GenericTextAreaInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HerramientasFormulario } from '@app-models/herramientasFormulario';

@Component({
  selector: 'app-modal-comentario',
  templateUrl: './modal-comentario.component.html',
  styleUrls: ['./modal-comentario.component.scss'],
})
export class ModalComentarioComponent extends HerramientasFormulario implements OnInit {
  /**
   * `FormGroup` de componente.
   */
  public form: FormGroup;

  /**
   * Construye una instancia de DetalleNotificacionComponent
   */
  public constructor(
    public dataModal: DynamicDialogConfig,
    public refDialog: DynamicDialogRef,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  /**
   * Configuracion del formulario.
   */
  public get config(): GenericFormElements {
    return {
      comentario: {
        name: 'comentario',
        required: true,
        cols: 30,
        rows: 12,
        disabled: true,
        value: this.dataModal?.data?.body,
        autoResize: true,
      } as GenericTextAreaInput,
    };
  }

  /**
   * Init pantalla
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.init();
  }

  /**
   * Acción del botón cancelar.
   */
  public cancelar(): void {
    this.refDialog.close();
  }

  /**
   * Ejecuta acciones iniciales del componente.
   */
  private init(): void {
    this.form = this.formBuilder.group({});
    this.formularioCargadoCorrectamente = true;
  }
}
