import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { IHerramientasFormulario } from '@app-models/herramientasFormulario';

@Injectable()
export class CancelGuard implements CanDeactivate<IHerramientasFormulario> {
  public canDeactivate(
    component: IHerramientasFormulario,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return component.alertaCambioCancelarFormulario();
  }
}
