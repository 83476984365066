import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToasterService } from './services/toaster.service';
import { uniqueId } from '@app-shared/generic-reactive-forms/utils/generics.utils';

type MessageType = 'error' | 'warn' | 'info' | 'message' | 'approval';

/**
 * Interfaz para manejo de toasts.
 */
export interface Toast {
  type: MessageType;
  body: string;
  title: string;
  close?: () => void;
  show?: boolean;
}

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit {
  /**
   * Mapa con mensajes.
   */
  private messages: Map<string, Toast> = new Map();

  /**
   * Constructor de clase `ToasterComponent`.
   */
  public constructor(private toasterService: ToasterService, private changeDetector: ChangeDetectorRef) {}

  /**
   * Iterador de mesajes.
   */
  public get toasts(): Toast[] {
    return Array.from(this.messages.values());
  }

  /**
   * Clases de estilos para el contenedor.
   */
  public get classContainer(): string {
    return this.toasts.length > 0 ? 'show' : '';
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.listenMessages();
  }

  /**
   * Clases para toast.
   */
  public classToast(toast: Toast) {
    return [toast.type, toast.show && 'show'].filter(Boolean);
  }

  /**
   * Handler para excucha de mensajes.
   */
  private listenMessages() {
    this.toasterService.$message.subscribe({
      next: (payload) => {
        if (payload) {
          this.push(payload);
        }
      },
    });
  }

  /**
   * Handler para añadir un nuevo mensaje.
   */
  private push(payload: Toast) {
    const id = uniqueId();

    const toast: Toast = {} as Toast;

    const close = () => {
      toast.show = false;
      this.changeDetector.detectChanges();

      setTimeout(() => {
        this.messages.delete(id);
        this.changeDetector.detectChanges();
      }, 200);
    };

    toast.type = payload?.type ?? 'message';
    toast.body = payload?.body ?? '';
    toast.title = payload?.title;
    toast.close = close;
    toast.show = false;

    setTimeout(() => {
      toast.show = true;
      this.changeDetector.detectChanges();
    }, 200);

    this.messages.set(id, toast);
    this.changeDetector.detectChanges();

    setTimeout(close, 20000);
  }
}
