import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SecureStorageService } from '../secure-storage.service';
import { CurrentUser } from '@app-models/currentUser.interface';

const GRAPH_URL = environment.authIdp.AAD.graphUrl;
const GRAPH_PARAMS = 'displayName,givenName,surname,employeeId,mail,jobTitle,department,companyName';
const GRAPH_ENDPOINT = `${GRAPH_URL}/v1.0/me?$select=${GRAPH_PARAMS}`;

@Injectable({
  providedIn: 'root',
})
export class AadService {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private http: HttpClient,
    private storageService: SecureStorageService
  ) {
    const idpSetted = typeof localStorage.getItem('idp') == 'string';
    const aadEnabled = environment.authMechanisms.azureAD;
    if (aadEnabled && idpSetted) {
      this.listenInteraction();
      this.listenAuth();
    }
  }

  private _handleError(error: HttpErrorResponse) {
    let errorMessage = 'No se obtuvo respuesta del servidor de autenticación';
    if (error.error.data) {
      errorMessage = error.error.data.userMessage;
    }
    return throwError(() => new Error(errorMessage));
  }

  public fetchProfile(): Observable<CurrentUser> {
    return this.http.get(GRAPH_ENDPOINT).pipe(
      map(({ surname, givenName, employeeId }: any) => {
        return this.convertirUsuarioEntraID(surname, givenName, employeeId);
      }),
      switchMap((currentUser) => this.findOrCreateUser(currentUser.numeroempleado).pipe(map(() => currentUser))),
      catchError(this._handleError),
    );
  }

  /**
   * Funcion para renovar el token
   *
   */
  public renewToken() {
    return this.authService.loginPopup({
      scopes: ['user.read'],
      account: this.authService.instance.getActiveAccount(),
    });
  }

  private checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  /**
   *  Devuelve el tiempo que le queda al token para expirar
   */
  public getTokenTimeToExpire(): number {
    const expiration = this.getTokenExpirationTime();
    const now = new Date().getTime();
    const timeToExpire = expiration - now;
    return timeToExpire < 0 ? 0 : timeToExpire;
  }

  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  /**
   * Convierte información de usuario obtenido mediante el servicio Microsoft Entra ID
   * al tipo `CurrentUser`.
   */
  private convertirUsuarioEntraID(surname, givenName, employeeId): CurrentUser {
    const apellidos = surname.split(' ');
    const externalUser: CurrentUser = {
      apellidopaterno: apellidos[0],
      apellidomaterno: apellidos[1],
      nombre: givenName,
      numeroempleado: employeeId,
    };
    return externalUser;
  }

  /**
   * Devuelve la fecha de expiración del token en milisegundos
   */
  public getTokenExpirationTime(): number {
    const account = this.authService.instance.getActiveAccount();
    return account.idTokenClaims.exp * 1000;
  }

  /*
    Verifica si el token ya expiro
  */
  public isTokenExpired(): boolean {
    const expiration = this.getTokenExpirationTime();
    const now = new Date().getTime();

    return expiration < now;
  }

  private listenInteraction() {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: () => {
          this.checkAndSetActiveAccount();
        },
      });
  }

  private listenAuth() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (authentication: any) => {
          if (authentication.payload['accessToken']) {
            const token = `${authentication.payload['tokenType']} ${authentication.payload['accessToken']}`;
            this.storageService.setItem('token', token);
          }
        },
      });
  }

  /**
   * Valida la existencia del usuario (activo|inactivo).
   * @param numeroPersona
   * @returns
   */
  private findOrCreateUser(numeroPersona: number | string): Observable<boolean> {
    return this.http
      .post<any>(`${environment.autenticacion}/user/create`, {
        numeroPersona,
      })
      .pipe(
        mergeMap((r) => of(true)),
        catchError((e) => {
          localStorage.clear();
          sessionStorage.clear();
          return of(false);
        })
      );
  }
}
