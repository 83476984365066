import { Location } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { GenericFormComponent } from '@app-shared/generic-reactive-forms/components/generic-form/generic-form.component';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { MessagesMainService } from '@app-shared/services/messages-main.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TipoAccesoConstantes, TipoUsuarioConstantes } from '../models/enum';
import { TipoAccesoDto } from '../models/models';
import { GenericRolesService } from '../services/generic-roles.service';
import { RolesAwareService } from '../services/roles-aware.service';
import { deshabilitarRoles } from '../utils';

@Component({
  selector: 'app-roles-aware-form',
  templateUrl: './roles-aware-form.component.html',
  styleUrls: ['./roles-aware-form.component.scss'],
  providers: [GenericFormService],
})
export class RolesAwareFormComponent extends GenericFormComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * ID de asunto.
   */
  @Input()
  public asuntoId: string | number;

  /**
   * ID de notificacion.
   */
  @Input()
  public notificacionId: string | number;

  /**
   * ID de notificacion.
   */
  @Input()
  public tareaId: string | number;

  /**
   * ID de notificacion.
   */
  @Input()
  public accesoDefault = false;

  /**
   * Retorna un booleano indicando si se debe omitir el fieldset.
   */
  public omitFieldSetValue: boolean;

  /**
   * Subject de acceso.
   */
  private accesoSubject$: BehaviorSubject<TipoAccesoDto> = new BehaviorSubject<TipoAccesoDto>(null);

  /**
   * Acceso default al formulario.
   */
  private accesoDefaultValue$ = new BehaviorSubject<boolean>(false);

  /**
   * @inheritdoc
   */
  public constructor(
    public genericRolesService: GenericRolesService,
    public genericFormService: GenericFormService,
    protected formBuilder: FormBuilder,
    protected injector: Injector,
    public rolesAwareFormService: RolesAwareService,
    protected location: Location,
    protected messagesMainService: MessagesMainService,
    @Optional() @SkipSelf() protected parentComponent: RolesAwareFormComponent
  ) {
    super(genericFormService, formBuilder, injector);
  }

  /**
   * Setter de booleano que indica si el botón es `small`.
   */
  @Input('omitFieldSet')
  public set omitFieldSet(attribute: boolean | '') {
    this.omitFieldSetValue = attribute === '' || attribute;
  }

  /**
   * Tipo de acceso.
   */
  public get acceso(): boolean {
    return !!this.accesoSubject$.value || this.accesoDefaultValue$.value;
  }

  /**
   * Tipo de acceso.
   */
  public get acceso$(): Observable<TipoAccesoDto> {
    return this.accesoSubject$.asObservable().pipe(filter((v) => !!v));
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.accesoDefaultValue$.next(this.accesoDefault);

    super.ngOnInit();

    if (this.parentComponent) {
      this.parentComponent.acceso$
        .pipe(takeUntil(this.destructionSubject$.asObservable()))
        .subscribe((r) => this.setTipoAcceso(r));

      this.parentComponent.accesoDefaultValue$
        .asObservable()
        .pipe(takeUntil(this.destructionSubject$.asObservable()))
        .subscribe((r) => this.accesoDefaultValue$.next(r));

      return;
    }

    this.inicializar();
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.accesoSubject$.complete();
    this.accesoDefaultValue$.complete();
  }

  /**
   * @inheritdoc
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const { asuntoId, notificacionId, tareaId } = changes;
    if (asuntoId && !asuntoId.isFirstChange() && asuntoId.currentValue !== asuntoId.previousValue) {
      this.inicializar();
      return;
    }

    if (
      notificacionId &&
      !notificacionId.isFirstChange() &&
      notificacionId.currentValue !== notificacionId.previousValue
    ) {
      this.inicializar();
      return;
    }

    if (tareaId && !tareaId.isFirstChange() && tareaId.currentValue !== tareaId.previousValue) {
      this.inicializar();
    }
  }

  /**
   * Inicializador del componente.
   */
  public inicializar() {
    if (deshabilitarRoles()) {
      this.accesoDefaultValue$.next(true);
      return;
    }

    if (this.asuntoId) {
      this.rolesAwareFormService.getAccesoPorAsuntoID(this.asuntoId).subscribe((r) => this.setTipoAcceso(r));
      return;
    }

    if (this.notificacionId) {
      this.rolesAwareFormService
        .getAccesoPorNotificacionID(this.notificacionId)
        .subscribe((r) => this.setTipoAcceso(r));
      return;
    }

    if (this.tareaId) {
      this.rolesAwareFormService.getAccesoPorTareaID(this.tareaId).subscribe((r) => this.setTipoAcceso(r));
      return;
    }

    this.accesoDefaultValue$.next(true);
  }

  /**
   * Setea el tipo de acceso para el formulario.
   */
  private setTipoAcceso(acceso: TipoAccesoDto): void {
    const handle = () => setTimeout(() => this.accesoSubject$.next(acceso));
    if (acceso && acceso.tipoUsuario === TipoUsuarioConstantes.EXTERNO) {
      handle();
      return;
    }

    if (acceso && acceso.tipoAcceso !== TipoAccesoConstantes.CONSULTA) {
      handle();
      return;
    }

    if (acceso && acceso.tipoAcceso === TipoAccesoConstantes.CONSULTA) {
      this.disable(true);
      handle();
      return;
    }

    this.messagesMainService.messageInfo(
      'Su usuario no posee permisos suficientes para acceder a la pantalla seleccionada.',
      'Contacte a su administrador'
    );

    this.location.back();
  }
}
