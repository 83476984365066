import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
    <div class="loading-background">
      <div class="ring">
        <div class="clock">
          <div class="minutes"></div>
          <div class="hours"></div>
        </div>
      </div>
      <div class="logo-loading-SAJ">
        <img class="animate-flicker" src="../../../../../assets/img/img-logo-loading/logo-animacion-01.svg" />
        <img
          class="animate-flicker animate-flicker-2"
          src="../../../../../assets/img/img-logo-loading/logo-animacion-02.svg"
        />
        <img
          class="animate-flicker animate-flicker-3"
          src="../../../../../assets/img/img-logo-loading/logo-animacion-03.svg"
        />
        <img
          class="animate-flicker animate-flicker-4"
          src="../../../../../assets/img/img-logo-loading/logo-animacion-04.svg"
        />
      </div>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .custom-spinner .p-progress-spinner-circle {
        animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
          custom-progress-spinner-color 6s ease-in-out infinite;
      }

      @keyframes custom-progress-spinner-color {
        100%,
        0% {
          stroke: var(--primary);
        }
        40% {
          stroke: var(--primary);
        }
        66% {
          stroke: #888;
        }
        80%,
        90% {
          stroke: #cccccc;
        }
      }

      @keyframes custom-progress-spinner-dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35px;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124px;
        }
      }
    `,
  ],
})
export class LoadingComponent {}
