import { Component } from '@angular/core';
import { SessionService } from 'src/app/state/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cierre-sesion',
  templateUrl: './cierre-sesion.component.html',
  styleUrls: ['./cierre-sesion.component.scss'],
})
export class CierreSesionComponent {
  constructor(private session: SessionService) {}
  /**
   * Metodo que determina si el usuario es externo o interno
   * @returns {boolean}
   */
  public usuarioExterno(): boolean {
    return environment.inHouse[0];
  }

  /**
   * Metodo del boton Aceptar y cerrar para reedireccionarnos a la intranet.
   */
  public aceptar() {
    this.session.logout();
  }
}
