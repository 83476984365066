import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderDirective } from "./directives/loader.directive";
import { LoaderService } from "./services/loader.service";

@NgModule({
  declarations: [LoaderDirective],
  imports: [CommonModule],
  exports: [LoaderDirective],
  providers: [LoaderService],
})
export class LoaderModule {}
export { LoaderService } from "./services/loader.service";
