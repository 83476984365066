<div class="form-group">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <div class="d-flex">
    <div class="col-md-4">
      <input
        pInputText
        [type]="type"
        [style]="fieldConfig?.inputStyle"
        class="ng-dirty !ng-invalid form-control generic-search"
        [ngClass]="fieldConfig?.inputStyleClass"
        [name]="fieldConfig.name"
        [id]="id"
        [autocomplete]="autocomplete"
        [formControl]="control"
        [placeholder]="placeholder"
        [maxlength]="maxlength"
        [minlength]="minlength"
        [pattern]="pattern"
        [spellcheck]="spellcheck"
        [readonly]="fieldConfig?.readonly"
        [max]="max"
        [min]="min"
        [step]="step"
        (keydown.enter)="onChange($event)"
        (onFocus)="onFocus($event)"
        (onBlur)="onBlur($event)"
        (input)="onInput($event)"
        #input
      />
    </div>

    <ng-container *ngIf="showSearch">
      <div class="col-md-8 input-group">
        <input
          pInputText
          type="text"
          readonly
          [disabled]="true"
          placeholder="{{ secondFieldPlaceholder }}"
          [formControl]="value"
          class="ng-dirty !ng-invalid form-control generic-search"
          [ngClass]="searchClasses"
          (click)="showModalAdvancedSearch($event)"
          (keypress)="onKeypessAdnvancedSearch($event)"
        />
        <div class="input-group-append" *ngIf="showSearchButton">
          <app-button
            variant="accent"
            (onClick)="showModalAdvancedSearch($event)"
            [disabled]="fieldConfig?.disabled || control?.disabled"
          >
            <i class="{{ icon }}"></i>
          </app-button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Validations -->
  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>

<p-dialog
  [(visible)]="showModal"
  [closeOnEscape]="true"
  [modal]="true"
  [position]="'center'"
  [resizable]="false"
  [draggable]="false"
  (onHide)="closeModal()"
  class="modal-search-dialog"
>
  <ng-template pTemplate="header">
    <h5 class="modal-title">{{ tituloHeaderModal }}</h5>
  </ng-template>

  <div class="modal-body" [ngClass]="{ 'pb-100': table.data?.length > 0 }">
    <form [formGroup]="filterFormgroup">
      <div class="row" *ngIf="this.busquedaJCValue; else busquedaDefault">
        <div class="col-md-4 form-group">
          <app-generic-input-dropdown [config]="configFilter.estado"></app-generic-input-dropdown>
        </div>
        <div class="col-md-4 form-group">
          <app-generic-input-dropdown [config]="configFilter.municipio"></app-generic-input-dropdown>
        </div>
        <div class="col-md-4 form-group">
          <app-generic-input-dropdown [config]="configFilter.ciudad"></app-generic-input-dropdown>
        </div>
        <div class="col-md-4 form-group">
          <app-generic-input-dropdown [config]="configFilter.empresaFilial"></app-generic-input-dropdown>
        </div>
        <div class="col-md-4 form-group">
          <app-generic-input-dropdown [config]="configFilter.destino"></app-generic-input-dropdown>
        </div>
        <div class="col-md-4 form-group">
          <app-generic-input-text [config]="configFilter.nombreInmueble"></app-generic-input-text>
        </div>
      </div>
      <ng-template #busquedaDefault>
        <div class="row">
          <div class="col-md-4 form-group">
            <app-generic-input-text [config]="configFilter.nombreInmueble"></app-generic-input-text>
          </div>
          <div class="col-md-4 form-group">
            <app-generic-input-dropdown [config]="configFilter.estado"></app-generic-input-dropdown>
          </div>
          <div class="col-md-4 form-group">
            <app-generic-input-dropdown [config]="configFilter.municipio"></app-generic-input-dropdown>
          </div>
          <div class="col-md-4 form-group">
            <app-generic-input-dropdown [config]="configFilter.ciudad"></app-generic-input-dropdown>
          </div>
        </div>
      </ng-template>
      <div class="text-right mb-4">
        <app-button variant="accent" (onClick)="onFilter()"> <i class="fa fa-search"></i> Filtrar </app-button>
      </div>
    </form>

    <app-generic-table [config]="configTabla" #table></app-generic-table>
  </div>

  <p-footer>
    <div class="modal-footer">
      <app-button class="btn-no-boton-accion" variant="gray" (onClick)="cancelar($event)">
        <i class="fas fa-times-circle"></i> Cancelar
      </app-button>
    </div>
  </p-footer>
</p-dialog>
