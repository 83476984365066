import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GenericReactiveFormsModule } from '../generic-reactive-forms/generic-reactive-forms.module';
import { RolesAdminAwareFormComponent } from './components/roles-admin-aware-form.component';
import { RolesAwareFormComponent } from './components/roles-aware-form.component';
import { GenericRolesModulosDirective } from './directives/generic-roles-modulos.directive';
import { GenericRolesDirective } from './directives/generic-roles.directive';
import { GenericRolesGuard } from './guards/generic-roles.guard';
import { GenericRolesService } from './services/generic-roles.service';
import { GenericRolesModulespermissionsService, GenericRolesModulesService } from './services/providers';

@NgModule({
  declarations: [
    GenericRolesDirective,
    GenericRolesModulosDirective,
    RolesAwareFormComponent,
    RolesAdminAwareFormComponent,
  ],
  imports: [CommonModule, GenericReactiveFormsModule],
  exports: [GenericRolesDirective, GenericRolesModulosDirective, RolesAwareFormComponent, RolesAdminAwareFormComponent],
})
export class GenericRolesModule {
  public static forRoot(): ModuleWithProviders<GenericRolesModule> {
    return {
      ngModule: GenericRolesModule,
      providers: [
        GenericRolesGuard,
        GenericRolesService,
        GenericRolesModulesService,
        GenericRolesModulespermissionsService,
      ],
    };
  }
}
