<app-header
  [imgUrl]="imgUrl"
  [user]="user"
  [notifications]="notificaciones"
  [messages]="mensajes"
  [appLogo]="appLogo"
></app-header>
<div class="app-body">
  <app-sidebar *ngIf="haveMenu"></app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <app-breadcrumbs></app-breadcrumbs>
      <!-- Breadcrumb Menu-->
      <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group">
          <a class="btn" [routerLink]="['/pendientes']"
            ><i class="cust_icon-home icono-color fa-1x"></i>&nbsp;Pendientes del Día</a
          >
          <a class="btn" [routerLink]="['/ayuda']"><i class="cust_icon-pregunta icono-color fa-1x"></i>&nbsp;Ayuda</a>
        </div>
      </li>
    </ol>
    <div class="container-fluid">
      <ng-container>
        <div [hidden]="manejoErrorService.existeErrorEnSistema | async">
          <router-outlet></router-outlet>
        </div>

        <!-- Pantalla de error genérica-->
        <div *ngIf="manejoErrorService.existeErrorEnSistema | async">
          <app-error-display></app-error-display>
        </div>
      </ng-container>
    </div>
  </main>
  <app-aside [messages]="mensajes"></app-aside>
  <!-- Dialogo de confirmacion generico -->
  <app-generic-confirm-dialog></app-generic-confirm-dialog>
  <!-- Visor de archivos -->
  <app-visor-archivos></app-visor-archivos>
</div>
<app-footer></app-footer>
