import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SafeResourcePipe } from "./safe-resource.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [SafeResourcePipe],
  exports: [SafeResourcePipe],
})
export class SafeResourceModule {}
