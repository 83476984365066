<header class="app-header navbar">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler *ngIf="$menuData | async">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler *ngIf="$menuData | async">
    <span class="navbar-toggler-icon"></span>
  </button>

  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right" *ngIf="notifications.length > 0">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <i class="icon-bell"></i>
        <span class="badge badge-pill badge-danger">5</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center">
          <strong>Notificaciones</strong>
        </div>
        <ng-container *ngFor="let notification of notifications">
          <a class="dropdown-item" [routerLink]="[notification.url]">
            <i class="fa" [ngClass]="notification.icon"></i>
            {{ notification.descripcion }}
          </a>
        </ng-container>
      </div>
    </li>

    <li class="nav-item d-md-down-none" *ngIf="messages.length > 0">
      <a class="nav-link" href="#" appAsideMenuToggler>
        <i class="icon-envelope-letter"></i><span class="badge badge-pill badge-danger">{{ messages.length }}</span>
      </a>
    </li>

    <li class="nav-item d-sm-down-none text-muted">
      Bienvenido, {{ usuario.nombre + ' ' + (usuario?.apellidopaterno || '') + ' ' + (usuario?.apellidomaterno || '') }}
    </li>

    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href=""
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <img
          [src]="imgUrl | safeResource: 'image'"
          class=""
          height="25"
          alt=""
          onError="this.src='assets/img/avatars/user-a.png'"
        />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Cuenta</strong></div>
        <a class="dropdown-item" href="" (click)="logOut(); (false)"><i class="fa fa-lock"></i> Cerrar sesión</a>
      </div>
    </li>
  </ul>
</header>
