import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { ManejoErroresService } from '@app-shared/services/manejo-errores.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent implements OnInit {
  private config: any;
  public imgUrl = 'assets/img/avatars/user-a.png';
  public appLogo = 'assets/img/logo_w.png';
  public user: any;
  public notificaciones: any[];
  public mensajes: any[];
  public haveMenu: boolean | undefined;

  constructor(private configService: ConfigService, public manejoErrorService: ManejoErroresService) {
    this.notificaciones = [];
    this.mensajes = [];
    this.config = this.configService.getConfig();
  }

  public ngOnInit() {
    this.haveMenu = this.config.haveMenu;
    this.appLogo = this.config.appLogo;
    this.manejoErrorService.escuchaEventosRuta();
  }
}
