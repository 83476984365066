<div class="form-group">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>
  <div class="d-flex">
    <div class="col-md-4">
      <!-- Field -->
      <p-dropdown
        [ngClass]="fieldConfig?.class"
        class="ng-dirty !ng-invalid form-control"
        [name]="fieldConfig.name"
        [inputId]="id"
        [formControl]="control"
        [placeholder]="placeholder"
        [options]="fieldConfig?.options"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        [optionDisabled]="fieldConfig?.optionDisabled"
        [optionGroupLabel]="fieldConfig?.optionGroupLabel"
        [optionGroupChildren]="fieldConfig?.optionGroupChildren"
        [scrollHeight]="scrollHeight"
        [style]="fieldConfig?.style"
        [panelStyle]="fieldConfig?.panelStyle"
        [styleClass]="fieldConfig?.styleClass"
        [panelStyleClass]="fieldConfig?.panelStyleClass"
        [filter]="filter"
        [filterValue]="fieldConfig?.filterValue"
        [filterBy]="filterby"
        [filterMatchMode]="filterMatchMode"
        [filterPlaceholder]="fieldConfig?.filterPlaceholder"
        [filterLocale]="fieldConfig?.filterLocale"
        [emptyFilterMessage]="emptyFilterMessage"
        [emptyMessage]="emptyMessage"
        [resetFilterOnHide]="fieldConfig?.resetFilterOnHide"
        [dropdownIcon]="dropdownIcon"
        [group]="fieldConfig?.group"
        [showClear]="showClear"
        [tooltip]="fieldConfig?.tooltip"
        (onChange)="onChange($event)"
        (onClick)="onClick($event)"
        (onFilter)="onFilter($event)"
        (onFocus)="onFocus($event)"
        (onBlur)="onBlur($event)"
        (onShow)="onShow($event)"
        (onHide)="onHide($event)"
      ></p-dropdown>
    </div>
    <ng-container>
      <div class="col-md-8 input-group search-btn" [ngClass]="fieldConfig?.styleClass" [style]="fieldConfig?.style">
        <input
          pInputText
          type="text"
          readonly
          [value]="fieldConfig?.messageValue"
          placeholder="{{ this.mensajeSinCoincidencias }}"
          [formControl]="value"
          class="form-control p-inputtext"
          [ngClass]="searchClasses"
        />
      </div>
    </ng-container>
  </div>
  <!-- Validations -->
  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>
