<div class="card-header d-flex justify-content-between align-items-center">
  <div class="card-header-title">
    <ng-container *ngIf="headerIcon">
      <i [class]="headerIcon"></i>
    </ng-container>
    <ng-content></ng-content>
  </div>
  <div *ngIf="showActions" class="card-header-actions">
    <ng-container *ngFor="let action of headerActions">
      <ng-container *ngIf="action.variant; else elseBlock">
        <app-button
          [variant]="action.variant"
          [outline]="action.outline"
          [disabled]="isDisabled(action)"
          noresponsivo="true"
          (onClick)="triggerAction($event, action)"
        >
          <i class="{{ action.icon }}"></i>
          <span>&nbsp;{{ action.title }}</span>
        </app-button>
      </ng-container>
      <ng-template #elseBlock>
        <ng-container *ngIf="action.backButton; else elseBlock2">
          <app-button variant="primary" backButton noresponsivo="true" (onClick)="triggerAction($event, action)">
            <i class="fas fa-arrow-left"></i>
          </app-button>
        </ng-container>
        <ng-template #elseBlock2>
          <button
            class="btn"
            [ngClass]="action.classlist"
            title="{{ action.title }}"
            (click)="triggerAction($event, action)"
          >
            <i class="{{ action.icon }}"></i>
            <span>&nbsp;{{ action.title }}</span>
          </button>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</div>
