export const PARAMETROS_PANTALLA_SEGUIMIENTO = {
  MIGA: 'Seguimiento',
  URL: 'pantalla-seguimiento',
  CONSTANTE: {
    MENU_PREDICTAMEN: 'Predictamen',
    MENU_DICTAMEN: 'Dictamen',
    MENU_CERRAR_ASUNTO: 'Cerrar Asunto',
    MENU_EDITAR_ASUNTO: 'Editar Asunto',
    MENU_EDITAR_ASUNTO_COLECTIVO: 'Editar Asunto Colectivo',
    MENU_EDITAR_INMUEBLE: 'Editar Inmueble',
    MENU_TAREAS: 'Tareas',
    MENU_HISTORIAL_SUCESOS: 'Historial Sucesos y Documentos',
    MENU_ESTADO_PROCESAL: 'Estado Procesal',
    MENU_EDITAR_INMUEBLE_ULC: 'Editar Inmueble Cumplimiento',
    MENU_PARAMETRIZAR_DOCUMENTO: 'Parametrizar Documento',
    MENU_CREAR_ASUNTO_ASOCIADO: 'Crear Asunto Asociado',
    ASUNTOS_COLECTIVO: 'asuntos-colectivos',
    AGREGAR_COLABORADORES: 'agregar-colaboradores',
  },
};
