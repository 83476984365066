<ng-container *ngIf="control">
  <div class="text-danger mt-2">
    <div *ngIf="control.errors?.required">{{ mensajeCampoRequerido }}</div>
    <div *ngIf="control.errors?.minlength">
      El Campo Solo Acepta un Mínimo de {{ control.errors?.minlength?.requiredLength }} Caracteres.
    </div>
    <div *ngIf="control.errors?.maxlength">
      El Campo Solo Acepta un Máximo de {{ control.errors?.maxlength?.requiredLength }} Caracteres.
    </div>
    <div *ngIf="validarMensajeInvalidoDefault()">{{ mensajeFormatoInvalido }}</div>
    <div *ngIf="control.errors?.phone">{{ genericvalidators.phone.validatorMessageFn(control) }}</div>
    <div *ngIf="control.errors?.invalid">{{ mensajeInformacionInvalida }}</div>
    <div *ngIf="control?.errors?.fileSizeValidator">
      <span>{{ mensajeTamanoInvalido }}</span>
    </div>
    <div *ngIf="control?.errors?.min">
      <span>El Valor Mínimo del Campo es de {{ control?.errors?.min?.min }}</span>
    </div>
    <div *ngIf="control?.errors?.max">
      <span>El Valor Máximo del Campo es de {{ control?.errors?.max?.max }}</span>
    </div>
    <ng-container *ngFor="let error of errors">
      <div>{{ error }}</div>
    </ng-container>
  </div>
</ng-container>
