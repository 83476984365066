import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericComponentsModule } from '../generic-components/generic-components.module';
import { PrimeNgExportsModule } from '../prime-ng-exports/prime-ng-exports.module';
import { GenericTableComponent } from './components/generic-table.component';
import { TemplateCellComponent } from './components/template-cell.component';
import { TableTemplateDirective } from './directive/table-template.directive';

@NgModule({
  declarations: [GenericTableComponent, TemplateCellComponent, TableTemplateDirective],
  imports: [CommonModule, GenericComponentsModule, PrimeNgExportsModule],
  exports: [GenericTableComponent, TemplateCellComponent, TableTemplateDirective],
})
export class GenericTablesModule {}
