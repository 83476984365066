<app-button variant="primary" #btnModal (onClick)="lgModal.show()" style="visibility: hidden">PDF</app-button>

<div
  bsModal
  #lgModal="bs-modal"
  [config]="modalConfig"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
>
  <div class="modal-dialog modal-custom">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{ nombre }}</h4>
        <app-button variant="primary" (onClick)="cerrarPdf(lgModal)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </app-button>
      </div>
      <div class="modal-body">
        <div id="visorPDF"></div>
      </div>
    </div>
  </div>
</div>
