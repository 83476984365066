<div class="form-group" [formGroup]="innerGroup">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <ng-container *ngIf="!fieldConfig.btnBlock">
    <div class="d-flex flex-row align-items-center">
      <div class="flex-shrink-0">
        <app-button
          variant="primary"
          [disabled]="control?.disabled"
          (onClick)="trigger()"
          (keydown)="onKeyDown($event)"
          (blur)="onBlur($event)"
        >
          <i class="cust_icon-Icono_Subir_Archivo fa-1x"></i> Subir Archivos
        </app-button>
      </div>
      <div class="pl-4 flex-grow-1 file-list">{{ fileName }}</div>
    </div>
  </ng-container>

  <ng-container *ngIf="fieldConfig.btnBlock">
    <div class="btn-block">
      <app-button
        [variant]="variant"
        [disabled]="control?.disabled"
        (onClick)="trigger()"
        (keydown)="onKeyDown($event)"
        (blur)="onBlur($event)"
      >
        <i class="cust_icon-Icono_Subir_Archivo fa-1x"></i>&nbsp; Subir Archivos
      </app-button>
      <div class="pt-2 file-list">{{ fileName }}</div>
    </div>
  </ng-container>

  <!-- Field -->
  <input
    type="file"
    [formControl]="innerControl"
    [accept]="accept"
    [multiple]="multiple"
    (input)="onInput($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"
    #input
  />

  <!-- Validations -->
  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>
