<div class="form-group">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="requiredConfiguration()">*</span> </span>
  </label>
  <div>
    <button class="btn btn-upload" type="button" (click)="open()">
      <i class="cust_icon-Icono_Subir_Archivo fa-1x"></i>
      <span *ngIf="config.showText">Subir Archivos</span>
    </button>
  </div>
  <div class="text-danger">
    <div *ngIf="required()">
      {{ this.mensajeCampoRequerido }}
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="showModal"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [position]="'center'"
  [resizable]="false"
  [draggable]="false"
  class="documents-dialog"
>
  <ng-template pTemplate="header">
    <h5 class="modal-title">Agregar Documentos</h5>
    <div class="p-dialog-header-icons">
      <app-button variant="primary" (onClick)="cancelar($event)">
        <span class="p-dialog-header-close-icon pi pi-times"></span>
      </app-button>
    </div>
  </ng-template>

  <form [formGroup]="uploaderformgroup">
    <p-table
      [value]="documentscontrol.controls"
      [scrollable]="true"
      scrollHeight="300px"
      styleClass="p-datatable-gridlines overlay-scroll"
    >
      <ng-template pTemplate="body" let-document>
        <tr>
          <td style="width: 35%">
            {{ getName(document) }}
          </td>
          <td style="width: 55%">
            <div class="file-row-item">
              <label [for]="getIdentifier(document)">
                <span class="btn btn-upload btn-file">
                  <i class="fa fa-upload"></i>
                  Subir Archivo
                </span>
              </label>
              <input
                [id]="getIdentifier(document)"
                [name]="getIdentifier(document)"
                type="file"
                [accept]="accept"
                [formControl]="getFileControl(document)"
                (change)="changeFile($event, document)"
                class="form-control btn btn-primary"
              />
              <div class="upload-name">
                {{ getControlFileName(document) }}
                <app-button
                  *ngIf="existsDocument(document)"
                  (onClick)="removeFile(document)"
                  variant="gray"
                  large
                  outline
                >
                  <i class="fas fa-times"></i>
                </app-button>
              </div>
            </div>

            <div class="text-danger">
              <div *ngIf="getFileControl(document)?.errors?.requiredFileType">
                <span>{{ mensajeFormatoInvalido }}</span>
              </div>

              <div *ngIf="getFileControl(document)?.errors?.fileSizeValidator">
                <span>{{ mensajeTamanoInvalido }}</span>
              </div>
            </div>
          </td>
          <td>
            <app-button variant="danger" [hidden]="isRequired(document)" (onClick)="removeDocument(document)">
              <i class="pi pi-times"></i>
            </app-button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="p-datatable-gridlines p-datatable add-box">
      <table aria-describedby="Carga de documentos">
        <thead class="d-none">
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="p-datatable-tbody">
          <tr>
            <td style="width: 35%">
              <div class="form-group">
                <p-dropdown
                  name="optionscontrol"
                  required
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  [emptyFilterMessage]="mensajeNoSeEncontraronResultado"
                  [emptyMessage]="mensajeNoSeEncontraronResultado"
                  [options]="options"
                  [optionLabel]="optionLabel"
                  [optionValue]="optionValue"
                  [showClear]="true"
                  placeholder="Seleccionar Documento"
                  [formControl]="optionscontrol"
                >
                </p-dropdown>
              </div>
            </td>
            <td style="width: 55%">
              <div class="form-group">
                <app-button
                  variant="success"
                  class="btn-no-boton-accion"
                  [disabled]="!validOption()"
                  (onClick)="addFile()"
                >
                  <i class="fa fa-plus-circle"></i> Añadir Archivo
                </app-button>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>

  <ng-container *ngIf="control">
    <p-footer>
      <div class="group-buttons float-right contenedor-acciones">
        <app-button class="btn-no-boton-accion" variant="gray" (onClick)="cancelar($event)">
          <i class="fas fa-times-circle"></i> Cancelar
        </app-button>
        <app-button class="btn-no-boton-accion" variant="primary" (onClick)="save()" [disabled]="disableSave()">
          <i class="fa fa-check-circle"></i> Guardar
        </app-button>
      </div>
    </p-footer>
  </ng-container>
</p-dialog>

<p-confirmDialog #cd styleClass="head-mesage-generic-file-upload" key="generic-file-upload-modal-dialog">
  <p-footer>
    <app-button class="btn-no-boton-accion" variant="gray" (onClick)="cd.reject()">
      <i class="fas fa-times-circle"></i> Cancelar
    </app-button>
    <app-button class="btn-no-boton-accion" variant="primary" (onClick)="cd.accept()">
      <i class="fa fa-check-circle"></i> Confirmar
    </app-button>
  </p-footer>
</p-confirmDialog>
