<div class="form-group">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <div class="d-flex">
    <div class="col-md-4">
      <input
        pInputText
        [type]="type"
        [style]="fieldConfig?.inputStyle"
        class="ng-dirty !ng-invalid form-control"
        [ngClass]="fieldConfig?.inputStyleClass"
        [name]="fieldConfig.name"
        [id]="id"
        [autocomplete]="autocomplete"
        [formControl]="control"
        [placeholder]="placeholder"
        [maxlength]="maxlength"
        [minlength]="minlength"
        [pattern]="pattern"
        [spellcheck]="spellcheck"
        [readonly]="fieldConfig?.readonly"
        [max]="max"
        [min]="min"
        [step]="step"
        (keydown.enter)="onChange($event)"
        (onFocus)="onFocus($event)"
        (onBlur)="onBlur($event)"
        (input)="onInput($event)"
        #input
      />
    </div>

    <ng-container *ngIf="showSearch">
      <div class="col-md-8 input-group search-btn" [ngClass]="fieldConfig?.styleClass" [style]="fieldConfig?.style">
        <input
          pInputText
          type="text"
          readonly
          placeholder="{{ this.mensajeSinCoincidencias }}"
          [formControl]="value"
          class="form-control p-inputtext"
          [ngClass]="searchClasses"
        />
        <div class="input-group-append" *ngIf="showSearchButton">
          <app-button variant="accent" (onClick)="onChange($event)" [disabled]="control?.disabled">
            <i class="{{ icon }}"></i>
          </app-button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Validations -->
  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>
