/**
 * Tipos genericos de campos.
 */
export enum GenericInputTypes {
  GenericFileUploadModal,
  GenericForm,
  GenericFormGroup,
  GenericInputCalendar,
  GenericInputDropdown,
  GenericInputFile,
  GenericInputNumber,
  GenericInputSearch,
  GenericInputText,
  GenericInputTextArea,
  GenericInputToggle,
}
