<div class="responsive">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmar Cancelación</h4>
  </div>
  <div class="modal-body text-center">
    <br />
    <h5 class="modal-title">Se detectaron cambios ¿Desea continuar sin guardar?</h5>
    <label> Esta acción no se podrá revertir </label>
  </div>
  <div class="modal-footer">
    <div class="group-buttons float-right">
      <div></div>
      <button class="btn btn-rounded btn-sm btn-light" role="button" (click)="cerrarModal()">
        <i class="fas fa-times-circle"></i> Cancelar
      </button>
      <button type="button" class="btn btn-rounded btn-sm btn-primary" role="button" (click)="aceptar()">
        <i class="fa fa-check-circle"></i> Confirmar
      </button>
    </div>
  </div>
</div>
