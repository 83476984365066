import { EnvironmentProviders, Provider } from '@angular/core';
import { RestringirTipoDirective } from '@app-shared/commons/directives/restringir-tipo.directive';
import { LoadingService } from '@app-shared/services/loading.service';
import { MessagesMainService } from '@app-shared/services/messages-main.service';
import { RestringirTipoDirective as RestringirTipoDirectiveOriginal } from 'src/app/directives/text-common/restringir-tipo.directive';
import { LoadingOverrideService } from 'src/app/services/loading-override.service';
import { MessagesNewMainService } from 'src/app/services/messages-new-main.service';

export function appOverrides(): Array<Provider | EnvironmentProviders> {
  return [
    /** Proveedor de servicio para consumo de toasts */
    {
      provide: MessagesMainService,
      useClass: MessagesNewMainService,
    },
    {
      provide: LoadingService,
      useClass: LoadingOverrideService,
    },
    {
      provide: RestringirTipoDirectiveOriginal,
      useClass: RestringirTipoDirective,
    },
  ];
}