import { Injectable } from '@angular/core';
import { IResponseList, IResponseSingle } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ciudades } from './ciudades';
@Injectable({
  providedIn: 'root',
})
export class CiudadesService {
  /**
   * Crea una instancia de CiudadesService
   * @constructor
   * @param baseService
   */
  public constructor(private baseService: BaseService) {}

  /**
   * Obtiene lista de ciudades
   * @returns - Observable con los datos de la petición
   */
  public getCiudades(): Observable<IResponseList<Ciudades>> {
    return this.baseService.get<IResponseList<Ciudades>>(`${environment.backendURL}/ciudades/`);
  }

  /**
   * Obtiene una ciudad por ID.
   * @param id - Identificador de la ciudad
   * @returns - Observable con los datos de la petición
   */
  public getCiudadIDCiudad(id: number): Observable<IResponseSingle<Ciudades>> {
    return this.baseService.get<IResponseSingle<Ciudades>>(`${environment.backendURL}/ciudades/${id}`);
  }

  /**
   * Obtiene una lista de ciudades por id estado
   * @param id - Identificador del estado
   * @returns  - Observable con los datos de la petición
   */
  public getCiudadIDEstado(id: number): Observable<IResponseList<Ciudades>> {
    return this.baseService.get<IResponseList<Ciudades>>(`${environment.backendURL}/ciudades/estado/${id}`);
  }

  /**
   *
   * @param idEstado - Identificador del estado
   * @param idMunicipio - Identificador del municipio
   * @returns  - Observable con los datos de la petición
   */
  public getMunicipioIDEstadoIDMunicipio(idEstado: number, idMunicipio: number): Observable<IResponseList<Ciudades>> {
    return this.baseService.get<IResponseList<Ciudades>>(
      `${environment.backendURL}/ciudades/estado/${idEstado}` + `/${idMunicipio}`
    );
  }
}
