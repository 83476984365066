<div class="responsive">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ tituloModal }}</h4>
  </div>
  <div class="modal-body text-center">
    <br />
    <h5 class="modal-title">{{ mensajeModal }}</h5>
  </div>
  <div class="modal-footer">
    <div class="group-buttons float-right">
      <div></div>
      <button class="btn btn-sm btn-light" role="button" (click)="cerrarModal()">
        <i class="fas fa-times-circle"></i> Quedarse aquí
      </button>
      <button type="button" class="btn btn-sm btn-primary" role="button" (click)="aceptar()">
        <i class="fa fa-check-circle"></i> Cancelar y salir
      </button>
    </div>
  </div>
</div>
