<div class="form-group">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <!-- Field -->
  <p-inputNumber
    type="number"
    [autocomplete]="autocomplete"
    [ngClass]="fieldConfig?.class"
    class="ng-dirty !ng-invalid form-control"
    [style]="{ width: '100%' }"
    [name]="fieldConfig.name"
    [inputId]="id"
    [formControl]="control"
    [placeholder]="placeholder"
    [max]="max"
    [min]="min"
    [step]="step"
    [showButtons]="fieldConfig?.showButtons"
    [buttonLayout]="fieldConfig?.buttonLayout"
    [locale]="fieldConfig?.locale"
    [mode]="fieldConfig?.mode"
    [prefix]="fieldConfig?.prefix"
    [suffix]="fieldConfig?.suffix"
    [currency]="fieldConfig?.currency"
    [minFractionDigits]="fieldConfig?.minFractionDigits"
    [maxFractionDigits]="fieldConfig?.maxFractionDigits"
    [maxlength]="maxlength"
    [useGrouping]="useGrouping"
    (onFocus)="onFocus($event)"
    (onBlur)="onBlur($event)"
    (onInput)="onInput($event)"
    #input
  ></p-inputNumber>

  <!-- Validations -->
  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>
