import { Component, Injector, OnDestroy } from '@angular/core';
import { MensajesGenerales } from '@app-services/constantes';
import {
  HelperComponentService,
  UnbindCallback,
} from '@app-shared/generic-components/services/helper-component.service';
import { Subject } from 'rxjs';

/**
 * Helper para gestion de formularios.
 *
 * Sucesor espiritual de @see HerramientasFormulario.
 */
@Component({ template: '' })
export abstract class AbstractHelperComponent implements OnDestroy {
  /**
   *
   * @deprecated
   */
  public mensajeCampoRequerido: string = MensajesGenerales.INFO.CAMPO_REQUERIDO;

  /**
   *
   * @deprecated
   */
  public mensajeNoSeEncontraronResultado: string = MensajesGenerales.INFO.NO_RESULTADOS_ENCONTRADOS;

  /**
   *
   * @deprecated
   */
  public mensajeCamposConAsterisco: string = MensajesGenerales.INFO.CAMPOS_CON_ASTERISCO;

  /**
   *
   * @deprecated
   */
  public mensajeSinCoincidencias: string = MensajesGenerales.INFO.SIN_COINCIDENCIAS;

  /**
   *
   * @deprecated
   */
  public mensajeFormatoInvalido: string = MensajesGenerales.VALIDACIONES.MENSAJE_FORMATO_INVALIDO;

  /**
   *
   * @deprecated
   */
  public mensajeTamanoInvalido: string = MensajesGenerales.VALIDACIONES.MENSAJE_TAMANO_INVALIDO;

  /**
   *
   * @deprecated
   */
  public mensajeCantidadInvalida: string = MensajesGenerales.VALIDACIONES.CANTIDAD_INVALIDA;

  /**
   *
   * @deprecated
   */
  public mensajeInformacionInvalida: string = MensajesGenerales.VALIDACIONES.INFORMACION_INVALIDA;

  /**
   *
   * @deprecated
   */
  public mensajeCancelacionCuerpo: string = MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_BODY;

  /**
   *
   * @deprecated
   */
  public mensajeCancelacionEncabezado: string = MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_HEADER;

  /**
   * Subject de destrucción de componente.
   */
  protected destructionSubject$ = new Subject<void>();

  /**
   * Referencia de binding.
   */
  protected removeBindReference: UnbindCallback;

  /**
   * Booleano que indica si el componente tiene cambios detectados.
   */
  protected cambios = false;

  /**
   * Servicio de helper.
   */
  protected helpercomponentservice: HelperComponentService;

  /**
   * Constructor de `AbstractHelperComponentComponent`.
   */
  public constructor(protected injector: Injector) {
    this.helpercomponentservice = this.injector.get(HelperComponentService);
    this.removeBindReference = this.helpercomponentservice.bindComponent(this);
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.removeBindReference();

    this.destructionSubject$.next();
    this.destructionSubject$.complete();
  }

  /**
   * Setea si el componente tiene cambios.
   */
  public setCambios(cambios: boolean) {
    this.cambios = cambios;
  }

  /**
   * Retorna un booleano indicando si el componente tiene cambios.
   */
  public existenCambios(): boolean {
    return this.cambios;
  }
}
