export const MODULOS_BASE = {
  MIGA: 'Modulos Base',
  URL: 'modulos-base',
  CONSTANTES: {
    EXTENSION_IMAGENES: ['jpeg', 'jpg', 'raw', 'png', 'gif'],
    EXTENSION_DOCUMENTOS: [
      'doc',
      'docx',
      'docm',
      'dotx',
      'dotm',
      'xls',
      'xlsx',
      'xlsm',
      'xltx',
      'xltm',
      'ppt',
      'pptx',
      'pptm',
      'potx',
      'potm',
      'ppsx',
      'ppsm',
      'pdf',
      'txt',
      'zip',
    ],
    EXTENSION_VIDEOS: ['asf', 'avi', 'mp4', 'm4v', 'mov', 'mpg', 'mpeg', 'wmv'],
    EXTENSION_AUDIO: ['aiff'],
    EXTENSION_COMPRIMIDO: ['zip'],
    EXTENSION_NO_SOPORTADO: ['xlsb', 'xlam', 'ppam', 'sldx', 'sldm', 'thmx'],
  },
};
