// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { ToasterService } from '@app-toaster';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';

const idpMechanisms = {
  AAD: ['azureAD'],
  SSO: ['huella', 'colaboradorDigital', 'authCode'],
  CST: ['custom'],
};
const getIdpFromAuthMechanism = (authMechanism: string) => {
  return Object.entries(idpMechanisms).reduce((acc, [idp, mechanisms]) => {
    return mechanisms.includes(authMechanism) ? idp : acc;
  }, '' as string);
};

const verifyIdpsEnabled = () => {
  const environmentMechanisms: Record<string, boolean> = environment.authMechanisms;
  return Object.entries(environmentMechanisms).reduce((acc, [mechanism, status]) => {
    if (status) {
      const idp = getIdpFromAuthMechanism(mechanism);
      const registered = acc.some((idpEnabled) => idpEnabled === idp);
      return registered ? acc : [...acc, idp];
    }
    return acc;
  }, [] as Array<string>);
};
const verifyIdpConfiguration = (idp: string) => {
  const environmentIdp: Record<string, any> = environment.authIdp;
  const credentialsIdp: Record<string, string> = environmentIdp[idp];
  return !Object.values(credentialsIdp).some((value: string) => value == null || value.length === 0);
};

@Injectable({
  providedIn: 'root',
})
export class DevguideService {
  private keysMessage: string | undefined;

  constructor(private toasterService: ToasterService, private configService: ConfigService) {}

  public fullConfigVerification() {
    this.keysMessage = `Estas claves de aplicación en el archivo de environment.ts son para fines de <strong>validación del funcionamiento de la plantilla</strong>. Procura <strong>dejar en blanco las claves que no se requieran</strong>.`;
    this.keysMessage += `<br>El mal uso de estas claves conducirá a eliminarse definitivamente, colabora con la comunidad y <strong>solicita tus claves</strong>.`;

    if (environment.authMechanisms.colaboradorDigital) {
      this.recaptchaConfigValidations();
    }
  }

  // ------ Verification Secure Storage Config

  private secureStorageConfigValidations() {
    const noAppId = environment.appId.length === 0;
    if (noAppId) {
      let message = `No se pueden encriptar las claves con Secure Storage sin clave de encriptación appId. Coloque un appId en el archivo de environments.ts.`;
      message += `<br><strong><a href="https://sites.google.com/coppel.com/developers/frameworks/webclient/documentacion?authuser=0#h.36f1dki1h8i0" target="_blank">Características de appId</a></strong>`;
      this.toasterService.showError(message, 'Empty appId');
    }
    return !noAppId;
  }

  // ------ Verification idP Config

  private idpConfigValidations() {
    const idpsEnabled = verifyIdpsEnabled();
    let configStatus = true;
    idpsEnabled.forEach((idp: string) => {
      const fullConfigured = verifyIdpConfiguration(idp);
      if (!fullConfigured) {
        // Verifica que los idP habilitados tengan las claves que requieren
        let message = `Se habilitaron mecanismos de autenticación que dependen de ${idp}. `;
        message += `Configure las credenciales para el Identity Provider ${idp} o deshabilite los mismos en el archivo de environments.ts. `;
        message += `<br><strong><a href="https://sites.google.com/coppel.com/developers/frameworks/webclient/documentacion#h.oerg1eog2jl7" target="_blank">Consultar Indicaciones</a></strong>`;
        this.toasterService.showError(message, `Missing configurations for ${idp}`);
        configStatus = false;
      }
    });
    if (this.configService.getConfig().webApiBaseUrl !== 'http://localhost:3200') {
      // Si el backend no es el fake-api de pruebas ...
      if (idpsEnabled.length === 1 && environment.idpSpecificationRequired === true) {
        // Si existe un solo idP para validar el token, no se necesita especificar el idp en los headers
        let message = `Su autenticación depende solamente del Identity Provider ${idpsEnabled[0]}. `;
        message += `Si es la configuración es definitiva, se recomienda colocar idpSpecificationRequired: false en el archivo de environments.ts. `;
        message += `<br><strong><a href="https://sites.google.com/coppel.com/developers/frameworks/webclient/documentacion#h.u5zzgyjwf7wv" target="_blank">Consultar Indicaciones</a></strong>`;
        this.toasterService.showWarn(message, `Probably unnecessary CORS configuration`);
      }
      if (idpsEnabled.length > 1 && environment.idpSpecificationRequired === false) {
        // Si hay más de un idP para validar el token, se necesita especificar el idP que lo va a gestionar
        let message = `Su autenticación depende de más de un Identity Provider. `;
        message += `Si es la configuración es definitiva, se recomienda colocar idpSpecificationRequired: true en el archivo de environments.ts. `;
        message += `<br><strong><a href="https://sites.google.com/coppel.com/developers/frameworks/webclient/documentacion#h.u5zzgyjwf7wv" target="_blank">Consultar Indicaciones</a></strong>`;
        this.toasterService.showWarn(message, `Necessary IDP specification`);
      }
    }
    return configStatus;
  }

  // ------ Verification idP Keys

  private verifyAADKeys() {
    const keys = environment.authIdp.AAD;
    const isGenuineKeys = keys.clientId !== '11a7a763-48d7-4e32-819e-55fd5b7be8c1';
    if (!isGenuineKeys) {
      const link = `<br><strong><a href="https://sites.google.com/coppel.com/developers/frameworks/webclient/documentacion?authuser=0#h.3k6lbm7gsxmv" target="_blank">Obtener mis claves</a></strong>`;
      this.toasterService.showError(this.keysMessage + link, `Tus claves de configuración para AAD no son legítimas`);
    }
    return isGenuineKeys;
  }

  private verifySSOKeys() {
    const keys = environment.authIdp.SSO;
    const isGenuineKeys = keys.appId !== '8f17b954-6150-4664-b0ba-7a55f9d098bb';
    if (!isGenuineKeys) {
      const link = `<br><strong><a href="https://sites.google.com/coppel.com/developers/frameworks/webclient/documentacion?authuser=0#h.ldojg3ujxpvx" target="_blank">Obtener mis claves</a></strong>`;
      this.toasterService.showError(
        this.keysMessage + link,
        `Tus claves de configuración para Authentication no son legítimas`
      );
    }
    return isGenuineKeys;
  }

  private verifyCaptchaKeys() {
    const keys = environment.recaptcha;
    const isGenuineKeys = keys.siteKey !== '6Le1ct4iAAAAAH1amwsbh0j6BKQZqjPaV3g1e32U';
    if (!isGenuineKeys) {
      const link = `<br><strong><a href="https://sites.google.com/coppel.com/developers/frameworks/webclient/novedades/sso-estatus#h.tiut1eo19fxc" target="_blank">Obtener mis claves</a></strong>`;
      this.toasterService.showError(
        this.keysMessage + link,
        `Tus claves de configuración para reCaptcha V3 no son legítimas`
      );
    }
    return isGenuineKeys;
  }

  // ------ Verification Recaptcha Config

  private recaptchaConfigValidations() {
    if (!environment.recaptcha.siteKey.length) {
      const message = `Se requiere reCAPTCHA V3 para colaboradorDigital pero no se proporcionó un reCAPTCHA Site Key.`;
      this.toasterService.showError(message, `Missing configurations for Recaptcha V3`);
    }
  }
}
