<ng-container *ngFor="let i of confirmations">
  <p-confirmDialog #cd [key]="i" [ngClass]="customClass">
    <p-footer>
      <ng-container *ngIf="getOption(cd, 'rejectVisible')">
        <app-button variant="gray" (onClick)="cd.reject()">
          <i class="fas fa-times-circle"></i> {{ getRejectLabel(cd) }}
        </app-button>
      </ng-container>
      <ng-container *ngIf="getOption(cd, 'acceptVisible')">
        <app-button variant="primary" (onClick)="cd.accept()">
          <i class="fa fa-check-circle"></i> {{ getAcceptLabel(cd) }}
        </app-button>
      </ng-container>
    </p-footer>
  </p-confirmDialog>
</ng-container>
