import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GenericRolesService } from '@app-shared/generic-roles/services/generic-roles.service';
import { deshabilitarRoles } from '@app-shared/generic-roles/utils';
import { LoadingService } from '@app-shared/services/loading.service';
import { SharedService } from '@app-shared/services/shared.services';
import { VisorArchivosService, VisorData } from '@app-shared/services/visor-archivos.service';
import { MODULOS_BASE } from '@app-shared/shared/constantes-modulos-base';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConfiguracionModalVisor, Documento } from './models/documento.interface';

/**
 * Constante cuando no es posible encontrar un documento.
 */
const FILE_NOT_FOUND = `Ha ocurrido un error y no fue posible recuperar el documento, contacte a su administrador para reportar el problema.`;

/**
 * Componente generico para hacer una visualización de un documento en base64 en un modal.
 *
 * :
 * - Incluir parametros  para indicar si el componente debe descargar el archivo.
 * - Incluir parametros para indicar el el componente debe abrir el archivo en una pestaña nueva.
 *
 */
@Component({
  selector: 'app-visor-archivos',
  templateUrl: './visor-archivos.component.html',
  styleUrls: ['./visor-archivos.component.scss'],
})
export class VisorArchivosComponent implements OnInit, OnDestroy {
  /**
   * Booleano que indica si se debe validar los permisos de descarga de documentos.
   */
  @Input()
  public validarPermisosDescarga = true;

  /**
   * Bandera que indica si se permite la descarga de documentos.
   */
  public permitirDescarga: boolean;
  /**
   * Muestra un contenedor con un progressBar para indicar que el archivo está siendo cargado.
   */
  public loading: boolean;
  /**
   * Bandera que indica si mostrar o no el modal.
   */
  public mostrarVisor: boolean;
  /**
   * Url del visor utilizado para visualizar documento.
   */
  public url: string;
  /**
   * Url sanitizada.
   */
  public urlVisor: SafeResourceUrl;
  /**
   * Bandera que indica si el archivo consultado es un documento.
   */
  public esDocumento: boolean;
  /**
   * Bandera que indica si el debe ser consultado desde Google Drive..
   */
  public esArchivoGoogleDrive: boolean;
  /**
   * Bandera que indica si el archivo consultado es una imagen.
   */
  public esImagen: boolean;
  /**
   * Bandera que indica si el archivo consultado es un video.
   */
  public esVideo: boolean;
  /**
   * Bandera que indica si el archivo consultado es un archivo de audio.
   */
  public esAudio: boolean;
  /**
   * Bandera que indica si el archivo consultado es un archivo comprimido.
   */
  public esComprimido: boolean;
  /**
   * Bandera que indica si el archivo consultado es un archivo soportado.
   */
  public esExtensionInvalida: boolean;

  /**
   * Contenido del archivo con formato myme type y base64.
   */
  public contentFile: SafeResourceUrl;
  /**
   * Mime type del archivo.
   */
  public typeFile: string;
  /**
   * Mensaje al ocurrir un error en el archivo consultado.
   */
  public errorConsultaDocumento: string;

  /**
   * Bandera que indica si se está ejecutando el proceso de descarga de archivo.
   */
  public descargandoArchivo: boolean;
  /**
   * Emmitter con id del documento a mostrar.
   */
  @Input() public documento: EventEmitter<string>;

  /**
   * Emmitter con el documeto para  visualizar.
   */
  @Input() private documentoDatos: EventEmitter<Documento>;

  /**
   * Configuracion adicional del modal, opcional.
   */
  @Input() private config: ConfiguracionModalVisor;

  /**
   * Emmitter con boolean que indica si el modal ha sido cerrado.
   */
  @Output() private estaCerradoElModal: EventEmitter<boolean> = new EventEmitter();

  /**
   * Subject para hacer desubscripciones automáticas de observables.
   */
  private unsubscriptor$ = new Subject<void>();
  /**
   * Identificador del archivo consultado en pantalla.
   */
  private idArchivoActual: string;

  /**
   * Guara los datos del documento ya sea por  id o por base64
   */
  private metaDatosDocumento: Documento;

  /**
   * Titulo a colocar en el modal.
   */
  private tituloModal: string;

  /**
   * Crea instancia de VisorArchivosComponent
   * @param sharedService
   * @param sanitizer
   * @param cd
   */
  public constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private loadingService: LoadingService,
    private cd: ChangeDetectorRef,
    private visorService: VisorArchivosService,
    private genericRolesService: GenericRolesService
  ) {
    this.permitirDescarga = environment.permitirDescargaArchivosVisor;
  }

  /**
   * Getter para condición de mostrar u ocultar panel de formatos con iconos.
   */
  public get mostrarContenedorIconos(): boolean {
    return this.esVideo || this.esAudio || this.esComprimido || this.esExtensionInvalida;
  }

  /**
   * Getteer configuración draggable del modal.
   */
  public get configDraggable(): boolean {
    return this.config?.draggable ?? false;
  }

  /**
   * Getteer configuración position del modal.
   */
  public get configPosition(): string {
    return this.config?.position ?? 'center';
  }

  /**
   * Getteer configuración resizable del modal.
   */
  public get configRezisable(): boolean {
    return this.config?.resizable ?? false;
  }

  /**
   * Getteer configuración modal del modal.
   */
  public get configModal(): boolean {
    return this.config?.modal ?? true;
  }

  /**
   * Getteer configuración appendTo del modal.
   */
  public get configAppendTo() {
    return this.config?.appendTo ?? null;
  }

  /**
   * Getter configuración del estilo del modal.
   */
  public get configStyle() {
    return this.config?.style ?? { width: '70vw', height: '85%' };
  }

  /**
   * Getter configuración del estilo del modal.
   */
  public get titulo() {
    return this.config?.tittle ?? this.tituloModal;
  }

  /**
   * Setter de valor titulo.
   */
  public set titulo(titulo: string) {
    this.tituloModal = titulo;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    if (this.documento) {
      this.documento
        .pipe(
          takeUntil(this.unsubscriptor$.asObservable()),
          mergeMap((idDocumento: string) => this.handlerEvaluarPermisosDescarga(idDocumento, idDocumento))
        )
        .subscribe((idDocumento: string) => {
          this.idArchivoActual = idDocumento;

          setTimeout(() => this.consultarArchivo(idDocumento));
        });
    }

    if (this.documentoDatos) {
      this.documentoDatos
        .pipe(
          takeUntil(this.unsubscriptor$.asObservable()),
          mergeMap((documento: Documento) => this.handlerEvaluarPermisosDescarga(documento.id, documento))
        )
        .subscribe((documento: Documento) => {
          this.obtenerArchivo(documento);
        });
    }

    if (!this.documento && !this.documentoDatos) {
      this.visorService.trigger$
        .pipe(
          takeUntil(this.unsubscriptor$.asObservable()),
          mergeMap((data: VisorData) => this.handlerEvaluarPermisosDescarga(data.idDocumento, data))
        )
        .subscribe((data: VisorData) => {
          this.idArchivoActual = data.idDocumento;
          this.consultarArchivo(data.idDocumento);
        });
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.unsubscriptor$.next();
    this.unsubscriptor$.complete();
  }

  /**
   * Cierra el modal de vizor de documentos.
   */
  public cerrarModal(): void {
    this.mostrarVisor = false;
    this.resetDatos();
    this.estaCerradoElModal.emit(true);
  }

  /**
   * Descargar archivo del visor.
   */
  public async descargar(): Promise<void> {
    try {
      this.descargandoArchivo = true;
      if (this.documentoDatos) {
        const stop = this.loadingService.trigger();
        const archivo = await dataUrlToFile(
          this.metaDatosDocumento.documento,
          this.metaDatosDocumento.nombre,
          this.metaDatosDocumento.tipo
        );
        this.ejecutaDescargaAutomatica(archivo);
        stop();
        this.descargandoArchivo = false;
      } else if (this.documento || this.idArchivoActual) {
        this.sharedService
          .descargarArchivo(this.idArchivoActual)
          .pipe(
            takeUntil(this.unsubscriptor$),
            finalize(() => {
              this.descargandoArchivo = false;
            })
          )
          .subscribe(
            (archivo: Blob) => {
              this.ejecutaDescargaAutomatica(archivo);
            },
            () => {
              this.errorConsultaDocumento = FILE_NOT_FOUND;
            }
          );
      }
    } catch (ex) {
      this.errorConsultaDocumento = FILE_NOT_FOUND;
    }
  }

  /**
   * Evalua si se tienen permisos de descara para el archivo en el visor.
   */
  private evaluarPermisosDescarga(idDocumento: string): Observable<boolean> {
    return this.genericRolesService
      .validarDescargaPorUsuarioProceso$(this.genericRolesService.idUsuario, idDocumento)
      .pipe(
        take(1),
        mergeMap((r) => {
          this.permitirDescarga = r?.datos?.descarga ?? false;

          return of(true);
        }),
        catchError((_) => {
          return of(true);
        })
      );
  }

  /**
   * Funcion para wrapper de consumo de evaluar permisos.
   */
  private handlerEvaluarPermisosDescarga<T>(idDocumento: string, data: T): Observable<T> {
    // Por defecto la descarga esta permitida.
    this.permitirDescarga = true;

    let validacion$ = of(data);

    if (!deshabilitarRoles() && this.validarPermisosDescarga) {
      // En el caso de un asunto, desactivamos la descarga.
      this.permitirDescarga = false;

      validacion$ = this.evaluarPermisosDescarga(idDocumento).pipe(mergeMap((r) => of(data)));
    }

    return validacion$;
  }

  /**
   * Dispara la descarga del archivo al ordenador.
   * @param archivo
   */
  private ejecutaDescargaAutomatica(archivo: Blob): void {
    const url = window.URL.createObjectURL(archivo);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.setAttribute('target', 'blank');
    a.href = url;
    a.download = this.titulo;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Ejecuta busqueda en el servicio web de archivo con su identificador.
   */
  private consultarArchivo(idDocumento: string): void {
    try {
      const stop = this.loadingService.trigger();
      this.mostrarVisor = true;
      this.loading = true;
      this.cd.detectChanges();
      this.sharedService
        .getDataVisor(idDocumento)
        .pipe(
          finalize(() => {
            stop();
            this.loading = false;
            this.cd.detectChanges();
          }),
          catchError((error) => {
            this.colocaError(FILE_NOT_FOUND);
            return of(null);
          }),
          takeUntil(this.unsubscriptor$)
        )
        .subscribe((response) => {
          if (!response) {
            return;
          }
          if (response.estado) {
            this.mostrarArchivo(
              response.datos.nombre,
              response.datos.tipo,
              response.datos.contenidoBase64,
              response.datos.urldoc
            );
          } else {
            this.colocaError(FILE_NOT_FOUND);
          }
        });
    } catch (ex) {
      this.colocaError(FILE_NOT_FOUND);
    }
  }

  /**
   * : Documentar
   * @param documento
   */
  private obtenerArchivo(documento: Documento): void {
    const stop = this.loadingService.trigger();
    this.metaDatosDocumento = documento;
    this.idArchivoActual = documento.id;
    this.mostrarVisor = true;
    this.loading = true;
    this.cd.detectChanges();
    this.mostrarArchivo(documento.nombre, documento.tipo, documento.documento, '');
    this.loading = false;
    stop();
    this.cd.detectChanges();
  }

  /**
   * Muestra el archivo a partir de los parametros dados.
   * @param nombre del archivo
   * @param tipo del archivo
   * @param contenido contenido en base64 del archivo.
   * @param urldoc del archivo, si lo tiene.
   */
  private mostrarArchivo(nombre: string, tipo: string, contenido: string, urldoc: string): void {
    this.titulo = nombre;
    this.typeFile = tipo;
    const fileExtension = nombre.split('.').pop();
    const blob = this.convertB64ToBlob(contenido, this.typeFile);
    const blobUrl = URL.createObjectURL(blob);

    this.contentFile = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl + '#toolbar=0');

    if (urldoc && !this.esFormatoNoSoportado(fileExtension)) {
      // Temporal solo para pruebas en lo que se regresa únicamente el ID.
      const arrArchivo = urldoc.split('/');
      let idArchivo = '';
      if (arrArchivo.length > 1) {
        idArchivo = arrArchivo[arrArchivo.length - 1];
      } else {
        idArchivo = urldoc;
      }
      // Indica que es un archivo que se leerá desde google drive.
      this.url = this.sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        `https://docs.google.com/viewer?srcid=${idArchivo}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`
      );
      this.urlVisor = this.url;
      this.esArchivoGoogleDrive = true;
    } else if (
      this.typeFile.toLocaleLowerCase().includes('image') ||
      this.esExtensionImagen(this.typeFile.toLocaleLowerCase())
    ) {
      this.esImagen = true;
    } else if (
      this.typeFile.toLocaleLowerCase().includes('video') ||
      this.esExtensionVideo(this.typeFile.toLocaleLowerCase())
    ) {
      this.esVideo = true;
    } else if (
      this.typeFile.toLocaleLowerCase().includes('audio') ||
      this.esExtensionAudio(this.typeFile.toLocaleLowerCase())
    ) {
      this.esAudio = true;
    } else if (
      this.typeFile.toLocaleLowerCase().includes('zip') ||
      this.esExtensionZip(this.typeFile.toLocaleLowerCase())
    ) {
      this.esComprimido = true;
    } else if (this.esFormatoNoSoportado(fileExtension)) {
      this.esExtensionInvalida = true;
    } else {
      this.esDocumento = true;
    }
  }

  /**
   * Funcion para tratar la decodificacion base 64 de los archivos
   */
  private convertB64ToBlob(b64Data: string, contentType: string) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Función para gestionar error en pantalla.
   */
  private colocaError(error: string): void {
    this.titulo = 'Ocurrió un error';
    this.errorConsultaDocumento = error;
  }

  /**
   * Función para validar si una extensión pertenece al conjunto de formatos de imagen.
   */
  private esExtensionImagen(pExtension: string): boolean {
    const index = MODULOS_BASE.CONSTANTES.EXTENSION_IMAGENES.indexOf(pExtension, 0);
    return index > -1;
  }

  /**
   * Función para validar si una extensión pertenece al conjunto de formatos de videos.
   */
  private esExtensionVideo(pExtension: string): boolean {
    const index = MODULOS_BASE.CONSTANTES.EXTENSION_VIDEOS.indexOf(pExtension, 0);
    return index > -1;
  }

  /**
   * Función para validar si una extensión pertenece al conjunto de formatos de documentos.
   */
  private esExtensionDocumento(pExtension: string): boolean {
    const index = MODULOS_BASE.CONSTANTES.EXTENSION_DOCUMENTOS.indexOf(pExtension, 0);
    return index > -1;
  }

  /**
   * Función para validar si una extensión pertenece al conjunto de formatos de audio.
   */
  private esExtensionAudio(pExtension: string): boolean {
    const index = MODULOS_BASE.CONSTANTES.EXTENSION_AUDIO.indexOf(pExtension, 0);
    return index > -1;
  }

  /**
   * Función para validar si una extensión pertenece al conjunto de formatos de documentos comprimidos.
   */
  private esExtensionZip(pExtension: string): boolean {
    const index = MODULOS_BASE.CONSTANTES.EXTENSION_COMPRIMIDO.indexOf(pExtension, 0);
    return index > -1;
  }

  /**
   * Función para validar si una extensión pertenece al conjunto de formatos de documentos no soportados.
   */
  private esFormatoNoSoportado(pExtension: string): boolean {
    const index = MODULOS_BASE.CONSTANTES.EXTENSION_NO_SOPORTADO.indexOf(pExtension, 0);
    return index > -1;
  }

  /**
   * Reset de los datos y banderas en pantalla.
   * @returns void
   */
  private resetDatos(): void {
    this.errorConsultaDocumento = this.urlVisor = this.url = this.titulo = this.typeFile = '';
    if (this.esDocumento) {
      this.esDocumento = false;
    }
    if (this.esArchivoGoogleDrive) {
      this.esArchivoGoogleDrive = false;
    }
    if (this.esImagen) {
      this.esImagen = false;
    }
    if (this.esVideo) {
      this.esVideo = false;
    }
    if (this.esAudio) {
      this.esAudio = false;
    }
    if (this.esComprimido) {
      this.esComprimido = false;
    }
    if (this.esExtensionInvalida) {
      this.esExtensionInvalida = false;
    }
  }
}

/**
 * Funcion para convertir un base64 a un archivo.
 * @param dataUrl
 * @param fileName
 * @param mimetype
 * @returns
 */
async function dataUrlToFile(dataUrl: string, fileName: string, mimetype: string): Promise<File> {
  const res: Response = await fetch(`data:${mimetype};charset=utf-8;base64,${dataUrl}`);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: mimetype });
}
