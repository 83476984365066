import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GenericComponentsModule } from '../generic-components/generic-components.module';
import { GenericTablesModule } from '../generic-tables/generic-tables.module';
import { PrimeNgExportsModule } from '../prime-ng-exports/prime-ng-exports.module';
import { GenericAdvancedSearchModalComponent } from './components/generic-advanced-search-modal/generic-advanced-search-modal.component';
import { GenericErrorsContainerComponent } from './components/generic-errors-container/generic-errors-container.component';
import { GenericFileUploadModalComponent } from './components/generic-fileupload-modal/generic-file-upload-modal.component';
import { GenericFormDebugComponent } from './components/generic-form-debug/generic-form-debug.component';
import { GenericFormGroupComponent } from './components/generic-form-group/generic-form-group.component';
import { GenericFieldDirective } from './components/generic-form/generic-field.directive';
import { GenericFormComponent } from './components/generic-form/generic-form.component';
import { GenericInputCalendarComponent } from './components/generic-input-calendar/generic-input-calendar.component';
import { GenericInputDropdownSearchComponent } from './components/generic-input-dropdown-search/generic-input-dropdown-search.component';
import { GenericInputDropdownComponent } from './components/generic-input-dropdown/generic-input-dropdown.component';
import { GenericInputFileComponent } from './components/generic-input-file/generic-input-file.component';
import { GenericInputNumberComponent } from './components/generic-input-number/generic-input-number.component';
import { GenericInputSearchComponent } from './components/generic-input-search/generic-input-search.component';
import { GenericInputTextComponent } from './components/generic-input-text/generic-input-text.component';
import { GenericInputTextAreaComponent } from './components/generic-input-textarea/generic-input-textarea.component';
import { GenericInputToggleComponent } from './components/generic-input-toggle-checkbox/generic-input-toggle-checkbox.component';
import { GenericSearchComponentsModule } from '@app-modules/generic-search-components/generic-search-components.module';

@NgModule({
  declarations: [
    GenericInputTextComponent,
    GenericFormGroupComponent,
    GenericInputNumberComponent,
    GenericInputDropdownComponent,
    GenericInputCalendarComponent,
    GenericInputSearchComponent,
    GenericFileUploadModalComponent,
    GenericFormDebugComponent,
    GenericInputTextAreaComponent,
    GenericErrorsContainerComponent,
    GenericFormComponent,
    GenericInputFileComponent,
    GenericInputToggleComponent,
    GenericFieldDirective,
    GenericInputDropdownSearchComponent,
    GenericAdvancedSearchModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericComponentsModule,
    PrimeNgExportsModule,
    GenericTablesModule,
    ModalModule.forRoot(),
    // GenericSearchComponentsModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    GenericInputTextComponent,
    GenericFormGroupComponent,
    GenericInputNumberComponent,
    GenericInputDropdownComponent,
    GenericInputCalendarComponent,
    GenericInputSearchComponent,
    GenericFileUploadModalComponent,
    GenericFormDebugComponent,
    GenericInputTextAreaComponent,
    GenericInputFileComponent,
    GenericInputToggleComponent,
    GenericFormComponent,
    GenericInputDropdownSearchComponent,
    GenericAdvancedSearchModalComponent,
    GenericFieldDirective,
  ],
})
export class GenericReactiveFormsModule {}
