import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { ParametrosModulos } from '@app-services/constantes';
import { GenericRolesGuard } from '@app-shared/generic-roles/guards/generic-roles.guard';
import { environment } from './../environments/environment';
import { FullLayoutComponent } from './containers/full-layout';
import { SimpleLayoutComponent } from './containers/simple-layout/simple-layout.component';
import { AuthGuard } from './guards/Auth.guard';

const isIframe = window !== window.parent && !window.opener;

export const APP_ROUTES: Routes = [
  {
    path: 'login',
    component: SimpleLayoutComponent,
    data: { title: 'Login' },
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard, GenericRolesGuard],
    canActivateChild: [GenericRolesGuard],
    data: { title: 'Home' },
    children: [
      {
        path: 'dashboard',
        data: { title: 'Dashboard' },
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'resources',
        data: { title: 'Resources' },
        loadChildren: () => import('./modules/resources/resources.module').then((m) => m.ResourcesModule),
      },
      {
        path: ParametrosModulos.DATA.MODULOS.EXPENDIENTE_UNICO_INMUBLE.URL,
        loadChildren: () =>
          import('@app-modules/expediente-unico-inmueble/expediente-unico-inmueble.module').then(
            (m) => m.ExpedienteUnicoInmuebleModule
          ),
        data: {
          title: ParametrosModulos.DATA.MODULOS.EXPENDIENTE_UNICO_INMUBLE.MIGA,
        },
      },
      {
        path: ParametrosModulos.DATA.MODULOS.PENDIENTES.URL,
        loadChildren: () =>
          import('@app-modules/pendientes-dia/pendientes-dia.module').then((m) => m.PendientesDiaModule),
        data: {
          title: ParametrosModulos.DATA.MODULOS.PENDIENTES.MIGA,
        },
      },
      {
        path: ParametrosModulos.DATA.MODULOS.ASUNTOS.URL,
        loadChildren: () => import('@app-modules/asuntos/asuntos.module').then((m) => m.AsuntosModule),
        data: {
          title: ParametrosModulos.DATA.MODULOS.ASUNTOS.MIGA,
        },
      },
      {
        path: ParametrosModulos.DATA.MODULOS.CATALOGOS.URL,
        loadChildren: () => import('@app-modules/catalogos/catalogos.module').then((m) => m.CatalogosModule),
        data: {
          title: 'Administración Catálogos',
          nolink: true,
        },
      },
      {
        path: 'roles',
        loadChildren: () => import('@app-modules/roles/roles.module').then((m) => m.RolesModule),
        data: {
          title: 'Roles',
        },
      },
      {
        path: 'reportes',
        loadChildren: () => import('@app-modules/reportes/reportes.module').then((m) => m.ReportesModule),
        data: {
          title: 'Reportes',
        },
      },
      {
        path: 'parametrizacion',
        loadChildren: () =>
          import('@app-modules/parametrizacion/parametrizacion.module').then((m) => m.ParametrizacionModule),
        data: {
          title: 'Parametrización',
        },
      },
      {
        path: ParametrosModulos.DATA.MODULOS.NOTIFICACIONES.URL,
        loadChildren: () =>
          import('@app-modules/notificaciones/notificaciones.module').then((m) => m.NotificacionesModule),
        data: {
          title: ParametrosModulos.DATA.MODULOS.NOTIFICACIONES.MIGA,
          nolink: true,
        },
      },
      {
        path: 'tareas',
        loadChildren: () => import('@app-modules/tareas/tareas.module').then((m) => m.TareasModule),
        data: {
          title: 'Tareas',
          nolink: true,
        },
      },
      {
        path: 'boveda',
        loadChildren: () => import('@app-modules/boveda/boveda.module').then((m) => m.BovedaModule),
        data: {
          title: 'Bóveda',
          nolink: true,
        },
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    if (environment.production) {
      this.router.errorHandler = (_error: any) => {
        this.router.navigate(['/dashboard']); // or redirect to default route
      };
    }
  }
}
