import { Component, Host, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges, SkipSelf } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { HerramientasFormulario } from '@app-models/herramientasFormulario';
import { GenericBaseInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { GenericValidators } from '@app-shared/generic-reactive-forms/validators';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

/**
 * Tipado para @see GenericValidators
 */
type GeGenericValidatorsType = typeof GenericValidators;

/**
 * Clase abstracta para construccion de componentes genericos de formulario.
 */

@Component({ template: '' })
export abstract class AbstractGenericInputComponent<T extends GenericBaseInput<unknown>, E extends AbstractControl>
  extends HerramientasFormulario
  implements OnInit, OnDestroy, OnChanges {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: T;

  /**
   * Input generico de configuración.
   */
  @Input()
  public useGroup: FormGroup;

  /**
   * `FormGroup` de formulario padre.
   */
  protected rootFormGroup: FormGroup;

  /**
   * Emisor de evento de destruccion de clase.
   */
  protected destroySubject$ = new Subject<void>();

  /**
   * Array de validadores internos para el campo.
   */
  protected internalValidators: ValidatorFn[] = [];

  /**
   * Array de validadores internos para el campo.
   */
  protected internalAsyncValidators: AsyncValidatorFn[] = [];

  /**
   * Constructor de clase AbstractGenericInputComponent.
   */
  constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService?: GenericFormService
  ) {
    super();
  }

  public get genericvalidators(): GeGenericValidatorsType {
    return GenericValidators;
  }

  /**
   * Configuracion de campo.
   */
  public get fieldConfig(): T {
    return this.config;
  }

  /**
   * Attributo `id`.
   */
  public get id(): string {
    return 'generic-field--' + this.fieldConfig.name;
  }

  /**
   * Atributo `placeholder`.
   */
  public get placeholder(): string | null {
    if (this.control.disabled) {
      return this.fieldConfig?.placeholder ?? (this.fieldConfig?.label ? this.fieldConfig?.label : null);
    }

    return this.fieldConfig?.placeholder ?? (this.fieldConfig?.label ? `Ingresar ${this.fieldConfig?.label}` : null);
  }

  /**
   * Acceso a control.
   */
  public get control(): E {
    return this.rootFormGroup.get(this.fieldConfig.name) as E;
  }

  /**
   * Clase CSS para un elemento requerido.
   */
  public get labelClasses(): string {
    return this.config.required && 'required-form-element';
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    if (!this.rootFormGroup) {
      this.rootFormGroup = this.formRef?.control ?? this.genericFormService?.form;
    }

    if (this.useGroup) {
      this.rootFormGroup = this.useGroup;
    }

    if (this.fieldConfig?.parentGroup) {
      let tempgroup: FormGroup;

      if (typeof this.fieldConfig?.parentGroup === 'string') {
        tempgroup = this.rootFormGroup.get(this.fieldConfig.parentGroup?.toString()) as FormGroup;
      } else {
        tempgroup = this.fieldConfig.parentGroup;
      }

      if (tempgroup) {
        this.rootFormGroup = tempgroup;
      }
    }

    const controlInstance = new FormControl({
      value: this.fieldConfig?.value ?? null,
      disabled: this.fieldConfig?.disabled,
    });

    // En inicializacion condicional de elementos en un formulario, el disabled no los inhabilita.
    // Esta suscripcion obtiene el ultimo valor de deshabilitacion y corrige el estado del campo.
    if (this.genericFormService) {
      this.genericFormService.disabled$.pipe(take(1)).subscribe((formDisabled) => {
        if (formDisabled === true && formDisabled !== controlInstance.disabled) {
          this.genericFormService.disableControl(formDisabled, controlInstance);
        }
      });
    }

    this.rootFormGroup.addControl(this.fieldConfig.name, controlInstance);
    this.setValidators(controlInstance);
  }

  /**
   * @inheritdoc
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const config = changes.config;
    const configPrev = config?.previousValue ?? {};
    const configCurrent = config?.currentValue ?? {};
    if (
      this.rootFormGroup &&
      this.control &&
      (configPrev?.validators?.length !== configCurrent?.validators?.length ||
        configPrev?.required !== configCurrent?.required)
    ) {
      this.setValidators((this.control as any) as FormControl);
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  /**
   * Sets the validators for the control.
   */
  protected setValidators(control: FormControl): void {
    Promise.resolve().then(() => {
      control.setValidators(
        [
          ...(this.fieldConfig.validators || []),
          ...(this.internalValidators || []),
          this.fieldConfig.required && Validators.required,
        ].filter(Boolean)
      );
      control.setAsyncValidators([
        ...(this.fieldConfig.asyncValidators || []),
        ...(this.internalAsyncValidators || []),
      ]);
      control.updateValueAndValidity();
    });
  }

  /**
   * Retorna un identificado unico para consultar por el campo input.
   * @see https://jsmates.com/blog/generating-simple-unique-identifier-using-javascript
   */
  protected uniqueId(): string {
    return uuid().toString();
  }
}
