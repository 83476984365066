<div class="form-group" *ngIf="fieldConfig && control">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <!-- Field -->
  <div class="form-group--input-wrapper">
    <label class="switch switch-lg switch-text switch-pill switch-success" [ngClass]="classes">
      <input
        type="checkbox"
        class="switch-input"
        [formControl]="control"
        (keydown)="onKeyDown($event)"
        (input)="onInput($event)"
        (blur)="onBlur($event)"
        (change)="onChange($event)"
        (focus)="onFocus($event)"
      />
      <span class="switch-label" [attr.data-on]="yesLabel" [attr.data-off]="noLabel"></span>
      <span class="switch-handle"></span>
    </label>
  </div>

  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>
