import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Column } from '../models/table-model';

@Component({
  selector: 'app-template-cell',
  templateUrl: './template-cell.component.html',
  styleUrls: ['./template-cell.component.scss'],
})
export class TemplateCellComponent implements OnInit {
  /**
   * Container donde se pintará el template.
   */
  @ViewChild('viewContainer', { read: ViewContainerRef, static: true })
  public viewContainer: ViewContainerRef;

  /**
   * Template a pintar.
   */
  @Input()
  public template: TemplateRef<{ data: any; index: number; column: Column }>;

  /**
   * Datos de la columna.
   */
  @Input()
  public column: Column;

  /**
   * Datos de la columna.
   */
  @Input()
  public index: number;

  /**
   * Datos del Row a pintar.
   */
  @Input()
  public row: any;

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    if (this.template) {
      this.viewContainer?.createEmbeddedView(this.template, { data: this.row, index: this.index, column: this.column });
    }
  }
}
