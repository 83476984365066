import { Component, Input } from '@angular/core';
import { CardBodyConfig } from '@app-shared/generic-components/models/card-models';
import { Memoize } from '@app-shared/generic-reactive-forms/utils/memoize.decorator';

@Component({
  selector: 'app-card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.scss'],
})
export class CardBodyComponent {
  /**
   * Configuración para header.
   *
   * @deprecated Usar el parametro no-padding para ajustar el padding de la tarjeta.
   */
  @Input()
  public config: CardBodyConfig = {} as CardBodyConfig;

  /**
   * Setter para deshabilitar padding.
   */
  @Input('no-padding')
  public set noPadding(attribute) {
    this.noPadding.disablePadding = attribute === '' || (attribute as boolean);
  }

  /**
   * Listado de clases del componente.
   */
  @Memoize
  get classlist(): object {
    return {
      'no-padding': this.noPadding?.disablePadding,
    };
  }
}
