import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '@app-loader';
import { ToasterService } from '@app-toaster';
import { SecureStorageService } from './secure-storage.service';

import { AadService } from './idps/aad.service';
import { CustomService } from './idps/custom.service';
import { SsoService } from './idps/sso.service';

const idpMechanisms = {
  AAD: ['azureAD'],
  SSO: ['huella', 'colaboradorDigital', 'authCode'],
  CST: ['custom'],
};
const getIdpFromAuthMechanism = (authMechanism: string) => {
  return Object.entries(idpMechanisms).reduce((acc, [idp, mechanisms]) => {
    return mechanisms.includes(authMechanism) ? idp : acc;
  }, '' as string);
};

@Injectable()
export class AuthenticationService {
  constructor(
    private router: Router,
    private storageService: SecureStorageService,
    private toasterService: ToasterService,
    private loaderService: LoaderService,
    private aadService: AadService,
    private ssoService: SsoService,
    private customService: CustomService
  ) {}

  /**
   * Obtiene el token de sesion.
   */
  public get token(): string {
    return this.storageService.getItem('token');
  }

  // ------ Bloque de métodos de lógica de autenticación

  public login(authMechanism: string, credentials: any, captchaToken?: string) {
    const idp = getIdpFromAuthMechanism(authMechanism);
    if (idp.length) {
      if (idp !== 'AAD') {
        this.loaderService.setVisibleState(true);
      }

      switch (authMechanism) {
        case 'azureAD':
          this.storageService.setItem('idp', idp);
          this.aadService.login();
          break;

        case 'huella':
          this.ssoService.loginHuella(credentials).subscribe({
            next: (token) => this.setLogin(idp, token),
            error: (error) => this.showError(error),
          });
          break;

        case 'colaboradorDigital':
          credentials.recaptcha = captchaToken;
          this.ssoService.loginColaboradorDigital(credentials).subscribe({
            next: (token) => this.setLogin(idp, token),
            error: (error) => this.showError(error),
          });
          break;

        case 'authCode':
          this.ssoService.loginAuthCode(credentials).subscribe({
            next: (token) => this.setLogin(idp, token),
            error: (error) => this.showError(error),
          });
          break;

        case 'custom':
          credentials.recaptcha = captchaToken;
          this.customService.login(credentials).subscribe({
            next: (token) => {
              this.setLogin(idp, token);
            },
            error: (error) => this.showError(error),
          });
          break;
      }
    } else {
      this.showError('No existe configuración para este Identity Provider');
    }
  }

  // ------ Actions

  private setLogin(idp: string, token: string, redirect = true) {
    this.loaderService.setVisibleState(false);
    this.storageService.setItem('idp', idp);
    this.storageService.setItem('token', token);
    if (redirect) {
      this.router.navigate(['']);
    }
  }

  private showError(error: string) {
    this.toasterService.showError(error);
    this.loaderService.setVisibleState(false);
  }
}
