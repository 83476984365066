<div *ngIf="toasts.length > 0" class="toaster-container" [ngClass]="classContainer">
  <ng-container *ngFor="let toast of toasts; index as i">
    <div class="toaster" [ngClass]="classToast(toast)">
      <header>
        <span class="tag">
          {{ toast.type }}
        </span>
        <span class="close" (click)="toast.close()"> ✖ </span>
      </header>
      <article>
        <h5 *ngIf="toast.title">{{ toast.title }}</h5>
        <div class="toaster-body" [innerHTML]="toast.body"></div>
      </article>
    </div>
  </ng-container>
</div>
