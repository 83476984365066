// tslint:disable: directive-selector

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { GenericRolesService } from '../services/generic-roles.service';

@Directive({
  selector: '[genericRolesPermiso]',
})
export class GenericRolesDirective {
  /**
   * Constructor de clase `GenericRolesDirective`.
   */
  public constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private genericRoles: GenericRolesService
  ) {}

  /**
   * Ambientes en los que se debe mostrar el template.
   */
  @Input()
  public set genericRolesPermiso(items: (string | number)[]) {
    const r = this.genericRoles.validarPermisos(items.map((i) => i.toString()));

    if (r) {
      this.triggerShow();
    }
  }

  /**
   * Muestra el template.
   */
  private triggerShow(): void {
    this.container.createEmbeddedView(this.templateRef);
  }
}
