import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaV3Module } from '@app-recaptcha';

import { LoginComponent } from './components/login/login.component';
import { LoginCDigitalComponent } from './components/login-cdigital/login-cdigital.component';

import { AadService } from 'src/app/services/idps/aad.service';
import { SsoService } from 'src/app/services/idps/sso.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomService } from 'src/app/services/idps/custom.service';
import { LoginCustomComponent } from './components/login-custom/login-custom.component';

import { HuellaModule, HuellaService } from '@oc/ngx-huella';
import { LoginHuellaComponent } from './components/login-huella/login-huella.component';
import { ReestablecerContrasenaComponent } from './components/reestablecer-contrasena/reestablecer-contrasena.component';
import { RecuperarContrasenaComponent } from './components/recuperar-contrasena/recuperar-contrasena.component';
import { ACCIONES_LOGIN } from '@app-models/login.interface';
import { DeterminarPasswordGuard } from '@app-guards/determinar-password.guard';
import { environment } from 'src/environments/environment';

const APP_ROUTES: Routes = [
  {
    path: environment.inHouse[0] ? 'v2' : '',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  {
    path: 'v2/recuperar-contrasena',
    component: RecuperarContrasenaComponent,
    data: {
      title: 'Recuperar Password',
      accion: ACCIONES_LOGIN.RECUPERAR_PASSWORD,
    },
  },
  {
    path: 'v2/recuperar-contrasena/:usuario',
    component: ReestablecerContrasenaComponent,
    data: {
      title: 'Recuperar Password',
      accion: ACCIONES_LOGIN.CAMBIO_PASSWORD,
    },
  },
  {
    path: 'v2/determinar-contrasena/:usuario',
    component: ReestablecerContrasenaComponent,
    data: {
      title: 'Determinar Password',
      accion: ACCIONES_LOGIN.CAMBIO_PASSWORD,
    },
    canActivate: [DeterminarPasswordGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RecaptchaV3Module,
    HuellaModule,
    RouterModule.forChild(APP_ROUTES),
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LoginComponent,
    LoginCDigitalComponent,
    LoginCustomComponent,
    LoginHuellaComponent,
    ReestablecerContrasenaComponent,
    RecuperarContrasenaComponent,
  ],
  providers: [AadService, SsoService, CustomService, AuthenticationService, HuellaService],
})
export class LoginModule {}
