import { Component, OnInit, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { SessionService } from "src/app/state/session.service";
import { MenuService } from "src/app/services/menu.service";
import { CurrentUser } from "@app-models/currentUser.interface";
import { GeneralesService } from '@app-services/utils/generales.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
})
export class AppHeaderComponent implements OnInit {
  @Input() public imgUrl: string;
  @Input() public appLogo: string;
  @Input() public user: any;
  @Input() public notifications: any[];
  @Input() public messages: any[];

  public subscription: Subscription;
  public $menuData;
  public usuario: CurrentUser;

  constructor(
    private menuService: MenuService,
    private session: SessionService,
    public generales: GeneralesService
  ) {
    this.$menuData = this.menuService.currentMenu;
    this.usuario = this.session.getCurrentUser();
    this.usuario.nombre = this.generales.capitalizarPrimeraLetra(this.usuario.nombre);
    if (this.usuario.apellidopaterno) {
      this.usuario.apellidopaterno = this.generales.capitalizarPrimeraLetra(
        this.usuario.apellidopaterno
      );
    }
    if (this.usuario.apellidomaterno) {
      this.usuario.apellidomaterno = this.generales.capitalizarPrimeraLetra(
        this.usuario.apellidomaterno
      );
    }
  }

  public logOut() {
    this.session.logout();
  }

  public ngOnInit() {
    if (document.querySelector('app-root')) {
      document.querySelector('app-root').classList.add('aside-menu-hidden');
    }
  }
}
