import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Interfaz de datos para visor.
 */
export interface VisorData {
  /**
   * ID de documento a visualizar.
   */
  idDocumento: string;

  /**
   * ID del asunto relacionado.
   */
  validarPermisos?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VisorArchivosService {
  /**
   * Subject para emision de eventos.
   */
  private triggerSubject$ = new Subject<VisorData>();

  /**
   * Observable para emision de datos del visor.
   */
  public get trigger$(): Observable<VisorData> {
    return this.triggerSubject$.asObservable();
  }

  /**
   * Disparador de la visualizacion de documentos.
   */
  public trigger(idDocumento: string, validarPermisos = false) {
    this.triggerSubject$.next({ idDocumento, validarPermisos });
  }
}
