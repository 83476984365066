import { Component, ElementRef, Host, Input, OnDestroy, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { genericRestrictHandler } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/generic-restrict-handler';
import { GenericTextAreaInput } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { whiteSpaceValidator } from '@app-shared/generic-reactive-forms/validators/whitespace';

@Component({
  selector: 'app-generic-input-textarea',
  templateUrl: './generic-input-textarea.component.html',
  styleUrls: ['./generic-input-textarea.component.scss'],
})
export class GenericInputTextAreaComponent
  extends AbstractGenericInputComponent<GenericTextAreaInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericTextAreaInput;

  /**
   * Referencia del input.
   */
  @ViewChild('input', { static: false })
  public input: ElementRef<HTMLTextAreaElement>;

  /**
   * Constructor de clase GenericInputTextComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService
  ) {
    super(formRef, genericFormService);
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.internalValidators = [whiteSpaceValidator];

    super.ngOnInit();
  }

  /**
   * Focus event handler.
   */
  public onFocus(e: unknown): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.focus.bind(this),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Blur event handler.
   */
  public onBlur(e: unknown): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.focus.bind(this),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Input event handler.
   */
  public onChange(e: unknown): void {
    if (this.fieldConfig?.onChange) {
      this.fieldConfig?.onChange(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.focus.bind(this),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Key-down event handler.
   */
  public onKeyDown(e: unknown): void {
    if (this.fieldConfig?.onKeyDown) {
      this.fieldConfig?.onKeyDown(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.focus.bind(this),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Key-down event handler.
   */
  public onInput(e: KeyboardEvent): void {
    if (this.fieldConfig?.restrict) {
      genericRestrictHandler<KeyboardEvent>(this.fieldConfig.restrict, e, this.control);
    }

    if (this.fieldConfig?.onInput) {
      this.fieldConfig?.onInput(e, {
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        validate: () => this.control.updateValueAndValidity(),
        focus: () => this.focus.bind(this),
        setValue: (value) => this.control.setValue(value),
      });
    }
  }

  /**
   * Trigger para focus en campo.
   */
  private focus(): void {
    this.input?.nativeElement.focus();
  }
}
