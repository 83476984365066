import { Directive, ElementRef, Input } from "@angular/core";
import { LoaderService } from "../services/loader.service";

@Directive({
  selector: "[appLoader]",
})
export class LoaderDirective {
  private overlayElement: HTMLElement;
  private loaderElement: HTMLElement;

  @Input() set showLoader(value: boolean) {
    this.loaderService.setVisibleState(value, this.overlayElement);
  }
  @Input() set spinnerSize(value: string) {
    this.loaderService.setSpinnerSize(value, this.loaderElement);
  }
  @Input() set overlayColor(value: string) {
    this.loaderService.setBackgroundColor(value, this.overlayElement);
  }

  constructor(
    private el: ElementRef,
    private loaderService: LoaderService,
  ) {
    this.el.nativeElement.style.position = "relative";
    this.overlayElement = this.loaderService.createOverlay();
    this.loaderElement = this.loaderService.createLoader();
    this.overlayElement.appendChild(this.loaderElement);
    this.el.nativeElement.appendChild(this.overlayElement);
  }
}
