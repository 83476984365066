<footer class="app-footer">
  <span>{{ footerTitle }}&copy; {{ copyRightYear }}</span>

  <span>
    &nbsp;
    <i
      class="fas fa-circle-info"
      style="font-size: 0.9rem"
      aria-hidden="true"
    ></i>
    &nbsp;
    {{ version }} <span *ngIf="!isProd">[{{ envName }}]</span>
  </span>
</footer>
