import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[textarea]',
  host: { '(input)': '$event' },
})
// tslint:disable-next-line: directive-class-suffix
export class LetrasNumerosCaracteresEspeciales {
  public lastValue: string;

  constructor(private element: ElementRef) {}

  @HostListener('input', ['$event'])
  public onInput(event) {
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
    event.target.setSelectionRange(start, end);
    event.preventDefault();

    if (!this.lastValue || (this.lastValue && event.target.value.length > 0 && this.lastValue !== event.target.value)) {
      const initalValue = this.element.nativeElement.value;
      this.element.nativeElement.value = initalValue.replace(/[^-A-ZÑÁÉÍÓÚ0-9a-zñáéíóú,.:;"()¿?¡! ]*/g, '');
      this.lastValue = this.element.nativeElement.value;
      const evt = new Event('input', { bubbles: false, cancelable: true });
      if (this.lastValue) {
        event.target.dispatchEvent(evt);
      }
    }
  }
}
