import { ProcesoAsuntos } from '@app-modules/asuntos/models/enums/proceso-asuntos.enum';
import { FiltroCallback } from '@app-shared/filtro-datos/filtrodatos.decorator';
import { IResponseSingle } from '@app-shared/models/IResponse';
import { of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { RolUsuarioGuardarDTO } from '../models/roles.models';
import { RolesService } from './roles.service';

const BLACKLIST = [
  ProcesoAsuntos.ALINEAMIENTO_NO_OFICIAL,
  ProcesoAsuntos.RENOVACION_LICENCIAS_PERMISOS,
  ProcesoAsuntos.LICENCIAS_Y_PERMISOS,
];

/**
 * Filtro para datos del vinculo rol-usuario.
 */
export const filtroUsuarioDatos: FiltroCallback<IResponseSingle<RolUsuarioGuardarDTO>> = (inject, data) => {
  const rolesService = inject(RolesService);

  if (!data?.datos?.usuarioRolProceso) {
    return of(data);
  }

  let flujosFiltrados = data?.datos?.usuarioRolProceso ?? [];

  return rolesService.obtenerFlujos$().pipe(
    take(1),
    mergeMap((r) => {
      const procesosCanonicos = r?.datos?.response ?? [];
      flujosFiltrados = flujosFiltrados.filter((i) => {
        const proceso = procesosCanonicos.find((p) => p.idFlujo === i.idFlujo);
        return proceso ? !BLACKLIST.includes(proceso.idProceso) : false;
      });

      data.datos.usuarioRolProceso = flujosFiltrados;
      return of(data);
    })
  );
};
