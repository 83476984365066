import { Injectable } from '@angular/core';
import { IResponseList, IResponseSingle } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Estados } from './estados';

@Injectable({
  providedIn: 'root',
})
export class EstadosService {
  constructor(private baseService: BaseService) {}

  public getEstados(): Observable<IResponseList<Estados>> {
    return this.baseService.get<IResponseList<Estados>>(`${environment.backendURL}/estados/`);
  }

  public getEstadoID(id: number): Observable<IResponseSingle<Estados>> {
    return this.baseService.get<IResponseSingle<Estados>>(`${environment.backendURL}/estados/${id}`);
  }

  public getEstadoIDPais(id: number): Observable<IResponseList<Estados>> {
    return this.baseService.get<IResponseList<Estados>>(`${environment.backendURL}/estados/pais/${id}`);
  }
}
