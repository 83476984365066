<div class="form-group" *ngIf="fieldConfig">
  <!-- Labels -->
  <label [attr.for]="id" *ngIf="fieldConfig?.label">
    <span [ngClass]="labelClasses"> {{ fieldConfig?.label }}<span *ngIf="fieldConfig.required">*</span> </span>
  </label>

  <!-- Field -->
  <p-dropdown
    [disabled]="fieldConfig?.disabled"
    [ngClass]="fieldConfig?.class"
    class="ng-dirty !ng-invalid form-control"
    [style]="{ width: '100%' }"
    [name]="fieldConfig.name"
    [inputId]="id"
    [formControl]="control"
    [placeholder]="placeholder"
    [options]="fieldConfig?.options"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [optionDisabled]="fieldConfig?.optionDisabled"
    [optionGroupLabel]="fieldConfig?.optionGroupLabel"
    [optionGroupChildren]="fieldConfig?.optionGroupChildren"
    [scrollHeight]="scrollHeight"
    [style]="fieldConfig?.style"
    [panelStyle]="fieldConfig?.panelStyle"
    [styleClass]="fieldConfig?.styleClass"
    [filter]="filter"
    [filterValue]="fieldConfig?.filterValue"
    [filterBy]="filterby"
    [filterMatchMode]="filterMatchMode"
    [filterPlaceholder]="fieldConfig?.filterPlaceholder"
    [filterLocale]="fieldConfig?.filterLocale"
    [emptyMessage]="emptyMessage"
    [emptyFilterMessage]="emptyFilterMessage"
    [resetFilterOnHide]="fieldConfig?.resetFilterOnHide"
    [dropdownIcon]="dropdownIcon"
    [group]="fieldConfig?.group"
    [showClear]="showClear"
    [tooltip]="fieldConfig?.tooltip"
    (onClick)="onClick($event)"
    (onFilter)="onFilter($event)"
    (onFocus)="onFocus($event)"
    (onBlur)="onBlur($event)"
    (onShow)="onShow($event)"
    (onHide)="onHide($event)"
    appendTo="body"
    [panelStyleClass]="panelStyleClass"
  ></p-dropdown>

  <!-- Validations -->
  <app-generic-errors-container [control]="control"></app-generic-errors-container>
</div>
