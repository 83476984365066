import { noWhitespaceValidator, noWhitespaceValidatorMessage, removeSpaces } from './blankspacevalidator';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { PhoneValidator, PhoneValidatorMessage } from './phonevalidator';

interface Validator {
  validator: ValidatorFn;
  validatorMessage?: string;
  validatorMessageFn?: (control: FormControl | AbstractControl) => string;
}

export const GenericValidators = {
  phone: {
    validator: PhoneValidator as ValidatorFn,
    validatorMessageFn: PhoneValidatorMessage,
  } as Validator,

  blankSpaces: {
    validator: noWhitespaceValidator as ValidatorFn,
    validatorMessageFn: noWhitespaceValidatorMessage,
  } as Validator,

  /**
   * Validator para eliminar los espacios en blanco
   */
  removeSpaceAtFirst: {
    validator: removeSpaces as ValidatorFn,
  } as Validator,
};
