import { Injectable } from "@angular/core";

@Injectable()
export class LoaderService {
  private fullContainer;
  private overlayElement;
  private loaderElement;

  constructor() {
    this.fullContainer = document.body;
    this.overlayElement = this.createOverlay();
    this.overlayElement.style.position = "fixed";
    this.loaderElement = this.createLoader();
    this.overlayElement.appendChild(this.loaderElement);
    this.fullContainer.appendChild(this.overlayElement);
  }

  /**
   * createOverlay
   */
  public createOverlay(full = false) {
    const overlayElement = document.createElement("div");
    overlayElement.style.position = full ? "fixed" : "absolute";
    overlayElement.style.display = "none";
    overlayElement.style.justifyContent = "center";
    overlayElement.style.alignItems = "center";
    overlayElement.style.top = "0";
    overlayElement.style.left = "0";
    overlayElement.style.width = "100%";
    overlayElement.style.height = "100%";
    overlayElement.style.zIndex = "9999";
    overlayElement.style.backgroundColor = "rgb(255, 255, 255, 0.5)";
    return overlayElement;
  }
  /**
   * createLoader
   */
  public createLoader() {
    const loaderElement = document.createElement("div");
    loaderElement.setAttribute("role", "status");
    loaderElement.classList.add("spinner-border");
    loaderElement.classList.add("text-primary");
    loaderElement.style.width = "12vh";
    loaderElement.style.height = "12vh";
    return loaderElement;
  }

  /**
   * setVisibleState
   */
  public setVisibleState(
    show: boolean,
    overlayElement: HTMLElement = this.overlayElement,
  ) {
    if (show) {
      overlayElement.style.display = "flex";
    } else {
      overlayElement.style.display = "none";
    }
  }
  /**
   * setBackgroundColor
   */
  public setBackgroundColor(
    value: string,
    overlayElement: HTMLElement = this.overlayElement,
  ) {
    overlayElement.style.backgroundColor = value;
  }
  /**
   * setSpinnerSize
   */
  public setSpinnerSize(
    value: string,
    loaderElement: HTMLElement = this.loaderElement,
  ) {
    loaderElement.style.width = value;
    loaderElement.style.height = value;
  }
}
