// tslint:disable: max-line-length
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IResponseAction, IResponsePage, IResponsePagedList, IResponseSingle } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListaColonias } from '../colonia/colonia.model';
import { FechasAperturaULC, InmuebleULC } from './inmuebleULC';
import { InmuebleDataTable, Inmuebles, InmueblesBusquedaParams, ResultadosBusquedaInmueblesDTO } from './inmuebles';

@Injectable({
  providedIn: 'root',
})
export class InmueblesService {
  /**
   * : Documentar
   */
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Constructor de clase `InmueblesService`.
   */
  public constructor(private baseService: BaseService) {}

  /**
   * Busqueda parametrica de inmuebles.
   */
  public busqueda(
    params: InmueblesBusquedaParams
  ): Observable<IResponseSingle<IResponsePage<ResultadosBusquedaInmueblesDTO>>> {
    return this.baseService.post<IResponseSingle<IResponsePage<ResultadosBusquedaInmueblesDTO>>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}/busqueda`,
      params
    );
  }

  /**
   * Busqueda parametrica de inmuebles.
   */
  public busquedaNuevos(
    params: InmueblesBusquedaParams
  ): Observable<IResponseSingle<IResponsePage<ResultadosBusquedaInmueblesDTO>>> {
    return this.baseService.post<IResponseSingle<IResponsePage<ResultadosBusquedaInmueblesDTO>>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}/busquedaInmuebles`,
      params
    );
  }

  /**
   * Consulta un inmueble por ID.
   * @param id
   * @returns
   */
  public obtenerInmueblePorId(id): Observable<any> {
    return this.baseService.post<any>(`${environment.backendURL}${environment.urls.inmueble_bis}/porIdInmueble`, {
      idInmueble: id,
    });
  }

  /**
   * : Documentar
   */
  public obtenerInmueblePorAsuntoId(id): Observable<any> {
    return this.baseService.get<any>(`${environment.backendURL}/aas/asunto/${id}/asociado`);
  }

  /**
   * : Documentar
   */
  public obtenerInmueblesAddress(filters: any): Observable<any> {
    return this.baseService.post<any>(
      `${environment.backendURL}${environment.urls.inmueble_bis}${environment.urls.busqueda}`,
      filters
    );
  }

  /**
   * Método que invoca al servicio para consultar un inmueble de cumplimiento regulatorio (ULC) por idproyecto
   * @param pIdInmueble identificador del inmueble.
   * @returns Observable con los datos de respuesta del servicio.
   */
  public obtenerInmuebleULCPorIdProyecto(pIdProyecto: number): Observable<IResponseSingle<InmuebleULC>> {
    return this.baseService.get<IResponseSingle<InmuebleULC>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}${environment.urls.cumplimientoRegulatorio}${environment.urls.proyecto}/${pIdProyecto}`
    );
  }

  /**
   * Método que invoca al servicio para actualizar un inmueble de cumplimiento regulatorio (ULC)
   * @param pInmueble Objeto con los datos a actualizar.
   * @param pIdProyecto Identificador del proyecto relacionado al inmueble.
   * @returns
   */
  public actualizarInmuebleULC(pInmueble: InmuebleULC): Observable<IResponseAction> {
    return this.baseService.put<IResponseAction>(
      `${environment.backendURL}${environment.urls.inmueble_bis}${environment.urls.cumplimientoRegulatorio}${environment.urls.proyecto}`,
      pInmueble,
      { headers: this.httpHeaders }
    );
  }

  /**
   * M;etodo para invocar servicio de edición de inmuebles de CR.
   * @returns
   */
  public editarInmuebleCR(pInmueble: InmuebleULC): Observable<IResponseAction> {
    return this.baseService.post<IResponseAction>(
      `${environment.backendURL}/cr/alineamiento/editar/inmueble`,
      pInmueble,
      { headers: this.httpHeaders }
    );
  }

  /**
   *
   * @param CP
   * @returns
   */
  public obtenerInfoSEPOMEX(CP): Observable<IResponseSingle<ListaColonias>> {
    return this.baseService.get<IResponseSingle<ListaColonias>>(`${environment.backendURL}/sepomex/validarCP/${CP}`);
  }

  /**
   * Ejecuta servicio para consultar inmuebles por nombre.
   * @param filtro
   * @param pagina
   * @param limite
   * @returns {Observable<IResponsePagedList<InmuebleDataTable>}
   */
  public obtieneInmueblesPorNombre(
    filtro: string,
    pagina: number,
    limite: number
  ): Observable<IResponsePagedList<InmuebleDataTable>> {
    return this.baseService.get<IResponsePagedList<InmuebleDataTable>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}/buscar-por-nombre?${filtro}&pagina=${pagina}&limite=${limite}`
    );
  }

  /**
   * Consulta inmueble por numero de tienda
   * @param numeroTienda
   * @param idProceso
   * @returns {Observable<IResponseSingle<unknown>> }
   */
  public obtieneInmueblePorNumeroTienda(
    numeroTienda: number,
    idProceso?: number
  ): Observable<IResponseSingle<Inmuebles>> {
    if (!idProceso) {
      idProceso = 0;
    }
    return this.baseService.get<IResponseSingle<Inmuebles>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}/buscar-por-numero-tienda/${numeroTienda}/${idProceso}`
    );
  }

  /**
   * Consulta un inmueble cerrado por ID de tienda.
   *
   * @param id
   * @returns { Observable<IResponseSingle<Inmuebles>>}
   */
  public obtenerInmuebleCerradoPorIdTienda(idTienda: number | string): Observable<IResponseSingle<Inmuebles>> {
    return this.baseService.get<IResponseSingle<Inmuebles>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}/${idTienda}${environment.urls.obtenerInmuebleCerrado}`
    );
  }

  /**
   * Consulta un inmueble cerrado por ID de tienda.
   *
   * @param id
   * @returns { Observable<IResponseSingle<Inmuebles>>}
   */
  public obtenerInmueblePorIdRenta(
    idTienda: number | string,
    idProceso: number = null
  ): Observable<IResponseSingle<Inmuebles>> {
    return this.baseService.get<IResponseSingle<Inmuebles>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}/${idTienda}${environment.urls.obtenerInmuebleCerrado}?idProceso=${idProceso}`
    );
  }

  /**
   * Consulta un inmueble por ID de tienda.
   * @param id
   * @returns { Observable<IResponseSingle<Inmuebles>>}
   */
  public obtenerInmueblePorIdTienda(
    idTienda: number | string,
    idProceso: number
  ): Observable<IResponseSingle<Inmuebles>> {
    return this.baseService.get<IResponseSingle<Inmuebles>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}${environment.urls.inmueble_buscar_por_numero_tienda}/${idTienda}/${idProceso}`
    );
  }

  /**
   * Obteners inmueble por asunto id con proceso de cumplimiento regulatorio.
   * @param idAsunto
   * @returns {Observable<IResponseSingle<InmuebleULC>>}
   */
  public obtenerInmueblePorAsuntoIdCR(idAsunto: number): Observable<IResponseSingle<InmuebleULC>> {
    return this.baseService.get<IResponseSingle<InmuebleULC>>(
      `${environment.backendURL}${environment.urls.inmueble_bis}/asunto/${idAsunto}`
    );
  }

  /**
   * Actualizar fechas de apertura de Bancoppel y Afore
   * @param idAsunto
   * @param fechaAperturaAfore
   * @param fechaAperturaBanco
   * @returns {Observable<IResponseAction>}
   */
  public actualizarFechasAperturaAforeBancoppel(fechas: FechasAperturaULC): Observable<IResponseAction> {
    return this.baseService.post<IResponseAction>(`${environment.backendURL}/cr/alineamiento/editar/fechas`, fechas);
  }
}
