import { ProcesoAsuntos } from '@app-modules/asuntos/models/enums/proceso-asuntos.enum';
import { ProcesoFlujosDTO } from '@app-modules/roles/models/roles.interface';
import { FiltroCallback } from '@app-shared/filtro-datos/filtrodatos.decorator';
import { IResponseList } from '@app-shared/models/IResponse';
import { of } from 'rxjs';

const BLACKLIST = [
  ProcesoAsuntos.ALINEAMIENTO_NO_OFICIAL,
  ProcesoAsuntos.RENOVACION_LICENCIAS_PERMISOS,
  ProcesoAsuntos.LICENCIAS_Y_PERMISOS,
];

/**
 * Filtro para datos.
 */
export const filtroProcesos: FiltroCallback<IResponseList<ProcesoFlujosDTO>> = (inject, data) => {
  if (data?.datos?.response !== undefined) {
    let response = data.datos.response;

    response = response.filter((i) => !BLACKLIST.includes(i.idProceso));

    data.datos.response = response;
  }

  return of(data);
};
