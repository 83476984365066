import { Injectable, OnDestroy } from '@angular/core';
import { MensajesGenerales } from '@app-services/constantes';
import { Observable, Subject } from 'rxjs';

/**
 * Interfaz de datos para servicio de confirmación.
 *
 * @see Confirmation Alias para confirmación de prime.
 */
export interface GenericConfirmation {
  message?: string;
  header?: string;
  accept?: () => void;
  reject?: () => void;
  acceptLabel?: string;
  rejectLabel?: string;
  acceptVisible?: boolean;
  rejectVisible?: boolean;
  customClass?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GenericConfirmationService implements OnDestroy {
  /**
   * Subject para confirmaciones.
   */
  private onConfirmSubjec$ = new Subject<GenericConfirmation>();

  /**
   * Subject de destruccion.
   */
  private readonly destroySubject$ = new Subject<void>();

  /**
   * Emisor de eventos de confirmación.
   */
  public get onConfirm$(): Observable<GenericConfirmation> {
    return this.onConfirmSubjec$.asObservable();
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  /**
   * Dispara un dialogo de confirmación básico.
   */
  public confirm(confirmation: GenericConfirmation): void {
    this.onConfirmSubjec$.next(confirmation);
  }

  /**
   * Dispara un dialogo de cancelación usando las constantes de cancelacion básicas.
   *
   * @see MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_HEADER Header
   * @see MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_BODY Body
   */
  public cancel(accept: () => void): void {
    this.onConfirmSubjec$.next({
      header: MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_HEADER,
      message: MensajesGenerales.VALIDACIONES.MENSAJE_CANCELACION_BODY,
      accept,
    });
  }
}
