import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MensajesGenerales } from '@app-services/constantes';
import { AddModelArgs, AddModelArgsDropdown, Filters } from '@app-shared/models/filters.interface';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Componente de filtros.
 *
 * @deprecated Dejar de usar en favor de formularios genericos. Esto es debido a que este componente tiene demasiadas
 * responsabilidades y esto no cumple con la S de SOLID.
 */
@Component({
  selector: 'app-card-filters',
  templateUrl: './card-filters.component.html',
  styleUrls: ['./card-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardFiltersComponent implements OnInit {
  @Input() public tittle = '';
  @Input() public iconTittle = '';
  @Input() public dataInput: Filters[];
  @Input() public formDinamic: FormGroup;
  @Input() public nameButton: string;
  @Input() public iconButton: string;
  @Input() public showMandatoryFieldsMessage = true;
  /**
   * Variable opcional para deshabilitar el boton del filtrado
   */
  @Input() public disabledButton = false;

  @Output() public onSearchInput = new EventEmitter<{ number: string; form: FormGroup }>();
  @Output() public onFilter: EventEmitter<any> = new EventEmitter();
  @Output() public onChangeValue: EventEmitter<any> = new EventEmitter();

  /**
   * Middleware para disparar eventos de búsqueda.
   */
  public searchSubject = new Subject<any>();

  /**
   * Mensaje para campos obligatorios con asterisco.
   */
  public mensajeCamposConAsterisco = MensajesGenerales.INFO.CAMPOS_CON_ASTERISCO;

  /**
   * Mensaje para dropdown sin coincidencias
   */
  public mensajeNoSeEncontraronResultado: string = MensajesGenerales.INFO.NO_RESULTADOS_ENCONTRADOS;

  /**
   * Getter para FormArray.
   */
  get dataArray(): FormArray {
    return this.formDinamic.get('dataArray') as FormArray;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.searchSubject
      .asObservable()
      .pipe(debounceTime(500))
      .subscribe((v) => this.onSearchInput.emit(v));
  }

  public searchInput(number: string, formControlName: string): void {
    const savParamters: AddModelArgs = {
      number,
      id: formControlName,
      form: this.formDinamic,
    };

    this.searchSubject.next(savParamters);
  }

  public filterData(): void {
    this.onFilter.emit(this.dataArray);
  }

  public changeValue(valor: string, formControlName: string) {
    const savParamters: AddModelArgsDropdown = {
      valor,
      id: formControlName,
    };
    this.onChangeValue.emit(savParamters);
  }

  /**
   * Valida que el formulario enviado sea correcto o
   * de lo contrario se encontrara deshabilitado el boton
   * de filtrar o ya sea manualmente con la variable disabledButton
   */
  public isValid() {
    return this.formDinamic.invalid || this.disabledButton;
  }

  /**
   * Retorna un arreglo de errores para el campo.
   */
  public getErrors(idDataArray: number, controlName: string): string[] {
    const dataArray = this.formDinamic?.get(`dataArray`);
    const group = dataArray?.get(idDataArray?.toString());

    if (!group) {
      return [];
    }

    const field = group.get(controlName);

    if (!field) {
      return [];
    }

    const errors = Object.entries(field.errors ?? {})
      .map((e) => e[1])
      .filter((e) => typeof e === 'string');

    return errors ?? [];
  }
}
