import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html',
})
export class AppAsideComponent {
  @Input() public messages: any;

  constructor() {
    /*empty*/
  }
}
