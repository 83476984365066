import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GenericComponentsModule } from '../generic-components/generic-components.module';
import { PrimeNgExportsModule } from '../prime-ng-exports/prime-ng-exports.module';
import { AlertaAbandonarVistaComponent } from './components/alerta-abandonar-vista/alerta-abandonar-vista.component';
import { AlertaCancelarCambiosComponent } from './components/alerta-cancelar-cambios/alerta-cancelar-cambios.component';
import { OpenPdfComponent } from './components/app-open-pdf/app-open-pdf.component';
import { CierreSesionComponent } from './components/cierre-sesion/cierre-sesion.component';
import { ErrorDisplayComponent } from './components/error-display/error-display.component';
import { LoadingComponent } from './components/loading/loading.component';
import { EnvironmentConditionalDirective } from './directives/environment-conditional.directive';
import { LetrasNumerosCaracteresEspeciales } from './directives/letras-numeros-caracteres-especiales.directive';
import { RestringirTipoDirective } from './directives/restringir-tipo.directive';

@NgModule({
  declarations: [
    AlertaAbandonarVistaComponent,
    OpenPdfComponent,
    CierreSesionComponent,
    ErrorDisplayComponent,
    LoadingComponent,
    RestringirTipoDirective,
    LetrasNumerosCaracteresEspeciales,
    EnvironmentConditionalDirective,
    AlertaCancelarCambiosComponent,
  ],
  imports: [CommonModule, ModalModule.forRoot(), GenericComponentsModule, PrimeNgExportsModule],
  exports: [
    AlertaAbandonarVistaComponent,
    AlertaCancelarCambiosComponent,
    OpenPdfComponent,
    CierreSesionComponent,
    ErrorDisplayComponent,
    LoadingComponent,
    RestringirTipoDirective,
    LetrasNumerosCaracteresEspeciales,
    PrimeNgExportsModule,
    EnvironmentConditionalDirective,
  ],
  bootstrap: [AlertaAbandonarVistaComponent, AlertaCancelarCambiosComponent, OpenPdfComponent],
})
export class CommonsModule {}
