<!-- Inicio del Card -->
<app-card>
  <!-- Inicio del Card Header -->
  <app-card-header>
    <i [class]="iconTittle"></i> {{ tittle }}
    <div class="float-right">
      <app-button variant="success" *ngIf="showAddButton" (onClick)="add()" noresponsivo>
        <i class="fas fa-plus-circle"></i>
        <span>Agregar</span>
      </app-button>
    </div>
  </app-card-header>
  <!-- Fin del Card Header -->
  <!-- Inicio del Card Body -->
  <app-card-body>
    <!-- Inicio del Form -->
    <form [formGroup]="formDinamic">
      <!-- Inicio del P-Table -->
      <p-table
        [value]="tableData"
        [styleClass]="styleClassTable"
        [loading]="loading"
        [lazy]="isLazy"
        [paginator]="true"
        (onLazyLoad)="customSort($event)"
        responsiveLayout="scroll"
        [showCurrentPageReport]="showCurrentPageReport"
        [paginator]="paginator"
        [rows]="recodsPerPage"
        [totalRecords]="totalRecords"
        currentPageReportTemplate="Mostrando {first} a {last} resultados de {totalRecords}"
        [rowsPerPageOptions]="[10, 25, 50]"
      >
        <!-- Inicio del P-Table Header -->
        <ng-template pTemplate="header">
          <tr>
            <!-- Columna de Datos -->
            <ng-container *ngFor="let tableColumns of tableColumns">
              <!-- Columna de Datos Sortable-->
              <th
                scope="col"
                *ngIf="tableColumns.isSortable; else notSortable"
                pSortableColumn="{{ tableColumns.dataKey }}"
                [hidden]="tableColumns.isHidden"
              >
                {{ tableColumns.name }} <p-sortIcon field="{{ tableColumns.dataKey }}"></p-sortIcon>
              </th>
              <!-- Columna de Datos No Sortable-->
              <ng-template #notSortable>
                <th scope="col" [hidden]="tableColumns.isHidden">{{ tableColumns.name }}</th>
              </ng-template>
            </ng-container>
            <!-- Columna de Acciones -->
            <th scope="col" *ngIf="showColumnActions" [style]="{ width: '20%' }">Acciones</th>
          </tr>
        </ng-template>
        <!-- Fin del P-Table Header -->
        <!-- Inicio del P-Table Body -->
        <ng-template pTemplate="body" let-rowData let-columns="tableColumns" let-rowIndex="rowIndex">
          <ng-container formArrayName="dataArrayFormTable">
            <tr [formGroupName]="rowIndex" [style]="styleTR">
              <ng-container *ngFor="let key of tableColumns">
                <td *ngIf="key.type === 'string'" [hidden]="key.isHidden">
                  <span *ngIf="!key.hideIndex">{{ rowIndex }} - </span> {{ rowData[key.dataKey] }}
                </td>

                <td *ngIf="key.type === 'input'" [hidden]="key.isHidden">
                  <input
                    pInputText
                    [type]="key.input.type"
                    [class]="key.input.class"
                    [placeholder]="key.input.placeholder"
                    [formControlName]="key.input.formControlName"
                    [maxlength]="key.input.maxlength"
                    [minlength]="key.input.minlength"
                    appRestringirTipo
                    [permitir]="key.input.permitir"
                    [disabled]="deshabilitarNoAdministradores && !rolAdministrador"
                  />
                  <div class="text-danger">
                    <div *ngIf="this.dataArrayFormTable.at(rowIndex).get(key.input.formControlName)?.errors?.required">
                      <ng-container *ngIf="customRequiredMessage; else defaultMessage">
                        <span>{{ this.customRequiredMessage }}</span>
                      </ng-container>
                    </div>
                    <div *ngIf="this.dataArrayFormTable.at(rowIndex).get(key.input.formControlName)?.errors?.pattern">
                      <span>{{ this.mensajeInformacionInvalida }}</span>
                    </div>
                  </div>
                </td>

                <td *ngIf="key.type === 'porcentaje'" [hidden]="key.isHidden">
                  <p-inputNumber
                    [inputStyleClass]="key.input.class"
                    [placeholder]="key.input.placeholder"
                    [formControlName]="key.input.formControlName"
                    [style]="{ width: '100%' }"
                    [size]="3"
                    [step]="0"
                    [min]="0"
                    [max]="100"
                    suffix="%"
                    [disabled]="deshabilitarNoAdministradores && !rolAdministrador"
                  >
                  </p-inputNumber>
                  <div class="text-danger">
                    <div *ngIf="this.dataArrayFormTable.at(rowIndex).get(key.input.formControlName)?.errors?.required">
                      {{ this.mensajeInformacionInvalida }}
                    </div>
                  </div>
                </td>

                <td *ngIf="key.type === 'dropdown'" [hidden]="key.isHidden">
                  <p-dropdown
                    [class]="key.dropdown.class"
                    [options]="key.dropdown.options"
                    [optionLabel]="key.dropdown.optionLabel"
                    [emptyMessage]="key.dropdown.emptyMessage"
                    [style]="{ width: '100%' }"
                    [placeholder]="key.dropdown.placeholder"
                    [formControlName]="key.dropdown.formControlName"
                    [filter]="key.dropdown.filter"
                    [showClear]="key.dropdown.showClear"
                    [emptyFilterMessage]="key.dropdown.emptyFilterMessage"
                    [optionValue]="key.dropdown.optionValue"
                    [disabled]="deshabilitarNoAdministradores && !rolAdministrador"
                  >
                  </p-dropdown>
                  <div class="text-danger">
                    <div
                      *ngIf="this.dataArrayFormTable.at(rowIndex).get(key.dropdown.formControlName)?.errors?.required"
                    >
                      <ng-container *ngIf="customRequiredMessage; else defaultMessage">
                        <span>{{ this.customRequiredMessage }}</span>
                      </ng-container>
                    </div>
                  </div>
                </td>

                <td *ngIf="key.type === 'dropDownDinamic'" [hidden]="key.isHidden">
                  <p-dropdown
                    [class]="key.dropdown.class"
                    [options]="key.dropdown.options"
                    [optionLabel]="key.dropdown.optionLabel"
                    [emptyMessage]="key.dropdown.emptyMessage"
                    [style]="{ width: '100%' }"
                    [placeholder]="key.dropdown.placeholder"
                    [formControlName]="key.dropdown.formControlName"
                    [filter]="key.dropdown.filter"
                    [showClear]="key.dropdown.showClear"
                    [emptyFilterMessage]="key.dropdown.emptyFilterMessage"
                    [optionValue]="key.dropdown.optionValue"
                    [disabled]="deshabilitarNoAdministradores && !rolAdministrador"
                  >
                  </p-dropdown>
                  <div class="text-danger">
                    <div
                      *ngIf="this.dataArrayFormTable.at(rowIndex).get(key.dropdown.formControlName)?.errors?.required"
                    >
                      {{ this.mensajeInformacionInvalida }}
                    </div>
                  </div>
                </td>
              </ng-container>
              <td *ngIf="showColumnActions">
                <button
                  *ngFor="let action of columnActions"
                  class="{{ action.classActionButton }}"
                  title="{{ action.title }}"
                  (click)="actionsClick(action.action, rowData)"
                  [disabled]="deshabilitarNoAdministradores && !rolAdministrador"
                >
                  <i class="{{ action.icon }}"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </form>
  </app-card-body>

  <!-- Inicio templeates-->
  <ng-template #defaultMessage>
    <span>
      {{ this.mensajeInformacionInvalida }}
    </span>
  </ng-template>
  <!--  Fin templates-->

  <!-- Inicio del Card Footer -->
  <app-card-footer *ngIf="showButtonsFooter">
    <ng-container left>
      <label class="col-form-label font-weight-bold" *ngIf="showMandatoryFieldsMessage">{{
        mensajeCamposConAsterisco
      }}</label>
    </ng-container>
    <ng-container right>
      <!-- Boton de Cancelar -->
      <app-button variant="gray" (onClick)="onCancelar()"> <i class="fas fa-times-circle"></i> Cancelar </app-button>
      <!-- Boton de Guardar -->
      <app-button
        variant="primary"
        (onClick)="onGuardar()"
        [disabled]="formDinamic.invalid || (deshabilitarNoAdministradores && !rolAdministrador)"
      >
        <i class="fa fa-check-circle"></i>&nbsp;Guardar
      </app-button>
    </ng-container>
  </app-card-footer>
  <!-- Fin del Card Footer -->
</app-card>
<!-- Fin del Card -->
