import { Directive, ElementRef } from "@angular/core";
import { environment } from "src/environments/environment";
import { CaptchaApiService } from "../services/captcha-api.service";

@Directive({
  selector: "[appCaptchaReader]",
})
export class CaptchaReaderDirective {
  constructor(
    private el: ElementRef,
    private captchaApiService: CaptchaApiService,
  ) {
    if (
      environment.authMechanisms.colaboradorDigital || environment.authMechanisms
        .custom &&
      environment.recaptcha.siteKey.length
    ) {
      this.captchaApiService.status$.subscribe({
        next: (status: any) => {
          const invalidValidForm = el.nativeElement.hasAttribute("disabled");
          if (status.loaded && !invalidValidForm) {
            this.el.nativeElement.removeAttribute("disabled");
          }
        },
        error: (_) => this.el.nativeElement.addAttribute("disabled"),
      });
    }
  }
}
