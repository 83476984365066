import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUser } from '@app-models/currentUser.interface';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly currentUser: Observable<any> = this.currentUserSubject.asObservable();

  constructor(private router: Router, private http: HttpClient) {}

  /**
   * Retorna un booleanon indicando si existe una sesión autenticada.
   */
  public get isUserAuthenticated$(): Observable<boolean> {
    if (this.currentUserSubject.value) {
      of(true);
    }

    return this.currentUserSubject.asObservable().pipe(filter((i) => Boolean(i)));
  }

  public setCurrentUser(currentUser: CurrentUser): void {
    this.currentUserSubject.next(currentUser);
  }
  public getCurrentUser(): CurrentUser {
    return this.currentUserSubject.getValue();
  }

  public logout() {
    localStorage.clear();
    sessionStorage.clear();
    if (environment.inHouse[0]) {
      window.location.href = '/login/v2';
    }
    return false;
  }
}
