import { Component, Host, Input, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { GenericDropdownInput, HandlerUtils } from '@app-shared/generic-reactive-forms/models/input-types';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-generic-input-dropdown',
  templateUrl: './generic-input-dropdown.component.html',
  styleUrls: ['./generic-input-dropdown.component.scss'],
})
export class GenericInputDropdownComponent
  extends AbstractGenericInputComponent<GenericDropdownInput, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericDropdownInput;

  /**
   * Constructor de clase GenericInputDropdownComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() genericFormService: GenericFormService
  ) {
    super(formRef, genericFormService);
  }

  /**
   * Estilo de panel.
   */
  public get panelStyleClass(): string {
    if (!this.config?.panelStyleClass) {
      return 'no-fixed-top fix-zindex-9999';
    }

    return ['no-fixed-top fix-zindex-9999', this.config?.panelStyleClass].filter(Boolean).join('');
  }

  /**
   * Altura de el dialogo de resultados del dropdown.
   */
  public get scrollHeight(): string {
    return this.fieldConfig?.scrollHeight ?? '150px';
  }

  /**
   * Nombre de la propiedad que muestra el label.
   */
  public get optionLabel(): string {
    return this.fieldConfig?.optionLabel ?? 'value';
  }

  /**
   * Nombre de la propiedad del valor de la opcion.
   */
  public get optionValue(): string {
    return this.fieldConfig?.optionValue ?? 'key';
  }

  /**
   * Booleano que indica si se mostrará el filtro del dropdown.
   */
  public get filter(): boolean {
    return this.fieldConfig?.filter ?? true;
  }

  /**
   * Texto que se debe mostrar en caso de que no se muestren resultados.
   */
  public get emptyFilterMessage(): string {
    return this.fieldConfig?.emptyFilterMessage ?? this.mensajeNoSeEncontraronResultado;
  }

  /**
   * Texto que se debe mostrar en caso de que no se muestren resultados.
   */
  public get emptyMessage(): string {
    return this.fieldConfig?.emptyMessage ?? this.mensajeNoSeEncontraronResultado;
  }

  /**
   * Booleano que indica si se mostrara el boton de limpieza del campo.
   */
  public get showClear(): boolean {
    return this.fieldConfig?.showClear || true;
  }

  /**
   * Icono que se mostrara para el dropdown.
   */
  public get dropdownIcon(): string {
    return this.fieldConfig?.dropdownIcon ?? 'pi pi-chevron-down';
  }

  /**
   * Nombre de la propiedad por la cual se filtrará el campo.
   */
  public get filterby(): string {
    return this.fieldConfig?.filterBy || this.fieldConfig?.optionLabel || 'value';
  }

  /**
   * Tipo de matcheo que se realiza en el filtrado.
   */
  public get filterMatchMode(): string {
    return this.fieldConfig?.filterMatchMode || 'contains';
  }

  /**
   * Atributo `placeholder`.
   */
  public get placeholder(): string | null {
    if (this.control.disabled) {
      return (
        this.fieldConfig?.placeholder ?? (this.fieldConfig?.label ? `Seleccionar ${this.fieldConfig?.label}` : null)
      );
    }

    return this.fieldConfig?.placeholder ?? (this.fieldConfig?.label ? `Seleccionar ${this.fieldConfig?.label}` : null);
  }

  /**
   * On onFocus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, this.getHandlerUtils());
    }
  }

  /**
   * On onBlur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, this.getHandlerUtils());
    }
  }

  /**
   * On onInput event handler.
   */
  public onChange(e): void {
    if (this.fieldConfig?.onChange) {
      this.fieldConfig?.onChange(e, this.getHandlerUtils());
    }
  }

  /**
   * On onClick event handler.
   */
  public onClick(e): void {
    if (this.fieldConfig?.onClick) {
      this.fieldConfig?.onClick(e, this.getHandlerUtils());
    }
  }

  /**
   * On onFilter event handler.
   */
  public onFilter(e): void {
    if (this.fieldConfig?.onFilter) {
      this.fieldConfig?.onFilter(e, this.getHandlerUtils());
    }
  }

  /**
   * On onShow event handler.
   */
  public onShow(e): void {
    if (this.fieldConfig?.onShow) {
      this.fieldConfig?.onShow(e, this.getHandlerUtils());
    }
  }

  /**
   * On onHide event handler.
   */
  public onHide(e): void {
    if (this.fieldConfig?.onHide) {
      this.fieldConfig?.onHide(e, this.getHandlerUtils());
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Suscripcion para disparar OnChange en cambios de valor programaticos.
    this.control.valueChanges
      .pipe(
        takeUntil(this.destroySubject$.asObservable()),
        distinctUntilChanged((prev, next) => {
          return prev === next;
        })
      )
      .subscribe((e) => {
        this.onChange({ value: e?.value ?? e });
      });
  }

  /**
   * Retorna objeto de utilidades para handlers de eventos.
   */
  private getHandlerUtils(): HandlerUtils {
    return {
      disable: this.control.disable.bind(this.control),
      enable: this.control.enable.bind(this.control),
      getCurrentValue: () => {
        return this.control.value;
      },
      getControl: () => {
        return this.control;
      },
    };
  }
}
