import { Injectable } from '@angular/core';
import { Procesos } from '@app-services/modules/Procesos/procesos';
import { TipoNegociacion } from '@app-services/modules/TipoNegociacion/tiponegociacion';
import { FiltroDatos } from '@app-shared/filtro-datos/filtrodatos.decorator';
import { IResponseList, IResponseSingle, IResponseSingleBoolean } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  CatalogoDto,
  ColaboradorCargaMasivaDTO,
  EliminarUsuarioDTO,
  EstadosMunicipiosDTO,
  RepartoNotificaciones,
  RolUsuarioGuardarDTO,
  RolUsuarioMasivoGuardarDTO,
  TipoAcceso,
  UsuarioDTO,
} from '../models/roles.models';
import { filtroUsuarioDatos } from './filtro-usuario-datos.filter';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UsuarioRolService {
  /**
   * Constructor de UsuarioRolService.
   */
  public constructor(private baseService: BaseService) {}

  /**
   * Controlador para @see com.coppel.sajv2.controllers.UsuariosRolController::obtenerRolPorUsuario
   */
  @FiltroDatos(filtroUsuarioDatos)
  public obtenerRolPorUsuario$(idUsuario: number | string): Observable<IResponseSingle<RolUsuarioGuardarDTO>> {
    return this.baseService.post<IResponseSingle<RolUsuarioGuardarDTO>>(
      `${environment.backendURL}/usuario/rol/rolPorUsuario`,
      {
        idUsuario,
        encabezadosInterceptoresCustom: {
          interceptarErrores: false,
          interceptorMensajes: false,
        },
      }
    );
  }

  /**
   * Controlador para @see com.coppel.sajv2.controllers.UsuariosRolController::guardar
   */
  public guardar$(data: RolUsuarioGuardarDTO): Observable<IResponseSingle<number>> {
    return this.baseService.post<IResponseSingle<number>>(`${environment.backendURL}/usuario/rol/`, data);
  }

  /**
   * Controlador para @see com.coppel.sajv2.controllers.UsuariosRolController::guardarCargaMasiva
   * Incorporar guardado masivo.
   */
  public guardarMasiva$(data: RolUsuarioMasivoGuardarDTO): Observable<IResponseSingle<number>> {
    return this.baseService.post<IResponseSingle<number>>(
      `${environment.backendURL}/usuario/rol${environment.urls.cargaMasiva}/`,
      data
    );
  }

  /**
   * Controlador para @see com.coppel.sajv2.controllers.UsuariosRolController::modificar
   */
  public editar$(data: RolUsuarioGuardarDTO): Observable<IResponseSingle<number>> {
    return this.baseService.put<IResponseSingle<number>>(`${environment.backendURL}/usuario/rol/`, data);
  }

  /**
   * Retorna el catalogo de Tipos de Acceso.
   */
  public tiposAcceso$(): Observable<IResponseList<TipoAcceso>> {
    return this.baseService.get<IResponseList<TipoAcceso>>(`${environment.backendURL}/catalogo/flujo-acceso`);
  }

  /**
   * Retorna el catalogo de Tipos de Acceso.
   */
  public repartoNotificaciones$(): Observable<IResponseList<RepartoNotificaciones>> {
    return this.baseService.get<IResponseList<RepartoNotificaciones>>(
      `${environment.backendURL}/catalogo/reparto-notificaciones`
    );
  }

  /**
   * Retorna datos relacionados a una region.
   */
  public regionData$(region: string | number): Observable<IResponseSingle<EstadosMunicipiosDTO>> {
    return this.baseService.get<IResponseSingle<EstadosMunicipiosDTO>>(
      `${environment.backendURL}/usuario/rol/regiones/${region}`
    );
  }

  /**
   * Busca un colaborador en  personal y valida si tiene un rol asignado.
   *
   * @param numeroColaborador - Numero de colaborador a buscar
   * @returns Observable con los datos de respuesta del servicio.
   */
  public buscaColaboradorAsignarRol$(
    numeroColaborador: number
  ): Observable<IResponseSingle<ColaboradorCargaMasivaDTO>> {
    return this.baseService.post(`${environment.backendURL}/usuario/rol/validarColaborador`, {
      numeroColaborador: numeroColaborador,
    });
  }

  /**
   * Busca un colaborador en  personal y valida si tiene un rol asignado.
   *
   * @param numeroColaborador - Numero de colaborador a buscar
   * @returns Observable con los datos de respuesta del servicio.
   */
  public buscaColaborador$(numeroColaborador: number): Observable<IResponseSingle<UsuarioDTO>> {
    return this.baseService.get(`${environment.backendURL}/usuarios`, {
      params: { numeroColaborador: numeroColaborador?.toString() },
    });
  }

  /**
   * Obtiene el listado de procesos disponibles para un rol.
   * @deprecated En desuso tras cambios de ultimo refactor de roles.
   */
  public getProcesosRol$(numPersona: number | string): Observable<IResponseList<CatalogoDto>> {
    if (!numPersona) {
      return this.baseService
        .get<IResponseList<Procesos>>(`${environment.backendURL}${environment.urls.procesos}`)
        .pipe(map((response) => response as IResponseList<CatalogoDto>));
    }

    return this.baseService.post<IResponseList<CatalogoDto>>(
      `${environment.backendURL}/usuario/rol/procesos-permisos`,
      { numPersona }
    );
  }

  /**
   * Obtiene el listado de flujos disponibles para un rol.
   */
  public getFlujosRol$(
    numPersona: number | string,
    idProceso: number | string
  ): Observable<IResponseList<CatalogoDto>> {
    if (!numPersona) {
      return this.baseService
        .get<IResponseList<TipoNegociacion>>(`${environment.backendURL}/tipos-negociaciones/`)
        .pipe(
          map((response) => {
            const items = response.datos.response;
            response.datos.response = items.filter((i) => i.idProceso === idProceso);

            return response;
          })
        );
    }

    return this.baseService.post<IResponseList<CatalogoDto>>(
      `${environment.backendURL}/usuario/rol/tipoNegociacon-permisos`,
      {
        numPersona,
        idProceso,
      }
    );
  }

  /**
   * Retorna un arreglo de Procesos por ID de area.
   *
   */
  public getProcesosPadrePorArea$(
    idArea: number | string,
    numPersona: number | string
  ): Observable<IResponseList<CatalogoDto>> {
    if (!numPersona) {
      return this.baseService
        .get<IResponseList<Procesos>>(`${environment.backendURL}/procesos/area/${idArea}/padres`)
        .pipe(map((response) => response as IResponseList<CatalogoDto>));
    }

    return this.baseService.post<IResponseList<CatalogoDto>>(`${environment.backendURL}/usuario/rol/area/padres`, {
      idArea: idArea?.toString(),
      numPersona: numPersona?.toString(),
    });
  }

  /**
   * Retorna un arreglo de Procesos por ID de proceso padre.
   *
   */
  public getProcesosHijoPorProceso$(
    idProcesoPadre: number | string,
    numPersona: number | string
  ): Observable<IResponseList<CatalogoDto>> {
    if (!numPersona) {
      return this.baseService
        .get<IResponseList<Procesos>>(`${environment.backendURL}/procesos/${idProcesoPadre}/hijos`)
        .pipe(map((response) => response as IResponseList<CatalogoDto>));
    }

    return this.baseService.post<IResponseList<CatalogoDto>>(
      `${environment.backendURL}/usuario/rol/proceso-usuario/hijos`,
      {
        idProceso: idProcesoPadre?.toString(),
        numPersona: numPersona?.toString(),
      }
    );
  }

  /**
   * Obtiene un arreglo con los permisos disponibles para pantalla de seguimiento.
   */
  public getPantallaSeguimientoPermisos$(
    numPersona: string | number,
    idAsunto: string | number
  ): Observable<IResponseList<number>> {
    if (!numPersona) {
      return of(null);
    }

    return this.baseService.post<IResponseList<number>>(
      `${environment.backendURL}/usuario/rol/proceso/pantalla-seguimiento`,
      {
        idAsunto: idAsunto?.toString(),
        numPersona: numPersona?.toString(),
      }
    );
  }

  /**
   * Obtiene un arreglo con los permisos disponibles para pantalla de seguimiento.
   */
  public validarExisteEncargado$(
    idUsuario: string | number,
    idFlujo: string | number
  ): Observable<IResponseSingle<EliminarUsuarioDTO>> {
    return this.baseService.post<IResponseSingle<EliminarUsuarioDTO>>(
      `${environment.backendURL}/usuario/rol/validar/${idUsuario}`,
      {
        idUsuario: idUsuario?.toString(),
        idFlujo: idFlujo?.toString(),
      }
    );
  }

  /**
   * Reenvia el correo de contraseña
   */
  public enviarCorreoContrasena$(idUsuario: number | string) {
    return this.baseService.post<IResponseSingleBoolean>(
      `${environment.backendURL}/usuarios/reenviar-correo-contrasenna`,
      { idUsuario }
    );
  }

  /**
   * Validador para eliminacion de procesos en rol.
   */
  public validarEliminarProceso$(rol: number | string, proceso: number | string) {
    const params = new HttpParams().append('idFlujo', proceso).append('idRol', rol);

    return this.baseService.get<IResponseSingleBoolean>(
      `${environment.backendURL}/rol/usuario/validar-eliminar-proceso`,
      {
        params: params,
        encabezadosInterceptoresCustom: {
          interceptarErrores: false,
          interceptorMensajes: false,
        },
      }
    );
  }
}
