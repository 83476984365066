// tslint:disable: max-line-length
import { PARAMETROS_PANTALLA_SEGUIMIENTO } from '@app-modules/asuntos/pantalla-seguimiento/pantalla-seguimiento.constants';

export const Constante = {
  SIN_DOCUMENTO_SELECCIONADO: 'No ha seleccionado ningún documento',
  ALERTA_DEJAR_FORMULARIO: 'Cambios Detectados',
  ALERTA_CANCELAR: 'Se detectaron cambios, ¿Desea cancelar y salir?',
  ALERTA_TITULO_PRINCIPAL: 'Se detectaron cambios, ¿Desea continuar sin guardar?',
  ALERTA_SUBTITULO_ELIMINAR: 'Esta acción no se podrá revertir',
  ALERTA_TITULO_CANCELAR: 'Cancelar Cambios',
  ALERTA_CONTENIDO_CANCELAR: '¿Está seguro que desea cancelar?',
  ALERTA_CAMBIOS_GUARDADOS: 'Sus cambios han sido guardado con éxito',
  FORMATOS_VALIDOS_ARCHIVO:
    '".doc, .docx, .docm, .dotx, .dotm, .xls, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xlam, .ppt, .pptx, .pptm, .potx, .potm, .ppam, .ppsx, .ppsm, .sldx, .sldm, .thmx, .pdf, .zip, .JPEG, .JPG, .RAW, .PNG, .gif, .asf, .avi, .mp4, .m4v, .mov, .mpg, .mpeg, .wmv, .aiff, .txt"',
  TAMANO_MAXIMO_ARCHIVO: 104857600,
  VALIDADORES: {
    EMAIL:
      // tslint:disable-next-line: quotemark
      "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$",
  },
  LABEL_CUATO_NIVEL: {
    TIPO_NEGOCIACION: 'Tipo Negociación',
    TIPO_ACCESORIO: 'Tipo Accesorio',
  },
};

export const MensajesGenerales = {
  INFO: {
    CAMPO_REQUERIDO: 'Campo Requerido.',
    CAMPOS_CON_ASTERISCO: 'Los campos con (*) son requeridos.',
    SIN_DOCUMENTACION: 'Sin Documentación',
    NO_RESULTADOS_ENCONTRADOS: 'Sin Resultados',
    SIN_COINCIDENCIAS: 'Sin Coincidencias',
    TRASACCION_EXITOSA: 'La información se ha guardado correctamente',
    EXITOSA: '¡Bien hecho! Sus cambios han sido guardados con éxito',
    BIEN_HECHO: '¡Bien hecho!',
    CAMBIOS_GUARDADOS: 'Sus cambios han sido guardados con éxito',
    SUCCESS: 'Success',
    FILE_UPLOADED: 'File Uploaded with Auto Mode',
    CONFIRMACION: 'Confirmación',
    ALERTA_ELIMINAR_ELEMENTO: '¿Seguro que deseas eliminar este elemento?',
    ALERTA_ACTUALIZADO: 'La información se ha actualizado correctamente',
    ALERTA_ELIMINADO: 'La información se ha eliminado correctamente',
    ALERTA_RECHAZADO: 'La solicitud ha sido rechazada correctamente',
    ALERTA_ACEPTADO: 'La solicitud ha sido aceptada correctamente',
    ALERTA_SELECCIONAR_FILTRO: 'Debe ingresar al menos un filtro de búsqueda',
    ALERTA_TOTAL_DE_CARACTERES: 'Debe ingresar al menos 3 caracteres validos en un campo para lanzar la búsqueda',
    ALERTA_SELECCIONAR_FILTROS: 'Debe Ingresar los Filtro de búsqueda',
    ALERTA_SELECCIONAR_FILTROS_OBLIGATORIOS: 'Debe ingresar los campos indicados',
    ALERTA_NO_HAY_REGISTROS_BUSQUEDA: 'No se encontraron registros coincidentes con la búsqueda',
    ALERTA_CONTENIDO_ELIMINAR_GENERICO: '¿Está seguro que desea eliminar <variable>?',
    ALERTA_TITULO_ELIMINAR_GENERICO: 'Eliminar',
    ALERTA_TITULO_DAR_BAJA: 'Dar de baja al Usuario',
    ALERTA_CONTENIDO_DAR_BAJA: '¿Está seguro de Dar de baja a <variable>',
    ALERTA_DAR_BAJA_CANCELADA:
      'Para Dar de Baja al usuario seleccionado se requiere contar con usuarios Encargado y Activo para el Reparto de notificaciones para los procesos que actualmente tiene configurado el usuario <variable>. Se le notificará por correo cuando se finalice la validación, así como el estatus de la baja que solicita.',
    FOLIO_SAJ_NO_ENCONTRADO: 'Folio SAJ no encontrado, favor de introducir un folio válido',
    FOLIO_SAJ_PERTENECIENTE_OTRO_PROCESO: 'Folio SAJ perteneciente a otro proceso, favor de introducir un folio válido',
    TOTAL_DE_CARACTERES_CAUSA: 'Debe ingresar un mínimo de 10 ó máximo 2000 caracteres',
    TOTAL_DE_CARACTERES_CORRECCION_ESCRITURA: 'Debe ingresar un mínimo de 10 ó máximo 500 caracteres',
  },
  ERRORES: {
    ERROR_CONEXION: 'Problema de conexión, favor de revisar su conexión al servidor',
    ERROR_NO_IDENTIFICADO: 'Problema no identificado, contactar al equipo de soporte',
    ERROR_NO_ACCESOS: 'No se cuenta con accesos para este módulo, favor de contactar a su superior',
    ERROR_TOKEN_EXPIRADO: 'Token no válido, sesión expirada',
    SISTEMA_NO_DISPONIBLE: 'El sistema no esta disponible por el momento.',
  },
  ADVERTENCIA: {
    SISTEMA_NO_DISPONIBLE: 'El sistema no está disponible por el momento',
    SALIO_MAL: 'Algo salió mal...',
  },
  OTROS: {
    SIN_RESULTADO: 'Sin resultado',
    BUSCANDO: 'Buscando ...',
    NO_HAY_REGISTROS: 'No hay registros que mostrar',
    SIN_ARCHIVO: 'Sin archivo',
  },
  FORMATOS: {
    FECHA_READ_ONLY: 'dd/MM/yyyy', // FORMATO AL SETAR UNA FECHA EN UN CONTROL
    FECHA_CONTROL: 'dd/mm/yy', // FORMATO PARA MOSTRAR FECHAS EN UNA TABLA
    FECHA_SEND_BACKEND: 'yyyy-MM-DD', // FORMATO DE FECHA EN LA QUE SE REGRESARA A UN ENTPOINT
    FECHA_PIPE_DYNAMIC: 'DD/MM/yyyy', // FORMATO PARA EL PIPE DINAMICO
    FECHA_MOMENT: 'DD/MM/YYYY', // FORMATO PARA EL FORMATO DE FECHA CON MOMENTJS
  },
  IDS: {
    ID_TAREA_INFORMATIVA: 1,
    ID_TAREA_DE_TAREAS: 2,
    ID_PAIS_MEXICO: 1,
  },
  ORDENAMIENTO: {
    NOMBRE: 'nombre',
  },
  VALIDACIONES: {
    MENSAJE_TAMANO_MAXIMO_SUPERADO: 'Ha superado el tamaño máximo de 100 MB.',
    MENSAJE_TAMANO_MAXIMO_SUPERADO_FILE_UPLOAD: 'Ha superado el tamaño máximo de {0}.',
    MENSAJE_TAMANO_INVALIDO: 'Tamaño Inválido.',
    MENSAJE_FORMATO_INVALIDO: 'Formato Inválido',
    MENSAJE_EXTENSION_INVALIDA: 'La extensión del archivo no es válido.',
    LONGITUD_INVALIDA: 'Longitud inválida.',
    INFORMACION_INVALIDA: 'Ingrese Información Válida.',
    CANTIDAD_INVALIDA: 'Ingrese Información Válida',
    MENSAJE_CANCELACION_BODY:
      '<center><strong>Se detectaron cambios ¿Desea continuar sin guardar?</strong><br>Esta acción no se podrá revertir</center>',
    MENSAJE_CANCELACION_HEADER: 'Confirmar Cancelación',
    MENSAJE_ELIMINACION_HEADER: 'Confirmar Eliminar',
    MENSAJE_DETALLE_TAMANO_ARCHIVO_INVALIDO: 'Tamaño Máximo de Carga {0}',
    MENSAJE_TIPO_ARCHIVO_INVALIDO: 'Extensión del Archivo Inválida',
    MENSAJE_DETALLE_TIPO_ARCHIVO_INVALIDO: 'Tipos de archivos permitidos: {0}.',
    MENSAJE_DETALLE_LIMITE_ARCHIVO: 'El límite es {0} máximo',
    MENSAJE_LIMITE_ARCHIVO: 'Excede el máximo de archivos',
  },
};

const FECHA_LIMITE = 'Fecha Límite';
const PATTERN_MONETARIO = '^(([0-9]+(\\d*|\\d{0,2}(\\d{3})*))|0)(\\.\\d{2,2})?$';
const FECHA_ASIGNACION = 'Fecha de Asignación';
/**
 *
 *
 * Si aumentamos el tamaño de este arbol de constantes, al cargarlas desde cualquier componente
 * estamos cargando info de otros modulos que pueden ni siquiera estar relacionados.
 */
export const ParametrosModulos = {
  DATA: {
    MODULOS: {
      DASHBOARD: {
        MIGA: 'dashboard',
        URL: 'dashboard',
      },
      ASUNTOS: {
        MIGA: 'Asuntos',
        URL: 'asuntos',
        TABLAS: [
          {
            COLUMNA1: {
              TITULO: 'Folio',
            },
            COLUMNA2: {
              TITULO: 'Fecha de Alta',
            },
            COLUMNA3: {
              TITULO: 'Centro',
            },
            COLUMNA4: {
              TITULO: 'Región',
            },
            COLUMNA5: {
              TITULO: 'Actor',
            },
            COLUMNA6: {
              TITULO: 'Hechos',
            },
            COLUMNA7: {
              TITULO: 'Responsable del Caso',
            },
            COLUMNA8: {
              TITULO: 'Condición',
            },
            COLUMNA9: {
              TITULO: 'Acciones',
            },
            COLUMNA10: {
              TITULO: 'Asociar',
            },
            COLUMNA11: {
              TITULO: 'Fecha Vigencia',
            },
            COLUMNA12: {
              TITULO: 'Estatus CCT',
            },
          },
        ],
        CONSTANTES: {
          FILTROS: {
            PROCESO: 'proceso',
            ESTADO: 'estado',
            CIUDADES: 'ciudad',
            EMPRESA_FILIAL: 'empFilial',
            DESTINO: 'destino',
            ESTATUS: 'estatus',
            CENTRO: 'centro',
            FECHA_DEL: 'fecDel',
            FECHA_AL: 'fecAl',
            RESPONSABLE_SEGUIMIENTO: 'respSeg',
            RESPONSABLE_CASO: 'respCaso',
            REGION: 'region',
            ACTOR: 'actor',
            HECHOS: 'hechos',

            OBRA_NUEVA: 'obranueva',
            REMODELACION: 'remodelacionMantenimiento',
            TIPO_ADQUISICION: 'tipoAdq',
            ABOGADO_EXTERNO: 'abogadoExterno',
            ESTADO_PROCESAL: 'estatusProcesal',
          },
          CLASES: {
            CLASS_ICON_SIN_ABOGADO: 'cust_icon-Icono_Condicion_Cancelar color-sin-abogado',
            CLASS_ICON_TAREAS_SIN_CUMPLIR: 'cust_icon-Icono_Condicion_Bandera color-tareas-sin-cumplir',
            CLASS_ICON_TAREAS_SIN_VALIDAR: 'cust_icon-Icono_Condicion_Documento_Cancelar color-tareas-sin-validar',
            CLASS_ICON_CERRADO: 'cust_icon-Icono_Condicion_Mano color-cerrado',
          },
          CONDICIONES: {
            CONDICION_SIN_ABOGADO: 'Sin Abogado Asignado',
            CONDICION_TAREAS_SIN_CUMPLIR: 'Tareas sin Cumplir',
            CONDICION_TAREAS_SIN_VALIDAR: 'Tareas sin Validar',
            CONDICION_CERRADO: 'Cerrado, con Tareas sin Cumplir y/o Tareas sin Validar',
          },
          VALIDADORES: {
            EMAIL: '[a-zA-Z0-9-_]+([.][a-zA-Z0-9-_]+)*@[a-zA-Z0-9_\\-]+([.][a-zA-Z0-9_\\-]+)*[.][a-zA-Z]{1,5}',
            EMAIL_COPPEL: '^[a-zA-Z0-9]+@coppel.com',
          },
          EXPRESIONES_REGULARES: {
            PATTERN_ACTOR_HECHO: '^[a-zA-Z0-9áéíóúÁÉÍÓÚ\\,\\:\\;\\"\\(\\)\\¿\\?\\¡\\!\\.\\-\u00f1\u00d1 ]*$',
          },
        },
      },
      /**
       * Se desacopló la información de la pantalla de seguimiento a un
       * archivo .param.ts del mismo modulo @see PantallaSeguimientoModule
       *
       * Dejamos una referencia para compatbilidad.
       */
      PANTALLA_SEGUIMIENTO: PARAMETROS_PANTALLA_SEGUIMIENTO,
      LISTADO_TAREAS_POR_VALIDAR: {
        MIGA: 'Validar Tareas',
        URL: 'tareas-por-validar',
        TABLAS: [
          {
            COLUMNA1: {
              TITULO: 'Folio',
            },
            COLUMNA2: {
              TITULO: 'Proceso',
            },
            COLUMNA3: {
              TITULO: 'Involucrado',
            },
            COLUMNA4: {
              TITULO: 'Tarea',
            },
            COLUMNA5: {
              TITULO: 'Comentarios',
            },
            COLUMNA6: {
              TITULO: FECHA_ASIGNACION,
            },
            COLUMNA7: {
              TITULO: FECHA_LIMITE,
            },
            COLUMNA8: {
              TITULO: 'Fecha Cumplida',
            },
          },
        ],
        CONSTANTES: {
          FILTROS: {
            FOLIO: 'folio',
            INVOLUCRADO: 'numeroInvolucrado',
            ID_PROCESO: 'idProceso',
            TIPO_AVISO: 'idTipoTarea',
            TIPO_NEGOCIACION: 'idTipoNegociacion',
            FECHA_LIMITE: 'fechaLimite',
          },
        },
      },
      LISTADO_TAREAS_POR_CUMPLIR: {
        MIGA: 'Cumplir Tareas',
        URL: 'tareas-por-cumplir',
        TABLAS: [
          {
            COLUMNA1: {
              TITULO: 'Folio',
            },
            COLUMNA2: {
              TITULO: 'Proceso',
            },
            COLUMNA3: {
              TITULO: 'Empresa',
            },
            COLUMNA4: {
              TITULO: 'Región',
            },
            COLUMNA5: {
              TITULO: 'Centro',
            },
            COLUMNA6: {
              TITULO: 'Tarea',
            },
            COLUMNA7: {
              TITULO: 'Involucrado',
            },
            COLUMNA8: {
              TITULO: FECHA_ASIGNACION,
            },
            COLUMNA9: {
              TITULO: FECHA_LIMITE,
            },
            COLUMNA10: {
              TITULO: 'Documento',
            },
            COLUMNA11: {
              TITULO: 'Correo',
            },
          },
        ],
        CONSTANTES: {
          FILTROS: {
            FOLIO: 'folio',
            INVOLUCRADO: 'numeroInvolucrado',
            ID_PROCESO: 'idProceso',
            TIPO_AVISO: 'idTipoTarea',
            TIPO_NEGOCIACION: 'idTipoNegociacion',
            FECHA_LIMITE: 'fechaLimite',
          },
        },
      },
      AGREGAR_CUMPLIMIENTO: {
        MIGA: 'Agregar Cumplimiento',
        URL: 'agregar-cumplimiento',
        CONSTANTES: {
          CAMPOS: {
            FOLIO: 'folio',
            CUMPLIMIENTO: 'cumplimiento',
            MOTIVOS_COMENTARIOS: 'motivosComentarios',
            ASIGNACION: 'asignacion',
            DESCRIPCION: 'descripcion',
            FECHA_LIMITE: 'limite',
            ARCHIVO: 'archivo',
          },
          PATHS: {
            TAREAS_POR_CUMPLIR: '/tareas-por-cumplir',
          },
        },
      },
      VALIDAR_CUMPLIMIENTO: {
        MIGA: 'Validar Cumplimiento de Tarea',
        URL: 'validacion',
        CONSTANTES: {
          CAMPOS: {
            FOLIO: 'folio',
            DESCRIPCION: 'descripcion',
            ASIGNACION: 'asignacion',
            FECHA_LIMITE: 'limite',
            FECHA_CUMPLIMIENTO: 'cumplimiento',
            ARCHIVO: 'pdf',
            MOTIVOS_COMENTARIOS: 'motivosComentarios',
          },
          PATHS: {
            TAREAS_POR_VALIDAR: '/tareas-por-validar',
          },
          ACCIONES: {
            ACEPTADO: 'aceptada',
            RECHAZADO: 'rechazada',
          },
          VALIDACION: {
            MOTIVOS_COMENTARIOS: 'Se requiere capturar el campo Motivo de rechazo/ Comentarios de Retroalimentación',
          },
        },
      },
      ASIGNACION_NOTARIO: {
        MIGA: 'Asignar Abogado',
        URL: 'asignar-abogado',
      },
      PREDICTAMEN: {
        MIGA: 'Predictamen',
        URL: 'predictamen',
      },
      VALIDACION_DOCUMENTOS: {
        MIGA: 'Validación de Documentos',
        URL: 'validacion-documentos',
        TABLAS: [
          {
            COLUMNA1: {
              TITULO: 'ID',
            },
            COLUMNA2: {
              TITULO: 'Documentos',
            },
            COLUMNA3: {
              TITULO: 'Archivos',
            },
            COLUMNA4: {
              TITULO: 'Estatus Jurídico',
            },
            COLUMNA5: {
              TITULO: 'Comentario Jurídico',
            },
            COLUMNA6: {
              TITULO: 'Historial',
            },
          },
        ],
        TABLASLICENCIASYPERMISOS: [
          {
            COLUMNA1: {
              TITULO: 'ID',
            },
            COLUMNA2: {
              TITULO: 'Licencia / Permiso',
            },
            COLUMNA3: {
              TITULO: 'Archivos',
            },
            COLUMNA4: {
              TITULO: 'Estatus Jurídico',
            },
            COLUMNA5: {
              TITULO: 'Motivo Rechazo',
            },
            COLUMNA6: {
              TITULO: 'Comentario Jurídico',
            },
            COLUMNA7: {
              TITULO: 'Acciones',
            },
            COLUMNA8: {
              TITULO: 'Expediente',
            },
          },
        ],
      },
      AYUDA: {
        MIGA: 'Ayuda',
        URL: 'ayuda',
      },
      NOTIFICACIONES: {
        MIGA: 'Notificaciones',
        URL: 'notificaciones',
      },
      PENDIENTES: {
        MIGA: 'Pendientes del Día',
        URL: 'pendientes',
      },
      DICTAMEN: {
        MIGA: 'Dictamen',
        URL: 'dictamen',
      },
      HISTORIAL: {
        MIGA: 'Suceso',
        URL: 'historico',
        HISTORIALNOTAS: {
          URL: 'historial-notas',
        },
        TABLAS: [
          {
            COLUMNA1: {
              TITULO: 'N°.',
            },
            COLUMNA2: {
              TITULO: 'Suceso',
            },
            COLUMNA3: {
              TITULO: 'Fecha',
            },
            COLUMNA4: {
              TITULO: 'Comentario',
            },
            COLUMNA5: {
              TITULO: 'Involucrado',
            },
            COLUMNA6: {
              TITULO: 'Estatus',
            },
            COLUMNA7: {
              TITULO: 'Acciones',
            },
          },
        ],
      },
      TAREAS_ASUNTO: {
        MIGA: 'Tareas de un Asunto',
        URL: 'tareas',
        TABLAS: [
          {
            COLUMNA1: {
              TITULO: 'Tarea',
            },
            COLUMNA2: {
              TITULO: 'Involucrado',
            },
            COLUMNA3: {
              TITULO: 'Fecha Asignación',
            },
            COLUMNA4: {
              TITULO: FECHA_LIMITE,
            },
            COLUMNA5: {
              TITULO: 'Fecha Cumplida',
            },
            COLUMNA6: {
              TITULO: 'Fecha Validación',
            },
            COLUMNA7: {
              TITULO: 'Estatus',
            },
            COLUMNA8: {
              TITULO: 'Tipo',
            },
            COLUMNA9: {
              TITULO: 'Acciones',
            },
          },
        ],
      },

      CIERRE_DE_PROCESO: {
        MIGA: 'Cerrar Asunto',
        URL: 'cierre-de-proceso',
        CONSTANTES: {
          TIPO_DOCUMENTO:
            '.doc, .docx, .docm, .dotx, .dotm, .xls, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xlam, .ppt, .pptx, .pptm, .potx, .potm, .ppam, .ppsx, .ppsm, .sldx, .sldm, .thmx, .pdf, .zip, .JPEG, .JPG, .RAW, .PNG, .gif, .asf, .avi, .mp4, .m4v, .mov, .mpg, .mpeg, .wmv, .aiff, .txt',
          TAMANO_ARCHIVO_INVALIDO: 'Tamaño Inválido.',
          DETALLE_TAMANO_ARCHIVO_INVALIDO: 'Tamaño Máximo de Carga {0}:',
          TIPO_ARCHIVO_INVALIDO: 'Extensión del Archivo Inválida',
          DETALLE_TIPO_ARCHIVO_INVALIDO: 'Tipos de archivos permitidos: {0}.',
          DETALLE_LIMITE_ARCHIVO: 'El límite es {0} máximo',
          LIMITE_ARCHIVO: 'Excede el máximo de archivos',
        },
      },
      INMUEBLES: {
        MIGA: 'Inmueble',
        URL: 'inmueble',
        CONSTANTES: {
          EXPRESIONES_REGULARES: {
            PATTERN_MONETARIO,
            PATTERN_M2: PATTERN_MONETARIO,
          },
          FECHAS: {
            DIAS_NOMBRES: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
            DIAS_NOMBRES_CORTOS: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            DIAS_NOMBRES_CORTOS_MIN: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            MESES_NOMBRES: [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ],
            MESES_NOMBRES_CORTOS: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            HOY: 'Hoy',
            BORRAR: 'Borrar',
            SEMANA: 'Sem',
          },
          TIPO_NEGOCIACION: {
            COMPRA: '2',
            PERMUTA: '5',
            RENTA: '3',
          },
          AVAMCE_PROCESAL: {
            ESCRITURACION: 70,
            ARRENDAMIENTO: 75,
          },
        },
        MENSAJES: {
          OBLIGATORIO_DESTINO: 'Selecciona una opción para el Destino',
          OBLIGATORIO_ESTATUS: 'Selecciona una opción para el Estatus',
          ACTUALIZACION_CORRECTA: 'Cambios guardados correctamente',
        },
      },
      ESTADO_PROCESAL: {
        MIGA: 'Estado Procesal',
        URL: 'estado-procesal',
        TABLAS: [
          {
            COLUMNA1: {
              TITULO: 'Nombre',
            },
            COLUMNA2: {
              TITULO: 'Porcentaje',
            },
            COLUMNA3: {
              TITULO: 'Tipo',
            },
            COLUMNA4: {
              TITULO: 'Disparador',
            },
            COLUMNA5: {
              TITULO: 'Responsable',
            },
            COLUMNA6: {
              TITULO: 'Tipo de Estado',
            },
            COLUMNA7: {
              TITULO: FECHA_ASIGNACION,
            },
            COLUMNA8: {
              TITULO: 'Días Transcurridos',
            },
            COLUMNA9: {
              TITULO: 'Días Vencidos',
            },
            COLUMNA10: {
              TITULO: 'Estatus',
            },
          },
        ],
      },
      CUMPLIMIENTO_REGULATORIO: {
        MIGA: 'Cumplimiento Regulatorio',
        URL: 'inmueble-form-cumplimiento',
        CONSTANTES: {
          EXPRESIONES_REGULARES: {
            PATTERN_NUMERO_SIN_SIGNO: '^[0-9]*$',
            PATTERN_M2: PATTERN_MONETARIO,
          },
          MENSAJES: {
            PROCESO_ASUNTO_INCORRECTO:
              'Para editar el inmueble el asunto debe ser de tipo Obra nueva, Remodelación o Inmueble en Operación.',
            PROCESO_ASUNTO_INCORRECTO_ALINEAMIENTO:
              'Solo está permitido editar el domicilio del inmueble si el inmueble se encuentra asociado a un asunto de Alineamiento y No. Oficial que este Activo',
          },
        },
      },
      REPORTES: {
        MIGA: 'Reportes',
        URL: 'reportes',
      },
      CATALOGOS: {
        MIGA: 'Catalogos',
        URL: 'catalogos',
        SUBMENUS: {
          CIUDAD: 'Ciudad',
          MUNICIPIO: 'Municipio',
          ESTADO: 'Estado',
          PAISES: 'Paises',
        },
        CONSTANTES: {
          EXPRESIONES_REGULARES: {
            PATTERN_CORREO: '[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}',
          },
        },
      },
      EXPENDIENTE_UNICO_INMUBLE: {
        MIGA: 'Consultar Inmueble',
        URL: 'EUI',
        MODULOS: {
          COSULTAR_INMUEBLE: {
            MIGA: 'Consultar',
            URL: 'consultar',
          },
          CONSULTAR_EXPEDIENTE: {
            MIGA: 'Expediente Único del Inmueble',
            URL: 'expediente',
          },
          CONSULTAR_INMUEBLES_RELACIONADOS: {
            MIGA: 'Inmuebles Relacionados',
            URL: 'inmuebles-relacionados',
          },
        },
      },
    },
  },
};

export const CONSTANTES_BOVEDA = {
  URL: 'boveda',
  PRESTAR: {
    URL: 'prestar-expediente-fisico-asunto',
  },
  CONSULTA: {
  URL: 'consulta-de-boveda',
  },
  MENSAJE_TITULO: 'Eliminar Expediente de la Ubicación',
  MENSAJE_DEVOLVER: 'El expediente físico del asunto {} ha sido devuelto a la Bóveda',
  MENSAJE_BORRAR_EXPEDIENTE:
    '<div class="mensaje">¿Está seguro que desea eliminar el expediente físico del asunto de esta ubicación?</div> <span class="advertencia">Esta acción no se podrá revertir</span>',
};

/**
 * Constantes para los identificadores de los tipos de proceso.
 * permuta y subdivision.
 * En futuros refactor agregar los faltantes.
 */
export const tiposProceso = {
  PERMUTA_ID: 5,
  SUBDIVISION_ID: 6,
};

/**
 * Constantes para throw error de servicios de autenticacion
 */
export const mensajesAutenticacion = {
  ERROR_DATOS_SSO: 'Error al validar el usuario actual',
  ERROR_INGRESO_HUELLA: 'Error al ingresar con huella',
  ERROR_HANDLER_AUTOMATICO: 'Error en redireccion a la intranet',
  ERROR_TOKEN_VIGENCIA: 'Error al Verificar el token',
};
