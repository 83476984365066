import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ConfirmationService } from 'primeng/api';
import { PrimeNgExportsModule } from '../prime-ng-exports/prime-ng-exports.module';
import { AlertaComponent } from './components/alerta/alerta.component';
import { ButtonComponent } from './components/button.component';
import { CardBodyComponent } from './components/card-body.component';
import { CardFooterComponent } from './components/card-footer.component';
import { CardHeaderComponent } from './components/card-header.component';
import { CardComponent } from './components/card.component';
import { GenericConfirmDialogComponent } from './components/generic-dialogs/generic-confirm-dialog.component';

@NgModule({
  declarations: [
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    ButtonComponent,
    AlertaComponent,
    GenericConfirmDialogComponent,
  ],
  imports: [CommonModule, RouterModule, AlertModule.forRoot(), PrimeNgExportsModule],
  exports: [
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    ButtonComponent,
    AlertaComponent,
    GenericConfirmDialogComponent,
  ],
  providers: [ConfirmationService],
})
export class GenericComponentsModule {}
