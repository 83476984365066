import {
  Component,
  ElementRef,
  Host,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  GenericAdvancedSearchModal,
  GenericDropdownInput,
  GenericDropdownOption,
  GenericFormElements,
  GenericTextInput,
} from '@app-shared/generic-reactive-forms/models/input-types';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

import { HttpParams } from '@angular/common/http';
import { MensajesGenerales } from '@app-services/constantes';
import { CiudadesService } from '@app-services/modules/Ciudades/ciudades.service';
import { DestinosService } from '@app-services/modules/Destinos/destinos.service';
import { EmpresasFilialesService } from '@app-services/modules/EmpresasFiliales/empresasfiliales.service';
import { Estados } from '@app-services/modules/Estados/estados';
import { EstadosService } from '@app-services/modules/Estados/estados.service';
import {
  InmuebleDataTable,
  InmueblesBusquedaParams,
  ResultadosBusquedaInmueblesDTO,
} from '@app-services/modules/Inmuebles/inmuebles';
import { InmueblesService } from '@app-services/modules/Inmuebles/inmuebles.service';
import { MunicipioService } from '@app-services/modules/Municipio/municipio.service';
import { AbstractGenericInputComponent } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/abstract-generic-input.component';
import { genericRestrictHandler } from '@app-shared/generic-reactive-forms/components/abstract-generic-input/generic-restrict-handler';
import { GenericFormService } from '@app-shared/generic-reactive-forms/services/generic-form.service';
import { GenericTableComponent } from '@app-shared/generic-tables/components/generic-table.component';
import { TableConfiguration, TableQueryParams, TableResponse } from '@app-shared/generic-tables/models/table-model';
import { prepareTableResponse } from '@app-shared/generic-tables/services/generic-table-manager.service';
import { IResponsePagedList, SpringPage } from '@app-shared/models/IResponse';

/**
 * Tiempo de espera entre escritura y busqueda.
 */
const DEBOUNCETIME = 1000;

interface EmitterSendData {
  event: Event;
  data: any;
}

@Component({
  selector: 'app-generic-advanced-search-modal',
  templateUrl: './generic-advanced-search-modal.component.html',
  styleUrls: ['./generic-advanced-search-modal.component.scss'],
})
export class GenericAdvancedSearchModalComponent
  extends AbstractGenericInputComponent<GenericAdvancedSearchModal, FormControl>
  implements OnInit, OnDestroy {
  /**
   * Bandera para indicar si es busquedad de JC
   */
  public busquedaJCValue = false;

  /**
   * Bandera para indicar si debe de mostrarse o no el modal
   */
  public showModal: boolean;

  /**
   * FormGroup base del modal.
   */
  public filterFormgroup: FormGroup;

  /**
   * Referencia a tabla generica
   */
  @ViewChild('table', { read: GenericTableComponent, static: false })
  public table: GenericTableComponent;

  /**
   * Input generico de configuración.
   */
  @Input()
  public config: GenericAdvancedSearchModal;

  /**
   * Filtro seleccionado en pantalla.
   */
  public filtroSeleccionado: HttpParams;

  /**
   * Bandera que indica si debe mostrar tabla.
   */
  public showTable = false;

  /**
   * Viewref a input de busqueda.
   */
  @ViewChild('input', { static: false }) public inputRef: ElementRef<HTMLInputElement>;

  /**
   * Valor interno del campo de lectura.
   */
  public innerValue: string = null;
  /**
   * listado de empresas filiales
   */
  public empresasFiliales: GenericDropdownOption[] = [];
  /**
   * Listado de Destino / Formato
   */
  public destinos: GenericDropdownOption[] = [];

  /**
   * Key down event subject.
   */
  protected keyDown$ = new Subject<Event>();

  /**
   * Selectvalue from modal event subject.
   */
  protected selectValue$ = new Subject<EmitterSendData>();

  /**
   * Busqueda manual event subject.
   */
  protected search$ = new Subject<Event>();

  /**
   * Título del modal.
   */
  protected tituloModal = 'Buscar Inmuebles';

  /**
   * Listado de estados.
   */
  private estados: GenericDropdownOption[] = [];

  /**
   * Listado de ciudades.
   */
  private ciudades: GenericDropdownOption[] = [];

  /**
   * Listado de municipios.
   */
  private municipios: GenericDropdownOption[] = [];

  /**
   * Ultima respuesta del servicio.
   */
  private response: SpringPage<ResultadosBusquedaInmueblesDTO>;

  /**
   * Constructor para GenericInputSearchComponent.
   */
  public constructor(
    @Host() @SkipSelf() @Optional() protected formRef: FormGroupDirective,
    @Optional() protected genericFormService: GenericFormService,
    private estadosService: EstadosService,
    private inmueblesService: InmueblesService,
    private ciudadesService: CiudadesService,
    private municipioService: MunicipioService,
    private formBuilder: FormBuilder,
    private empresasFilialesService: EmpresasFilialesService,
    private destinosService: DestinosService
  ) {
    super(formRef, genericFormService);

    this.internalValidators.push(searchValidatorFactory(this));
  }

  /**
   * Setter de booleano que indica si la busuqeda de inmueble es para JC
   */
  @Input('busquedaJC')
  public set busquedaJC(attribute: boolean | '') {
    this.busquedaJCValue = attribute === '' || attribute;
  }

  /**
   * Booleano que indica si se debe mostrar el campo de búsqueda.
   */
  public get showSearch(): boolean {
    return this.config.showSearch ?? true;
  }

  /**
   * Booleano que indica si se debe mostrar el icono de busqueda.
   */
  public get showSearchButton(): boolean {
    return this.config.showSearchButton !== undefined ? this.config.showSearchButton : true;
  }

  /**
   * Atributo autocomplete.
   */
  public get autocomplete(): string {
    return this.config?.autocomplete ?? 'off';
  }

  /**
   * Atributo spellcheck.
   */
  public get spellcheck(): boolean {
    return this.config?.spellcheck ?? false;
  }

  /**
   * Retorna el valor asociado al input.
   */
  public get value(): FormControl {
    return this.rootFormGroup.get(this.fieldConfig.name + 'value') as FormControl;
  }

  /**
   * Retorna icono de busqueda.
   */
  public get icon(): string {
    return this.fieldConfig?.icon ?? 'fa fa-search';
  }

  /**
   * Retorna propiedad maxlength.
   */
  public get maxlength(): number {
    return this.fieldConfig?.maxlength;
  }

  /**
   * Retorna propiedad minlength.
   */
  public get minlength(): number {
    return this.fieldConfig?.minlength;
  }

  /**
   * Retorna propiedad maxlength.
   */
  public get min(): number {
    return this.fieldConfig?.min;
  }

  /**
   * Retorna propiedad minlength.
   */
  public get max(): number {
    return this.fieldConfig?.max;
  }

  /**
   * Retorna propiedad minlength.
   */
  public get step(): number {
    return this.fieldConfig?.step;
  }

  /**
   * Retorna propiedad pattern.
   */
  public get pattern(): RegExp {
    return this.fieldConfig?.pattern;
  }

  /**
   * Retorna propiedad pattern.
   */
  public get type(): string {
    return this.fieldConfig?.type || 'text';
  }

  /**
   * Clase CSS para el campo de busqueda.
   */
  public get searchClasses(): object {
    return {
      'ng-invalid': this.control?.invalid,
      'ng-dirty': this.control?.invalid,
      cursor: !this.fieldConfig?.disabled,
    };
  }

  /**
   * Getter para titulo que tendrá el modal.
   */
  public get tituloHeaderModal(): string {
    return this.tituloModal;
  }

  /**
   * Placeholcer para segundo campo..
   */
  public get secondFieldPlaceholder(): string {
    return (
      this.fieldConfig?.secondFieldPlaceholder ?? (this.fieldConfig?.label ? `Buscar ${this.fieldConfig?.label}` : null)
    );
  }

  /**
   * Configuración de filtros de busqueda.
   */
  public get configFilter(): GenericFormElements {
    return {
      nombreInmueble: {
        name: 'nombreInmueble',
        label: 'Nombre de Inmueble',
        restrict: {
          maxlength: 255,
        },
        maxlength: 255,
      } as GenericTextInput,
      estado: {
        name: 'estado',
        label: 'Estado',
        options: this.estados,
        onChange: (e, utils) => {
          const value = this.filterFormgroup.get('estado')?.value;
          const ciudadControl = this.filterFormgroup.get('ciudad');
          const municipioControl = this.filterFormgroup.get('municipio');

          ciudadControl?.setValue(null);
          municipioControl?.setValue(null);

          if (!value) {
            this.ciudades = [];
            this.municipios = [];
            ciudadControl?.disable();
            municipioControl?.disable();
            return;
          }

          this.municipioService
            .getMunicipioIDEstado(value)
            .pipe(takeUntil(this.destroySubject$.asObservable()))
            .subscribe((r) => {
              if (!r || !r.datos) {
                this.ciudades = [];
                this.municipios = [];
                return;
              }
              municipioControl?.enable();
              const mapeo = r.datos.response;
              this.municipios = mapeo.map(
                (i) =>
                  ({
                    key: i.id?.toString(),
                    value: i.nombre,
                  } as GenericDropdownOption)
              );
            });
        },
      } as GenericDropdownInput,
      municipio: {
        name: 'municipio',
        label: 'Municipio',
        options: this.municipios,
        disabled: true,
        onChange: (e, utils) => {
          const valueEstado = this.filterFormgroup.get('estado')?.value;
          const valueMunicipio = this.filterFormgroup.get('municipio')?.value;
          const ciudadControl = this.filterFormgroup.get('ciudad');
          ciudadControl?.setValue(null);

          if (!valueEstado || !valueMunicipio) {
            this.ciudades = [];
            ciudadControl?.disable();
            return;
          }

          this.ciudadesService
            .getMunicipioIDEstadoIDMunicipio(valueEstado, valueMunicipio)
            .pipe(takeUntil(this.destroySubject$.asObservable()))
            .subscribe((r) => {
              if (!r || !r.datos) {
                this.ciudades = [];
                return;
              }
              ciudadControl?.enable();
              const mapeo = r.datos.response;
              this.ciudades = mapeo.map(
                (i) =>
                  ({
                    key: i.id?.toString(),
                    value: i.nombre,
                  } as GenericDropdownOption)
              );
            });
        },
      } as GenericDropdownInput,
      ciudad: {
        name: 'ciudad',
        label: 'Ciudad',
        options: this.ciudades,
        disabled: true,
      } as GenericDropdownInput,
      empresaFilial: {
        name: 'empresaFilial',
        label: 'Empresa Filial',
        options: this.empresasFiliales,
      } as GenericDropdownInput,
      destino: {
        name: 'destino',
        label: 'Destino / Formato',
        placeholder: 'Seleccionar Destino Final',
        options: this.destinos,
      } as GenericDropdownInput,
    };
  }

  /**
   * Configuracion para la tabla generica.
   */
  public get configTabla(): TableConfiguration {
    if (this.busquedaJCValue) {
      return {
        columns: [
          {
            name: 'ID Tienda / Centro',
            field: 'numTiendaCentro',
          },
          {
            name: 'Estatus Colectivo',
            field: 'stColectivo',
          },
          {
            name: 'Destino / Formato',
            field: 'destino',
          },
          {
            name: 'Estado',
            field: 'estado',
          },
          {
            name: 'Municipio',
            field: 'municipio',
          },
          {
            name: 'Ciudad',
            field: 'ciudad',
          },
          {
            name: 'Nombre del Inmueble',
            field: 'nomInmueble',
          },
          {
            name: 'Domicilio',
            field: 'domicilio',
          },
        ],
        actions: [
          {
            title: 'Seleccionar Inmueble',
            icon: 'fas fa-plus',
            variant: 'success',
            onClick: (_, action, data) => {
              this.onSelectRow(_, data);
            },
          },
        ],
        params: null,
        paginator: true,
        dataHandler: (params: TableQueryParams) => {
          return this.cargarDatosTabla(params);
        },
      };
    } else {
      return {
        columns: [
          {
            name: 'ID Tienda / Centro',
            field: 'numTiendaCentro',
          },
          {
            name: 'Estado',
            field: 'estado',
          },
          {
            name: 'Municipio',
            field: 'municipio',
          },
          {
            name: 'Ciudad',
            field: 'ciudad',
          },
          {
            name: 'Colonia',
            field: 'colonia',
          },
          {
            name: 'Entre Calle',
            field: 'entreCalle',
          },
          {
            name: 'Y Calle',
            field: 'ycalle',
          },
          {
            name: 'Código Postal',
            field: 'codigoPostal',
          },
        ],
        actions: [
          {
            title: 'Seleccionar Inmueble',
            icon: 'fas fa-plus',
            variant: 'success',
            onClick: (_, action, data) => {
              this.onSelectRow(_, data);
            },
          },
        ],
        params: null,
        paginator: true,
        dataHandler: (params: TableQueryParams) => {
          return this.cargarDatosTabla(params);
        },
      };
    }
  }

  /**
   * Funcion para setear el valor asociado al input.
   */
  public setValue(value: string) {
    this.innerValue = value;

    this.value.setValue(this.innerValue);
  }

  public focus(): void {
    if (!this.control.touched) {
      return;
    }
    this.inputRef?.nativeElement?.focus();
  }

  /**
   * On click event handler.
   */
  public onClick(e): void {
    if (this.fieldConfig?.onClick) {
      this.fieldConfig?.onClick(e, {
        setValue: (value) => this.control.setValue(value),
        setAssociatedValue: this.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * On focus event handler.
   */
  public onFocus(e): void {
    if (this.fieldConfig?.onFocus) {
      this.fieldConfig?.onFocus(e, {
        setValue: (value) => this.control.setValue(value),
        setAssociatedValue: this.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * On blur event handler.
   */
  public onBlur(e): void {
    if (this.fieldConfig?.onBlur) {
      this.fieldConfig?.onBlur(e, {
        setValue: (value) => this.control.setValue(value),
        setAssociatedValue: this.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * On input event handler.
   */
  public onInput(e): void {
    if (this.fieldConfig?.restrict) {
      genericRestrictHandler<KeyboardEvent>(this.fieldConfig.restrict, e, this.control);
    }

    if (this.fieldConfig?.onInput) {
      this.fieldConfig?.onInput(e, {
        setValue: (value) => this.control.setValue(value),
        setAssociatedValue: this.setValue.bind(this),
        disable: this.control.disable.bind(this.control),
        enable: this.control.enable.bind(this.control),
        focus: this.focus.bind(this),
        validate: () => this.control.updateValueAndValidity(),
      });
    }
  }

  /**
   * On blur event handler.
   */
  public onChange(e): void {
    this.search$.next(e);
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.construyeFormulario();
    this.cargaCatalogos();

    this.rootFormGroup.addControl(
      this.fieldConfig.name + 'value',
      new FormControl({
        value: null,
        disabled: true,
      })
    );

    this.setValue(null);

    const actions = {
      setValue: (value) => this.control.setValue(value),
      setAssociatedValue: this.setValue.bind(this),
      disable: this.control.disable.bind(this.control),
      enable: this.control.enable.bind(this.control),
      focus: this.focus.bind(this),
      validate: () => this.control.updateValueAndValidity(),
      getCurrentValue: () => {
        return this.control.value;
      },
      getControl: () => {
        return this.control;
      },
    };

    const handleChange = (e: Event) => {
      this.control.disable();
      this.fieldConfig?.onChange(e, actions);
    };

    // Inicializamos handler `onChange` para busquedas.
    if (this.fieldConfig?.onChange) {
      // Realizamos onChange en busqueda por valueChanges.
      this.control.valueChanges
        .pipe(debounceTime(DEBOUNCETIME), distinctUntilChanged())
        .pipe(takeUntil(this.destroySubject$.asObservable()))
        .subscribe((e: Event) => handleChange(e));
      // Realizamos onChange en busqueda por botón.
      this.search$
        .asObservable()
        .pipe(debounceTime(DEBOUNCETIME))
        .pipe(takeUntil(this.destroySubject$.asObservable()))
        .subscribe((e: Event) => handleChange(e));
    }

    if (this.fieldConfig?.onKeyDown) {
      this.keyDown$
        .asObservable()
        .pipe(takeUntil(this.destroySubject$.asObservable()))
        .subscribe((e: Event) => this.fieldConfig?.onKeyDown(e, actions));
    }

    if (this.fieldConfig.onSelectValue) {
      this.selectValue$
        .asObservable()
        .pipe(takeUntil(this.destroySubject$.asObservable()))
        .subscribe((e) => this.fieldConfig?.onSelectValue(e?.event, actions, e?.data));
    }
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy() {
    super.ngOnDestroy();
    this.keyDown$.complete();
    this.selectValue$.complete();
  }

  /**
   * Evento en botón cancelar.
   * @param e
   */
  public cancelar(e): void {
    this.showModal = false;
  }

  /**
   * Asigna los nuevos filtros seleccionados
   */
  public onFilter(): void {
    this.filtroSeleccionado = new HttpParams();

    if (this.filterFormgroup.get('nombreInmueble')?.value?.trim()) {
      this.filtroSeleccionado = this.filtroSeleccionado.append(
        'nombre',
        this.filterFormgroup.get('nombreInmueble')?.value.trim()
      );
    }

    if (this.filterFormgroup.get('estado')?.value) {
      this.filtroSeleccionado = this.filtroSeleccionado.append('idEstado', this.filterFormgroup.get('estado')?.value);
    }

    if (this.filterFormgroup.get('ciudad')?.value) {
      this.filtroSeleccionado = this.filtroSeleccionado.append('idCiudad', this.filterFormgroup.get('ciudad')?.value);
    }

    if (this.filterFormgroup.get('municipio')?.value) {
      this.filtroSeleccionado = this.filtroSeleccionado.append(
        'idMunicipio',
        this.filterFormgroup.get('municipio')?.value
      );
    }

    this.table?.manager.reset();
  }

  /**
   * Trigger para keypress de Escape.
   * @param event
   */
  @HostListener('keydown', ['$event'])
  public keyEvent(e: KeyboardEvent) {
    this.keyDown$.next(e);
  }

  /**
   * Abre modal de busqueda avanzada.
   */
  public showModalAdvancedSearch(e): void {
    this.showModal = true;
    this.filterFormgroup.reset();
  }

  /**
   * Evento onkeypress para input busqueda avanzada
   * @param e
   * @returns
   */
  public onKeypessAdnvancedSearch(e): void {
    e.preventDefault();
  }

  /**
   * Accion al cerrar formulario
   */
  public closeModal(): void {
    this.filterFormgroup.reset();
    this.table.manager.clearData();
  }

  /**
   * Carga catálogos en pantalla.
   */
  private cargaCatalogos(): void {
    this.estadosService
      .getEstadoIDPais(MensajesGenerales.IDS.ID_PAIS_MEXICO)
      .pipe(take(1))
      .subscribe((r) => {
        if (!r || !r?.estado) {
          return;
        }

        this.estados = r.datos?.response?.map(
          (t: Estados) => ({ key: t.id.toString(), value: t.nombre } as GenericDropdownOption)
        );
      });

    if (this.busquedaJCValue) {
      forkJoin([
        this.empresasFilialesService.getEmpresasFiliales().pipe(map((r) => r?.datos?.response)),
        this.destinosService.obtenerDestinos().pipe(map((r) => r?.datos?.response)),
      ]).subscribe((response) => {
        const [empresas, destinos] = response;

        this.empresasFiliales = empresas.map(
          (val) => ({ key: val.id.toString(), value: val.nombre } as GenericDropdownOption)
        );
        this.destinos = destinos.map((val) => ({ key: val.id.toString(), value: val.nombre } as GenericDropdownOption));
      });
    }
  }

  /**
   * Construye formulario de filtros
   */
  private construyeFormulario(): void {
    this.filterFormgroup = this.formBuilder.group({});
  }

  /**
   * Realiza el llamado a la api para obtener las notificaciones.
   * @param filtro
   * @param params
   * @returns datos tabla
   */
  private cargarDatosTabla(params: TableQueryParams): Observable<TableResponse> {
    if (!this.filtroSeleccionado) {
      return of(null);
    }

    if (this.busquedaJCValue) {
      return this.inmueblesService
        .busquedaNuevos(this.obtenerFiltros({ page: params.page ?? 0, size: params.limit }))
        .pipe(
          take(1),
          map((respuesta) => {
            return this.prepararRespuestaParaTablaJC(respuesta, params);
          })
        );
    }

    return this.inmueblesService
      .obtieneInmueblesPorNombre(this.filtroSeleccionado?.toString(), params.page, params.limit)
      .pipe(
        take(1),
        map((respuesta) => {
          return this.prepararRespuestaParaTabla(respuesta, params);
        })
      );
  }

  /**
   * Obtiene los valores de filtrado para la busqueda de inmuebles
   */
  private obtenerFiltros(params?: { page: number; size: number }): InmueblesBusquedaParams {
    const nomInmueble = this.filterFormgroup.get('nombreInmueble')?.value;
    const estado = this.filterFormgroup.get('estado')?.value;
    const ciudad = this.filterFormgroup.get('ciudad')?.value;
    const municipio = this.filterFormgroup.get('municipio')?.value;
    const destino = this.filterFormgroup.get('destino')?.value;
    const filiales = this.filterFormgroup.get('empresaFilial')?.value;
    return {
      ...(nomInmueble && { nombreInmueble: nomInmueble }),
      ...(estado && { idEstado: estado }),
      ...(ciudad && { idCiudad: ciudad }),
      ...(municipio && { idMunicipio: municipio }),
      ...(destino && { destinoInmueble: destino }),
      ...(filiales && { idEmpresa: filiales }),
      page: params?.page ?? 0,
      size: params?.size ?? 10,
    } as InmueblesBusquedaParams;
  }

  /**
   * Preparars respuesta para tabla
   * @param respuesta
   * @param params
   * @returns TableResponse
   */
  private prepararRespuestaParaTabla(
    respuesta: IResponsePagedList<InmuebleDataTable>,
    params: TableQueryParams
  ): TableResponse {
    if (respuesta.estado) {
      return prepareTableResponse(params, { totalItems: respuesta.datos.totalRegistros }, respuesta.datos.inmuebles);
    } else {
      return prepareTableResponse(params, { totalItems: 0 }, []);
    }
  }

  /**
   * Preparars respuesta para tabla de JC
   * @param respuesta
   * @param params
   * @returns TableResponse
   */
  private prepararRespuestaParaTablaJC(respuesta: IResponsePagedList<any>, params: TableQueryParams): TableResponse {
    if (respuesta?.estado) {
      respuesta?.datos?.response?.content.forEach((i) => {
        const data = [
          i.nomVialidad,
          i.numExterior,
          i.numInterior ? `Interior ${i.numInterior}` : false,
          i.desColonia,
          i.codigoPostal,
        ].filter(Boolean);
        i.domicilio = data.join(', ');
      });
      return prepareTableResponse(
        params,
        { totalItems: respuesta?.datos?.response?.totalElements },
        respuesta?.datos?.response?.content
      );
    } else {
      return prepareTableResponse(params, { totalItems: 0 }, []);
    }
  }

  /**
   * Evento al seleccionar renglón de la tabla.
   * @param data
   */
  private onSelectRow(e, data): void {
    this.selectValue$.next({ event: e, data } as EmitterSendData);
    this.showModal = false;
  }
}

/**
 * Fábrica para validadador
 * @param component
 * @returns
 */
function searchValidatorFactory(component: GenericAdvancedSearchModalComponent): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (control.disabled) {
      return null;
    }

    if ((control.value === null || control.value === '') && component.innerValue === null) {
      return null;
    }

    if (control.value !== null && component.innerValue === null) {
      return {
        invalid: true,
      };
    }

    return null;
  };
}
