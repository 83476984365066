import { Directive, Input, TemplateRef } from '@angular/core';

// Deshabilitamos regla para nombrao de direciva.
// tslint:disable: directive-selector

@Directive({
  selector: '[tableTemplate]',
})
export class TableTemplateDirective {
  /**
   * Nombre del template
   */
  @Input()
  public name = '';

  /**
   * Constructor de directiva `TableTemplateDirective`.
   */
  constructor(public readonly template: TemplateRef<any>) {}
}
