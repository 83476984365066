import { AbstractType, Injectable, InjectionToken, Injector, Type } from '@angular/core';

/**
 * Servicio para provisionamiento de dependencias inyectables para
 * el decorador @see src\app\shared\filtro-datos\filtrodatos.decorator.ts
 */
@Injectable()
export class FiltrodatosService {
  /**
   * Referencia local del injector de dependencias.
   */
  private static injector: Injector;

  /**
   * Constructor para clase `FiltrodatosService`.
   */
  public constructor(injector: Injector) {
    FiltrodatosService.injector = injector;
  }

  /**
   * Getter para obtener servicios del injector de dependecias.
   */
  public static getService<T>(token: Type<T> | InjectionToken<T> | AbstractType<T>): T {
    if (!FiltrodatosService.injector) {
      throw new Error('FiltrodatosService no ha sido inicializado');
    }
    return FiltrodatosService.injector.get(token);
  }
}
