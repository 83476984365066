<app-card-body>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-12 title">
        Comentario recibido por el gestor de la Licencia/Permiso desde el sistema IFS (SUNO)
      </div>
      <div class="col-md-12">
        <app-generic-input-textarea [config]="config.comentario"> </app-generic-input-textarea>
      </div>
    </div>
  </form>
</app-card-body>
<app-card-footer>
  <ng-container right>
    <app-button variant="primary" (onClick)="cancelar()"> <i class="fa fa-check-circle"></i> Cerrar </app-button>
  </ng-container>
</app-card-footer>
