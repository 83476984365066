import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GenericComponentsModule } from '@app-shared/generic-components/generic-components.module';

// Import components
import {
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  AppSidebarNavComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent,
} from './components';
import { FullLayoutComponent } from './full-layout.component';
const LAYOUT_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  AppSidebarNavComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent,
];

// Import global directives
import {
  AsideToggleDirective,
  BrandMinimizeDirective,
  MobileSidebarToggleDirective,
  NavDropdownDirective,
  NavDropdownToggleDirective,
  ReplaceDirective,
  SidebarMinimizeDirective,
  SidebarOffCanvasCloseDirective,
  SidebarToggleDirective,
} from './directives';
const LAYOUT_DIRECTIVES = [
  AsideToggleDirective,
  NavDropdownDirective,
  NavDropdownToggleDirective,
  ReplaceDirective,
  SidebarMinimizeDirective,
  SidebarOffCanvasCloseDirective,
  BrandMinimizeDirective,
  SidebarToggleDirective,
  MobileSidebarToggleDirective,
];

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

// Import Own Resources
import { CommonsModule } from '@app-shared/commons/commons.module';
import { SharedModule } from '@app-shared/shared.module';
import { MenuService } from 'src/app/services/menu.service';
import { SafeResourceModule } from 'src/app/shared-modules/pipes/safe-resource/safe-resource.module';

@NgModule({
  declarations: [FullLayoutComponent, ...LAYOUT_COMPONENTS, ...LAYOUT_DIRECTIVES],
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    SafeResourceModule,
    GenericComponentsModule,
    CommonsModule,
    SharedModule,
  ],
  exports: [FullLayoutComponent],
  providers: [MenuService],
})
export class FullLayoutModule {}
